import { getCuts } from "@/api/cuts"
import { getTsisProductMetrics } from "@/api/tsis_product_metrics"
import { getTsisUniqueRespondents } from "@/api/tsis_unique_respondents"
import {
  FilterGroupKeys,
  FilterList,
  FilterListDropdown,
  FilterListItem,
  SurveyDates,
  getDisplayToggleState,
  useFilterGroupStore,
} from "@/components/filters"
import { WIDGET_ASPECT_RATIO, Widget, WidgetPropsBase } from "@/components/widget"
import { WithAspectRatio } from "@/components/with_aspect_ratio"
import { useChartLoading } from "@/hooks/use_chart_loading"
import { useDefaultFilter, useFilter } from "@/hooks/use_filter"
import { useSurveyDates } from "@/hooks/use_survey_dates"
import { HighchartsReact as HC, Highcharts, defaultChartOptions } from "@/lib/highcharts"
import { Skeleton } from "@/ui/skeleton"
import { ArrowRightIcon, BarChartIcon } from "@radix-ui/react-icons"
import { useQueries } from "@tanstack/react-query"
import { Point } from "highcharts"
import { floor, groupBy, last, round } from "lodash"
import React, { useEffect, useState } from "react"

const DISPLAY_FILTER_OPTIONS: FilterListItem[] = [
  { id: "breakdown", name: "Breakdown" },
  { id: "net-score", name: "Net Score & Distribution" },
  { id: "pervasion", name: "Pervasion" },
]

export const NetScoreAndAnswerDistributionWidget = ({ ...props }: WidgetPropsBase) => {
  return (
    <Widget
      displayFilterToggles={DISPLAY_FILTER_OPTIONS}
      ignoredGlobalFilters={["citations", "sectors"]}
      componentKey={"net_score_answer_distribution"}
      {...props}
    >
      <Widget.PrimaryFilters>
        <NetScoreAndAnswerDistributionPrimaryFilters />
      </Widget.PrimaryFilters>

      <Widget.SecondaryFilters>
        <NetScoreAndAnswerDistributionSecondaryFilters hiddenFilters={props.hiddenFilters} />
      </Widget.SecondaryFilters>

      <Widget.Body>
        <Content renderAspectRatioContainer={props.renderAspectRatioContainer} />
        {props.children}
      </Widget.Body>
    </Widget>
  )
}

const NetScoreAndAnswerDistributionPrimaryFilters = () => {
  const companies = useFilter("companies", { returnType: "multiple" })
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)

  return (
    <>
      <FilterList
        name="Company"
        filterGroupId="companies"
        endpoint="/api/v1/companies"
        enableMultiRowSelection={false}
        onHandleFilterChange={(filterGroupId, items) => {
          updateFilterGroups({
            [filterGroupId]: {
              name: "Company",
              selectedItems: items,
            },
            products: {
              name: "Products",
              selectedItems: [],
            },
          })
        }}
      />
      <ArrowRightIcon className="hidden w-4 h-4 md:block text-muted-foreground align-self-center" />
      <FilterList
        name="Products"
        filterGroupId="products"
        endpoint="/api/v1/products"
        dependencies={["companies"]}
        params={{
          companies,
        }}
        formatter={(item) => ({
          ...item,
          subtitle: item.sector.name,
        })}
      />
    </>
  )
}

const NetScoreAndAnswerDistributionSecondaryFilters = ({ hiddenFilters }: Pick<WidgetPropsBase, "hiddenFilters">) => {
  const filters: Array<[FilterGroupKeys, React.ReactNode]> = [
    ["survey_dates", <SurveyDates mode={SURVEY_DATE_MODE} size="sm" />],
    [
      "cuts",
      <FilterListDropdown
        className="w-full text-sm"
        name="Cut"
        text="Cut"
        size="sm"
        filterGroupId="cuts"
        endpoint="/api/v1/cuts"
        enableMultiRowSelection={false}
      />,
    ],
  ]

  const filterComponents = filters
    .filter(([key, _component]) => !hiddenFilters?.includes(key))
    .map(([key, component]) => <React.Fragment key={key}>{component}</React.Fragment>)

  return <div className="flex items-center flex-grow space-x-4">{filterComponents}</div>
}

const SURVEY_DATE_MODE = "multiple"
const METRIC_SERIES: Highcharts.SeriesOptionsType[] = [
  {
    id: "adoption_percentage",
    name: "Adoption %",
    color: "#2E7D32",
    type: "column",
  },
  {
    id: "increase_percentage",
    name: "Increase %",
    color: "#C8E6C9",
    type: "column",
  },
  {
    id: "flat_percentage",
    name: "Flat %",
    color: "#BDBDBD",
    type: "column",
  },
  {
    id: "decrease_percentage",
    name: "Decrease %",
    color: "#FFCDD2",
    type: "column",
  },
  {
    id: "replacing_percentage",
    name: "Replace %",
    color: "#C62828",
    type: "column",
  },
  {
    id: "net_score",
    name: "Net Score",
    color: "#2196F3",
    type: "line",
    yAxis: 1,
  },
]

function Content(props: Pick<WidgetPropsBase, "renderAspectRatioContainer">) {
  const { renderAspectRatioContainer } = props
  const display = useFilterGroupStore((s) => s.display)
  const company = useFilter("companies", { returnType: "single" })
  const products = useFilter("products", { returnType: "multiple" })
  const { value: cut } = useDefaultFilter("cuts", {
    queryFn: getCuts,
    select: (data: any) => data.filter((item: any) => item.name === "All Respondents"),
    returnType: "single",
  })

  const { value: surveys, isLoading: surveyIsLoading } = useSurveyDates(SURVEY_DATE_MODE)

  const widgetEnabled = !!company && !!cut && !!surveys.length && !surveyIsLoading
  const [uniqueRespondents, productMetrics] = useQueries({
    queries: [
      {
        queryKey: ["tsis_unique_respondents", cut, company, products, surveys],
        queryFn: () => {
          return getTsisUniqueRespondents({
            cut,
            companies: company,
            metrics: null,
            products,
            sectors: null,
            surveys,
          })
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        enabled: widgetEnabled,
      },
      {
        queryKey: ["tsis_product_metrics", company, cut, products, surveys],
        queryFn: () => {
          return getTsisProductMetrics({
            citations: null,
            company,
            cut,
            sector: null,
            products,
            surveys,
          })
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        enabled: widgetEnabled,
      },
    ],
  })

  const uniqueRespondentBySurvey = uniqueRespondents.data
  const metricsGroupedBySurvey = groupBy(productMetrics.data, "survey.short_name")

  const isLoading = uniqueRespondents.isLoading || productMetrics.isLoading

  if (!company) {
    return (
      <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
        <div className="h-full flex flex-col items-center justify-center">
          <BarChartIcon className="h-48 w-48 text-muted" />
          <p className="text-muted-foreground text-sm">Please select a company to view the net score and answer distribution chart</p>
        </div>
      </WithAspectRatio>
    )
  }

  if (productMetrics.data?.length === 0) return <Widget.Empty />

  const showBreakdown = getDisplayToggleState(display?.selectedItems, "breakdown", true)
  const showNetScore = getDisplayToggleState(display?.selectedItems, "net-score", true)
  const showPervasion = getDisplayToggleState(display?.selectedItems, "pervasion", true)

  return (
    <>
      {showBreakdown && (
        <Breakdown
          metricsGroupedBySurvey={metricsGroupedBySurvey}
          uniqueRespondentsBySurvey={uniqueRespondentBySurvey}
          loading={isLoading}
        />
      )}

      <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer} asChild>
        <div className="relative flex flex-col justify-between flex-1 mt-2">
          {showNetScore && (
            <div className="relative flex-1 basis-8/12 min-h-80">
              <SubsampleCandlestickChart
                loading={isLoading}
                metricsGroupedBySurvey={metricsGroupedBySurvey}
                uniqueRespondentsBySurvey={uniqueRespondentBySurvey}
              />
            </div>
          )}
          {showPervasion && (
            <div className="relative flex-1 basis-4/12 min-h-80">
              <PervasionChart metricsGroupedBySurvey={metricsGroupedBySurvey} loading={isLoading} />
            </div>
          )}
        </div>
      </WithAspectRatio>
    </>
  )
}

interface BreakdownProps {
  metricsGroupedBySurvey: Record<string, any[]>
  uniqueRespondentsBySurvey: any[]
  loading?: boolean
}

function Breakdown(props: BreakdownProps) {
  const { metricsGroupedBySurvey, uniqueRespondentsBySurvey, loading = true } = props

  const latestSurvey = last(uniqueRespondentsBySurvey)
  const metrics = metricsGroupedBySurvey[latestSurvey?.survey_short_name as string] || []
  const formattedDate = latestSurvey?.survey_short_name || "N/A"
  const uniqueRespondents = latestSurvey?.unique_respondents || 0

  return (
    <div className="flex-none flex flex-col lg:flex-row gap-3 mt-2">
      {loading ?
        Array.from({ length: 4 }).map((_, index) => (
          <Skeleton key={index} className="flex-1 rounded bg-slate-100 p-3 text-sm space-y-2 h-32" />
        ))
      : <>
          <div className="flex-1 rounded bg-slate-100 p-3 text-sm space-y-2">
            <div className="flex justify-between">
              <p className="text-slate-500">Citation Weighted Net Score</p>
              <p className="text-black font-semibold text-nowrap">{formattedDate}</p>
            </div>
            <p className="text-black text-xl font-semibold">{round(citationWeightedAverage(metrics, "net_score"), 2)}%</p>
          </div>
          <div className="flex-1 rounded bg-slate-100 p-3 text-sm space-y-2">
            <div className="flex justify-between">
              <p className="text-slate-500">Citation Weighted Pervasion</p>
              <p className="text-black font-semibold text-nowrap">{formattedDate}</p>
            </div>
            <p className="text-black text-xl font-semibold">{round(citationWeightedAverage(metrics, "pervasion_of_sector"), 1)}%</p>
          </div>
          <div className="flex-1 rounded bg-slate-100 p-3 text-sm space-y-2">
            <div className="flex justify-between">
              <p className="text-slate-500">Unique Respondents</p>
              <p className="text-black font-semibold text-nowrap">{formattedDate}</p>
            </div>
            <p className="text-black text-xl font-semibold">{uniqueRespondents.toLocaleString("en-US")}</p>
          </div>
          <div className="flex-1 rounded bg-slate-100 p-3 text-sm space-y-2">
            <div className="flex justify-between space-y-1">
              <p className="text-slate-500">Survey Answers Distribution</p>
              <p className="text-black font-semibold text-nowrap">{formattedDate}</p>
            </div>
            <div className="flex flex-col lg:flex-row lg:gap-2 text-slate-800">
              <div className="grid grid-cols-2 gap-2 w-full">
                {METRIC_SERIES.filter((item) => item.id !== "net_score").map(({ name, id }) => (
                  <div key={id} className="flex flex-col gap-1">
                    <p className="text-xs font-semibold mb-0">
                      {round(citationWeightedAverage(metrics, id as string))}% {name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

interface SubsampleCandlestickChartProps {
  metricsGroupedBySurvey: Record<string, any[]>
  uniqueRespondentsBySurvey: any[]
  loading?: boolean
}

function SubsampleCandlestickChart(props: SubsampleCandlestickChartProps) {
  const { metricsGroupedBySurvey, uniqueRespondentsBySurvey, loading = true } = props

  const [options, setOptions] = useState<Highcharts.Options>({
    ...defaultChartOptions,
    xAxis: {
      labels: {
        step: 1,
      },
    },
    yAxis: [
      {
        minorGridLineWidth: 0,
        labels: {
          format: "{value}%",
        },
        title: {
          text: "Net Score Candlestick",
        },
        min: 0,
        max: 100,
        tickAmount: 6,
        tickInterval: 20,
        opposite: true,
      },
      {
        gridLineWidth: 0,
        labels: {
          format: "{value}%",
        },
        endOnTick: false,
        startOnTick: false,
        minTickInterval: 5,
        tickLength: 10,
        tickWidth: 1,
        tickPosition: "outside",
        tickPixelInterval: 100,
        title: {
          text: "Citation-Weighted Net Score",
        },
      },
    ],
    plotOptions: {
      column: {
        stacking: "percent",
        groupPadding: 0.05,
        pointPadding: 0.05,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return `${round(this.y || 0)}%`
          },
          style: {
            color: "#000000",
            textOutline: "none",
          },
        },
      },
      line: {
        marker: {
          symbol: "circle",
        },
      },
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    series: [],
  })

  const chartRef = useChartLoading(loading)

  useEffect(() => {
    if (!metricsGroupedBySurvey || !uniqueRespondentsBySurvey) return

    setOptions({
      xAxis: {
        categories: Object.keys(metricsGroupedBySurvey || {}),
      },
      series: METRIC_SERIES.map((series) => ({
        ...series,
        data: Object.entries(metricsGroupedBySurvey || {}).map(([key, metrics]) => ({
          name: key,
          y: round(citationWeightedAverage(metrics, series.id as string), 2),
          custom: {
            citations: metrics.reduce((acc, item) => acc + (item["citations"] || 0), 0),
            cut_name: metrics[0]?.cut?.name,
          },
        })),
      })) as Highcharts.SeriesOptionsType[],
      tooltip: {
        enabled: true,
        useHTML: true,
        followPointer: true,
        snap: 1,
        formatter: function () {
          const uniqueRespondents =
            uniqueRespondentsBySurvey?.find((item: any) => item.survey_short_name === this.point.name)?.unique_respondents || 0

          let label = `<p><b>Survey: </b>${this.point.name}</p>
              <p><b>Citation-Weighted ${this.series.name}: </b>${floor(this.point.y || 0, 1).toFixed(1)}%</p>
              `

          if (this.series.type === "column") {
            label += `<p><b>Unique Respondents: </b>${uniqueRespondents}</p>`
            label += `<p><b>Cut Description: </b>${(this.point as Point & { custom: { cut_name: string } }).custom?.cut_name}</p>`
          }

          return label
        },
      },
    })
  }, [metricsGroupedBySurvey, uniqueRespondentsBySurvey])

  return (
    <HC highcharts={Highcharts} options={options} containerProps={{ className: "absolute top-0 right-0 bottom-0 left-0" }} ref={chartRef} />
  )
}
interface PervasionChartProps {
  metricsGroupedBySurvey: Record<string, any[]>
  loading?: boolean
}

function PervasionChart(props: PervasionChartProps) {
  const { metricsGroupedBySurvey, loading = true } = props

  const [options, setOptions] = useState<Highcharts.Options>({
    ...defaultChartOptions,
    xAxis: {
      labels: {
        step: 1,
      },
    },
    legend: {
      enabled: false,
    },
    yAxis: {
      minorGridLineWidth: 0,
      labels: {
        format: "{value}%",
      },
      title: {
        text: "Citation-Weighted Pervasion",
      },
    },
    series: [],
  })

  const chartRef = useChartLoading(loading)

  useEffect(() => {
    if (!metricsGroupedBySurvey) return

    setOptions({
      xAxis: {
        categories: Object.keys(metricsGroupedBySurvey || {}),
      },
      series: [
        {
          id: "pervasion_of_sector",
          name: "Pervasion",
          color: "#FCAD03",
          type: "line",
          data: Object.entries(metricsGroupedBySurvey || {}).map(([surveyShortName, values]) => {
            return {
              name: surveyShortName,
              y: round(citationWeightedAverage(values, "pervasion_of_sector"), 1),
            }
          }),
        },
      ],
      tooltip: {
        enabled: true,
        useHTML: true,
        followPointer: true,
        snap: 1,
        formatter: function () {
          return `<p><b>Survey: </b>${this.point.name}</p>
          <p><b>Citation-Weighted ${this.series.name}: </b>${floor(this.point.y || 0, 1).toFixed(1)}%</p>
          `
        },
      },
    })
  }, [metricsGroupedBySurvey])

  return (
    <HC highcharts={Highcharts} options={options} containerProps={{ className: "absolute top-0 right-0 bottom-0 left-0" }} ref={chartRef} />
  )
}

const citationWeightedAverage = (metrics: any[], key: string): number => {
  const numerator = metrics.map((item) => item[key] * item["citations"]).reduce((acc, item) => acc + item, 0)
  const citations = metrics.reduce((acc, item) => acc + (item["citations"] || 0), 0)
  return (numerator / citations) * 100
}
