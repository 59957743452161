import {
  FilterGroups,
  FilterGroupStoreProvider,
  FilterListDropdown,
  getAllFilterGroupsFromStore,
  GlobalStore,
  RadioList,
  SurveyDates,
  useFilterGroupStore,
} from "@/components/filters"
import { WIDGET_ASPECT_RATIO } from "@/components/widget"
import { METRIC_FILTER_OPTIONS, PUBLIC_PRIVATE_OPTIONS, SurveyZScoreWidget } from "@/components/widgets/screener_survey_z_score"
import { useDefaultFilter, useFilter } from "@/hooks/use_filter"
import { useSurveyDates } from "@/hooks/use_survey_dates"
import { AspectRatio } from "@/ui/aspect_ratio"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "@/ui/breadcrumb"
import { Card, CardContent, CardHeader } from "@/ui/card"
import { Spinner } from "@/ui/spinner"
import { ScreenerTabs } from "@/views/data-analyzer/screeners"
import React, { useCallback, useState } from "react"

export default function AnalyzeSurveyZScoreScreener() {
  const [loading, setLoading] = useState(true)

  const handleGlobalFilterChange = useCallback((state: FilterGroups) => {
    const surveys = state.survey_dates?.selectedItems?.length

    if (surveys) setLoading(false)

    GlobalStore.getState().updateFilterGroups(state)
  }, [])

  return (
    <div className="mb-[500px] space-y-2">
      <Breadcrumb className="mb-2">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/analyze">Data Analyzer</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Screeners</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="flex flex-col gap-3 lg:flex-row">
        <div className="flex-shrink space-y-3 md:min-w-[500px]">
          <ScreenerTabs />

          <FilterGroupStoreProvider onAppliedFiltersChange={handleGlobalFilterChange} enableGlobalStoreSubscription={false}>
            <GlobalFilters />
          </FilterGroupStoreProvider>
        </div>

        <div className="flex-1">
          {loading ?
            <AspectRatio ratio={WIDGET_ASPECT_RATIO}>
              <div className="flex flex-col items-center justify-center h-full gap-3">
                <Spinner className="w-24 h-24" />
                <p className="text-sm text-muted-foreground">Loading widgets...</p>
              </div>
            </AspectRatio>
          : <SurveyZScoreWidget hidePrimaryFilters hiddenFilters={["survey_dates"]} />}
        </div>
      </div>
    </div>
  )
}

const GlobalFilters = () => {
  useSurveyDates("single")

  useDefaultFilter("metrics", {
    returnType: "single",
    queryFn: () => [{ id: "net_score", name: "Net Score" }],
  })

  const appliedFilterGroups = useFilterGroupStore(getAllFilterGroupsFromStore)
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const sectors = useFilter("sectors", { returnType: "multiple" })

  return (
    <Card className="space-y-2">
      <CardHeader></CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 gap-2">
          <SurveyDates mode="single" className="items-start justify-between max-w-48" text="Survey" />

          <RadioList
            className="max-w-48"
            text="Metric"
            filterGroupId="metrics"
            items={METRIC_FILTER_OPTIONS}
            defaultSelection={METRIC_FILTER_OPTIONS.find((o) => o.id == "net_score")!}
          />

          <FilterListDropdown
            className="w-full text-sm"
            name="Sector"
            text="Sector"
            fallbackText="All"
            filterGroupId="sectors"
            endpoint="/api/v1/sectors"
            onHandleFilterChange={(filterGroupId, selectedItems) => {
              const products =
                appliedFilterGroups.products?.selectedItems.filter((product) => {
                  return selectedItems.some((sector) => (product.name as string).includes(sector.name as string))
                }) || []

              updateFilterGroups({
                [filterGroupId]: {
                  name: "Sectors",
                  selectedItems,
                },
                products: {
                  name: "Products",
                  selectedItems: products,
                },
              })
            }}
          />

          <FilterListDropdown
            className="w-full text-sm"
            name="Companies"
            text="Company"
            fallbackText="All"
            filterGroupId="companies"
            endpoint="/api/v1/companies"
            params={{
              sectors,
            }}
            formatter={(item) => ({
              ...item,
              subtitle: item.ticker,
            })}
          />

          <RadioList
            className="max-w-48"
            text="Public / Private"
            filterGroupId="public_private"
            items={PUBLIC_PRIVATE_OPTIONS}
            defaultSelection={PUBLIC_PRIVATE_OPTIONS.find((o) => o.id == "all")!}
          />
        </div>
      </CardContent>
    </Card>
  )
}
