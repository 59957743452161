import React from "react"

export default function AnalyzeCompanyProductIcon({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="298" height="343" fill="none" viewBox="0 0 298 343" className={className}>
      <path
        fill="#2B6CB0"
        d="M53.408 6.188c0-4.247 4.189-7.23 8.21-5.846L231.13 58.684a6.185 6.185 0 014.174 5.846v272.287a6.187 6.187 0 01-6.192 6.183H59.6a6.187 6.187 0 01-6.192-6.183V6.188z"
      ></path>
      <path
        fill="#4299E1"
        d="M173.382 177.601a6.187 6.187 0 016.192-6.183h112.234a6.187 6.187 0 016.192 6.183v159.216a6.187 6.187 0 01-6.192 6.183H179.574a6.187 6.187 0 01-6.192-6.183V177.601zM0 109.586a6.187 6.187 0 016.192-6.183h122.296a6.188 6.188 0 016.193 6.183v227.231a6.188 6.188 0 01-6.193 6.183H6.192A6.187 6.187 0 010 336.817V109.586z"
      ></path>
      <path
        fill="#fff"
        d="M77.403 160.597a3.867 3.867 0 013.87-3.864h27.865c2.137 0 3.87 1.73 3.87 3.864v27.824a3.867 3.867 0 01-3.87 3.865H81.273a3.867 3.867 0 01-3.87-3.865v-27.824zM20.899 160.597a3.867 3.867 0 013.87-3.864h27.865c2.137 0 3.87 1.73 3.87 3.864v27.824a3.867 3.867 0 01-3.87 3.865H24.769a3.867 3.867 0 01-3.87-3.865v-27.824zM77.403 212.381a3.867 3.867 0 013.87-3.864h27.865c2.137 0 3.87 1.73 3.87 3.864v27.824a3.867 3.867 0 01-3.87 3.865H81.273a3.867 3.867 0 01-3.87-3.865v-27.824zM20.899 212.381a3.867 3.867 0 013.87-3.864h27.865c2.137 0 3.87 1.73 3.87 3.864v27.824a3.867 3.867 0 01-3.87 3.865H24.769a3.867 3.867 0 01-3.87-3.865v-27.824zM77.403 264.165a3.868 3.868 0 013.87-3.865h27.865a3.868 3.868 0 013.87 3.865v27.824a3.868 3.868 0 01-3.87 3.865H81.273a3.868 3.868 0 01-3.87-3.865v-27.824zM20.899 264.165a3.868 3.868 0 013.87-3.865h27.865a3.868 3.868 0 013.87 3.865v27.824a3.868 3.868 0 01-3.87 3.865H24.769a3.868 3.868 0 01-3.87-3.865v-27.824zM246.14 212.381a3.867 3.867 0 013.87-3.864h27.865c2.138 0 3.87 1.73 3.87 3.864v27.824a3.867 3.867 0 01-3.87 3.865H250.01a3.867 3.867 0 01-3.87-3.865v-27.824zM189.636 212.381a3.867 3.867 0 013.871-3.864h27.864a3.867 3.867 0 013.871 3.864v27.824a3.868 3.868 0 01-3.871 3.865h-27.864a3.868 3.868 0 01-3.871-3.865v-27.824zM246.14 264.165a3.868 3.868 0 013.87-3.865h27.865a3.867 3.867 0 013.87 3.865v27.824a3.867 3.867 0 01-3.87 3.865H250.01a3.868 3.868 0 01-3.87-3.865v-27.824zM189.636 264.165a3.868 3.868 0 013.871-3.865h27.864a3.868 3.868 0 013.871 3.865v27.824a3.868 3.868 0 01-3.871 3.865h-27.864a3.868 3.868 0 01-3.871-3.865v-27.824z"
      ></path>
    </svg>
  )
}
