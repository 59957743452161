import { createDashboard } from "@/api/dashboards"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/ui/alert_dialog"
import { buttonVariants } from "@/ui/button"
import { Input } from "@/ui/input"
import React, { useState } from "react"

interface CreateDashboardDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
}

export function CreateDashboardDialog(props: CreateDashboardDialogProps) {
  const { open, onOpenChange } = props
  const [dashboardName, setDashboardName] = useState("")

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent className="w-[50%]">
        <AlertDialogHeader>
          <AlertDialogTitle>Create New Dashboard</AlertDialogTitle>
        </AlertDialogHeader>
        <Input placeholder="New Dashboard Name (Required)" onChange={(e) => setDashboardName(e.target.value)} />
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => onOpenChange(false)}>Cancel</AlertDialogCancel>
          <AlertDialogAction
            disabled={!dashboardName}
            className={buttonVariants({ variant: "default" })}
            onClick={async () => {
              const response = await createDashboard({ name: dashboardName })

              if (response?.id) {
                window.location.href = `/dashboards/${response.id}`
              }
            }}
          >
            Create
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
