import { SelectCompanyDialog } from "@/components/dialogs/select_company"
import { SelectProductDialog } from "@/components/dialogs/select_product"
import { SelectSectorDialog } from "@/components/dialogs/select_sector"
import { cn } from "@/lib/utils"
import AnalyzeCompanyProductIcon from "@/svgs/analyze_company_product_icon"
import AnalyzeIdeaGeneratorIcon from "@/svgs/analyze_idea_generator_icon"
import AnalyzeSectorIcon from "@/svgs/analyze_sector_icon"
import AnalyzeSharedAccountsIcon from "@/svgs/analyze_shared_accounts_icon"
import { Card, CardDescription, CardHeader, CardTitle } from "@/ui/card"
import React, { useMemo, useState } from "react"

export default function DataAnalyzer() {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [dialogTrigger, setDialogTrigger] = useState("")

  const handleDialogOpen = (trigger: string) => {
    setIsDialogOpen(true)
    setDialogTrigger(trigger)
  }

  const analyzers = useMemo(
    () =>
      [
        {
          id: "ANALYZE_COMPANY",
          title: "Company and Product Analysis",
          description: "Explore a particular company / product",
          icon: <AnalyzeCompanyProductIcon className="h-10 w-10" />,
          onClick: () => handleDialogOpen("ANALYZE_COMPANY"),
        },
        {
          id: "SECTOR_COMPANY",
          title: "Sector Analysis",
          description: "Explore a particular company / product",
          icon: <AnalyzeSectorIcon className="h-10 w-10" />,
          onClick: () => handleDialogOpen("ANALYZE_SECTOR"),
        },
        {
          id: "SHARED_ACCOUNT",
          title: "Shared Accounts",
          description: "Gain insightful stack overlap",
          icon: <AnalyzeSharedAccountsIcon className="h-10 w-10" />,
          onClick: () => handleDialogOpen("SHARED_ACCOUNT"),
        },
        {
          id: "IDEA_GENERATOR",
          title: `Idea Generator "Screener"`,
          description: "Uncover potential ideas",
          icon: <AnalyzeIdeaGeneratorIcon className="h-10 w-10" />,
          onClick: () => (window.location.href = "/analyze/screeners"),
        },
      ] as Analyzer[],
    []
  )

  return (
    <div className="md:container space-y-4">
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl">Data Analyzer</h1>
      </div>
      <div className="grid gap-6 md:grid-cols-3">
        {analyzers.map((analyzer) => (
          <AnalyzerCard key={analyzer.id} analyzer={analyzer} />
        ))}
      </div>

      {isDialogOpen && dialogTrigger === "ANALYZE_COMPANY" && <SelectCompanyDialog open={true} onOpenChange={setIsDialogOpen} />}
      {isDialogOpen && dialogTrigger === "ANALYZE_SECTOR" && <SelectSectorDialog open={true} onOpenChange={setIsDialogOpen} />}
      {isDialogOpen && dialogTrigger === "SHARED_ACCOUNT" && <SelectProductDialog open={true} onOpenChange={setIsDialogOpen} />}
    </div>
  )
}

const AnalyzerCard = ({ className, analyzer }: AnalyzerCardProps) => {
  const { title, description, icon, onClick, disabled } = analyzer

  return (
    <Card
      onClick={onClick}
      className={cn(
        "text-center overflow-hidden cursor-pointer",
        className,
        !disabled && "hover:shadow hover:bg-[#F0F7FE] hover:border-[#80BDFA]",
        disabled && "opacity-70 cursor-not-allowed"
      )}
      aria-disabled={disabled}
    >
      <CardHeader className="space-y-4 p-8">
        <div className="flex justify-center">{icon}</div>
        <CardTitle>{title}</CardTitle>
        <CardDescription className="truncate">{description}</CardDescription>
      </CardHeader>
    </Card>
  )
}

interface AnalyzerCardProps {
  analyzer: Analyzer
  className?: string
}
type Analyzer = {
  id: string
  title: string
  description: string
  icon: React.ReactNode
  onClick: () => void
  disabled?: boolean
}
