import React, { ReactNode, createContext, useContext, useState } from "react"
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/ui/hover_card"
import { HoverCardArrow } from "@radix-ui/react-hover-card"
import { useHover } from "@/hooks/use_hover"
import { cn } from "@/lib/utils"

interface HoverTabsContext {
  activeTab: string | number
  setActiveTab: (tabId: string | number) => void
}

const HoverTabsContext = createContext<HoverTabsContext>({
  activeTab: 0,
  setActiveTab: () => {},
})

const activeClasses = "text-black border-black"
const viewClasses = "whitespace-nowrap text-ellipsis overflow-hidden"
const baseClasses = "cursor-pointer flex items-center gap-2 border-b py-2 pl-6 text-blue-500 border-white"

const HoverTabs = ({ children, defaultActiveTab }: HoverTabsProps) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab || 0)
  return (
    <HoverTabsContext.Provider value={{ activeTab, setActiveTab }}>
      <div>{children}</div>
    </HoverTabsContext.Provider>
  )
}

const TabGroup = ({ children }: TabGroupProps) => {
  return <ul className="flex gap-1">{children}</ul>
}

const Tab = (props: TabProps) => {
  const { text, link, showHoverMenu, hoverMenuIcon, children, tabId } = props

  const [hoverTabRef, isHovered] = useHover()
  const { activeTab } = useContext(HoverTabsContext)
  const isActive = activeTab === tabId

  return (
    <li className={cn(baseClasses, { [activeClasses]: isActive })} ref={hoverTabRef}>
      {isActive ?
        <span className={viewClasses}>{text}</span>
      : <a className={viewClasses} href={link}>
          {text}
        </a>
      }

      {showHoverMenu && (
        <HoverCard openDelay={100} closeDelay={200}>
          <HoverCardTrigger
            className={cn("bg-white border border-gray-900 p-1 rounded-sm", {
              invisible: !isHovered,
              visible: isHovered,
            })}
          >
            {hoverMenuIcon}
          </HoverCardTrigger>
          <HoverCardContent align="center" sideOffset={2} className="p-2">
            <HoverCardArrow />
            {children}
          </HoverCardContent>
        </HoverCard>
      )}
    </li>
  )
}

const Trigger = ({ icon }: TriggerProps) => {
  return <HoverCardTrigger className={"bg-white border border-gray-900 p-1 rounded-sm"}>{icon}</HoverCardTrigger>
}

const MenuList = ({ children }: MenuListProps) => {
  return <ul>{children}</ul>
}

const StaticTab = ({ text, onClickHandler, icon }: StaticTabProps) => {
  return (
    <li className={baseClasses} onClick={onClickHandler}>
      {icon ? icon : null}
      {text}
    </li>
  )
}

HoverTabs.TabGroup = TabGroup
HoverTabs.Tab = Tab
HoverTabs.MenuTrigger = Trigger
HoverTabs.MenuList = MenuList
HoverTabs.StaticTab = StaticTab

export default HoverTabs

interface HoverTabsProps {
  children: ReactNode
  defaultActiveTab: string | number
}

interface MenuListProps {
  children: ReactNode
}

interface TabGroupProps {
  children: ReactNode
}

interface TriggerProps {
  icon: ReactNode
}

interface TabProps {
  text: string
  link: string
  showHoverMenu: boolean
  hoverMenuIcon: ReactNode
  children: ReactNode
  tabId: string | number
}

interface StaticTabProps {
  text: string
  icon?: ReactNode
  onClickHandler: () => void
}
