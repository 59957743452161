import { getScreenerIdeaGeneratorSeries } from "@/api/screeners"
import { DataTable, DataTablePagination, DataTableProps } from "@/components/data_table"
import {
  FilterGroupKeys,
  FilterListDropdown,
  FilterListItem,
  FilterRange,
  RadioList,
  SurveyDates,
  getSelectedRange,
  useFilterGroupStore,
} from "@/components/filters"
import { WIDGET_ASPECT_RATIO, Widget, WidgetPropsBase } from "@/components/widget"
import { WithAspectRatio } from "@/components/with_aspect_ratio"
import { useDatatable } from "@/hooks/use_datatable"
import { useEffectExceptOnMount } from "@/hooks/use_effect_except_on_mount"
import { useFilter } from "@/hooks/use_filter"
import { useSurveyDates } from "@/hooks/use_survey_dates"
import { DEFAULT_PAGE_SIZE, cn } from "@/lib/utils"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, AccordionHeader } from "@/ui/accordion"
import { Button } from "@/ui/button"
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from "@/ui/dropdown_menu"
import { TabsList, TabsTrigger } from "@/ui/tabs"
import { DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import { useQuery } from "@tanstack/react-query"
import { ColumnDef, getCoreRowModel, getPaginationRowModel } from "@tanstack/react-table"
import React, { useRef } from "react"

export const IdeaGeneratorWidget = ({ ...props }: WidgetPropsBase) => {
  return (
    <Widget componentKey={"idea_generator"} defaultTab={DEFAULT_SCREENER_TAB} {...props}>
      <Widget.PrimaryFilters>
        <IdeaGeneratorWidgetPrimaryFilters />
      </Widget.PrimaryFilters>

      <Widget.SecondaryFilters>
        <IdeaGeneratorWidgetSecondaryFilters hiddenFilters={props.hiddenFilters} />

        <TabsList>
          <TabsTrigger value="single">Single</TabsTrigger>
          <TabsTrigger value="aggregate">Aggregate</TabsTrigger>
        </TabsList>
      </Widget.SecondaryFilters>

      <Widget.Body>
        <Content renderAspectRatioContainer={props.renderAspectRatioContainer} />
        {props.children}
      </Widget.Body>
    </Widget>
  )
}

const IdeaGeneratorWidgetPrimaryFilters = () => {
  const sectors = useFilter("sectors", { returnType: "multiple" })

  return (
    <Accordion type="multiple" className="w-[900px] space-y-4" defaultValue={["basic", "spend-intentions", "financial-metrics"]}>
      <AccordionItem value="basic" className="rounded border overflow-hidden">
        <AccordionHeader className="px-3 bg-secondary">
          <AccordionTrigger className="text-lg font-semibold">Basic</AccordionTrigger>
        </AccordionHeader>

        <AccordionContent className="grid grid-cols-3 gap-2 p-2">
          <FilterListDropdown
            buttonClassName="max-w-none"
            className="w-full text-sm"
            name="Sector"
            text="Sector"
            fallbackText="All"
            filterGroupId="sectors"
            endpoint="/api/v1/sectors"
          />

          <FilterListDropdown
            buttonClassName="max-w-none"
            className="w-full text-sm"
            name="Companies"
            text="Companies"
            fallbackText="All"
            filterGroupId="companies"
            endpoint="/api/v1/companies"
            params={{
              sectors,
            }}
            formatter={(item) => ({
              ...item,
              subtitle: item.ticker,
            })}
          />

          <FilterListDropdown
            buttonClassName="min-w-56 max-w-none"
            className="w-full text-sm"
            name="Cut"
            text="Cut"
            fallbackText="All"
            filterGroupId="cuts"
            endpoint="/api/v1/cuts"
          />
        </AccordionContent>
      </AccordionItem>

      <AccordionItem value="spend-intentions" className="rounded border overflow-hidden">
        <AccordionHeader className="px-3 bg-secondary">
          <AccordionTrigger className="text-lg font-semibold">Spend Intentions</AccordionTrigger>
        </AccordionHeader>

        <AccordionContent className="grid grid-cols-3 gap-2 p-2">
          <FilterRange text="Adoption %" filterGroupId="adoption" />
          <FilterRange text="Adoption % Y/Y" filterGroupId="adoption_yoy" />
          <FilterRange text="Adoption % S/S" filterGroupId="adoption_sos" />
          <FilterRange text="Increase %" filterGroupId="increase" />
          <FilterRange text="Increase % Y/Y" filterGroupId="increase_yoy" />
          <FilterRange text="Increase % S/S" filterGroupId="increase_sos" />
          <FilterRange text="Flat %" filterGroupId="flat" />
          <FilterRange text="Flat % Y/Y" filterGroupId="flat_yoy" />
          <FilterRange text="Flat % S/S" filterGroupId="flat_sos" />
          <FilterRange text="Decrease %" filterGroupId="decrease" />
          <FilterRange text="Decrease % Y/Y" filterGroupId="decrease_yoy" />
          <FilterRange text="Decrease % S/S" filterGroupId="decrease_sos" />
          <FilterRange text="Replacing %" filterGroupId="replacing" />
          <FilterRange text="Replacing % Y/Y" filterGroupId="replacing_yoy" />
          <FilterRange text="Replacing % S/S" filterGroupId="replacing_sos" />
          <FilterRange text="Citations" filterGroupId="citations" />
          <FilterRange text="Citations Y/Y" filterGroupId="citations_yoy" />
          <FilterRange text="Citations S/S" filterGroupId="citations_sos" />
          <FilterRange text="Net Score" filterGroupId="net_score" />
          <FilterRange text="Net Score Y/Y" filterGroupId="net_score_yoy" />
          <FilterRange text="Net Score S/S" filterGroupId="net_score_sos" />
          <FilterRange text="Pervasion" filterGroupId="pervasion" />
          <FilterRange text="Pervasion Y/Y" filterGroupId="pervasion_yoy" />
          <FilterRange text="Pervasion S/S" filterGroupId="pervasion_sos" />
        </AccordionContent>
      </AccordionItem>

      <AccordionItem value="financial-metrics" className="rounded border overflow-hidden">
        <AccordionHeader className="px-3 bg-secondary">
          <AccordionTrigger className="text-lg font-semibold">Financial Metrics</AccordionTrigger>
        </AccordionHeader>
        <AccordionContent className="grid grid-cols-3 gap-2 p-2">
          <FilterRange text="CY/CY Revenue" filterGroupId="cy_cy_revenue" />
          <RadioList
            text="Market Cap"
            filterGroupId="market_capitalization"
            items={MARKET_CAP_RADIO_ITEMS}
            defaultSelection={MARKET_CAP_RADIO_ITEMS[0]!}
          />
          <FilterRange text="Short Ratio" filterGroupId="short_ratio" />
          <FilterRange text="Price to Earnings" filterGroupId="price_to_earnings" />
          <FilterRange text="EV to Sales TTM" filterGroupId="ev_to_sales_ttm" />
          <FilterRange text="EV to Sales NTM" filterGroupId="ev_to_sales_ntm" />
          <FilterRange text="YTD Stock Return" filterGroupId="return_ytd" />
          <FilterRange text="1mo Stock Return" filterGroupId="return_last_1_month" />
          <FilterRange text="3mo Stock Return" filterGroupId="return_last_3_month" />
          <FilterRange text="6mo Stock Return" filterGroupId="return_last_6_month" />
          <FilterRange text="12mo Stock Return" filterGroupId="return_last_12_month" />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

const IdeaGeneratorWidgetSecondaryFilters = ({ hiddenFilters }: Pick<WidgetPropsBase, "hiddenFilters">) => {
  const filters: Array<[FilterGroupKeys, React.ReactNode]> = [["survey_dates", <SurveyDates mode={SURVEY_DATE_MODE} size="sm" />]]

  const filterComponents = filters
    .filter(([key, _component]) => !hiddenFilters?.includes(key))
    .map(([key, component]) => <React.Fragment key={key}>{component}</React.Fragment>)

  return <div className="flex items-center flex-grow space-x-4">{filterComponents}</div>
}

export const MARKET_CAP_RADIO_ITEMS: FilterListItem[] = [
  { id: "all", name: "All" },
  { id: "lt_2B", name: "< $2B" },
  { id: "gte_2b_lt_5B", name: "$2B - $4.9B" },
  { id: "gte_5b_lt_10B", name: "$5B - $9.9B" },
  { id: "gte_10b_lt_20B", name: "$10B - $19B" },
  { id: "gte_20b", name: ">= $20B" },
  { id: "na", name: "N/A" },
]
const DEFAULT_SCREENER_TAB = "single"
const SURVEY_DATE_MODE = "single"

function Content(props: Pick<WidgetPropsBase, "renderAspectRatioContainer">) {
  const { renderAspectRatioContainer } = props
  const datatableFilterGroup = useFilterGroupStore((s) => s.datatable)
  const displayFilterGroup = useFilterGroupStore((s) => s.display)
  const { value: surveys, isLoading: surveyIsLoading } = useSurveyDates(SURVEY_DATE_MODE)
  const cuts = useFilter("cuts", { returnType: "multiple" })
  const companies = useFilter("companies", { returnType: "multiple" })
  const sectors = useFilter("sectors", { returnType: "multiple" })
  const adoption = useFilter("adoption", { returnType: "single" })
  const adoption_sos = useFilter("adoption_sos", { returnType: "single" })
  const adoption_yoy = useFilter("adoption_yoy", { returnType: "single" })
  const decrease = useFilter("decrease", { returnType: "single" })
  const decrease_sos = useFilter("decrease_sos", { returnType: "single" })
  const decrease_yoy = useFilter("decrease_yoy", { returnType: "single" })
  const flat = useFilter("flat", { returnType: "single" })
  const flat_sos = useFilter("flat_sos", { returnType: "single" })
  const flat_yoy = useFilter("flat_yoy", { returnType: "single" })
  const increase = useFilter("increase", { returnType: "single" })
  const increase_sos = useFilter("increase_sos", { returnType: "single" })
  const increase_yoy = useFilter("increase_yoy", { returnType: "single" })
  const replacing = useFilter("replacing", { returnType: "single" })
  const replacing_sos = useFilter("replacing_sos", { returnType: "single" })
  const replacing_yoy = useFilter("replacing_yoy", { returnType: "single" })
  const citations = useFilter("citations", { returnType: "single" })
  const citations_sos = useFilter("citations_sos", { returnType: "single" })
  const citations_yoy = useFilter("citations_yoy", { returnType: "single" })
  const net_score = useFilter("net_score", { returnType: "single" })
  const net_score_sos = useFilter("net_score_sos", { returnType: "single" })
  const net_score_yoy = useFilter("net_score_yoy", { returnType: "single" })
  const pervasion = useFilter("pervasion", { returnType: "single" })
  const pervasion_sos = useFilter("pervasion_sos", { returnType: "single" })
  const pervasion_yoy = useFilter("pervasion_yoy", { returnType: "single" })
  const cy_cy_revenue = useFilter("cy_cy_revenue", { returnType: "single" })
  const marketCap = useFilter("market_capitalization", { returnType: "single" })
  const short_ratio = useFilter("short_ratio", { returnType: "single" })
  const price_to_earnings = useFilter("price_to_earnings", { returnType: "single" })
  const ev_to_sales_ttm = useFilter("ev_to_sales_ttm", { returnType: "single" })
  const ev_to_sales_ntm = useFilter("ev_to_sales_ntm", { returnType: "single" })
  const return_ytd = useFilter("return_ytd", { returnType: "single" })
  const return_last_1_month = useFilter("return_last_1_month", { returnType: "single" })
  const return_last_3_month = useFilter("return_last_3_month", { returnType: "single" })
  const return_last_6_month = useFilter("return_last_6_month", { returnType: "single" })
  const return_last_12_month = useFilter("return_last_12_month", { returnType: "single" })

  const adoptionRange = getSelectedRange(adoption, true, []).map((value) => value / 100)
  const adoptionSosRange = getSelectedRange(adoption_sos, true, []).map((value) => value / 100)
  const adoptionYoyRange = getSelectedRange(adoption_yoy, true, []).map((value) => value / 100)
  const decreaseRange = getSelectedRange(decrease, true, []).map((value) => value / 100)
  const decreaseSosRange = getSelectedRange(decrease_sos, true, []).map((value) => value / 100)
  const decreaseYoyRange = getSelectedRange(decrease_yoy, true, []).map((value) => value / 100)
  const flatRange = getSelectedRange(flat, true, []).map((value) => value / 100)
  const flatSosRange = getSelectedRange(flat_sos, true, []).map((value) => value / 100)
  const flatYoyRange = getSelectedRange(flat_yoy, true, []).map((value) => value / 100)
  const increaseRange = getSelectedRange(increase, true, []).map((value) => value / 100)
  const increaseSosRange = getSelectedRange(increase_sos, true, []).map((value) => value / 100)
  const increaseYoyRange = getSelectedRange(increase_yoy, true, []).map((value) => value / 100)
  const replacingRange = getSelectedRange(replacing, true, []).map((value) => value / 100)
  const replacingSosRange = getSelectedRange(replacing_sos, true, []).map((value) => value / 100)
  const replacingYoyRange = getSelectedRange(replacing_yoy, true, []).map((value) => value / 100)
  const citationsRange = getSelectedRange(citations, true, [])
  const citationsSosRange = getSelectedRange(citations_sos, true, [])
  const citationsYoyRange = getSelectedRange(citations_yoy, true, [])
  const netScoreRange = getSelectedRange(net_score, true, []).map((value) => value / 100)
  const netScoreSosRange = getSelectedRange(net_score_sos, true, []).map((value) => value / 100)
  const netScoreYoyRange = getSelectedRange(net_score_yoy, true, []).map((value) => value / 100)
  const pervasionRange = getSelectedRange(pervasion, true, []).map((value) => value / 100)
  const pervasionSosRange = getSelectedRange(pervasion_sos, true, []).map((value) => value / 100)
  const pervasionYoyRange = getSelectedRange(pervasion_yoy, true, []).map((value) => value / 100)
  const cyCyRevenueRange = getSelectedRange(cy_cy_revenue, true, []).map((value) => value / 100)
  const shortRatioRange = getSelectedRange(short_ratio, true, []).map((value) => value / 100)
  const priceToEarningsRange = getSelectedRange(price_to_earnings, true, []).map((value) => value / 100)
  const evToSalesTtmRange = getSelectedRange(ev_to_sales_ttm, true, []).map((value) => value / 100)
  const evToSalesNtmRange = getSelectedRange(ev_to_sales_ntm, true, []).map((value) => value / 100)
  const returnYtdRange = getSelectedRange(return_ytd, true, []).map((value) => value / 100)
  const returnLast1MonthRange = getSelectedRange(return_last_1_month, true, []).map((value) => value / 100)
  const returnLast3MonthRange = getSelectedRange(return_last_3_month, true, []).map((value) => value / 100)
  const returnLast6MonthRange = getSelectedRange(return_last_6_month, true, []).map((value) => value / 100)
  const returnLast12MonthRange = getSelectedRange(return_last_12_month, true, []).map((value) => value / 100)

  const currentTab = (displayFilterGroup?.selectedItems.find((item) => item.id === "selected-tab")?.name as string) || DEFAULT_SCREENER_TAB
  const currentTabKey = useRef(currentTab)
  const table = useDatatable<ScreenerMetric>({
    columns,
    data: [],
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id.toString(),
    getPaginationRowModel: getPaginationRowModel(),
    enableColumnPinning: true,
    enableSorting: true,
    manualPagination: true,
    manualSorting: true,
    defaultColumn: {
      size: 200,
    },
    initialState: datatableFilterGroup?.data || {
      columnOrder: columns.map((c) => c.id!),
      columnPinning: {
        left: ["ticker", "company", "product", "sector"],
      },
      columnVisibility: {
        company: false,
      },
      pagination: { pageIndex: 0, pageSize: DEFAULT_PAGE_SIZE },
    },
  })

  const page = table.getState().pagination?.pageIndex + 1
  const limit = table.getState().pagination?.pageSize
  const sort = datatableFilterGroup?.data?.sorting

  const widgetEnabled = !!surveys.length && !surveyIsLoading

  const { isLoading, data: queryData } = useQuery({
    queryKey: [
      "screener_idea_generator_tabular",
      {
        currentTab: currentTabKey.current,
        cuts,
        companies,
        sectors,
        surveys,
        adoptionRange,
        adoptionSosRange,
        adoptionYoyRange,
        decreaseRange,
        decreaseSosRange,
        decreaseYoyRange,
        flatRange,
        flatSosRange,
        flatYoyRange,
        increaseRange,
        increaseSosRange,
        increaseYoyRange,
        replacingRange,
        replacingSosRange,
        replacingYoyRange,
        citationsRange,
        citationsSosRange,
        citationsYoyRange,
        netScoreRange,
        netScoreSosRange,
        netScoreYoyRange,
        pervasionRange,
        pervasionSosRange,
        pervasionYoyRange,
        cyCyRevenueRange,
        marketCap,
        shortRatioRange,
        priceToEarningsRange,
        evToSalesTtmRange,
        evToSalesNtmRange,
        returnYtdRange,
        returnLast1MonthRange,
        returnLast3MonthRange,
        returnLast6MonthRange,
        returnLast12MonthRange,
        page,
        limit,
        sort,
      },
    ],
    queryFn: () => {
      return getScreenerIdeaGeneratorSeries({
        aggregate: currentTab === "aggregate",
        cuts,
        companies,
        sectors,
        surveys,
        adoption_percentage: adoptionRange,
        adoption_percentage_delta_sos: adoptionSosRange,
        adoption_percentage_delta_yoy: adoptionYoyRange,
        decrease_percentage: decreaseRange,
        decrease_percentage_delta_sos: decreaseSosRange,
        decrease_percentage_delta_yoy: decreaseYoyRange,
        flat_percentage: flatRange,
        flat_percentage_delta_sos: flatSosRange,
        flat_percentage_delta_yoy: flatYoyRange,
        increase_percentage: increaseRange,
        increase_percentage_delta_sos: increaseSosRange,
        increase_percentage_delta_yoy: increaseYoyRange,
        replacing_percentage: replacingRange,
        replacing_percentage_delta_sos: replacingSosRange,
        replacing_percentage_delta_yoy: replacingYoyRange,
        citations: citationsRange,
        citations_sos: citationsSosRange,
        citations_yoy: citationsYoyRange,
        net_score: netScoreRange,
        net_score_delta_sos: netScoreSosRange,
        net_score_delta_yoy: netScoreYoyRange,
        pervasion: pervasionRange,
        pervasion_delta_sos: pervasionSosRange,
        pervasion_delta_yoy: pervasionYoyRange,
        cy_cy_revenue: cyCyRevenueRange,
        market_capitalization: marketCap,
        short_ratio: shortRatioRange,
        price_to_earnings: priceToEarningsRange,
        ev_to_sales_ttm: evToSalesTtmRange,
        ev_to_sales_ntm: evToSalesNtmRange,
        return_ytd: returnYtdRange,
        return_last_1_month: returnLast1MonthRange,
        return_last_3_month: returnLast3MonthRange,
        return_last_6_month: returnLast6MonthRange,
        return_last_12_month: returnLast12MonthRange,
        page,
        limit,
        sort,
      })
    },
    placeholderData: (prevData, query: any) => {
      return query?.queryKey?.[1]?.currentTab === currentTab ? prevData : undefined
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: widgetEnabled,
  })

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (active && over && active.id !== over.id) {
      const columnOrder = table.getState().columnOrder

      const oldIndex = columnOrder.indexOf(active.id as string)
      const newIndex = columnOrder.indexOf(over.id as string)

      table.setColumnOrder(arrayMove(columnOrder, oldIndex, newIndex))
    }
  }

  useEffectExceptOnMount(() => {
    const isViewingSingle = currentTab === "single"
    const columnVisibility = { company: !isViewingSingle, product: isViewingSingle, sector: isViewingSingle }
    currentTabKey.current = currentTab
    table.setState((prev) => ({
      ...prev,
      columnVisibility: { ...prev.columnVisibility, ...columnVisibility },
      pagination: { ...prev.pagination, pageIndex: 0 },
    }))
  }, [currentTab])

  const data = queryData?.data || []
  const total = queryData?.total
  table.setOptions((prev) => ({ ...prev, data, rowCount: total }))

  if ((data?.length === 0 || !widgetEnabled) && !isLoading) return <Widget.Empty />

  return (
    <div className="relative flex-1">
      <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
        <ScreenerTable table={table} loading={isLoading} handleDragEnd={handleDragEnd} enableDragAndDropHeader />
      </WithAspectRatio>
    </div>
  )
}

interface ScreenerTableProps<TData> extends DataTableProps<TData> {}

function ScreenerTable<TData>({ table, ...props }: ScreenerTableProps<TData>) {
  return (
    <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-col space-y-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="ml-auto">
            Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {table
            .getAllColumns()
            .filter((column) => column.getCanHide())
            .map((column) => (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
                onSelect={(e) => e.preventDefault()}
              >
                {column.columnDef.header as string}
              </DropdownMenuCheckboxItem>
            ))}
        </DropdownMenuContent>
      </DropdownMenu>

      <DataTable table={table} {...props} className={cn("table-fixed", props.className)} />
      <DataTablePagination table={table} />
    </div>
  )
}

const getCellColor = (value?: number) => {
  if (!value) return "bg-white text-black"

  if (value >= 30) return "bg-green-300 text-green-700"
  if (value >= 20) return "bg-green-200 text-green-700"
  if (value >= 10) return "bg-green-100 text-green-700"
  if (value > 0) return "bg-green-50 text-green-700"
  if (value === 0) return "bg-white text-black"
  if (value >= -25) return "bg-red-50 text-red-700"
  if (value >= -50) return "bg-red-100 text-red-700"
  if (value >= -75) return "bg-red-200 text-red-700"
  return "bg-red-300 text-red-700"
}

const percentageFormatter = (value?: number) => (value ? new Intl.NumberFormat("en-US", { style: "percent" }).format(value / 100) : "-")
const numberFormatter = (value?: number) => (value ? new Intl.NumberFormat("en-US").format(value) : "-")

const columns: ColumnDef<ScreenerMetric>[] = [
  {
    id: "ticker",
    accessorKey: "company.ticker",
    header: "Ticker",
    enableHiding: false,
    size: 100,
    meta: {
      cellProps: {
        className: "truncate bg-slate-50",
      },
    },
  },
  {
    id: "company",
    accessorKey: "company.name",
    header: "Company",
    enableHiding: false,
    size: 150,
    meta: {
      cellProps: {
        className: "truncate bg-slate-50",
      },
    },
  },
  {
    id: "product",
    accessorFn: (row) => row.product?.name,
    header: "Product",
    enableHiding: false,
    size: 150,
    meta: {
      cellProps: {
        className: "truncate bg-slate-50",
      },
    },
  },
  {
    id: "sector",
    accessorFn: (row) => row.sector?.name,
    header: "Sector",
    enableHiding: false,
    size: 200,
    meta: {
      cellProps: {
        className: "truncate bg-slate-50",
      },
    },
  },
  {
    id: "cut",
    accessorKey: "cut.name",
    header: "Cut",
    size: 200,
    meta: {
      cellProps: {
        className: "truncate bg-slate-50",
      },
    },
  },
  {
    id: "adoption_percentage",
    accessorKey: "adoption_percentage",
    header: "Adoption %",
    size: 170,
    cell: (context) => percentageFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "adoption_percentage_delta_yoy",
    accessorKey: "adoption_percentage_delta_yoy",
    header: "Adoption % Delta Y/Y",
    size: 220,
    cell: (context) => numberFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "adoption_percentage_delta_sos",
    accessorKey: "adoption_percentage_delta_sos",
    header: "Adoption % Delta S/S",
    size: 220,
    cell: (context) => numberFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "increase_percentage",
    accessorKey: "increase_percentage",
    header: "Increase %",
    size: 150,
    cell: (context) => percentageFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "increase_percentage_delta_yoy",
    accessorKey: "increase_percentage_delta_yoy",
    header: "Increase % Delta Y/Y",
    size: 220,
    cell: (context) => numberFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "increase_percentage_delta_sos",
    accessorKey: "increase_percentage_delta_sos",
    header: "Increase % Delta S/S",
    size: 220,
    cell: (context) => numberFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "flat_percentage",
    accessorKey: "flat_percentage",
    header: "Flat %",
    size: 150,
    cell: (context) => percentageFormatter(context.getValue<number>()),
    meta: {
      cellProps: {
        className: "text-center",
      },
    },
  },
  {
    id: "flat_percentage_delta_yoy",
    accessorKey: "flat_percentage_delta_yoy",
    header: "Flat % Delta Y/Y",
    cell: (context) => numberFormatter(context.getValue<number>()),
    meta: {
      cellProps: {
        className: "text-center",
      },
    },
  },
  {
    id: "flat_percentage_delta_sos",
    accessorKey: "flat_percentage_delta_sos",
    header: "Flat % Delta S/S",
    cell: (context) => numberFormatter(context.getValue<number>()),
    meta: {
      cellProps: {
        className: "text-center",
      },
    },
  },
  {
    id: "decrease_percentage",
    accessorKey: "decrease_percentage",
    header: "Decrease %",
    size: 150,
    cell: (context) => percentageFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "decrease_percentage_delta_yoy",
    accessorKey: "decrease_percentage_delta_yoy",
    header: "Decrease % Delta Y/Y",
    size: 220,
    cell: (context) => numberFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "decrease_percentage_delta_sos",
    accessorKey: "decrease_percentage_delta_sos",
    header: "Decrease % Delta S/S",
    size: 220,
    cell: (context) => numberFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "replacing_percentage",
    accessorKey: "replacing_percentage",
    header: "Replacing %",
    size: 150,
    cell: (context) => percentageFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "replacing_percentage_delta_yoy",
    accessorKey: "replacing_percentage_delta_yoy",
    header: "Replacing % Delta Y/Y",
    size: 220,
    cell: (context) => numberFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "replacing_percentage_delta_sos",
    accessorKey: "replacing_percentage_delta_sos",
    header: "Replacing % Delta S/S",
    size: 220,
    cell: (context) => numberFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "citations",
    accessorKey: "citations",
    header: "Citations",
    size: 150,
    meta: {
      cellProps: {
        className: "text-center",
      },
    },
  },
  {
    id: "citations_yoy",
    accessorKey: "citations_yoy",
    header: "Citations Y/Y",
    size: 160,
    meta: {
      cellProps: {
        className: "text-center",
      },
    },
  },
  {
    id: "citations_sos",
    accessorKey: "citations_sos",
    header: "Citations S/S",
    size: 160,
    meta: {
      cellProps: {
        className: "text-center",
      },
    },
  },
  {
    id: "net_score",
    accessorKey: "net_score",
    header: "Net Score",
    size: 150,
    cell: (context) => percentageFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "net_score_delta_yoy",
    accessorKey: "net_score_delta_yoy",
    header: "Net Score Delta Y/Y",
    cell: (context) => numberFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "net_score_delta_sos",
    accessorKey: "net_score_delta_sos",
    header: "Net Score Delta S/S",
    cell: (context) => numberFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "pervasion",
    accessorKey: "pervasion",
    header: "Pervasion",
    size: 150,
    cell: (context) => percentageFormatter(context.getValue<number>()),
    meta: {
      cellProps: {
        className: "text-center",
      },
    },
  },
  {
    id: "pervasion_delta_yoy",
    accessorKey: "pervasion_delta_yoy",
    header: "Pervasion Delta Y/Y",
    cell: (context) => numberFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "pervasion_delta_sos",
    accessorKey: "pervasion_delta_sos",
    header: "Pervasion Delta S/S",
    cell: (context) => numberFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
]

type ScreenerMetric = {
  id: number
  company: {
    name: string
    ticker: string
  }
  product: {
    name: string
  }
  sector: {
    name: string
  }
  cut: {
    name: string
  }
  adoption_percentage: number
  adoption_percentage_delta_yoy: number
  adoption_percentage_delta_sos: number
  increase_percentage: number
  increase_percentage_delta_yoy: number
  increase_percentage_delta_sos: number
  flat_percentage: number
  flat_percentage_delta_yoy: number
  flat_percentage_delta_sos: number
  decrease_percentage: number
  decrease_percentage_delta_yoy: number
  decrease_percentage_delta_sos: number
  replacing_percentage: number
  replacing_percentage_delta_yoy: number
  replacing_percentage_delta_sos: number
  citations: number
  citations_yoy: number
  citations_sos: number
  net_score: number
  net_score_delta_yoy: number
  net_score_delta_sos: number
  pervasion: number
  pervasion_delta_yoy: number
  pervasion_delta_sos: number
}
