import { createViewItem } from "@/api/view_items"
import { getWidgets } from "@/api/widgets"
import { useDebounceValue } from "@/hooks/use_debounce_value"
import { cn } from "@/lib/utils"
import { Button } from "@/ui/button"
import { Card, CardDescription, CardHeader, CardTitle } from "@/ui/card"
import { CheckboxRadio } from "@/ui/checkbox"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/ui/dialog"
import { Input } from "@/ui/input"
import { useQuery } from "@tanstack/react-query"
import React, { useState } from "react"
import { toast } from "sonner"

interface WidgetGalleryDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  dashboardViewId: string
}

export function WidgetGalleryDialog(props: WidgetGalleryDialogProps) {
  const { open, onOpenChange, dashboardViewId } = props

  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounceValue(searchTerm, 500)
  const [selectedWidget, setSelectedWidget] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState<string>("All Widgets")

  const { isLoading, data: widgets } = useQuery({
    queryKey: ["widgets", debouncedSearchTerm, selectedCategory],
    queryFn: () => getWidgets({ searchTerm: debouncedSearchTerm, category: selectedCategory }),
  })

  const resetDefaults = () => {
    setSearchTerm("")
    setSelectedWidget(null)
    setSelectedCategory("All Widgets")
  }

  const handleOnOpenChange = (open: boolean) => {
    onOpenChange(open)
    resetDefaults()
  }

  const handleSearchInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchTerm(e.target.value)
  }

  const handleAddToDashboard = () => {
    createViewItem(dashboardViewId, {
      w: 5,
      h: 4,
      widget_type: selectedWidget?.component_key,
    })
      .then(() => {
        window.location.reload()
      })
      .catch((error) => {
        toast.error(`Uh oh!`, {
          description: "Something went wrong!",
        })
      })
  }

  const categories = ["All Widgets", "Time Series", "Survey Highlights", "Spend Focus", "Shared Accounts", "Screener"]

  return (
    <Dialog open={open} onOpenChange={(value) => handleOnOpenChange(value)}>
      <DialogContent className="md:max-w-screen-xl p-0 h-2/3 flex flex-col">
        <DialogHeader className="p-6 pb-0">
          <DialogTitle>Widgets</DialogTitle>
        </DialogHeader>

        <div className="grid grid-cols-12 border-t flex-1 overflow-hidden">
          <div className="col-span-3 space-y-4 border-r p-6">
            <Input placeholder="Search all widgets" onChange={handleSearchInputChange} value={searchTerm} />
            <h6 className="text-sm font-semibold leading-none">Browse</h6>
            <div className="space-y-1">
              {categories.map((text) => (
                <Button
                  key={text}
                  variant="ghost"
                  className={cn("justify-start w-full text-blue-500", {
                    "bg-accent": text === selectedCategory,
                  })}
                  onClick={() => setSelectedCategory(text)}
                >
                  {text}
                </Button>
              ))}
            </div>
          </div>
          <div className="col-span-9 flex flex-col bg-gray-100 space-y-4 p-6 overflow-x-scroll">
            <Button className="ml-auto absolute right-6" onClick={() => handleAddToDashboard()} disabled={!selectedWidget?.id}>
              Add Widget
            </Button>

            <div className="flex-1">
              {isLoading ?
                <div className="flex justify-center mt-10">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              : widgets && widgets.length ?
                <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,1fr))] gap-3 mt-8">
                  {widgets.map((widget: any) => {
                    const { id, name, description } = widget
                    return (
                      <Card
                        key={id}
                        role="button"
                        onClick={() => setSelectedWidget(widget)}
                        className={cn("hover:bg-[#F0F7FE] hover:border-[#80BDFA]", {
                          "bg-[#F0F7FE] border-[#80BDFA]": selectedWidget?.id === id,
                        })}
                      >
                        <CardHeader>
                          <div className="flex flex-row items-center space-x-1.5">
                            <CheckboxRadio checked={selectedWidget?.id === id} aria-label={name} />
                            <CardTitle className="text-blue-500 truncate" aria-label={name} title={name}>
                              {name}
                            </CardTitle>
                          </div>
                          <CardDescription>{description}</CardDescription>
                        </CardHeader>
                      </Card>
                    )
                  })}
                </div>
              : <div className="flex justify-center mt-10">
                  <p className="font-bold">No results found.</p>
                </div>
              }
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
