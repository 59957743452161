import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="greeting"
export default class extends Controller {
  static values = {
    name: String,
  }

  connect() {
    this.element.innerHTML = `<span>${this.getGreeting()},</span>&nbsp;${this.nameValue}!`
  }

  getGreeting() {
    const today = new Date()
    const currentHour = today.getHours()

    if (currentHour < 12) {
      return "Good morning"
    }

    if (currentHour < 18) {
      return "Good afternoon"
    }

    return "Good evening"
  }
}
