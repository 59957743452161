import React from "react"
import { NavbarProps } from "../navbar"
import { Sheet, SheetContent, SheetTrigger } from "@/ui/sheet"
import { ExitIcon, HamburgerMenuIcon } from "@radix-ui/react-icons"
import { NavigationMenu, NavigationMenuLink } from "@/ui/navigation_menu"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, AccordionHeader } from "@/ui/accordion"

export default function MobileMainNav(props: NavbarProps) {
  return (
    <nav className="md:hidden text-white flex justify-between items-center">
      <a href={props.rootPath}>
        <img aria-label="ETR brand logo" src={props.logo}></img>
      </a>
      <Sheet>
        <SheetTrigger>
          <HamburgerMenuIcon width={24} height={24} />
        </SheetTrigger>

        <SheetContent side="top" className="border-slate-800 bg-slate-800 text-white">
          <NavigationMenu orientation="vertical">
            <NavigationMenuLink href="/" className="w-18">
              <img aria-label="ETR brand logo" src={props.logo}></img>
            </NavigationMenuLink>
            <div className="flex flex-col h-[75lvh] mt-10 overflow-auto">
              <NavigationMenuLink href="/dashboards" className="mt-4 text-md hover:underline">
                Dashboards
              </NavigationMenuLink>
              <Accordion type="multiple">
                <AccordionItem value="research" className="border-none">
                  <AccordionHeader>
                    <AccordionTrigger className="text-md">Research</AccordionTrigger>
                  </AccordionHeader>
                  <AccordionContent className="ml-6">
                    <AccordionItem value="tsis">
                      <AccordionHeader>
                        <AccordionTrigger>Technology Spending Intentions</AccordionTrigger>
                      </AccordionHeader>
                      <AccordionContent className="ml-6">
                        <AccordionItem value="tsis-report" className="border-none py-4">
                          <NavigationMenuLink href="/offerings?types[]=survey_report&subtypes[]=tsis" className="hover:underline">
                            Reports
                          </NavigationMenuLink>
                        </AccordionItem>
                        <AccordionItem value="tsis-data-analyzer" className="border-none py-4">
                          <NavigationMenuLink href="/analyze" className="hover:underline">
                            Data Analyzer
                          </NavigationMenuLink>
                        </AccordionItem>
                        <AccordionItem value="tsis-screeners" className="border-none py-4">
                          <NavigationMenuLink href="/analyze/screeners" className="hover:underline">
                            Screeners
                          </NavigationMenuLink>
                        </AccordionItem>
                      </AccordionContent>
                    </AccordionItem>
                    <AccordionItem value="observatory" className="py-4">
                      <NavigationMenuLink href="/offerings?types[]=survey_report&subtypes[]=observatory" className="hover:underline">
                        Observatory
                      </NavigationMenuLink>
                    </AccordionItem>
                    <AccordionItem value="insights" className="py-4">
                      <NavigationMenuLink href="/offerings?types[]=insight" className="hover:underline">
                        Insights
                      </NavigationMenuLink>
                    </AccordionItem>
                    <AccordionItem value="drilldowns" className="py-4">
                      <NavigationMenuLink href="/offerings?types[]=survey_report&subtypes[]=drilldown" className="hover:underline">
                        Drill Downs
                      </NavigationMenuLink>
                    </AccordionItem>
                    <AccordionItem value="ai-product-series" className="py-4">
                      <NavigationMenuLink href="/offerings?types[]=survey_report&subtypes[]=ai_product_series" className="hover:underline">
                        AI Product Series
                      </NavigationMenuLink>
                    </AccordionItem>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>

              <NavigationMenuLink href="/admin" className="text-md no-underline hover:underline">
                Admin
              </NavigationMenuLink>

              <NavigationMenuLink
                className="items-center mt-10 ml-auto font-bold text-sm no-underline flex"
                data-turbo-method="delete"
                href={props.destroyUserSessionPath}
              >
                Logout <ExitIcon className="ml-2" />
              </NavigationMenuLink>
            </div>
          </NavigationMenu>
        </SheetContent>
      </Sheet>
    </nav>
  )
}
