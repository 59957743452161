import { format, isSameDay } from "date-fns"
import { CalendarIcon } from 'lucide-react'
import * as React from "react"
import { DateRange } from "react-day-picker"

import { cn } from "@/lib/utils"
import { Button } from "@/ui/button"
import { Calendar } from "@/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/ui/popover"

export interface PresetRange {
  label: string
  value: DateRange
}

interface DateRangePickerProps extends React.HTMLAttributes<HTMLDivElement> {
  initialDateRange?: DateRange
  onDateRangeChange?: (range: DateRange | undefined) => void
  presetRanges?: PresetRange[]
  numberOfMonths?: number
  calendarClassName?: string
}

export function DateRangePicker({
  className,
  initialDateRange,
  onDateRangeChange,
  presetRanges,
  numberOfMonths = 2,
  calendarClassName,
  ...props
}: DateRangePickerProps) {
  const [date, setDate] = React.useState<DateRange | undefined>(initialDateRange)

  const handleDateChange = (newDate: DateRange | undefined) => {
    setDate(newDate)
    onDateRangeChange?.(newDate)
  }

  const isPresetActive = (preset: PresetRange) => {
    if (!date?.from || !date?.to) return false
    return (
      isSameDay(preset.value.from!, date.from) &&
      isSameDay(preset.value.to!, date.to)
    )
  }

  return (
    <div className={cn("grid gap-2", className)} {...props}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "justify-start text-left font-normal",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <div className="flex">
            <div>
              <Calendar
                initialFocus
                mode="range"
                defaultMonth={date?.to}
                selected={date}
                onSelect={handleDateChange}
                numberOfMonths={numberOfMonths}
                className={calendarClassName}
              />
            </div>
            <div className="flex flex-col border-l p-3">
              {presetRanges && presetRanges.map((preset, index) => (
                <Button
                  key={index}
                  onClick={() => handleDateChange(preset.value)}
                  variant={isPresetActive(preset) ? "default" : "ghost"}
                  className=" justify-start mb-2"
                >
                  {preset.label}
                </Button>
              ))}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

