import { FilterGroupStoreProvider, FilterList, useFilterGroupStore } from "@/components/filters"
import { useDefaultFilter, useFilter } from "@/hooks/use_filter"
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogFooter } from "@/ui/alert_dialog"
import { buttonVariants } from "@/ui/button"
import { ArrowRightIcon } from "@radix-ui/react-icons"
import React from "react"

interface SelectProductDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
}

export function SelectProductDialog(props: SelectProductDialogProps) {
  const { open, onOpenChange } = props

  return (
    <FilterGroupStoreProvider>
      <SelectProductDialogContent open={open} onOpenChange={onOpenChange} />
    </FilterGroupStoreProvider>
  )
}

function SelectProductDialogContent(props: SelectProductDialogProps) {
  const { open, onOpenChange } = props
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const sectorsFilterGroup = useFilterGroupStore((s) => s.sectors)
  const productsFilterGroup = useFilterGroupStore((s) => s.products)

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent className="max-w-[50rem]">
        <div className="flex flex-col items-center justify-between space-y-6 md:flex-row md:space-x-2 md:space-y-0">
          <FilterList
            name="Sector"
            filterGroupId="sectors"
            endpoint="/api/v1/sectors"
            onHandleFilterChange={(filterGroupId, selectedItems) => {
              const products =
                productsFilterGroup?.selectedItems.filter((product) => {
                  return selectedItems.some((sector) => (product.name as string).includes(sector.name as string))
                }) || []

              updateFilterGroups({
                [filterGroupId]: {
                  name: "Sectors",
                  selectedItems,
                },
                products: {
                  name: "Products",
                  selectedItems: products,
                },
              })
            }}
          />
          <ArrowRightIcon className="hidden w-4 h-4 md:block text-muted-foreground align-self-center" />
          <FilterList
            name="Products"
            filterGroupId="products"
            endpoint="/api/v1/products"
            dependencies={["sectors"]}
            params={{
              sectors: sectorsFilterGroup?.selectedItems.map((item) => item.id),
            }}
            formatter={(item) => ({
              ...item,
              subtitle: item.sector.name,
            })}
          />
        </div>

        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>

          <AlertDialogAction
            className={buttonVariants({ variant: "default" })}
            onClick={() => {
              const params = new URLSearchParams({
                sectors: (sectorsFilterGroup?.selectedItems.map((item) => item.id) || []).join(","),
                products: (productsFilterGroup?.selectedItems.map((item) => item.id) || []).join(","),
              }).toString()

              window.location.href = `/analyze/shared_accounts?${params}`
            }}
          >
            Analyze
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
