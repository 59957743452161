import { deleteDashboardView, updateDashboardView } from "@/api/dashboard_views"
import { CreateDashboardViewDialog } from "@/components/dialogs/create_dashboard_view"
import { DeleteConfirmationDialog } from "@/components/dialogs/delete_confirmation"
import { RenameResourceDialog } from "@/components/dialogs/rename_resource"
import { ShareDashboardDialog } from "@/components/dialogs/share_dashboard"
import { WidgetGalleryDialog } from "@/components/dialogs/widget_gallery"
import Grid from "@/components/grid"
import HoverTabs from "@/components/hover_tabs"
import EmptyViewAddWidgetImage from "@/svgs/empty_view_add_widget_image"
import EmptyViewCustomizeWidgetImage from "@/svgs/empty_view_customize_widget_image"
import { Button } from "@/ui/button"
import { Separator } from "@/ui/separator"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/ui/tooltip"
import { DotsVerticalIcon } from "@radix-ui/react-icons"
import React, { useCallback, useState } from "react"
import { HiChevronDown, HiMiniPlus, HiMiniUserPlus } from "react-icons/hi2"
import { useQuery } from "@tanstack/react-query"
import { getWidgets } from "@/api/widgets"

type HandleDialogOpenFn = (trigger: string) => void

export default function DashboardView(props: DashboardViewProps) {
  const { dashboardViews = [], dashboardId, dashboardViewId, dashboardName, canUpdateDashboard, canShareDashboard, items } = props

  const [dialogTrigger, setDialogTrigger] = useState("")
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedView, setSelectedView] = useState<View | null>(null)

  useQuery({
    queryKey: ["widgets"],
    queryFn: () => getWidgets(),
  })

  const handleDialogOpen: HandleDialogOpenFn = (trigger) => {
    setIsDialogOpen(true)
    setDialogTrigger(trigger)
  }

  const handleRenameDashboardView = useCallback(
    async (name: string) => {
      if (!selectedView?.id) return

      await updateDashboardView(dashboardId, selectedView.id, { name })

      window.location.reload()
    },
    [selectedView?.id]
  )

  const handleDashboardDelete = async () => {
    const deleteViewId = selectedView?.id
    const isDeleteSelectedActive = deleteViewId === dashboardViewId
    const viewForDeleteIdx = dashboardViews.map((view) => view.id).indexOf(deleteViewId)

    await deleteDashboardView({ dashboardId, dashboardViewId: deleteViewId })

    let url = `/dashboards`

    const hasViewBefore = !!dashboardViews?.[viewForDeleteIdx - 1]
    const hasViewAfter = !!dashboardViews[viewForDeleteIdx + 1]

    if (isDeleteSelectedActive) {
      if (hasViewBefore) {
        url += `/${dashboardId}/views/${dashboardViews[viewForDeleteIdx - 1]?.id}`
      } else if (hasViewAfter) {
        url += `/${dashboardId}/views/${dashboardViews[viewForDeleteIdx + 1]?.id}`
      } else {
        url += `/${dashboardId}`
      }
    } else {
      url += `/${dashboardId}/views/${dashboardViewId}`
    }

    window.location.replace(url)
  }

  return (
    <>
      <HoverTabs defaultActiveTab={dashboardViewId}>
        <section className="bg-white border-b flex-1 grid gap-2 px-3 lg:px-12 py-3 pb-0">
          <div className="flex">
            <Button className="py-2 px-3 rounded gap-2">
              {dashboardName}
              <HiChevronDown />
            </Button>

            {canShareDashboard && (
              <Button
                className="px-3 gap-2 ml-auto"
                variant="outline"
                onClick={() => {
                  setIsDialogOpen(true)
                  setDialogTrigger("SHARE_DASHBOARD")
                }}
              >
                <HiMiniUserPlus />
                Share Dashboard
              </Button>
            )}
          </div>

          <HoverTabs.TabGroup>
            {dashboardViews?.length ?
              dashboardViews.map((view) => {
                return (
                  <HoverTabs.Tab
                    key={view.id}
                    tabId={view.id}
                    text={view.name}
                    link={`/dashboards/${dashboardId}/views/${view.id}`}
                    showHoverMenu={canUpdateDashboard}
                    hoverMenuIcon={<DotsVerticalIcon />}
                  >
                    <HoverTabs.MenuList>
                      <li
                        className={"cursor-pointer text-sm text-left hover:bg-slate-100 p-1"}
                        onClick={() => {
                          setSelectedView(view)
                          handleDialogOpen("RENAME_VIEW")
                        }}
                      >
                        Rename
                      </li>
                      <Separator />
                      <li
                        className={"text-red-600 cursor-pointer text-sm text-left hover:bg-slate-100 p-1"}
                        onClick={() => {
                          setSelectedView(view)
                          handleDialogOpen("DELETE_VIEW")
                        }}
                      >
                        Delete View
                      </li>
                    </HoverTabs.MenuList>
                  </HoverTabs.Tab>
                )
              })
            : null}

            {canUpdateDashboard && (
              <HoverTabs.StaticTab
                text="Add View"
                icon={<HiMiniPlus />}
                onClickHandler={() => {
                  setIsDialogOpen(true)
                  setDialogTrigger("ADD_VIEW")
                }}
              />
            )}
          </HoverTabs.TabGroup>
        </section>

        <section className="mx-12 my-6">
          {items?.length ?
            <>
              <div className="flex justify-end">
                {canUpdateDashboard ?
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          className="col-2 flex justify-end"
                          onClick={() => handleDialogOpen("WIDGET_GALLERY")}
                          disabled={items.length >= 10}
                        >
                          Add Widget
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>
                          {items.length >= 10 ?
                            "You have reached the maximum number of widgets for this view. 10/10"
                          : `You can add up to ${10 - items.length} more widgets to this view`}
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                : null}
              </div>
              <Grid
                items={items}
                dashboardId={dashboardId}
                dashboardViewId={dashboardViewId}
                isDraggable={canUpdateDashboard}
                isResizable={canUpdateDashboard}
              />
            </>
          : dashboardViewId ?
            <div className="flex flex-col items-center gap-10 text-center">
              <div className="space-y-3">
                <h2 className="text-3xl">View Is Empty</h2>
                <p className="text-sm text-muted-foreground">Refer to the following steps to get started</p>
              </div>
              <div className="grid grid-flow-col auto-cols-max gap-28 justify-between">
                <div className="flex flex-col items-center gap-1">
                  <EmptyViewAddWidgetImage />
                  <h4 className="text-base font-semibold">1. Add a Widget</h4>
                  <p className="text-sm text-muted-foreground w-56">Add content to your view by selecting widgets from our library</p>
                </div>

                <div className="flex flex-col items-center gap-1">
                  <EmptyViewCustomizeWidgetImage />
                  <h4 className="text-base font-semibold">2. Customize</h4>
                  <p className="text-sm text-muted-foreground w-56">Create content hierarchy by sizing and arranging widgets</p>
                </div>
              </div>

              <Button onClick={() => handleDialogOpen("WIDGET_GALLERY")}>Add Widget</Button>
            </div>
          : <>
              <div className="flex flex-col items-center gap-10 text-center">
                <div className="space-y-3">
                  <h2 className="text-3xl">No Views Found</h2>
                  <p className="text-sm text-muted-foreground">Create a new view to get started</p>
                </div>
                <Button onClick={() => handleDialogOpen("ADD_VIEW")}>Add View</Button>
              </div>
            </>
          }
        </section>
      </HoverTabs>

      <CreateDashboardViewDialog
        open={isDialogOpen && dialogTrigger === "ADD_VIEW"}
        onOpenChange={setIsDialogOpen}
        dashboardId={dashboardId}
      />
      <RenameResourceDialog
        open={isDialogOpen && dialogTrigger === "RENAME_VIEW"}
        onConfirm={handleRenameDashboardView}
        onOpenChange={setIsDialogOpen}
        defaultValue={selectedView?.name}
        onCancel={() => setSelectedView(null)}
      />
      <DeleteConfirmationDialog
        open={isDialogOpen && dialogTrigger === "DELETE_VIEW"}
        onConfirm={handleDashboardDelete}
        onOpenChange={setIsDialogOpen}
        message="This action cannot be undone. This will permanently delete this view and all its associated widgets."
        onCancel={() => setSelectedView(null)}
      />
      <ShareDashboardDialog
        dashboardId={dashboardId}
        open={isDialogOpen && dialogTrigger === "SHARE_DASHBOARD"}
        onOpenChange={setIsDialogOpen}
      />

      <WidgetGalleryDialog
        open={isDialogOpen && dialogTrigger === "WIDGET_GALLERY"}
        onOpenChange={setIsDialogOpen}
        dashboardViewId={dashboardViewId}
      />
    </>
  )
}

interface View {
  id: string
  dashboardId: string
  items: []
  name: string
}

interface DashboardViewProps {
  dashboardViews: View[]
  items: any[]
  dashboardId: string
  dashboardViewId: string
  dashboardName: string
  canUpdateDashboard: boolean
  canShareDashboard: boolean
}
