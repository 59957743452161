import React from "react"

export default function InsightsIcon({ className }: { className?: string }) {
  return (
    <svg width="340" height="340" viewBox="0 0 340 340" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g clipPath="url(#clip0_699_145104)">
        <path
          d="M22.0105 64.8911C19.9168 65.7397 18.0832 66.5417 16.5282 67.2566C17.0055 69.5139 17.6273 72.2586 18.4173 75.405C20.8162 84.9595 24.7466 98.1409 30.8514 112.585C43.1413 141.664 63.7893 174.802 97.4364 194.569C130.948 214.257 166.745 214.562 194.768 209.628C208.735 207.168 220.566 203.438 228.886 200.328C231.88 199.209 234.408 198.174 236.406 197.314C236.394 195.213 236.319 192.583 236.111 189.514C235.524 180.849 233.886 168.792 229.69 155.353C221.338 128.607 202.886 96.3804 161.955 74.1445C120.623 51.6909 82.6306 50.8789 54.948 55.6052C41.0666 57.9752 29.7788 61.7422 22.0105 64.8911Z"
          stroke="url(#paint0_linear_699_145104)"
          strokeWidth="20"
        />
        <path
          d="M317.992 64.8911C320.086 65.7397 321.92 66.5417 323.475 67.2566C322.997 69.5139 322.376 72.2586 321.586 75.405C319.187 84.9595 315.256 98.1409 309.151 112.585C296.862 141.664 276.214 174.802 242.566 194.569C209.055 214.257 173.258 214.562 145.235 209.628C131.268 207.168 119.437 203.438 111.117 200.328C108.123 199.209 105.594 198.174 103.597 197.314C103.609 195.213 103.684 192.583 103.892 189.514C104.479 180.849 106.117 168.792 110.313 155.353C118.665 128.607 137.117 96.3804 178.048 74.1445C219.38 51.6909 257.372 50.8789 285.055 55.6052C298.936 57.9752 310.224 61.7422 317.992 64.8911Z"
          stroke="url(#paint1_linear_699_145104)"
          strokeWidth="20"
        />
        <path
          d="M163.651 82.5167C166.161 80.8211 168.337 79.493 170.05 78.5068C171.702 79.457 173.786 80.7289 176.188 82.3495C182.682 86.7305 191.444 93.6231 200.278 103.549C217.833 123.271 235.957 155.261 236.818 204.305C237.676 253.24 220.315 283.885 203.279 302.301C194.678 311.599 186.039 317.901 179.607 321.853C176.988 323.462 174.747 324.675 173.038 325.536C171.211 324.633 168.78 323.346 165.922 321.63C159.135 317.554 150.014 311.1 140.87 301.675C122.73 282.979 104.154 252.283 103.869 204.07C103.583 155.774 121.5 123.868 139.195 104.005C148.101 94.0076 157.014 86.9992 163.651 82.5167ZM176.679 327.199L176.68 327.2C176.684 327.201 176.684 327.201 176.679 327.199Z"
          stroke="url(#paint2_linear_699_145104)"
          strokeWidth="20"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_699_145104" x1="1.08305" y1="70.2071" x2="250.673" y2="194.893" gradientUnits="userSpaceOnUse">
          <stop stopColor="#55E3F6" />
          <stop offset="1" stopColor="#21409C" />
        </linearGradient>
        <linearGradient id="paint1_linear_699_145104" x1="338.92" y1="70.2071" x2="89.3304" y2="194.893" gradientUnits="userSpaceOnUse">
          <stop stopColor="#55E3F6" />
          <stop offset="1" stopColor="#21409C" />
        </linearGradient>
        <linearGradient id="paint2_linear_699_145104" x1="170.357" y1="67.2021" x2="170.357" y2="336.522" gradientUnits="userSpaceOnUse">
          <stop stopColor="#55E3F6" />
          <stop offset="1" stopColor="#21409C" />
        </linearGradient>
        <clipPath id="clip0_699_145104">
          <rect width="340" height="340" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
