import { FilterListItem, useFilterGroupStore } from "@/components/filters"
import { useFilter } from "@/hooks/use_filter"
import { cn, getFilterSelectionText } from "@/lib/utils"
import { Button, ButtonProps } from "@/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/ui/popover"
import { Separator } from "@/ui/separator"
import { Switch } from "@/ui/switch"
import { ToggleGroup, ToggleGroupItem } from "@/ui/toggle_group"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import { ToggleGroupMultipleProps, ToggleGroupSingleProps } from "@radix-ui/react-toggle-group"
import { formatISO, getYear, parse, parseISO } from "date-fns"
import React, { useEffect, useRef, useState } from "react"
import { useDebouncedCallback } from "use-debounce"

enum SurveyDateToggleGroupType {
  PERIOD = "period",
  YEAR = "year",
}
interface SurveyDatesProps extends ButtonProps {
  mode: ToggleGroupSingleProps["type"] | ToggleGroupMultipleProps["type"]
  text?: string
}

const SURVEY_PERIODS = ["Jan", "Apr", "Jul", "Oct"]

const SURVEY_YEARS = Array.from({ length: new Date().getFullYear() - 2010 + 1 }, (_, i) => 2010 + i)
  .map(String)
  .reverse()

export function SurveyDates({ className, mode, text = "Surveys", ...props }: SurveyDatesProps) {
  const misc = useFilterGroupStore((s) => s.misc)
  const filterGroup = useFilterGroupStore((s) => s.survey_dates)
  const selectedValueText = getFilterSelectionText(filterGroup?.selectedItems, {
    multipleSelectionText: Boolean(misc?.selectedItems?.find((item) => item.id === "fetch_latest_survey")?.name) ? "Latest" : "Multiple",
  })

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className={cn("px-3 flex items-center bg-slate-100 border-transparent", className)} {...props}>
          <span className="truncate">
            {text}
            {": "}

            <span className="text-sky-700 font-medium">{selectedValueText}</span>
          </span>
          <ChevronDownIcon className="w-4 h-4 ml-2" />
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="space-y-4">
        <SurveyDatesPopoverContent mode={mode} />
      </PopoverContent>
    </Popover>
  )
}

const SurveyDatesPopoverContent = ({ mode }: { mode: SurveyDatesProps["mode"] }) => {
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const misc = useFilterGroupStore((s) => s.misc)
  const dates = useFilter("survey_dates", { returnType: "multiple" })
  const settings = misc?.selectedItems || []
  const shouldFetchLatestSurvey = Boolean(settings?.find((setting) => setting.id === "fetch_latest_survey")?.name)
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>(getSelectedPeriodsFromDates(dates as string[]))
  const [selectedYears, setSelectedYears] = useState<string[]>(getSelectedYearsFromDates(dates as string[]))
  const [showLatestDataEnabled, setShowLatestDataEnabled] = useState(Boolean(shouldFetchLatestSurvey))
  const hasSurveyDatesBeenRendered = useRef(false)

  const handleValueChange = (toggleType: SurveyDateToggleGroupType) => (value: string | string[]) => {
    if (mode === "single" && !value) return

    const values = Array.isArray(value) ? value : [value]

    toggleType === SurveyDateToggleGroupType.PERIOD ? setSelectedPeriods(values) : setSelectedYears(values)
  }

  const handleUpdateFilterGroups = useDebouncedCallback(() => {
    if (!hasSurveyDatesBeenRendered.current) {
      hasSurveyDatesBeenRendered.current = true
      return
    }

    if (showLatestDataEnabled) {
      updateFilterGroups({
        misc: {
          name: "Misc",
          hidden: true,
          selectedItems: [...settings.filter((setting) => setting.id !== "fetch_latest_survey"), { id: "fetch_latest_survey", name: true }],
        },
      })

      return
    }

    const periods = selectedPeriods.length ? selectedPeriods : SURVEY_PERIODS
    const years = selectedYears.length ? selectedYears : SURVEY_YEARS
    const dates = getDatesFromPeriodsAndYears(periods, years)

    updateFilterGroups({
      misc: {
        name: "Misc",
        hidden: true,
        selectedItems: settings.filter((setting) => setting.id !== "fetch_latest_survey"),
      },
      survey_dates: {
        name: "Surveys",
        permanent: true,
        selectedItems: dates,
      },
    })
  }, 500)

  useEffect(handleUpdateFilterGroups, [selectedPeriods, selectedYears, showLatestDataEnabled])

  return (
    <>
      <div className="items-top flex space-x-2">
        <div className="grid gap-1.5 leading-none">
          <label
            htmlFor="latest-data"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Latest Survey Data
          </label>
          <p className="text-xs text-muted-foreground">Models show the most recent data from the current survey.</p>
        </div>

        <Switch
          id="latest-data"
          checked={showLatestDataEnabled}
          onCheckedChange={setShowLatestDataEnabled}
          className="data-[state=checked]:bg-blue-500 data-[state=checked]:text-primary-foreground"
        />
      </div>
      {!showLatestDataEnabled && (
        <>
          <Separator className="my-2" />
          {mode === "single" ?
            <>
              <ToggleGroup
                type={mode}
                className="flex-col items-stretch gap-2"
                onValueChange={handleValueChange(SurveyDateToggleGroupType.PERIOD)}
                value={selectedPeriods[0]}
              >
                <h6 className="text-sm font-medium m-0">Survey Periods</h6>
                <div className="grid grid-cols-4 gap-1">
                  {SURVEY_PERIODS.map((period) => (
                    <ToggleGroupItem
                      key={period}
                      value={period}
                      aria-label={`Toggle ${period}`}
                      disabled={showLatestDataEnabled}
                      className="data-[state=on]:bg-blue-500 data-[state=on]:text-primary-foreground"
                    >
                      {period}
                    </ToggleGroupItem>
                  ))}
                </div>
              </ToggleGroup>
              <Separator className="my-2" />
              <ToggleGroup
                type={mode}
                className="flex-col items-stretch gap-2"
                onValueChange={handleValueChange(SurveyDateToggleGroupType.YEAR)}
                value={selectedYears[0]}
              >
                <h6 className="text-sm font-medium m-0">Survey Years</h6>
                <div className="grid grid-cols-3 gap-1">
                  {SURVEY_YEARS.map((year) => (
                    <ToggleGroupItem
                      key={year}
                      value={year}
                      aria-label={`Toggle ${year}`}
                      disabled={showLatestDataEnabled}
                      className="data-[state=on]:bg-blue-500 data-[state=on]:text-primary-foreground"
                    >
                      {year}
                    </ToggleGroupItem>
                  ))}
                </div>
              </ToggleGroup>
            </>
          : <>
              <ToggleGroup
                type={mode}
                className="flex-col items-stretch gap-2"
                onValueChange={handleValueChange(SurveyDateToggleGroupType.PERIOD)}
                value={selectedPeriods}
              >
                <h6 className="text-sm font-medium m-0">Survey Periods</h6>
                <div className="grid grid-cols-4 gap-1">
                  {SURVEY_PERIODS.map((period) => (
                    <ToggleGroupItem
                      key={period}
                      value={period}
                      aria-label={`Toggle ${period}`}
                      disabled={showLatestDataEnabled}
                      className="data-[state=on]:bg-blue-500 data-[state=on]:text-primary-foreground"
                    >
                      {period}
                    </ToggleGroupItem>
                  ))}
                </div>
              </ToggleGroup>
              <Separator className="my-2" />
              <ToggleGroup
                type={mode}
                className="flex-col items-stretch gap-2"
                onValueChange={handleValueChange(SurveyDateToggleGroupType.YEAR)}
                value={selectedYears}
              >
                <h6 className="text-sm font-medium m-0">Survey Years</h6>
                <div className="grid grid-cols-3 gap-1">
                  {SURVEY_YEARS.map((year) => (
                    <ToggleGroupItem
                      key={year}
                      value={year}
                      aria-label={`Toggle ${year}`}
                      disabled={showLatestDataEnabled}
                      className="data-[state=on]:bg-blue-500 data-[state=on]:text-primary-foreground"
                    >
                      {year}
                    </ToggleGroupItem>
                  ))}
                </div>
              </ToggleGroup>
            </>
          }
        </>
      )}
    </>
  )
}

const getSelectedPeriodsFromDates = (dates: string[]): string[] => [
  ...new Set(
    dates.map((date) =>
      parseISO(date).toLocaleString("default", {
        month: "short",
      })
    )
  ),
]

const getSelectedYearsFromDates = (dates: string[]): string[] => [...new Set(dates.map((date) => getYear(parseISO(date)).toString()))]

const getDatesFromPeriodsAndYears = (periods: string[], years: string[]): FilterListItem[] =>
  years.flatMap((year) =>
    periods.map((period) => {
      const month = SURVEY_PERIODS.indexOf(period) * 3 + 1
      const date = parse(`${year}-${month}-01`, "yyyy-M-dd", new Date())
      const id = formatISO(date, { representation: "date" })

      return {
        id,
        name: `${period} ${year}`,
      }
    })
  )
