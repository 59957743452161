import { useEffect, useRef } from "react"

/**
 * Identical to React.useEffect, except that it never runs on mount. This is
 * the equivalent of the componentDidUpdate lifecycle function.
 */
export const useEffectExceptOnMount = (effect: React.EffectCallback, dependencies: React.DependencyList) => {
  const mounted = useRef(false)

  useEffect(() => {
    if (mounted.current) {
      const unmount = effect()
      return () => unmount && unmount()
    } else {
      mounted.current = true
    }
  }, dependencies)

  // Reset on unmount for the next mount.
  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])
}
