import React from "react"

export default function ObservatoryIcon({ className }: { className?: string }) {
  return (
    <svg width="340" height="340" viewBox="0 0 340 340" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M252.721 243.478C301.014 243.478 291.667 228.419 291.667 228.419L248.988 185.012L247.601 77.3671C254.125 75.7152 258.952 69.8053 258.952 62.7679C258.952 54.451 252.21 47.7088 243.893 47.7088C236.829 47.7088 230.901 52.5721 229.275 59.133H125.223L78.7622 11.8789C78.7622 11.8789 65.7797 11.8789 65.7796 62.2487C65.7796 162.052 149.763 243.478 252.721 243.478ZM236.218 80.6177L237.412 173.237L191.581 126.624L236.218 80.6177ZM230.061 70.5572H136.455L183.57 118.476L230.061 70.5572ZM110.957 199.339C97.9748 186.357 93.8205 167.144 93.8205 167.144L98.494 212.321L79.2806 286.059H63.4426L47.6045 328.121H189.368L169.636 286.059H156.134L131.728 220.63L137.959 212.321C137.959 212.321 123.939 212.321 110.957 199.339Z"
        fill="url(#paint0_linear_1170_26)"
      />
      <defs>
        <linearGradient id="paint0_linear_1170_26" x1="47.6045" y1="-9.41162" x2="204.427" y2="327.602" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B895F5" />
          <stop offset="1" stopColor="#571CAF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
