import { cn, getCommonPinningStyles } from "@/lib/utils"
import { Button } from "@/ui/button"
import { Input } from "@/ui/input"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/ui/select"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/ui/table"
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core"
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers"
import { horizontalListSortingStrategy, SortableContext, useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import {
  CaretDownIcon,
  CaretSortIcon,
  CaretUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  DragHandleDots2Icon,
} from "@radix-ui/react-icons"
import { Cell, flexRender, Header, type Table as TableTypeDef } from "@tanstack/react-table"
import React, { CSSProperties } from "react"

export interface DataTableProps<TData> {
  loading?: boolean
  className?: string
  table: TableTypeDef<TData>
  enableDragAndDropHeader?: boolean
  handleDragEnd?(event: DragEndEvent): void
}

export function DataTable<TData>(props: DataTableProps<TData>) {
  const { table, className, loading, enableDragAndDropHeader = false, handleDragEnd } = props
  const columnOrder = table.getState().columnOrder || table.getAllColumns().map((c) => c.id!)
  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}))

  return (
    <DndContext collisionDetection={closestCenter} modifiers={[restrictToHorizontalAxis]} onDragEnd={handleDragEnd} sensors={sensors}>
      <Table className={cn("table-auto w-full", className)} loading={loading}>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className="sticky z-[2] top-0">
              <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
                {headerGroup.headers.map((header) => (
                  <DraggableTableHeader key={header.id} header={header} draggable={enableDragAndDropHeader} />
                ))}
              </SortableContext>
            </TableRow>
          ))}
        </TableHeader>

        <TableBody>
          {table.getRowModel().rows?.length ?
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                {row.getVisibleCells().map((cell) => (
                  <SortableContext key={cell.id} items={columnOrder} strategy={horizontalListSortingStrategy}>
                    <DragAlongCell key={cell.id} cell={cell} />
                  </SortableContext>
                ))}
              </TableRow>
            ))
          : <TableRow>
              <TableCell colSpan={table.getVisibleFlatColumns().length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </DndContext>
  )
}

const DraggableTableHeader = ({ header, draggable = false }: { header: Header<any, unknown>; draggable?: boolean }) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
    id: header.column.id,
  })

  const isPinned = header.column.getIsPinned()
  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    // position: "relative",
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: "width transform 0.2s ease-in-out",
    whiteSpace: "nowrap",
    width: header.column.getSize(),
    zIndex: isDragging || isPinned ? 1 : 0,
  }

  const enableDragging = draggable && !isPinned

  if (header.isPlaceholder) {
    return <TableHead colSpan={header.colSpan}></TableHead>
  }

  return (
    <TableHead
      colSpan={header.colSpan}
      ref={setNodeRef}
      style={{ ...getCommonPinningStyles(header.column), ...style }}
      title={
        header.column.getCanSort() ?
          header.column.getNextSortingOrder() === "asc" ?
            "Sort ascending"
          : header.column.getNextSortingOrder() === "desc" ?
            "Sort descending"
          : "Clear sort"
        : undefined
      }
    >
      <span className="inline-flex items-center justify-between">
        {enableDragging ?
          <Button variant="ghost" size="sm" {...attributes} {...listeners}>
            <DragHandleDots2Icon />
          </Button>
        : null}
        {header.column.getCanSort() ?
          <Button className="font-semibold text-slate-700" variant="ghost" size="sm" onClick={header.column.getToggleSortingHandler()}>
            {flexRender(header.column.columnDef.header, header.getContext())}
            {{
              asc: <CaretUpIcon className="ml-2 h-4 w-4" />,
              desc: <CaretDownIcon className="ml-2 h-4 w-4" />,
            }[header.column.getIsSorted() as string] ?? <CaretSortIcon className="ml-2 h-4 w-4" />}
          </Button>
        : <Button className="font-semibold text-slate-700" variant="ghost" size="sm">
            {flexRender(header.column.columnDef.header, header.getContext())}
          </Button>
        }
      </span>
    </TableHead>
  )
}

const DragAlongCell = ({ cell }: { cell: Cell<any, unknown> }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  })

  const isPinned = cell.column.getIsPinned()
  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    // position: "relative",
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: "width transform 0.2s ease-in-out",
    width: cell.column.getSize(),
    zIndex: isDragging || isPinned ? 1 : 0,
  }

  const meta = cell.column.columnDef.meta

  return (
    <TableCell
      style={{ ...getCommonPinningStyles(cell.column), ...style }}
      ref={setNodeRef}
      title={cell.getValue()}
      {...meta?.cellProps}
      {...meta?.getCellProps?.(cell.getContext())}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </TableCell>
  )
}

interface DataTablePaginationProps<TData> {
  table: TableTypeDef<TData>
  pageSizeOptions?: number[]
}

export function DataTablePagination<TData>({ table, pageSizeOptions = [25, 50, 100] }: DataTablePaginationProps<TData>) {
  return (
    <div className="flex w-full flex-col-reverse items-center justify-between gap-4 overflow-x-auto overflow-y-hidden p-1 sm:flex-row sm:gap-8">
      <div className="flex-1 whitespace-nowrap text-sm text-muted-foreground">
        Showing {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} -{" "}
        {Math.min((table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize, table.getRowCount())} of{" "}
        {table.getRowCount().toLocaleString()} rows
      </div>
      <div className="flex flex-col-reverse items-center gap-4 sm:flex-row sm:gap-6 lg:gap-8">
        <div className="flex items-center space-x-2">
          <p className="whitespace-nowrap text-sm font-medium">Rows per page</p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value))
            }}
          >
            <SelectTrigger className="h-8 w-[4.5rem]">
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {pageSizeOptions.map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center justify-center text-sm font-medium">
          Page {table.getPageCount() ? table.getState().pagination.pageIndex + 1 : 0} of {table.getPageCount()}
        </div>
        <div className="flex items-center space-x-2">
          <Button
            aria-label="Go to first page"
            variant="outline"
            className="hidden size-8 p-0 lg:flex"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <DoubleArrowLeftIcon className="size-4" aria-hidden="true" />
          </Button>
          <Button
            aria-label="Go to previous page"
            variant="outline"
            size="icon"
            className="size-8"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <ChevronLeftIcon className="size-4" aria-hidden="true" />
          </Button>
          <Button
            aria-label="Go to next page"
            variant="outline"
            size="icon"
            className="size-8"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <ChevronRightIcon className="size-4" aria-hidden="true" />
          </Button>
          <Button
            aria-label="Go to last page"
            variant="outline"
            size="icon"
            className="hidden size-8 lg:flex"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <DoubleArrowRightIcon className="size-4" aria-hidden="true" />
          </Button>
        </div>
      </div>
    </div>
  )
}

interface DataSearchProps<TData> {
  className?: string
  table: TableTypeDef<TData>
  searchKey: string
  disabled?: boolean
}

export function DataSearch<TData>(props: DataSearchProps<TData>) {
  const { table, searchKey, disabled } = props

  return (
    <div className="flex items-center">
      <Input
        className="max-w-sm"
        onChange={(event) => table.getColumn(searchKey)?.setFilterValue(event.target.value)}
        placeholder="Search"
        type="search"
        value={(table.getColumn(searchKey)?.getFilterValue() as string) ?? ""}
        disabled={disabled}
      />
    </div>
  )
}
