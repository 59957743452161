import { getCuts } from "@/api/cuts"
import { getSharedAccountsPeerPositionSeries } from "@/api/shared_accounts/peer_position"
import {
  FilterGroupKeys,
  FilterList,
  FilterListDropdown,
  FilterRange,
  RadioList,
  SurveyDates,
  getSelectedRange,
  useFilterGroupStore,
} from "@/components/filters"
import { WIDGET_ASPECT_RATIO, Widget, WidgetPropsBase } from "@/components/widget"
import { WithAspectRatio } from "@/components/with_aspect_ratio"
import { useChartLoading } from "@/hooks/use_chart_loading"
import { useDefaultFilter, useFilter } from "@/hooks/use_filter"
import { useSurveyDates } from "@/hooks/use_survey_dates"
import { HighchartsReact as HC, Highcharts, defaultChartOptions } from "@/lib/highcharts"
import { SURVEY_NS_COMPARISON_ITEMS } from "@/lib/utils"
import { ArrowRightIcon, BarChartIcon } from "@radix-ui/react-icons"
import { useQuery } from "@tanstack/react-query"
import { sortBy } from "lodash"
import React, { useEffect, useMemo } from "react"

export const SharedAccountsPeerPositionWidget = ({ ...props }: WidgetPropsBase) => {
  return (
    <Widget componentKey={"peer_position"} {...props}>
      <Widget.PrimaryFilters>
        <SharedAccountsPeerPositionWidgetPrimaryFilters />
      </Widget.PrimaryFilters>

      <Widget.SecondaryFilters>
        <SharedAccountsPeerPositionWidgetSecondaryFilters hiddenFilters={props.hiddenFilters} />
      </Widget.SecondaryFilters>

      <Widget.Body>
        <Content renderAspectRatioContainer={props.renderAspectRatioContainer} />
        {props.children}
      </Widget.Body>
    </Widget>
  )
}

const SharedAccountsPeerPositionWidgetPrimaryFilters = () => {
  const productFilterGroup = useFilterGroupStore((s) => s.products)
  const sectors = useFilter("sectors", { returnType: "multiple" })
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)

  return (
    <>
      <FilterList
        name="Sector"
        filterGroupId="sectors"
        endpoint="/api/v1/sectors"
        onHandleFilterChange={(filterGroupId, items) => {
          const products =
            productFilterGroup?.selectedItems.filter((product) => {
              return items.some((sector) => (product.name as string).includes(sector.name as string))
            }) || []

          updateFilterGroups({
            [filterGroupId]: {
              name: "Sectors",
              selectedItems: items,
            },
            products: {
              name: "Products",
              selectedItems: products,
            },
          })
        }}
      />
      <ArrowRightIcon className="hidden w-4 h-4 md:block text-muted-foreground align-self-center" />
      <FilterList
        name="Products"
        filterGroupId="products"
        endpoint="/api/v1/products"
        params={{
          sectors,
        }}
        formatter={(item) => ({
          ...item,
          subtitle: item.sector.name,
        })}
      />
    </>
  )
}

const SharedAccountsPeerPositionWidgetSecondaryFilters = ({ hiddenFilters }: Pick<WidgetPropsBase, "hiddenFilters">) => {
  const displaySectorFilterGroup = useFilterGroupStore((s) => s.display_sectors)
  const displayProductFilterGroup = useFilterGroupStore((s) => s.display_products)
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)

  const filters: Array<[FilterGroupKeys, React.ReactNode]> = [
    ["survey_dates", <SurveyDates mode={SURVEY_DATE_MODE} size="sm" />],
    [
      "cuts",
      <FilterListDropdown
        className="w-full text-sm"
        name="Cut"
        text="Cut"
        fallbackText="All Respondents"
        filterGroupId="cuts"
        endpoint="/api/v1/cuts"
        enableMultiRowSelection={false}
        size="sm"
      />,
    ],
    [
      "metrics",
      <FilterListDropdown
        className="w-full text-sm"
        name="Metrics"
        text="Metrics"
        fallbackText="All"
        filterGroupId="metrics"
        endpoint="/api/v1/metrics"
        size="sm"
      />,
    ],
    [
      "display_sectors",
      <FilterListDropdown
        className="w-full text-sm"
        name="Display Sector"
        text="Sector"
        fallbackText="All"
        filterGroupId="display_sectors"
        endpoint="/api/v1/sectors"
        enableMultiRowSelection={false}
        size="sm"
        onHandleFilterChange={(filterGroupId, selectedItems) => {
          updateFilterGroups({
            [filterGroupId]: {
              name: "Display Sector",
              ...displaySectorFilterGroup,
              selectedItems,
            },
            display_products: {
              name: "Display Products",
              ...displayProductFilterGroup,
              selectedItems: [],
            },
          })
        }}
      />,
    ],
    [
      "display_products",
      <FilterListDropdown
        className="w-full text-sm"
        name="Display Products"
        text="Products"
        fallbackText="All"
        filterGroupId="display_products"
        endpoint="/api/v1/products"
        params={{ sectors: displaySectorFilterGroup?.selectedItems.map((item) => item.id) }}
        dependencies={["display_sectors"]}
        formatter={(item) => ({
          ...item,
          subtitle: item.sector.name,
        })}
        size="sm"
      />,
    ],
    ["citations", <FilterRange text="Min Market Share(n)" filterGroupId="citations" size="sm" />],
    [
      "shared_ns_comparison",
      <RadioList
        text="Shared NS"
        filterGroupId="shared_ns_comparison"
        items={SURVEY_NS_COMPARISON_ITEMS}
        defaultSelection={SURVEY_NS_COMPARISON_ITEMS[0]!}
        size="sm"
      />,
    ],
  ]

  const filterComponents = filters
    .filter(([key, _component]) => !hiddenFilters?.includes(key))
    .map(([key, component]) => <React.Fragment key={key}>{component}</React.Fragment>)

  return <div className="flex items-center flex-grow space-x-4">{filterComponents}</div>
}

const SURVEY_DATE_MODE = "multiple"
const HIGHCHARTS_SELECTED_SERIES_LINE_ID = "selected-product-line"

function Content(props: Pick<WidgetPropsBase, "renderAspectRatioContainer">) {
  const { renderAspectRatioContainer } = props

  const targetSectors = useFilter("sectors", { returnType: "multiple" })
  const targetProducts = useFilter("products", { returnType: "multiple" })
  const metrics = useFilter("metrics", { returnType: "multiple" })
  const displaySectors = useFilter("display_sectors", { returnType: "multiple" })
  const displayProducts = useFilter("display_products", { returnType: "multiple" })
  const citations = useFilter("citations", { returnType: "single" })
  const marketShareRange = getSelectedRange(citations, true)
  const sharedNSComparison = useFilter("shared_ns_comparison", { returnType: "single" })
  const { value: cut } = useDefaultFilter("cuts", {
    queryFn: getCuts,
    select: (data: any) => data.filter((item: any) => item.name === "All Respondents"),
    returnType: "single",
  })

  const { value: surveys, isLoading: surveyIsLoading } = useSurveyDates(SURVEY_DATE_MODE)
  const widgetEnabled = !!cut && !!surveys.length && !surveyIsLoading && !!displaySectors.length

  const { isLoading, data } = useQuery({
    queryKey: [
      "shared_accounts_peer_position",
      { cut, metrics, targetSectors, targetProducts, displaySectors, displayProducts, sharedNSComparison, marketShareRange, surveys },
    ],
    queryFn: () => {
      return getSharedAccountsPeerPositionSeries({
        cut,
        metrics,
        sectors: targetSectors,
        products: targetProducts,
        surveys: surveys,
        display_products: displayProducts,
        display_sectors: displaySectors,
        shared_ns_comparison: sharedNSComparison,
        market_share_range: marketShareRange,
      } as any)
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: widgetEnabled,
  })

  if (!widgetEnabled) {
    return (
      <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
        <div className="h-full flex flex-col items-center justify-center">
          <BarChartIcon className="h-48 w-48 text-muted" />
          <p className="text-muted-foreground text-sm">Please select a display sector to view this visual.</p>
        </div>
      </WithAspectRatio>
    )
  }

  if (data?.length === 0) return <Widget.Empty />

  return (
    <div className="relative flex-1">
      <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
        <SharedAccountsPeerPositionChart series={data} loading={isLoading} />
      </WithAspectRatio>
    </div>
  )
}

interface SharedAccountsPeerPositionChartProps {
  series: Highcharts.SeriesOptionsType[]
  loading?: boolean
}

function SharedAccountsPeerPositionChart(props: SharedAccountsPeerPositionChartProps) {
  const { series, loading = true } = props

  const options = useMemo<Highcharts.Options>(
    () => ({
      ...defaultChartOptions,
      chart: {
        ...defaultChartOptions.chart,
        events: {
          click: function (e) {
            this.getSelectedPoints().forEach((point) => {
              point.select(false, false)
            })
          },
        },
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        title: {
          text: "Overlap %",
        },
        labels: {
          format: "{value}%",
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
      },
      yAxis: {
        title: {
          text: "Shared Net Score",
        },
        labels: {
          format: "{value}%",
        },
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          findNearestPointBy: "xy",
          dataLabels: {
            enabled: true,
            format: "{point.name}",
            padding: 5,
            style: {
              fontWeight: "initial",
            },
          },
          stickyTracking: false,
          cursor: "pointer",
          marker: {
            enabled: true,
            radius: 4.5,
            symbol: "circle",
            states: {
              select: {
                enabled: true,
                fillColor: "current",
                lineWidth: 0,
              },
            },
          },
          point: {
            events: {
              select: function () {
                this.series.chart.series.forEach((series) => {
                  if (series.name !== this.series.name) {
                    series.update({ visible: false } as Highcharts.SeriesOptionsType, false)
                  }
                })

                this.series.chart.addSeries(
                  {
                    id: HIGHCHARTS_SELECTED_SERIES_LINE_ID,
                    type: "line",
                    allowPointSelect: false,
                    showInLegend: false,
                    color: this.series.color,
                    dataLabels: {
                      enabled: false,
                    },
                    data: sortBy(
                      this.series.data.map((point) => ({
                        name: point.name,
                        x: point.x,
                        y: point.y,
                        custom: point.custom,
                        visible: true,
                      })),
                      "custom.survey_date"
                    ),
                  },
                  false
                )

                chartRef.current?.chart?.redraw()
              },
              unselect: function () {
                this.series.chart.get(HIGHCHARTS_SELECTED_SERIES_LINE_ID)?.remove(false)

                this.series.chart.series.forEach((series) => {
                  if (series.name !== this.series.name) {
                    series.update({ visible: true } as Highcharts.SeriesOptionsType, false)
                  }
                })

                chartRef.current?.chart?.redraw()
              },
            },
          },
        },
      },
      series,
      tooltip: {
        useHTML: true,
        followPointer: true,
        formatter: function () {
          return `<p>Product - Sector: <span class="font-bold">${this.point.name}</span></p>
        <p>Overlap %: <span class="font-bold">${this.point.x}%</span></p>
        <p>Shared Net Score: <span class="font-bold">${this.point.y}%</span></p>
        <p>Survey Date: <span class="font-bold">${this.point.custom?.survey_date_formatted}</span></p>
        <p>Shared N: <span class="font-bold">${this.point.custom?.shared_n}</span></p>
        <p>Survey Net Score: <span class="font-bold">${this.point.custom?.survey_net_score}%</span></p>
        `
        },
      },
    }),
    [series]
  )

  const chartRef = useChartLoading(loading)

  useEffect(() => {
    chartRef?.current?.chart?.getSelectedPoints().forEach((point) => {
      point.select(false, false)
    })
  })

  return (
    <HC highcharts={Highcharts} options={options} containerProps={{ className: "absolute top-0 right-0 bottom-0 left-0" }} ref={chartRef} />
  )
}
