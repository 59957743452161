import { getCutGroups } from "@/api/cut_groups"
import { getTsisSectorMetrics } from "@/api/tsis_sector_metrics"
import {
  FilterGroupKeys,
  FilterList,
  FilterListItem,
  MetricList,
  SurveyDates,
  getDisplayToggleState,
  getSelectedMetrics,
  useFilterGroupStore,
} from "@/components/filters"
import { WIDGET_ASPECT_RATIO, Widget, WidgetPropsBase } from "@/components/widget"
import { WithAspectRatio } from "@/components/with_aspect_ratio"
import { useChartLoading } from "@/hooks/use_chart_loading"
import { useFilter } from "@/hooks/use_filter"
import { useSurveyDates } from "@/hooks/use_survey_dates"
import { HighchartsReact as HC, Highcharts, defaultChartOptions } from "@/lib/highcharts"
import { cn } from "@/lib/utils"
import { BarChartIcon } from "@radix-ui/react-icons"
import { useQuery } from "@tanstack/react-query"
import { groupBy, round, sum } from "lodash"
import React, { useEffect, useState } from "react"

const DISPLAY_FILTER_OPTIONS: FilterListItem[] = [
  { id: "enterprise", name: "Enterprise" },
  { id: "industry", name: "Industry" },
  { id: "job-title", name: "Job Title" },
  { id: "region", name: "Region" },
]

export const SectorRespondentSubsampleWidget = ({ ...props }: WidgetPropsBase) => {
  return (
    <Widget
      componentKey={"sector_respondent_subsample"}
      displayFilterToggles={DISPLAY_FILTER_OPTIONS}
      ignoredGlobalFilters={["cuts", "companies", "products"]}
      {...props}
    >
      <Widget.PrimaryFilters>
        <FilterList name="Sector" filterGroupId="sectors" endpoint="/api/v1/sectors" enableMultiRowSelection={false} />
      </Widget.PrimaryFilters>

      <Widget.SecondaryFilters>
        <SectorRespondentSubsampleSecondaryFilters hiddenFilters={props.hiddenFilters} />
      </Widget.SecondaryFilters>

      <Widget.Body>
        <Content renderAspectRatioContainer={props.renderAspectRatioContainer} />
        {props.children}
      </Widget.Body>
    </Widget>
  )
}

const SectorRespondentSubsampleSecondaryFilters = ({ hiddenFilters }: Pick<WidgetPropsBase, "hiddenFilters">) => {
  const filters: Array<[FilterGroupKeys, React.ReactNode]> = [
    ["survey_dates", <SurveyDates mode={SURVEY_DATE_MODE} size="sm" />],
    ["metrics", <MetricList items={METRIC_FILTER_OPTIONS} defaultMetrics={DEFAULT_METRICS} />],
  ]

  const filterComponents = filters
    .filter(([key, _component]) => !hiddenFilters?.includes(key))
    .map(([key, component]) => <React.Fragment key={key}>{component}</React.Fragment>)

  return <div className="flex items-center flex-grow space-x-4">{filterComponents}</div>
}

const SURVEY_DATE_MODE = "multiple"
const METRIC_FILTER_OPTIONS: FilterListItem[] = [
  {
    name: "Adoption %",
    id: "adoption_percentage",
  },
  {
    name: "Increase %",
    id: "increase_percentage",
  },
  {
    name: "Flat %",
    id: "flat_percentage",
  },
  {
    name: "Decrease %",
    id: "decrease_percentage",
  },
  {
    name: "Replacing %",
    id: "replacing_percentage",
  },
  {
    name: "Net Score",
    id: "net_score",
  },
  {
    name: "Pervasion",
    id: "pervasion_of_sector",
  },
  {
    name: "Sector NS / Survey NS",
    id: "sector_net_score_divided_survey_net_score",
  },
]
const DEFAULT_METRICS = METRIC_FILTER_OPTIONS.filter((item) => item.id == "net_score")

function Content(props: Pick<WidgetPropsBase, "renderAspectRatioContainer">) {
  const { renderAspectRatioContainer } = props
  const display = useFilterGroupStore((s) => s.display)
  const metricFilterGroup = useFilterGroupStore((s) => s.metrics)
  const sector = useFilter("sectors", { returnType: "single" })
  const { value: surveys, isLoading: surveyIsLoading } = useSurveyDates(SURVEY_DATE_MODE)
  const metrics = getSelectedMetrics(metricFilterGroup?.selectedItems, DEFAULT_METRICS)

  const { isLoading: isCutGroupsLoading, data: cutGroups } = useQuery({
    queryKey: ["tsis_sector_metrics_cut_groups"],
    queryFn: getCutGroups,
    select: (data: any) =>
      data.filter((item: any) => item.name !== "Combo Cuts" && item.name !== "All Respondents").map((item: any) => item.id),
  })

  const widgetEnabled = !!sector && !isCutGroupsLoading && !!surveys && !surveyIsLoading

  const { isLoading: isDataLoading, data } = useQuery({
    queryKey: ["tsis_sector_metrics", { sector, surveys, cutGroups }],
    queryFn: () => {
      return getTsisSectorMetrics({
        citations: null,
        cut: null,
        cut_groups: cutGroups,
        sectors: sector,
        surveys,
      })
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: widgetEnabled,
  })

  const isLoading = isDataLoading || isCutGroupsLoading || surveyIsLoading

  const dataFilterByEnterpriseSizeCut = data?.filter(
    (item: any) => item.cut.cut_group.name === "Enterprise Size Cuts" || item.cut.cut_group.name === "Index Cuts"
  )

  const dataFilterByIndustryCut = data?.filter((item: any) => item.cut.cut_group.name === "Industry Cuts")

  const dataFilterByJobTitleCut = data?.filter((item: any) => item.cut.cut_group.name === "Job Title Cuts")

  const dataFilterByRegionCut = data?.filter((item: any) => item.cut.cut_group.name === "Region Cuts")

  const showEnterprise = getDisplayToggleState(display?.selectedItems, "enterprise", true)
  const showIndustry = getDisplayToggleState(display?.selectedItems, "industry", true)
  const showJobTitle = getDisplayToggleState(display?.selectedItems, "job-title", true)
  const showRegion = getDisplayToggleState(display?.selectedItems, "region", true)

  if (!widgetEnabled) {
    return (
      <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
        <div className="flex flex-col items-center justify-center h-full">
          <BarChartIcon className="w-48 h-48 text-muted" />
          <p className="text-sm text-muted-foreground">Please select a sector to view the sector respondent subsample graph</p>
        </div>
      </WithAspectRatio>
    )
  }

  if (data?.length === 0) return <Widget.Empty />

  return (
    <div className="relative grid flex-1 grid-cols-1 gap-6 md:grid-cols-2">
      {showEnterprise && (
        <div
          className={cn("flex flex-col gap-2 min-h-80 relative", {
            "col-span-2": !showIndustry || (!showJobTitle && !showRegion),
          })}
        >
          <RespondentSubsampleChart
            title="Enterprise Size"
            metrics={metrics}
            metricsGroupedBySurvey={groupBy(dataFilterByEnterpriseSizeCut, "survey.short_name")}
            metricsGroupedByCut={groupBy(dataFilterByEnterpriseSizeCut, "cut.name")}
            loading={isLoading}
          />
        </div>
      )}

      {showIndustry && (
        <div
          className={cn("flex flex-col gap-2 min-h-80 relative", {
            "col-span-2": !showEnterprise || (!showJobTitle && !showRegion),
          })}
        >
          <RespondentSubsampleChart
            title="Industry"
            metrics={metrics}
            metricsGroupedBySurvey={groupBy(dataFilterByIndustryCut, "survey.short_name")}
            metricsGroupedByCut={groupBy(dataFilterByIndustryCut, "cut.name")}
            loading={isLoading}
          />
        </div>
      )}

      {showJobTitle && (
        <div
          className={cn("flex flex-col gap-2 min-h-80 relative", {
            "col-span-2": !showRegion || (!showEnterprise && !showIndustry),
          })}
        >
          <RespondentSubsampleChart
            title="Job Title"
            metrics={metrics}
            metricsGroupedBySurvey={groupBy(dataFilterByJobTitleCut, "survey.short_name")}
            metricsGroupedByCut={groupBy(dataFilterByJobTitleCut, "cut.name")}
            loading={isLoading}
          />
        </div>
      )}

      {showRegion && (
        <div
          className={cn("flex flex-col gap-2 min-h-80 relative", {
            "col-span-2": !showJobTitle || (!showEnterprise && !showIndustry),
          })}
        >
          <RespondentSubsampleChart
            title="Region"
            metrics={metrics}
            metricsGroupedBySurvey={groupBy(dataFilterByRegionCut, "survey.short_name")}
            metricsGroupedByCut={groupBy(dataFilterByRegionCut, "cut.name")}
            loading={isLoading}
          />
        </div>
      )}
    </div>
  )
}

interface RespondentSubsampleChartProps {
  title: string
  metrics: FilterListItem[]
  metricsGroupedByCut: Record<string, any[]>
  metricsGroupedBySurvey: Record<string, any[]>
  loading?: boolean
}

function RespondentSubsampleChart(props: RespondentSubsampleChartProps) {
  const { title, metrics, metricsGroupedByCut, metricsGroupedBySurvey, loading = true } = props

  const [options, setOptions] = useState<Highcharts.Options>({
    ...defaultChartOptions,
    chart: {
      ...defaultChartOptions.chart,
      type: "column",
    },
    title: {
      align: "left",
      text: title,
      margin: 30,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          color: "#000000",
          crop: false,
          overflow: "allow",
          style: {
            fontWeight: "normal",
          },
          rotation: 270,
          y: -20,
          formatter: function () {
            return `${round(this.point.y || 0)}%`
          },
        },
      },
      column: {
        groupPadding: 0.08,
        pointPadding: 0.08,
      },
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      followPointer: true,
      snap: 1,
      formatter: function () {
        return `
          <p><b>Respondent Cut: </b>${this.point.category}</p>
          <p><b>${this.series.name} ${this.point.series.yAxis.options.title?.text}: </b>${this.point.y}%</p>
          <p><b>Citations: </b>${this.point.custom?.citations}</p>
        `
      },
    },
    xAxis: {
      categories: [],
      crosshair: true,
      labels: {
        style: {
          width: 85,
          textOverflow: "ellipsis",
        },
      },
    },
    yAxis: {
      labels: {
        format: "{value}%",
      },
      startOnTick: true,
      endOnTick: true,
      minPadding: 0,
      maxPadding: 0,
    },
    series: [],
  })

  const chartRef = useChartLoading(loading)

  useEffect(() => {
    if (!metricsGroupedBySurvey || !metricsGroupedByCut) return

    const categories = Object.keys(metricsGroupedByCut || {})
    const series = Object.entries(metricsGroupedBySurvey || {}).map(([surveyShortName, sectorMetrics]) => {
      const sorted = sectorMetrics.toSorted((a, b) => categories.indexOf(a.cut.name) - categories.indexOf(b.cut.name))

      return {
        name: surveyShortName,
        data: sorted.map((item) => {
          const metricValues = metrics.map((m) => item[m.id])
          const value = sum(metricValues)
          return {
            y: round(value * 100, 1),
            custom: {
              citations: item.unique_respondents,
            },
          }
        }),
      }
    }) as Highcharts.SeriesOptionsType[]

    setOptions({
      xAxis: {
        categories,
      },
      yAxis: {
        title: {
          text: metrics.map((m) => m?.name).join(" + "),
        },
      },
      series,
    })
  }, [metricsGroupedBySurvey, metricsGroupedByCut])

  return (
    <HC highcharts={Highcharts} options={options} containerProps={{ className: "absolute top-0 right-0 bottom-0 left-0" }} ref={chartRef} />
  )
}
