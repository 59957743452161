import React from "react"
import DesktopMainNav from "./navigations/desktop_main_nav"
import MobileMainNav from "./navigations/mobile_main_nav"

export interface NavbarProps {
  rootPath: string
  adminPath?: string
  destroyUserSessionPath: string
  logo: string
}

export default function Navbar(props: NavbarProps) {
  return (
    <>
      <DesktopMainNav {...props} />
      <MobileMainNav {...props} />
    </>
  )
}
