import { FilterGroup, FilterGroupKeys, useFilterGroupStore } from "@/components/filters"
import { QueryObserverOptions, UseQueryResult, useQuery } from "@tanstack/react-query"
import { capitalize } from "lodash"
import { useEffect } from "react"

type UseDefaultFilterArgs<T extends "single" | "multiple"> = {
  returnType: T
} & Partial<FilterGroup>

type UseDefaultFilterSingleReturn = {
  value: string | number
} & UseQueryResult<unknown, Error>

type UseDefaultFilterMultipleReturn = {
  value: string[] | number[]
} & UseQueryResult<unknown, Error>

export function useFilter(filterGroupId: FilterGroupKeys, { returnType }: { returnType: "single" }): string | number
export function useFilter(filterGroupId: FilterGroupKeys, { returnType }: { returnType: "multiple" }): string[] | number[]
export function useFilter(filterGroupId: FilterGroupKeys, { returnType }: { returnType: "single" | "multiple" }) {
  const filterGroup = useFilterGroupStore((s) => s[filterGroupId])
  const selectedItems = filterGroup?.selectedItems

  if (returnType === "single") {
    return selectedItems?.[0]?.id
  } else if (returnType === "multiple") {
    return selectedItems?.map((item) => item.id) ?? []
  } else {
    return null as never
  }
}

export function useDefaultFilter(
  filterGroupId: FilterGroupKeys,
  { returnType, name, hidden, permanent, ...opts }: UseDefaultFilterArgs<"single"> & Partial<QueryObserverOptions>
): UseDefaultFilterSingleReturn
export function useDefaultFilter(
  filterGroupId: FilterGroupKeys,
  { returnType, name, hidden, permanent, ...opts }: UseDefaultFilterArgs<"multiple"> & Partial<QueryObserverOptions>
): UseDefaultFilterMultipleReturn
export function useDefaultFilter(
  filterGroupId: FilterGroupKeys,
  { returnType, name, hidden, permanent, ...opts }: UseDefaultFilterArgs<"single" | "multiple"> & Partial<QueryObserverOptions>
) {
  const filterGroup = useFilterGroupStore((s) => s[filterGroupId])
  const updateAppliedFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const queryEnabled = opts.enabled ?? !Boolean(filterGroup?.selectedItems?.length)

  const { data, isLoading, ...restOfResponse } = useQuery({
    queryKey: ["use-filter", filterGroupId],
    enabled: queryEnabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...opts,
  })

  if (returnType !== "single" && returnType !== "multiple") {
    throw new Error("Invalid returnType")
  }

  const value = returnType === "single" ? useFilter(filterGroupId, { returnType }) : useFilter(filterGroupId, { returnType })

  useEffect(() => {
    const dataEqual = JSON.stringify(filterGroup?.selectedItems) === JSON.stringify([data].flat())

    if (queryEnabled && !isLoading && !dataEqual) {
      updateAppliedFilterGroups({
        [filterGroupId]: {
          name: name || capitalize(filterGroupId),
          hidden,
          permanent,
          ...filterGroup,
          selectedItems: [data].flat(),
        },
      })
    }
  }, [queryEnabled, isLoading, data, filterGroupId, updateAppliedFilterGroups])

  return {
    value,
    data,
    isLoading,
    ...restOfResponse,
  }
}
