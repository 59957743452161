import React from "react"

export default function TsisIcon({ className }: { className?: string }) {
  return (
    <svg width="340" height="340" viewBox="0 0 340 340" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M197.917 76.5197V0L37.7773 103.341V298.19L100.098 340V139.629L197.917 76.5197Z" fill="url(#paint0_linear_699_141515)" />
      <path
        d="M132.223 263.48V340L292.362 236.659V41.8098L230.042 6.10352e-05V200.371L132.223 263.48Z"
        fill="url(#paint1_linear_699_141515)"
      />
      <defs>
        <linearGradient id="paint0_linear_699_141515" x1="47.0687" y1="73.2091" x2="47.0687" y2="346.335" gradientUnits="userSpaceOnUse">
          <stop stopColor="#63B3ED" />
          <stop offset="1" stopColor="#3182CE" />
        </linearGradient>
        <linearGradient id="paint1_linear_699_141515" x1="283.071" y1="266.791" x2="283.071" y2="-6.33536" gradientUnits="userSpaceOnUse">
          <stop stopColor="#63B3ED" />
          <stop offset="1" stopColor="#3182CE" />
        </linearGradient>
      </defs>
    </svg>
  )
}
