import { FilterGroupKeys, useFilterGroupStore } from "@/components/filters"
import { useFilter } from "@/hooks/use_filter"
import { cn, getFilterSelectionText } from "@/lib/utils"
import { Button, ButtonProps } from "@/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/ui/popover"
import { Slider } from "@/ui/slider"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import React, { useState } from "react"
import { useDebouncedCallback } from "use-debounce"

interface FilterRangeProps extends ButtonProps {
  className?: string
  text: string
  fallbackText?: string
  filterGroupId: FilterGroupKeys
}

export const DEFAULT_RANGE = [0, 100]

const RANGE_MIN = 0
const RANGE_MAX = 100
const RANGE_STEP = 5

export function FilterRange({ className, text, fallbackText, filterGroupId, ...props }: FilterRangeProps) {
  const filterGroup = useFilterGroupStore((s) => s[filterGroupId])
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const selection = useFilter(filterGroupId, { returnType: "single" })
  const [range, setRange] = useState<number[]>(getSelectedRange(selection))
  const [min, max] = range
  const selectedValueText = getFilterSelectionText(filterGroup?.selectedItems, { fallbackText: fallbackText || "0-Max" })

  const handleOnChange = (values: number[]) => {
    const [min, max] = values

    if ((min !== 0 && !min) || !max) return

    const identifier = `${min}-${max}`
    const name = `${min}-${max === RANGE_MAX ? "Max" : max}`

    setRange(values)

    debouncedUpdatedAppliedFilterGroups({
      [filterGroupId]: {
        name: text,
        ...filterGroup,
        selectedItems: [{ id: identifier, name }],
      },
    })
  }

  const debouncedUpdatedAppliedFilterGroups = useDebouncedCallback(updateFilterGroups, 250)

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn("flex items-center px-3 border-transparent justify-between bg-slate-100", className)}
          {...props}
        >
          <span className="truncate">
            {text}
            {": "}

            <span className="text-sky-700 font-medium">{selectedValueText}</span>
          </span>
          <ChevronDownIcon className="w-4 h-4 ml-2" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="space-y-4">
        <h6>
          {text}: {min} - {max == RANGE_MAX ? "Max" : max}
        </h6>
        <Slider minStepsBetweenThumbs={1} min={RANGE_MIN} max={RANGE_MAX} step={RANGE_STEP} onValueChange={handleOnChange} value={range} />
        <div className="flex justify-between w-full">
          <span>0</span>
          <span>Max</span>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export const getSelectedRange = (value: string | number, exclude_max: boolean = false, defaultRange?: number[]) => {
  const range =
    value ?
      value
        .toString()
        .split("-")
        .map((n) => parseInt(n))
    : defaultRange || DEFAULT_RANGE

  return exclude_max ? range.filter((n) => n !== RANGE_MAX) : range
}
