import Highcharts from "highcharts"
import highchartsAccessibility from "highcharts/modules/accessibility"
import HighchartsReact from "highcharts-react-official"

// init the module
highchartsAccessibility(Highcharts)

const defaultChartOptions = {
  chart: {
    plotBackgroundImage: "/images/ETR-graph-background-transparent.png",
  },
  credits: {
    enabled: false,
  },
  title: {
    text: undefined,
  },
  legend: {
    maxHeight: 120,
  },
}

export { Highcharts, HighchartsReact, defaultChartOptions }
