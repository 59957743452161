import { getCuts } from "@/api/cuts"
import { getTsisSectorMetrics } from "@/api/tsis_sector_metrics"
import {
  FilterGroupKeys,
  FilterList,
  FilterListDropdown,
  FilterListItem,
  MetricList,
  SurveyDates,
  getSelectedMetrics,
  useFilterGroupStore,
} from "@/components/filters"
import { WIDGET_ASPECT_RATIO, Widget, WidgetPropsBase } from "@/components/widget"
import { WithAspectRatio } from "@/components/with_aspect_ratio"
import { useChartLoading } from "@/hooks/use_chart_loading"
import { useDefaultFilter, useFilter } from "@/hooks/use_filter"
import { useSurveyDates } from "@/hooks/use_survey_dates"
import { HighchartsReact as HC, Highcharts, defaultChartOptions } from "@/lib/highcharts"
import { toPercentString } from "@/lib/utils"
import { TabsContent, TabsList, TabsTrigger } from "@/ui/tabs"
import { useQuery } from "@tanstack/react-query"
import { groupBy, round } from "lodash"
import React, { useEffect, useState } from "react"

export const SpendFocusWidget = ({ ...props }: WidgetPropsBase) => {
  return (
    <Widget componentKey={"spend_focus"} defaultTab="trends" ignoredGlobalFilters={["products", "sectors"]} {...props}>
      <Widget.PrimaryFilters>
        <SpendFocusPrimaryFilters />
      </Widget.PrimaryFilters>

      <Widget.SecondaryFilters>
        <SpendFocusSecondaryFilters hiddenFilters={props.hiddenFilters} />
        <TabsList>
          <TabsTrigger value="positions">Positions</TabsTrigger>
          <TabsTrigger value="trends">Trends</TabsTrigger>
        </TabsList>
      </Widget.SecondaryFilters>

      <Widget.Body>
        <Content renderAspectRatioContainer={props.renderAspectRatioContainer} />
        {props.children}
      </Widget.Body>
    </Widget>
  )
}

const SpendFocusPrimaryFilters = () => {
  const companies = useFilter("companies", { returnType: "multiple" })

  return (
    <FilterList
      name="Sector"
      filterGroupId="sectors"
      endpoint="/api/v1/sectors"
      params={{
        companies,
      }}
    />
  )
}

const SpendFocusSecondaryFilters = ({ hiddenFilters }: Pick<WidgetPropsBase, "hiddenFilters">) => {
  const filters: Array<[FilterGroupKeys, React.ReactNode]> = [
    ["survey_dates", <SurveyDates mode={SURVEY_DATE_MODE} size="sm" />],
    ["metrics", <MetricList items={METRIC_FILTER_OPTIONS} defaultMetrics={DEFAULT_METRICS} />],
    [
      "cuts",
      <FilterListDropdown
        className="w-full text-sm"
        name="Cut"
        text="Cut"
        size="sm"
        filterGroupId="cuts"
        endpoint="/api/v1/cuts"
        enableMultiRowSelection={false}
      />,
    ],
  ]

  const filterComponents = filters
    .filter(([key, _component]) => !hiddenFilters?.includes(key))
    .map(([key, component]) => <React.Fragment key={key}>{component}</React.Fragment>)

  return <div className="flex items-center flex-grow space-x-4">{filterComponents}</div>
}

const SURVEY_DATE_MODE = "multiple"
const METRIC_FILTER_OPTIONS: FilterListItem[] = [
  {
    name: "Adoption %",
    id: "adoption_percentage",
  },
  {
    name: "Increase %",
    id: "increase_percentage",
  },
  {
    name: "Flat %",
    id: "flat_percentage",
  },
  {
    name: "Decrease %",
    id: "decrease_percentage",
  },
  {
    name: "Replacing %",
    id: "replacing_percentage",
  },
  {
    name: "Net Score",
    id: "net_score",
  },
  {
    name: "Pervasion",
    id: "pervasion_of_survey",
  },
  {
    name: "Sector NS / Survey NS",
    id: "sector_net_score_divided_survey_net_score",
  },
]
const DEFAULT_METRICS = METRIC_FILTER_OPTIONS.filter((item) => item.id == "net_score")

function Content(props: Pick<WidgetPropsBase, "renderAspectRatioContainer">) {
  const { renderAspectRatioContainer } = props
  const metricFilterGroup = useFilterGroupStore((s) => s.metrics)
  const sectors = useFilter("sectors", { returnType: "multiple" })
  const { value: surveys, isLoading: surveyIsLoading } = useSurveyDates(SURVEY_DATE_MODE)
  const { value: cut } = useDefaultFilter("cuts", {
    queryFn: getCuts,
    select: (data: any) => data.filter((item: any) => item.name === "All Respondents"),
    returnType: "single",
  })

  const metrics = getSelectedMetrics(metricFilterGroup?.selectedItems, DEFAULT_METRICS)

  const widgetEnabled = !!cut && !!surveys && !surveyIsLoading

  const { data, isLoading } = useQuery({
    queryKey: ["tsis_sector_metrics", { cut, sectors, surveys }],
    queryFn: () => {
      return getTsisSectorMetrics({
        citations: null,
        cut,
        sectors,
        surveys,
      })
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: widgetEnabled,
  })
  const metricsGroupedBySector = groupBy(data, "sector.name")
  const metricsGroupedBySurveyDate = groupBy(data, "survey.short_name")

  if (data?.length === 0) return <Widget.Empty />

  return (
    <>
      <TabsContent value="positions" className="relative flex-1">
        <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
          <SpendFocusPositionsChart metrics={metrics} metricsGroupedBySector={metricsGroupedBySector} loading={isLoading} />
        </WithAspectRatio>
      </TabsContent>

      <TabsContent value="trends" className="relative flex-1">
        <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
          <SpendFocusTrendsChart
            metrics={metrics}
            metricsGroupedBySector={metricsGroupedBySector}
            metricsGroupedBySurveyDate={metricsGroupedBySurveyDate}
            loading={isLoading}
          />
        </WithAspectRatio>
      </TabsContent>
    </>
  )
}

interface SpendFocusPositionsChartProps {
  metrics: FilterListItem[]
  metricsGroupedBySector: Record<string, any[]>
  loading?: boolean
}

function SpendFocusPositionsChart(props: SpendFocusPositionsChartProps) {
  const { metrics, metricsGroupedBySector, loading = true } = props

  const [options, setOptions] = useState<Highcharts.Options>({
    ...defaultChartOptions,
    chart: {
      ...defaultChartOptions.chart,
      type: "scatter",
      events: {
        click: function (e) {
          this.getSelectedPoints().forEach((point) => {
            point.select(false, false)
          })
        },
      },
    },
    xAxis: {
      title: {
        text: "Pervasion %",
      },
      labels: {
        format: "{value}%",
      },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
    },
    yAxis: {
      labels: {
        format: "{value}%",
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      scatter: {
        allowPointSelect: true,
        dataLabels: {
          enabled: true,
          format: "{point.name}",
          padding: 5,
          style: {
            fontWeight: "initial",
          },
        },
        events: {
          click: function (event) {
            const targetSeriesName = event.point.series.name.substring(0, event.point.series.name.indexOf(".trend"))
            const selectedSeries = this.chart.series.find((series) => series.name === targetSeriesName)
            const selectedSeriesTrend = this.chart.series.find((series) => series.name === `${targetSeriesName}.trend`)
            selectedSeries?.points[0]?.select(false, false)
            selectedSeriesTrend?.remove()
          },
        },
        point: {
          events: {
            unselect: function () {
              const selectedSeries = this.series.chart.series.find((series) => series.name === `${this.name}.trend`)

              selectedSeries?.remove()
            },
          },
        },
        stickyTracking: false,
      },
      series: {
        cursor: "pointer",
        marker: {
          radius: 4.5,
          symbol: "circle",
          states: {
            hover: {
              enabled: true,
              lineColor: "rgb(100,100,100)",
            },
          },
        },
      },
    },
    series: [],
  })

  const chartRef = useChartLoading(loading)

  useEffect(() => {
    if (!metricsGroupedBySector) return

    setOptions({
      yAxis: {
        title: {
          text: metrics.map((m) => m?.name).join(" + "),
        },
      },
      tooltip: {
        formatter: function () {
          return `<p><b>Sector: </b>${this.point.name}</p>
          <p><b>Pervasion: </b>${toPercentString(this.point.x / 100)}</p>
          <p><b>${metrics.map((m) => m.name).join(" + ")}: </b>${toPercentString((this.point.y || 0) / 100)}</p>
          <p><b>Survey Date: </b>${this.point.custom?.survey_name}</p>
          `
        },
      },
      plotOptions: {
        scatter: {
          point: {
            events: {
              select: function () {
                const sector = this.name
                const data = metricsGroupedBySector[sector]

                if (!data || !sector) return false

                this.series.chart.addSeries({
                  color: this.series.color,
                  showInLegend: false,
                  allowPointSelect: true,
                  type: "line",
                  name: `${sector}.trend`,
                  data: data.map((item) => ({
                    id: item.id,
                    name: item.sector?.name,
                    x: round(item.pervasion_of_survey * 100, 2),
                    y: round(metrics.reduce((acc, m) => acc + (item[m.id] || 0), 0) * 100, 2),
                    custom: {
                      survey_name: item.survey?.short_name,
                    },
                  })),
                })
              },
            },
          },
        },
      },
      series: Object.entries(metricsGroupedBySector || {}).map(([key, value]) => ({
        name: key,
        allowPointSelect: true,
        data: [value?.[value.length - 1]].map((item: any) => ({
          name: item.sector?.name,
          x: round(item.pervasion_of_survey * 100, 2),
          y: round(metrics.reduce((acc, m) => acc + (item[m.id] || 0), 0) * 100, 2),
          custom: {
            survey_name: item.survey?.short_name,
          },
        })),
      })) as Highcharts.SeriesOptionsType[],
    })
  }, [metricsGroupedBySector])

  return (
    <HC highcharts={Highcharts} options={options} containerProps={{ className: "absolute top-0 right-0 bottom-0 left-0" }} ref={chartRef} />
  )
}

interface SpendFocusTrendsChartProps {
  metrics: FilterListItem[]
  metricsGroupedBySector: Record<string, any[]>
  metricsGroupedBySurveyDate: Record<string, any[]>
  loading?: boolean
}

function SpendFocusTrendsChart(props: SpendFocusTrendsChartProps) {
  const { metrics, metricsGroupedBySector, metricsGroupedBySurveyDate, loading = true } = props

  const [options, setOptions] = useState<Highcharts.Options>({
    ...defaultChartOptions,
    chart: {
      ...defaultChartOptions.chart,
      type: "column",
    },
    xAxis: {
      categories: [],
      crosshair: true,
    },
    yAxis: {
      labels: {
        format: "{value}%",
      },
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        return `<p><b>Sector: </b>${this.point.category}</p>
          <p><b>${this.series.name}: </b>${round(this.point.y || 0, 1)}%</p>`
      },
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          color: "#000000",
          crop: false,
          overflow: "allow",
          style: {
            fontWeight: "normal",
          },
          rotation: 270,
          y: -20,
          formatter: function () {
            return `${round(this.point.y || 0)}%`
          },
        },
      },
    },
    series: [],
  })

  const chartRef = useChartLoading(loading)

  useEffect(() => {
    if (!metricsGroupedBySurveyDate || !metricsGroupedBySector) return

    const categories = Object.keys(metricsGroupedBySector || {}).sort((a, b) => a.localeCompare(b))
    const series = Object.entries(metricsGroupedBySurveyDate || {}).map(([surveyShortName, sectorMetrics]) => {
      const sorted = sectorMetrics.toSorted((a, b) => a.sector?.name.localeCompare(b.sector?.name))

      return {
        name: surveyShortName,
        data: sorted.map((item) => round(metrics.reduce((acc, m) => acc + item[m.id], 0) * 100, 2)),
      }
    }) as Highcharts.SeriesOptionsType[]

    setOptions({
      xAxis: {
        categories,
      },
      yAxis: {
        title: {
          text: metrics.map((m) => m?.name).join(" + "),
        },
      },
      series,
    })
  }, [metricsGroupedBySurveyDate, metricsGroupedBySector])

  return (
    <HC highcharts={Highcharts} options={options} containerProps={{ className: "absolute top-0 right-0 bottom-0 left-0" }} ref={chartRef} />
  )
}
