export { DisplayFilters, getDisplayToggleState } from "@/components/filters/display_filters"
export type { DisplayFiltersProps } from "@/components/filters/display_filters"
export {
  FilterGroupStoreContext,
  FilterGroupStoreProvider,
  GlobalStore,
  getAllFilterGroupsFromStore,
  getFilteredGroupsFromStore,
  useFilterGroupStore,
} from "@/components/filters/filter_group_store_provider"
export type { FilterGroupStoreProviderProps } from "@/components/filters/filter_group_store_provider"
export { FilterList, FilterListDropdown } from "@/components/filters/filter_list"
export { FilterRange, getSelectedRange } from "@/components/filters/filter_range"
export { MetricList, getSelectedMetrics } from "@/components/filters/metric_list"
export { RadioList, getSelectedRadioValue } from "@/components/filters/radio_list"
export { SelectedFilters } from "@/components/filters/selected_filters"
export { SurveyDates } from "@/components/filters/survey_dates"

const FILTER_GROUP_IDS = {
  ADOPTION: "adoption",
  ADOPTION_SOS: "adoption_sos",
  ADOPTION_YOY: "adoption_yoy",
  INCREASE: "increase",
  INCREASE_SOS: "increase_sos",
  INCREASE_YOY: "increase_yoy",
  FLAT: "flat",
  FLAT_SOS: "flat_sos",
  FLAT_YOY: "flat_yoy",
  DECREASE: "decrease",
  DECREASE_SOS: "decrease_sos",
  DECREASE_YOY: "decrease_yoy",
  REPLACING: "replacing",
  REPLACING_SOS: "replacing_sos",
  REPLACING_YOY: "replacing_yoy",
  NET_SCORE: "net_score",
  NET_SCORE_SOS: "net_score_sos",
  NET_SCORE_YOY: "net_score_yoy",
  PERVASION: "pervasion",
  PERVASION_SOS: "pervasion_sos",
  PERVASION_YOY: "pervasion_yoy",
  CITATIONS: "citations",
  CITATIONS_SOS: "citations_sos",
  CITATIONS_YOY: "citations_yoy",
  CY_CY_REVENUE: "cy_cy_revenue",
  MARKET_CAPITALIZATION: "market_capitalization",
  SHORT_RATIO: "short_ratio",
  PRICE_TO_EARNINGS: "price_to_earnings",
  EV_TO_SALES_TTM: "ev_to_sales_ttm",
  EV_TO_SALES_NTM: "ev_to_sales_ntm",
  RETURN_YTD: "return_ytd",
  RETURN_LAST_1_MONTH: "return_last_1_month",
  RETURN_LAST_3_MONTH: "return_last_3_month",
  RETURN_LAST_6_MONTH: "return_last_6_month",
  RETURN_LAST_12_MONTH: "return_last_12_month",
  COMPANIES: "companies",
  CUTS: "cuts",
  CUT_GROUPS: "cut_groups",
  DATATABLE: "datatable",
  DISPLAY: "display",
  DISPLAY_SECTORS: "display_sectors",
  DISPLAY_PRODUCTS: "display_products",
  MISC: "misc",
  METRICS: "metrics",
  PUBLIC_PRIVATE: "public_private",
  PRODUCTS: "products",
  SECTORS: "sectors",
  SHARED_NS_COMPARISON: "shared_ns_comparison",
  SURVEY_DATES: "survey_dates",
  Y_AXIS: "y_axis",
} as const

export const FILTER_GROUP_PRIORITIES: { [key in FilterGroupValues]?: number } = {
  survey_dates: 100,
  sectors: 200,
  companies: 300,
  products: 400,
} as const

export const getMaxPriority = () => Object.entries(FILTER_GROUP_PRIORITIES).reduce((acc, [_, priority]) => Math.max(acc, priority), 0)

export type FilterGroup = {
  name: string
  hidden?: boolean
  permanent?: boolean
  selectedItems: FilterListItem[]
  data?: any
}

export type ValueOf<T> = T[keyof T]

export type FilterGroups = {
  [id in FilterGroupValues]?: FilterGroup
}

export type FilterGroupKeys = keyof FilterGroups
export type FilterGroupValues = ValueOf<typeof FILTER_GROUP_IDS>

export type FilterListItem = {
  id: number | string
  name: string | boolean
}
