import request from "@/api/client"
import { getCompanies } from "@/api/companies"
import { getCuts } from "@/api/cuts"
import {
  FilterGroupKeys,
  FilterGroups,
  FilterGroupStoreProvider,
  FilterListDropdown,
  FilterRange,
  getAllFilterGroupsFromStore,
  GlobalStore,
  SurveyDates,
  useFilterGroupStore,
} from "@/components/filters"
import { WIDGET_ASPECT_RATIO } from "@/components/widget"
import { WIDGETS } from "@/components/widgets"
import { useDefaultFilter } from "@/hooks/use_filter"
import { useSurveyDates } from "@/hooks/use_survey_dates"
import { AspectRatio } from "@/ui/aspect_ratio"
import { Spinner } from "@/ui/spinner"
import React, { useCallback, useState } from "react"
import { Element, Link } from "react-scroll"
import { getWidgets } from "@/api/widgets"
import { useQuery } from "@tanstack/react-query"

interface Model {
  id: string
  component: keyof typeof WIDGETS
  ignoredFilters?: FilterGroupKeys[]
  props?: any
}

export default function AnalyzeCompany() {
  const widgets = useQuery({
    queryKey: ["widgets"],
    queryFn: () => getWidgets(),
  })

  const [models, setModels] = useState<Model[]>([
    {
      id: "respondent-composition",
      component: "respondent_composition",
      props: {
        hidePrimaryFilters: true,
        analyze: "company",
        ignoredGlobalFilters: ["citations", "cuts", "sectors", "survey_dates"],
      },
    },
    {
      id: "net-score",
      component: "net_score_answer_distribution",
      props: {
        hidePrimaryFilters: true,
        hideSecondaryFilters: true,
      },
    },
    {
      id: "respondent-subsample",
      component: "respondent_subsample",
      props: {
        hidePrimaryFilters: true,
        hiddenFilters: ["survey_dates", "citations"],
      },
    },
    {
      id: "subsample",
      component: "subsample_candlestick",
      props: {
        hidePrimaryFilters: true,
        hiddenFilters: ["citations"],
      },
    },
    {
      id: "product-area",
      component: "product_area",
      props: {
        hidePrimaryFilters: true,
        hiddenFilters: ["survey_dates", "cuts", "citations"],
      },
    },
    {
      id: "relative-sector",
      component: "relative_sector",
      props: {
        hiddenFilters: ["survey_dates", "cuts", "citations"],
      },
    },
    {
      id: "adoption-replacing-reasoning",
      component: "adoption_replacing_reasoning",
      props: {
        hidePrimaryFilters: true,
        hiddenFilters: ["cuts"],
      },
    },
  ])
  const [loading, setLoading] = useState(true)

  const handleGlobalFilterChange = useCallback((state: FilterGroups) => {
    const companies = state.companies?.selectedItems?.length
    const sectors = state.sectors?.selectedItems?.length
    const cuts = state.cuts?.selectedItems?.length
    const surveys = state.survey_dates?.selectedItems?.length

    if (companies && sectors && cuts && surveys) setLoading(false)

    GlobalStore.getState().updateFilterGroups(state)
  }, [])

  return (
    <div className="mb-[500px]">
      <div className="sticky z-10 bg-white top-12 md:top-16">
        <FilterGroupStoreProvider onAppliedFiltersChange={handleGlobalFilterChange} enableGlobalStoreSubscription={false}>
          <GlobalFilters />
        </FilterGroupStoreProvider>
        <div className="border-t border-b border-gray-300 border-solid">
          <div className="container flex">
            {models.map((model) => (
              <Link
                key={model.id}
                to={model.id}
                spy={true}
                hashSpy={true}
                smooth={true}
                duration={500}
                isDynamic={true}
                activeClass="text-black border-black border-b-2"
                className="text-blue-500 transition-colors no-underline p-2.5 cursor-pointer select-none whitespace-nowrap max-w-80 overflow-hidden overflow-ellipsis"
                offset={-180}
              >
                {widgets.data?.find((widget) => widget.component_key === model.component).name}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="container">
        {loading ?
          <AspectRatio ratio={WIDGET_ASPECT_RATIO}>
            <div className="flex flex-col items-center justify-center h-full gap-3">
              <Spinner className="w-24 h-24" />
              <p className="text-sm text-muted-foreground">Loading widgets...</p>
            </div>
          </AspectRatio>
        : models.map((model) => {
            const WidgetComponent = WIDGETS[model.component]

            return (
              <Element key={model.id} name={model.id} className="mt-5">
                <WidgetComponent {...model.props} />
              </Element>
            )
          })
        }
      </div>
    </div>
  )
}

const GlobalFilters = () => {
  const appliedFilterGroups = useFilterGroupStore(getAllFilterGroupsFromStore)
  const updateAppliedFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const companyName = new URLSearchParams(window.location.search).get("name")
  const { value: companies } = useDefaultFilter("companies", {
    queryFn: getCompanies,
    select: (data: any) => data.filter((item: any) => item.name === companyName),
    name: "Company",
    returnType: "single",
    permanent: true,
  })

  useDefaultFilter("sectors", {
    enabled: !!companies && !Boolean(appliedFilterGroups.sectors?.selectedItems?.length),
    queryKey: ["use-filter", "sectors", companies],
    queryFn: () => {
      return request({
        url: "/api/v1/sectors",
        method: "GET",
        params: {
          companies: [companies],
        },
      })
    },
    select: (data: any) => [data[0]],
    name: "Sector",
    returnType: "single",
    permanent: true,
  })

  useDefaultFilter("cuts", {
    queryFn: getCuts,
    select: (data: any) => data.filter((item: any) => item.name === "All Respondents"),
    returnType: "single",
    permanent: true,
  })

  useSurveyDates("multiple")

  return (
    <div className="container flex flex-wrap gap-2 py-2">
      <FilterListDropdown
        buttonClassName="min-w-56"
        className="w-full text-sm"
        name="Company"
        text="Company"
        fallbackText="None"
        filterGroupId="companies"
        endpoint="/api/v1/companies"
        enableMultiRowSelection={false}
        onHandleFilterChange={(_filterGroupId, selectedItems) => {
          updateAppliedFilterGroups({
            companies: {
              ...appliedFilterGroups.companies,
              name: appliedFilterGroups.companies?.name || "Company",
              selectedItems,
            },
            sectors: {
              ...appliedFilterGroups.sectors,
              name: appliedFilterGroups.sectors?.name || "Sector",
              selectedItems: [],
            },
            products: {
              ...appliedFilterGroups.products,
              name: appliedFilterGroups.products?.name || "Products",
              selectedItems: [],
            },
          })
        }}
      />

      <FilterListDropdown
        className="w-full text-sm"
        name="Products"
        text="Products"
        fallbackText="All"
        filterGroupId="products"
        endpoint="/api/v1/products"
        params={{
          companies,
        }}
        formatter={(item) => ({
          ...item,
          subtitle: item.sector.name,
        })}
      />

      <SurveyDates mode="multiple" />

      <FilterListDropdown
        buttonClassName="min-w-56"
        className="w-full text-sm"
        name="Cut"
        text="Cut"
        fallbackText="All Respondents"
        filterGroupId="cuts"
        endpoint="/api/v1/cuts"
        enableMultiRowSelection={false}
      />

      <FilterRange text="Citations" filterGroupId="citations" />
    </div>
  )
}
