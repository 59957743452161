import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/ui/alert_dialog"
import React, { useEffect, useState } from "react"
import { getUsers } from "@/api/users"
import { deleteDashboardUser, getDashboardUsers, createDashboardUser, putDashboardUser } from "@/api/dashboard_users"
import { Command, CommandEmpty, CommandItem, CommandList } from "@/ui/command"
import { Button } from "@/ui/button"
import { Cross1Icon } from "@radix-ui/react-icons"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/ui/select"
import { Combobox } from "@/components/combobox"
import { cn } from "@/lib/utils"

interface Option {
  value: string
  label: string
}

const userAccessOptions: Option[] = [
  { value: "viewer", label: "Viewer" },
  { value: "editor", label: "Editor" },
]
const defaultUserRoleValue = userAccessOptions.find((opts) => opts.value === "viewer")!

const mapAccessValueToLabel = (value: string) => {
  const result = userAccessOptions.find((opts) => opts.value === value)
  if (!result) return ""

  return result.label
}

interface MemberProps {
  member: { roleSelectOption: Option; memberSelectOption: Option }
  onRemove: (value: string) => void
  onChange: (memeberSelectOption: Option, option: Option) => void
}

function Member(props: MemberProps) {
  const {
    member: { roleSelectOption, memberSelectOption },
    onRemove,
    onChange,
  } = props

  const [shareRole, setShareRole] = useState(roleSelectOption || defaultUserRoleValue)

  useEffect(() => {
    setShareRole(roleSelectOption)
  }, [roleSelectOption])

  const handleSelectChange = (option: Option | null) => {
    if (!option) return

    setShareRole(option)
    onChange(memberSelectOption, option)
  }

  const handleRemoveClick = () => {
    if (!onRemove) return

    onRemove(memberSelectOption.value)
  }

  return (
    <CommandItem className="space-x-2">
      <p className="flex-1">{memberSelectOption.label}</p>
      <Select
        onValueChange={(value) => handleSelectChange({ value, label: mapAccessValueToLabel(value) })}
        value={shareRole.value}
        disabled={shareRole.value === "owner"}
      >
        <SelectTrigger className="w-[120px] bg-white">
          <SelectValue placeholder="N/A" />
        </SelectTrigger>
        <SelectContent>
          {shareRole.value === "owner" && (
            <SelectItem key="owner" value="owner">
              Owner
            </SelectItem>
          )}

          {userAccessOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <Button
        size="icon"
        variant="outline"
        disabled={shareRole.value === "owner"}
        className={cn("bg-white border-red-500 text-red-500 hover:bg-red-500 hover:text-white", {
          invisible: shareRole.value === "owner",
        })}
        onClick={handleRemoveClick}
      >
        <Cross1Icon />
      </Button>
    </CommandItem>
  )
}

const mapUserResultsToOptions = (results: any) => {
  return (
    results?.map((u: any) => ({
      value: u.id,
      label: `${u.first_name} ${u.last_name}`,
    })) || []
  )
}

interface ShareDashboardDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  onConfirm?: () => void
  dashboardId: string | undefined
}

export function ShareDashboardDialog(props: ShareDashboardDialogProps) {
  const { open, onOpenChange } = props

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <ShareDashboardContent {...props} />
      </AlertDialogContent>
    </AlertDialog>
  )
}

function ShareDashboardContent({ dashboardId }: ShareDashboardDialogProps) {
  const initialMemberOptionGroups: Array<{
    memberSelectOption: Option
    roleSelectOption: Option
  }> = []

  const [memberOptionGroups, setMemberOptionGroups] = useState(initialMemberOptionGroups)
  const [memberSelectOption, setMemberSelectOption] = useState(undefined)
  const [roleSelectOption, setRoleSelectOption] = useState(defaultUserRoleValue)
  const [users, setUsers] = useState([])

  useEffect(() => {
    const fetchDataAndSetInitialState = async () => {
      const users = await getUsers({ searchTerm: "" })
      const dashboardUsers = await getDashboardUsers(dashboardId)

      const initialMemberSelectOptions = dashboardUsers.map((dashboardUser: any) => {
        return {
          memberSelectOption: {
            value: dashboardUser.id,
            label: `${dashboardUser.user.first_name} ${dashboardUser.user.last_name}`,
          },
          roleSelectOption: {
            value: dashboardUser.role,
            label: mapAccessValueToLabel(dashboardUser.role),
          },
        }
      })

      setUsers(mapUserResultsToOptions(users))
      setMemberOptionGroups(initialMemberSelectOptions)
    }
    fetchDataAndSetInitialState()
  }, [])

  const handleMemberSelectChange = (value: string | null) => {
    if (!value) return

    setMemberSelectOption(undefined)

    createDashboardUser({
      dashboardId,
      dashboardUser: {
        userId: value,
        role: roleSelectOption.value,
      },
    })
      .then((res) => {
        setMemberOptionGroups([
          ...memberOptionGroups,
          {
            memberSelectOption: {
              value: res.id,
              label: `${res.user.first_name} ${res.user.last_name}`,
            },
            roleSelectOption,
          },
        ])
      })
      .catch((err) => console.log(err))
  }

  const handleRoleSelectChange = (option: Option | null) => {
    if (!option) return
    setRoleSelectOption(option)
  }

  const handleUserSearchChange = async (value: string) => {
    const response = await getUsers({ searchTerm: value })

    setUsers(mapUserResultsToOptions(response))
  }

  return (
    <>
      <AlertDialogHeader>
        <AlertDialogTitle className="text-left">Share Dashboard</AlertDialogTitle>
        <AlertDialogDescription className="text-left">Invite member(s) to view or edit this dashboard</AlertDialogDescription>
      </AlertDialogHeader>

      <div className="flex flex-row space-x-2">
        <div className="flex-1">
          <Combobox
            options={users}
            onSearchChange={handleUserSearchChange}
            text="Select a user"
            placeholder="Search for a user"
            onValueChange={handleMemberSelectChange}
            value={memberSelectOption}
          />
        </div>

        <Select
          onValueChange={(value) => handleRoleSelectChange({ value, label: mapAccessValueToLabel(value) })}
          value={roleSelectOption.value}
        >
          <SelectTrigger className="w-[120px]">
            <SelectValue placeholder="N/A" />
          </SelectTrigger>
          <SelectContent>
            {userAccessOptions.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <p className="font-semibold leading-none">Members</p>
      <Command>
        <CommandEmpty>No members</CommandEmpty>
        <CommandList>
          {memberOptionGroups?.map((member) => (
            <Member
              key={member.memberSelectOption.value}
              member={member}
              onRemove={async (id) => {
                await deleteDashboardUser({ dashboardId, id })
                setMemberOptionGroups(memberOptionGroups.filter((m) => m.memberSelectOption.value !== id))
              }}
              onChange={async (memberSelectOption, roleSelectOption) => {
                const newMemberOptionGroups = [...memberOptionGroups]
                const index = newMemberOptionGroups.findIndex((mog) => mog.memberSelectOption.value === memberSelectOption.value)

                if (index < 0) {
                  return
                }

                newMemberOptionGroups[index]!.roleSelectOption = roleSelectOption

                await putDashboardUser({
                  dashboardId,
                  id: memberSelectOption.value,
                  dashboardUser: {
                    role: roleSelectOption.value,
                  },
                })
                setMemberOptionGroups(newMemberOptionGroups)
              }}
            />
          ))}
        </CommandList>
      </Command>
      <AlertDialogFooter>
        <AlertDialogCancel>Finish</AlertDialogCancel>
      </AlertDialogFooter>
    </>
  )
}
