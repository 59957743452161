import { useState, useEffect } from "react"

export function useDebounceValue(value: string | null = null, ms: number) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const delaySetValueTimeoutId = setTimeout(() => {
      setDebouncedValue(value)
    }, ms)

    return () => clearTimeout(delaySetValueTimeoutId)
  }, [value, ms])

  return debouncedValue
}
