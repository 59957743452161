import { getCutGroups } from "@/api/cut_groups"
import { getTsisCompanyMetrics } from "@/api/tsis_company_metrics"
import { getTsisProductMetrics } from "@/api/tsis_product_metrics"
import {
  FilterGroupKeys,
  FilterList,
  FilterListDropdown,
  FilterListItem,
  FilterRange,
  MetricList,
  SurveyDates,
  getDisplayToggleState,
  getSelectedRange,
  getSelectedMetrics,
  useFilterGroupStore,
} from "@/components/filters"
import { WIDGET_ASPECT_RATIO, Widget, WidgetPropsBase } from "@/components/widget"
import { WithAspectRatio } from "@/components/with_aspect_ratio"
import { useChartLoading } from "@/hooks/use_chart_loading"
import { useDefaultFilter, useFilter } from "@/hooks/use_filter"
import { useSurveyDates } from "@/hooks/use_survey_dates"
import { HighchartsReact as HC, Highcharts, defaultChartOptions } from "@/lib/highcharts"
import { cn } from "@/lib/utils"
import { BarChartIcon } from "@radix-ui/react-icons"
import { useQuery } from "@tanstack/react-query"
import { groupBy, round, sum } from "lodash"
import React, { useEffect, useState } from "react"

const DISPLAY_FILTER_OPTIONS: FilterListItem[] = [
  { id: "enterprise", name: "Enterprise" },
  { id: "industry", name: "Industry" },
  { id: "job-title", name: "Job Title" },
  { id: "region", name: "Region" },
]

export const RespondentSubsampleWidget = ({ ...props }: WidgetPropsBase) => {
  return (
    <Widget
      componentKey={"respondent_subsample"}
      displayFilterToggles={DISPLAY_FILTER_OPTIONS}
      ignoredGlobalFilters={["cuts", "products", "sectors"]}
      {...props}
    >
      <Widget.PrimaryFilters>
        <RespondentSubsamplePrimaryFilters />
      </Widget.PrimaryFilters>

      <Widget.SecondaryFilters>
        <RespondentSubsampleSecondaryFilters hiddenFilters={props.hiddenFilters} />
      </Widget.SecondaryFilters>

      <Widget.Body>
        <Content renderAspectRatioContainer={props.renderAspectRatioContainer} />
        {props.children}
      </Widget.Body>
    </Widget>
  )
}

const RespondentSubsamplePrimaryFilters = () => {
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)

  return (
    <>
      <FilterList
        name="Company"
        filterGroupId="companies"
        endpoint="/api/v1/companies"
        enableMultiRowSelection={false}
        onHandleFilterChange={(filterGroupId, items) => {
          updateFilterGroups({
            [filterGroupId]: {
              name: "Company",
              selectedItems: items,
            },
            products: {
              name: "Products",
              selectedItems: [],
            },
          })
        }}
      />
    </>
  )
}

const RespondentSubsampleSecondaryFilters = ({ hiddenFilters }: Pick<WidgetPropsBase, "hiddenFilters">) => {
  const companies = useFilter("companies", { returnType: "multiple" })
  const products = useFilter("products", { returnType: "multiple" })
  const items = products?.length ? PRODUCT_METRIC_FILTER_OPTIONS : COMPANY_METRIC_FILTER_OPTIONS

  const filters: Array<[FilterGroupKeys, React.ReactNode]> = [
    ["survey_dates", <SurveyDates mode={SURVEY_DATE_MODE} size="sm" />],
    [
      "products",
      <FilterListDropdown
        className="w-full text-sm"
        name="Products"
        text="Products"
        fallbackText="All"
        size="sm"
        filterGroupId="products"
        endpoint="/api/v1/products"
        enableMultiRowSelection={false}
        params={{
          companies,
        }}
        formatter={(item) => ({
          ...item,
          subtitle: item.sector.name,
        })}
      />,
    ],
    ["metrics", <MetricList items={[...METRIC_FILTER_OPTIONS, ...items]} defaultMetrics={DEFAULT_METRICS} />],
    ["citations", <FilterRange text="Citations" filterGroupId="citations" size="sm" />],
  ]

  const filterComponents = filters
    .filter(([key, _component]) => !hiddenFilters?.includes(key))
    .map(([key, component]) => <React.Fragment key={key}>{component}</React.Fragment>)

  return <div className="flex items-center flex-grow space-x-4">{filterComponents}</div>
}

const SURVEY_DATE_MODE = "multiple"
const METRIC_FILTER_OPTIONS: FilterListItem[] = [
  {
    name: "Adoption %",
    id: "adoption_percentage",
  },
  {
    name: "Increase %",
    id: "increase_percentage",
  },
  {
    name: "Flat %",
    id: "flat_percentage",
  },
  {
    name: "Decrease %",
    id: "decrease_percentage",
  },
  {
    name: "Replacing %",
    id: "replacing_percentage",
  },
  {
    name: "Net Score",
    id: "net_score",
  },
]

const COMPANY_METRIC_FILTER_OPTIONS: FilterListItem[] = [
  {
    name: "Pervasion",
    id: "pervasion_of_survey",
  },
]

const PRODUCT_METRIC_FILTER_OPTIONS: FilterListItem[] = [
  {
    name: "Pervasion",
    id: "pervasion_of_sector",
  },
  {
    name: "Vendor NS / Sector NS",
    id: "vendor_sector_net_score_divided_sector_net_score",
  },
]

const DEFAULT_METRICS = METRIC_FILTER_OPTIONS.filter((item) => item.id == "net_score")

function Content(props: Pick<WidgetPropsBase, "renderAspectRatioContainer">) {
  const { renderAspectRatioContainer } = props
  const displayFilterGroup = useFilterGroupStore((s) => s.display)
  const metricsFilterGroup = useFilterGroupStore((s) => s.metrics)
  const company = useFilter("companies", { returnType: "single" })
  const product = useFilter("products", { returnType: "single" })
  const citations = useFilter("citations", { returnType: "single" })
  const { value: cutGroups } = useDefaultFilter("cut_groups", {
    queryFn: getCutGroups,
    select: (data: any) => data.filter((item: any) => item.name !== "Combo Cuts" && item.name !== "All Respondents"),
    returnType: "multiple",
    hidden: true,
  })
  const { value: surveys, isLoading: surveyIsLoading } = useSurveyDates(SURVEY_DATE_MODE)
  const metrics = getSelectedMetrics(metricsFilterGroup?.selectedItems, DEFAULT_METRICS)
  const citationsRange = getSelectedRange(citations, true)
  const hasSurvey = Boolean(surveys?.length) && !surveyIsLoading
  const shouldFetchCompanyMetrics = Boolean(company) && !Boolean(product) && Boolean(cutGroups?.length) && hasSurvey
  const shouldFetchProductMetrics = Boolean(product) && Boolean(cutGroups?.length) && hasSurvey
  const widgetEnabled = shouldFetchCompanyMetrics || shouldFetchProductMetrics

  const { isLoading: isLoadingCompanyMetrics, data: companyMetrics } = useQuery({
    queryKey: ["tsis_company_metrics", { citationsRange, company, surveys }],
    queryFn: () => {
      return getTsisCompanyMetrics({
        citations: citationsRange,
        company,
        cut: null,
        cut_groups: cutGroups,
        surveys,
      })
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: shouldFetchCompanyMetrics,
  })

  const { isLoading: isLoadingProductMetrics, data: productMetrics } = useQuery({
    queryKey: ["tsis_product_metrics", { citationsRange, product, surveys }],
    queryFn: () => {
      return getTsisProductMetrics({
        citations: citationsRange,
        company: null,
        cut: null,
        cut_groups: cutGroups,
        sector: null,
        products: product,
        surveys,
      })
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: shouldFetchProductMetrics,
  })

  const isLoading = shouldFetchCompanyMetrics ? isLoadingCompanyMetrics : isLoadingProductMetrics
  const data = shouldFetchCompanyMetrics ? companyMetrics : productMetrics

  const dataFilterByEnterpriseSizeCut = data?.filter(
    (item: any) => item.cut.cut_group.name === "Enterprise Size Cuts" || item.cut.cut_group.name === "Index Cuts"
  )

  const dataFilterByIndustryCut = data?.filter((item: any) => item.cut.cut_group.name === "Industry Cuts")

  const dataFilterByJobTitleCut = data?.filter((item: any) => item.cut.cut_group.name === "Job Title Cuts")

  const dataFilterByRegionCut = data?.filter((item: any) => item.cut.cut_group.name === "Region Cuts")

  const showEnterprise = getDisplayToggleState(displayFilterGroup?.selectedItems, "enterprise", true)
  const showIndustry = getDisplayToggleState(displayFilterGroup?.selectedItems, "industry", true)
  const showJobTitle = getDisplayToggleState(displayFilterGroup?.selectedItems, "job-title", true)
  const showRegion = getDisplayToggleState(displayFilterGroup?.selectedItems, "region", true)

  if (!widgetEnabled) {
    return (
      <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
        <div className="h-full flex flex-col items-center justify-center">
          <BarChartIcon className="h-48 w-48 text-muted" />
          <p className="text-muted-foreground text-sm">Please select a sector and a product to view the respondent subsample graph</p>
        </div>
      </WithAspectRatio>
    )
  }

  return (
    <div className="relative grid flex-1 grid-cols-1 gap-6 md:grid-cols-2">
      {showEnterprise && (
        <div
          className={cn("flex flex-col gap-2 min-h-80 relative", {
            "col-span-2": !showIndustry || (!showJobTitle && !showRegion),
          })}
        >
          <RespondentSubsampleChart
            title="Enterprise Size"
            metrics={metrics}
            metricsGroupedBySurvey={groupBy(dataFilterByEnterpriseSizeCut, "survey.short_name")}
            metricsGroupedByCut={groupBy(dataFilterByEnterpriseSizeCut, "cut.name")}
            loading={isLoading}
          />
        </div>
      )}

      {showIndustry && (
        <div
          className={cn("flex flex-col gap-2 min-h-80 relative", {
            "col-span-2": !showEnterprise || (!showJobTitle && !showRegion),
          })}
        >
          <RespondentSubsampleChart
            title="Industry"
            metrics={metrics}
            metricsGroupedBySurvey={groupBy(dataFilterByIndustryCut, "survey.short_name")}
            metricsGroupedByCut={groupBy(dataFilterByIndustryCut, "cut.name")}
            loading={isLoading}
          />
        </div>
      )}

      {showJobTitle && (
        <div
          className={cn("flex flex-col gap-2 min-h-80 relative", {
            "col-span-2": !showRegion || (!showEnterprise && !showIndustry),
          })}
        >
          <RespondentSubsampleChart
            title="Job Title"
            metrics={metrics}
            metricsGroupedBySurvey={groupBy(dataFilterByJobTitleCut, "survey.short_name")}
            metricsGroupedByCut={groupBy(dataFilterByJobTitleCut, "cut.name")}
            loading={isLoading}
          />
        </div>
      )}

      {showRegion && (
        <div
          className={cn("flex flex-col gap-2 min-h-80 relative", {
            "col-span-2": !showJobTitle || (!showEnterprise && !showIndustry),
          })}
        >
          <RespondentSubsampleChart
            title="Region"
            metrics={metrics}
            metricsGroupedBySurvey={groupBy(dataFilterByRegionCut, "survey.short_name")}
            metricsGroupedByCut={groupBy(dataFilterByRegionCut, "cut.name")}
            loading={isLoading}
          />
        </div>
      )}
    </div>
  )
}

interface RespondentSubsampleChartProps {
  title: string
  metrics: FilterListItem[]
  metricsGroupedByCut: Record<string, any[]>
  metricsGroupedBySurvey: Record<string, any[]>
  loading?: boolean
}

function RespondentSubsampleChart(props: RespondentSubsampleChartProps) {
  const { title, metrics, metricsGroupedByCut, metricsGroupedBySurvey, loading = true } = props

  const [options, setOptions] = useState<Highcharts.Options>({
    ...defaultChartOptions,
    legend: {
      reversed: true,
    },
    chart: {
      ...defaultChartOptions.chart,
      type: "column",
    },
    title: {
      align: "left",
      text: title,
      margin: 30,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          color: "#000000",
          crop: false,
          overflow: "allow",
          style: {
            fontWeight: "normal",
          },
          rotation: 270,
          y: -20,
          formatter: function () {
            return `${round(this.point.y || 0)}%`
          },
        },
      },
      column: {
        groupPadding: 0.08,
        pointPadding: 0.08,
      },
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      followPointer: true,
      snap: 1,
      formatter: function () {
        return `
          <p><b>Respondent Cut: </b>${this.point.category}</p>
          <p><b>${this.series.name}: </b>${this.point.y}%</p>
          <p><b>Citations: </b>${this.point.custom?.citations}</p>
        `
      },
    },
    xAxis: {
      crosshair: true,
      reversedStacks: true,
      type: "category",
      labels: {
        style: {
          width: 85,
          textOverflow: "ellipsis",
        },
      },
    },
    yAxis: {
      labels: {
        format: "{value}%",
      },
      startOnTick: true,
      endOnTick: true,
      minPadding: 0,
      maxPadding: 0,
    },
    series: [],
  })

  const chartRef = useChartLoading(loading)

  useEffect(() => {
    if (!metricsGroupedBySurvey || !metricsGroupedByCut) return

    const sortedByLatestSurveyDate = Object.entries(metricsGroupedBySurvey).toReversed()

    const series = sortedByLatestSurveyDate.map(([surveyName, productMetrics], idx) => {
      const data = productMetrics.map((item) => {
        const metricValues = metrics.map((m) => item[m.id])
        const value = sum(metricValues)
        return {
          name: item.cut.name,
          y: round(value * 100, 1),
          custom: {
            citations: item.citations ?? item.unique_respondents,
          },
        }
      })
      return {
        name: surveyName,
        data: idx === 0 ? data.sort((a, b) => b.y - a.y) : data,
      }
    }) as Highcharts.SeriesOptionsType[]

    setOptions({
      yAxis: {
        title: {
          text: metrics.map((m) => m?.name).join(" + "),
        },
      },
      series,
    })
  }, [metricsGroupedBySurvey, metricsGroupedByCut])

  return (
    <HC highcharts={Highcharts} options={options} containerProps={{ className: "absolute top-0 right-0 bottom-0 left-0" }} ref={chartRef} />
  )
}
