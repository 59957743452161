import { getTsisRespondentDemographics } from "@/api/tsis_respondent_demographics"
import {
  FilterGroupKeys,
  FilterList,
  FilterListItem,
  getDisplayToggleState,
  MetricList,
  SurveyDates,
  useFilterGroupStore,
} from "@/components/filters"
import { Widget, WidgetPropsBase } from "@/components/widget"
import { WithAspectRatio } from "@/components/with_aspect_ratio"
import { useChartLoading } from "@/hooks/use_chart_loading"
import { useFilter } from "@/hooks/use_filter"
import { useSurveyDates } from "@/hooks/use_survey_dates"
import { HighchartsReact as HC, Highcharts } from "@/lib/highcharts"
import { cn } from "@/lib/utils"
import { Skeleton } from "@/ui/skeleton"
import { useQuery } from "@tanstack/react-query"
import { SeriesOptionsType } from "highcharts"
import { groupBy } from "lodash"
import React, { useEffect, useState } from "react"

const DISPLAY_FILTER_OPTIONS: FilterListItem[] = [
  { id: "breakdown", name: "Breakdown" },
  { id: "industry", name: "Industry" },
  { id: "enterprise", name: "Enterprise" },
  { id: "region", name: "Region" },
  { id: "job-title", name: "Job Title" },
]

interface RespondentCompositionProps extends WidgetPropsBase {
  analyze?: "company" | "sector"
}

export const RespondentCompositionWidget = ({ ...props }: RespondentCompositionProps) => {
  return (
    <Widget
      componentKey={"respondent_composition"}
      displayFilterToggles={DISPLAY_FILTER_OPTIONS}
      ignoredGlobalFilters={["citations", "cuts", "survey_dates"]}
      {...props}
    >
      <Widget.PrimaryFilters>
        <RespondentCompositionPrimaryFilters analyze={props.analyze} />
      </Widget.PrimaryFilters>

      <Widget.SecondaryFilters>
        <RespondentCompositionSecondaryFilters hiddenFilters={props.hiddenFilters} />
      </Widget.SecondaryFilters>

      <Widget.Body>
        <Content renderAspectRatioContainer={true} />
        {props.children}
      </Widget.Body>
    </Widget>
  )
}

const RespondentCompositionPrimaryFilters = (props: { analyze?: "company" | "sector" }) => {
  const sectors = useFilter("sectors", { returnType: "multiple" })
  const companies = useFilter("companies", { returnType: "multiple" })

  return (
    <>
      {props.analyze !== "company" && <FilterList name="Sectors" filterGroupId="sectors" endpoint="/api/v1/sectors" />}

      {props.analyze !== "sector" && (
        <FilterList
          name="Companies"
          filterGroupId="companies"
          endpoint="/api/v1/companies"
          params={{
            sectors,
          }}
        />
      )}

      <FilterList
        name="Products"
        filterGroupId="products"
        endpoint="/api/v1/products"
        formatter={(item) => ({
          ...item,
          subtitle: item.sector.name,
        })}
        params={{
          sectors,
          companies,
        }}
      />
    </>
  )
}

const RespondentCompositionSecondaryFilters = ({ hiddenFilters }: Pick<WidgetPropsBase, "hiddenFilters">) => {
  const filters: Array<[FilterGroupKeys, React.ReactNode]> = [
    ["survey_dates", <SurveyDates mode="single" size="sm" />],
    ["metrics", <MetricList items={METRIC_FILTER_OPTIONS} defaultMetrics={[]} />],
  ]

  const filterComponents = filters
    .filter(([key, _component]) => !hiddenFilters?.includes(key))
    .map(([key, component]) => <React.Fragment key={key}>{component}</React.Fragment>)

  return <div className="flex items-center flex-grow space-x-4">{filterComponents}</div>
}

function Content(props: Pick<WidgetPropsBase, "renderAspectRatioContainer">) {
  const display = useFilterGroupStore((s) => s.display)
  const { renderAspectRatioContainer } = props
  const { value: survey, isLoading: surveyIsLoading } = useSurveyDates("single")

  const metrics = useFilter("metrics", { returnType: "multiple" })
  const sectors = useFilter("sectors", { returnType: "multiple" })
  const companies = useFilter("companies", { returnType: "multiple" })
  const products = useFilter("products", { returnType: "multiple" })

  const { data, isLoading } = useQuery({
    queryKey: [
      "tsis_respondent_demographics",
      {
        survey,
        metrics,
        companies,
        sectors,
        products,
      },
    ],
    queryFn: () => {
      return getTsisRespondentDemographics({
        survey,
        metrics,
        companies,
        sectors,
        products,
      })
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!survey.length && !surveyIsLoading,
  })

  const filterDataValues = ["Restricted", "Non-Classified", "Government"]

  const filteredIndustryData = data?.filter((r: RespondentCompositionData) => !filterDataValues.includes(r.industry))

  const filteredEnterpriseData = data?.filter((r: RespondentCompositionData) => !filterDataValues.includes(r.enterprise_size))

  const filteredRegionData = data?.filter((r: RespondentCompositionData) => !filterDataValues.includes(r.region))

  const filteredTitleData = data?.filter((r: RespondentCompositionData) => !filterDataValues.includes(r.job_title_bucket))

  const getSeriesData = (
    filteredData: RespondentCompositionData[],
    groupByKey: string,
    compareFn: (a: PointData, b: PointData) => number = (a, b) => b.y - a.y
  ): PointData[] => {
    const groupData = groupBy(filteredData, groupByKey)

    return Object.keys(groupData)
      .map((dataKey) => {
        const total = groupData[dataKey]?.length || 0
        const percentage = (total / filteredData.length) * 100
        return {
          name: dataKey,
          y: percentage,
          rounded: Math.round(percentage),
          total: total,
        }
      })
      .toSorted(compareFn)
  }

  const isEnterpriseHidden = getDisplayToggleState(display?.selectedItems, "enterprise", DEFAULT_DISPLAY_TOGGLE_STATE) === false
  const isRegionHidden = getDisplayToggleState(display?.selectedItems, "region", DEFAULT_DISPLAY_TOGGLE_STATE) === false
  const isJobTitleHidden = getDisplayToggleState(display?.selectedItems, "job-title", DEFAULT_DISPLAY_TOGGLE_STATE) === false
  const isIndustryHidden = getDisplayToggleState(display?.selectedItems, "industry", DEFAULT_DISPLAY_TOGGLE_STATE) === false
  const isBreakdownShown = getDisplayToggleState(display?.selectedItems, "breakdown", true) === true

  const enterpriseSizeOrder = ["Large", "Midsize", "Small"]
  const jobTitleOrder = [
    "CxO - CIO, CTO, CISO, CMO, CDO, CPO",
    "VP, Head Of, Director, Manager, Principal",
    "Analyst, Architect, Engineer, Lead, Specialist",
  ]

  const DEFAULT_CHART_RATIO = 3 / 1
  const LEFT_CHART_RATIO = isEnterpriseHidden && isRegionHidden && isJobTitleHidden ? DEFAULT_CHART_RATIO : 1
  const RIGHT_CHARTS_RATIO = isIndustryHidden ? 4 / 1 : DEFAULT_CHART_RATIO

  if (data?.length === 0) return <Widget.Empty />

  return (
    <>
      {isBreakdownShown && <Breakdown data={data} loading={isLoading} />}

      <div
        className={cn(
          "bg-[url('/images/ETR-graph-background-transparent.png')] bg-center bg-contain bg-no-repeat my-3 grid gap-4",
          isIndustryHidden || (isJobTitleHidden && isRegionHidden && isEnterpriseHidden) ? "grid-cols-1" : "grid-cols-2"
        )}
      >
        {!isIndustryHidden ?
          <WithAspectRatio ratio={LEFT_CHART_RATIO} shouldWrap={renderAspectRatioContainer}>
            <RespondentCompositionChart loading={isLoading} title="Industry" data={getSeriesData(filteredIndustryData, "industry")} />
          </WithAspectRatio>
        : null}

        <div>
          {!isEnterpriseHidden ?
            <WithAspectRatio ratio={RIGHT_CHARTS_RATIO} shouldWrap={renderAspectRatioContainer}>
              <RespondentCompositionChart
                title="Enterprise Size"
                loading={isLoading}
                data={getSeriesData(
                  filteredEnterpriseData,
                  "enterprise_size",
                  (a, b) => enterpriseSizeOrder.indexOf(a.name) - enterpriseSizeOrder.indexOf(b.name)
                )}
              />
            </WithAspectRatio>
          : null}

          {!isRegionHidden ?
            <WithAspectRatio ratio={RIGHT_CHARTS_RATIO} shouldWrap={renderAspectRatioContainer}>
              <RespondentCompositionChart loading={isLoading} title="Region" data={getSeriesData(filteredRegionData, "region")} />
            </WithAspectRatio>
          : null}

          {!isJobTitleHidden ?
            <WithAspectRatio ratio={RIGHT_CHARTS_RATIO} shouldWrap={renderAspectRatioContainer}>
              <RespondentCompositionChart
                title="Job Title"
                loading={isLoading}
                data={getSeriesData(
                  filteredTitleData,
                  "job_title_bucket",
                  (a, b) => jobTitleOrder.indexOf(a.name) - jobTitleOrder.indexOf(b.name)
                )}
              />
            </WithAspectRatio>
          : null}
        </div>
      </div>
    </>
  )
}

function Breakdown(props: BreakdownProps) {
  const { data, loading = true } = props

  let nSizeGroups = [
    { id: "fortune100", name: "Fortune 100", count: 0 },
    { id: "fortune500", name: "Fortune 500", count: 0 },
    { id: "fortune1000", name: "Fortune 1000", count: 0 },
    { id: "sp500", name: "S&P 500", count: 0 },
    { id: "global1000", name: "Global 1000", count: 0 },
    { id: "global2000", name: "Global 2000", count: 0 },
    { id: "private225", name: "Private", count: 0 },
  ]

  data?.forEach((respondent: any) =>
    nSizeGroups.forEach((group) => {
      if (respondent[group.id]) group.count += 1
    })
  )

  const global2000 = nSizeGroups.find((g) => g.id === "global2000")?.count || 0
  const private225 = nSizeGroups.find((g) => g.id === "private225")?.count || 0
  const giantPublicPrivate = {
    id: "giant_public_private",
    name: "Giant Public + Private",
    count: global2000 + private225,
  }

  return (
    <div className="flex flex-col gap-3 mt-2 md:flex-row">
      {loading ?
        Array.from({ length: 2 }).map((_, index) => (
          <Skeleton key={index} className="flex-1 rounded bg-slate-100 p-3 text-sm space-y-2 h-28" />
        ))
      : <>
          <div className="w-full p-3 text-sm rounded bg-slate-100">
            <p className="text-slate-500">Participants</p>
            <p className="text-xl font-semibold text-black">N = {props.data?.length || 0}</p>
          </div>
          <div className="w-full p-3 text-sm rounded bg-slate-100">
            <p className="text-slate-500">Survey Composition</p>
            <div className="flex flex-col lg:flex-row lg:gap-2 text-slate-800">
              <div className="w-full">
                {nSizeGroups.slice(0, 3).map((group) => (
                  <div key={group.id}>
                    <b>{group.count}</b> {group.name}(N)
                  </div>
                ))}
              </div>
              <div className="w-full">
                {nSizeGroups.slice(3, 6).map((group) => (
                  <div key={group.id}>
                    <b>{group.count}</b> {group.name}(N)
                  </div>
                ))}
              </div>

              <div className="w-full">
                <div>
                  <b>{giantPublicPrivate.count}</b> {giantPublicPrivate.name}(N)
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

function RespondentCompositionChart(props: ChartProps) {
  const { loading = true } = props

  const [options, setOptions] = useState<Highcharts.Options>({
    ...CHART_DEFAULT_OPTIONS,
    title: {
      align: "left",
      text: props.title,
    },
    xAxis: {
      categories: getCategoryNames(props.data),
    },
    series: [
      {
        name: props.title,
        data: props.data,
      },
    ] as SeriesOptionsType[],
  })

  useEffect(() => {
    setOptions({
      xAxis: { categories: getCategoryNames(props.data) },
      series: [
        {
          name: props.title,
          data: props.data,
        },
      ] as SeriesOptionsType[],
    })
  }, [props.data, props.title])

  const chartRef = useChartLoading(loading)

  return <HC highcharts={Highcharts} options={options} containerProps={{ className: "h-full" }} ref={chartRef} />
}

function getCategoryNames(chartData: PointData[]): string[] {
  return chartData.map((data) => data.name)
}

const CHART_DEFAULT_OPTIONS: Highcharts.Options = {
  chart: {
    type: "bar",
    backgroundColor: "rgba(0,0,0,0)",
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    visible: false,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        color: "#000000",
        enabled: true,
        format: "{point.rounded}%",
      },
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    pointFormat:
      '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/> <span style="color:{point.color}">N</span>: <b>{point.total} respondents</b><br/>',
  },
}

const DEFAULT_DISPLAY_TOGGLE_STATE = true

const METRIC_FILTER_OPTIONS: FilterListItem[] = [
  {
    id: "ADOPTION",
    name: "Adoption %",
  },
  {
    id: "INCREASE",
    name: "Increase %",
  },
  {
    id: "FLAT",
    name: "Flat %",
  },
  {
    id: "DECREASE",
    name: "Decrease %",
  },
  {
    id: "REPLACING",
    name: "Replacing %",
  },
]

interface PointData {
  name: string
  y: number
  total: number
  rounded: number
}

interface ChartProps {
  title: string
  data: PointData[]
  loading?: boolean
}

interface BreakdownProps {
  data: RespondentCompositionData[]
  loading: boolean
}

interface RespondentCompositionData {
  id: string
  country: string
  enterprise_size: string
  footprint: string
  fortune100: boolean
  fortune1000: boolean
  fortune500: boolean
  global1000: boolean
  global2000: boolean
  industry: string
  job_title: string
  job_title_bucket: string
  job_title_standardized: string
  private225: boolean
  region: string
  sector_type: string
  sp500: boolean
  ticker: string
}
