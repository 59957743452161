import request from "./client"

/**
 * Fetches a list of widgets from the API.
 *
 * @param {Object} [options={}] - The options for fetching widgets.
 * @param {?string} [options.searchTerm=""] - The search term to filter widgets. Can be null.
 * @param {string} [options.category="All Widgets"] - The category to filter widgets.
 * @returns {Promise<Object[]>} A promise that resolves to the response from the API.
 */
export function getWidgets({ searchTerm = "", category = "All Widgets" } = {}) {
  return request({
    url: "/api/v1/widgets",
    method: "GET",
    params: {
      search: searchTerm,
      category,
    },
  })
}
