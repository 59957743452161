import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/ui/alert_dialog"
import { buttonVariants } from "@/ui/button"
import { Input } from "@/ui/input"
import React, { useState } from "react"

interface RenameResourceDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  onConfirm?: (name: string) => void
  defaultValue?: string
  onCancel?: () => void
}

export function RenameResourceDialog(props: RenameResourceDialogProps) {
  const { open, onOpenChange, onConfirm, defaultValue, onCancel } = props
  const [name, setName] = useState("")

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent className="w-[50%]">
        <AlertDialogHeader>
          <AlertDialogTitle>Rename Dashboard</AlertDialogTitle>
        </AlertDialogHeader>
        <Input placeholder="Name (Required)" defaultValue={defaultValue} onChange={(e) => setName(e.target.value)} />
        <AlertDialogFooter>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={onCancel}>Cancel</AlertDialogCancel>
            <AlertDialogAction className={buttonVariants({ variant: "default" })} onClick={() => onConfirm?.(name)}>
              Save
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
