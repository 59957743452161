import { format } from "date-fns-tz"
import { parseISO } from "date-fns"
import React, { useState } from "react"
import { BsCalendarEvent, BsListCheck } from "react-icons/bs"
import { Offering } from "./types"
import { OfferingIcon } from "./icon"
import { capitalize } from "lodash"
import { LuExternalLink, LuArrowDownToLine } from "react-icons/lu"
import { BsDownload } from "react-icons/bs"
import { cn } from "@/lib/utils"
import { Accordion, AccordionTrigger, AccordionItem, AccordionContent } from "@/ui/accordion"
import { Badge } from "@/ui/badge"
import { IoLockClosed } from "react-icons/io5"

interface DetailItemProps {
  icon: React.JSX.Element
  label?: string
  value: string | number
}

interface DownloadItemProps {
  icon: React.JSX.Element
  link: string
  value: string | number
  filename: string
}

function DetailItem(props: DetailItemProps): React.JSX.Element {
  const { icon, label, value } = props
  return (
    <div className="flex gap-2">
      {icon}
      <div className="text-xs">
        <span>{label}</span>: <span className="text-sky-700">{value}</span>
      </div>
    </div>
  )
}

function DownloadItem(props: DownloadItemProps): React.JSX.Element {
  const { icon, link, value, filename } = props

  return (
    <div className="flex gap-2">
      {icon}
      <div className="text-xs">
        <a className="text-sky-700 hover:underline" href={link} download={filename}>
          {value}
        </a>
      </div>
    </div>
  )
}

function SurveySampleSizes(props: any): React.JSX.Element | null {
  const { surveySampleSizes } = props
  if (!surveySampleSizes) return null
  return (
    <>
      <DetailItem icon={<BsListCheck className="w-3 text-sky-700" />} label="Survey Sample Size" value={surveySampleSizes.total} />
      <DetailItem icon={<BsListCheck className="w-3 text-sky-700" />} label="Fortune 500" value={surveySampleSizes.fortune500} />
      <DetailItem icon={<BsListCheck className="w-3 text-sky-700" />} label="Global 2000" value={surveySampleSizes.global2000} />
    </>
  )
}

function SurveySearchLink(props: any): React.JSX.Element {
  const { offerable } = props
  return (
    <a
      className="font-medium text-sky-700 hover:underline items-center flex gap-2 text-xs"
      data-turbo-frame="_top"
      href={`/offerings?subtypes[]=${offerable.subtype}&types[]=survey_report&survey=${offerable.survey.id}`}
    >
      <LuExternalLink className="text-sky-700 w-3" />
      <span className="text-sky-700">Reports in Survey</span>
    </a>
  )
}

const AnalyzeLink = (props: { type: "company" | "sector"; name: string }): React.JSX.Element => {
  const { type, name } = props
  return (
    <p>
      <span className="text-slate-700 mr-2 italic font-semibold text-xs">{capitalize(type)}</span>
      <span className="text-xs text-sky-700 italic hover:underline">
        <a href={`/analyze/${type}?name=${encodeURIComponent(name)}`}>{name}</a>
      </span>
    </p>
  )
}

function OfferingShowView(props: { offering: Offering }): React.JSX.Element {
  const { offering } = props
  const isSurveyReport = offering.offerable_type === "SurveyReport"
  const surveySampleSizes = isSurveyReport ? offering.offerable.survey_sample_size : null
  const publishedAtDate = format(parseISO(offering.published_at), "MMM do yyyy, z")
  const downloadableAssets = offering.assets.filter((asset: any) => asset.downloadable)

  return (
    <Accordion type="single" collapsible defaultValue="item-1">
      <AccordionItem value="item-1">
        <AccordionTrigger
          icon={<LuArrowDownToLine />}
          className="bg-white w-full p-4 justify-end border-b xl:[&[data-state=open]>svg]:rotate-90 xl:[&[data-state=closed]>svg]:rotate-[270deg]"
        />
        <AccordionContent contentClassName="xl:data-[state=closed]:animate-accordion-left xl:data-[state=open]:animate-accordion-right">
          <div className={cn("p-4 border-b bg-white")}>
            <div className="flex flex-col gap-2 xl:w-[300px]">
              <div>
                <OfferingIcon offering={offering} textVariant="sm" />
                <div className="text-sm space-x-2 font-semibold py-3">
                  <p className="inline m-0">{offering.name}</p>
                  {!offering.accessible ?
                    <Badge className="px-0.5 bg-violet-100 hover:bg-violet-100">
                      <IoLockClosed className="w-3 h-3 text-violet-600" />
                    </Badge>
                  : null}

                  {offering.exclusive ?
                    <Badge className="bg-violet-100 text-violet-600 hover:bg-violet-100">Exclusive</Badge>
                  : null}
                </div>
                <p className="text-xs whitespace-pre-line">{offering.description}</p>
              </div>
              <div>
                {isSurveyReport && offering.offerable.company ?
                  <AnalyzeLink type="company" name={offering.offerable.company.name} />
                : null}
                {isSurveyReport && offering.offerable.sector ?
                  <AnalyzeLink type="sector" name={offering.offerable.sector.name} />
                : null}
              </div>

              <div>
                <p className="text-sm font-semibold py-3">Details</p>
                <div className="flex flex-col gap-2">
                  <DetailItem icon={<BsCalendarEvent className="w-3 text-sky-700" />} label="Released" value={publishedAtDate} />
                  {isSurveyReport ?
                    <DetailItem
                      icon={<BsCalendarEvent className="w-3 text-sky-700" />}
                      label="Survey Date"
                      value={offering.offerable.survey.short_name}
                    />
                  : null}
                  <SurveySampleSizes surveySampleSizes={surveySampleSizes} />
                  {(isSurveyReport && offering.offerable.subtype === "ai_product_series") || offering.offerable.subtype === "tsis" ?
                    <SurveySearchLink offerable={offering.offerable} />
                  : null}
                </div>
              </div>

              {!offering.accessible || downloadableAssets.length === 0 ? null : (
                <div>
                  <p className="text-sm font-semibold py-3">Downloads</p>
                  <div className="flex flex-col gap-2">
                    {downloadableAssets.map((asset: any) => {
                      return (
                        <DownloadItem
                          value={asset.name}
                          icon={<BsDownload className="w-3 text-sky-700" />}
                          link={`/offerings/${offering.id}/assets/${asset.id}/download`}
                          key={asset.id}
                          filename={asset.filename}
                        />
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

export default OfferingShowView
