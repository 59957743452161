import request from "./client"

export function createViewItem(viewId, data) {
  const url = `/api/v1/dashboard_views/${viewId}/view_items`
  return request({
    url,
    method: "POST",
    data: {
      view_item: data,
    },
  })
}

export function updateViewItem(viewId, viewItemId, data) {
  const url = `/api/v1/dashboard_views/${viewId}/view_items/${viewItemId}`
  return request({
    url,
    method: "PUT",
    data: {
      view_item: data,
    },
  })
}

export function deleteViewItem(viewId, viewItemId) {
  const url = `/api/v1/dashboard_views/${viewId}/view_items/${viewItemId}`
  return request({
    url,
    method: "DELETE",
  })
}
