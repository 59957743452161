import React from "react"

export default function WidgetNoDataIcon({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="136" height="132" fill="none" viewBox="0 0 136 132" className={className}>
      <rect width="136" height="131.541" fill="#EEF2FF" rx="65.77"></rect>
      <path
        fill="#818CF8"
        d="M85.44 45.33c0 2.871-8.147 5.199-18.195 5.199-10.05 0-18.196-2.328-18.196-5.2 0-2.87 8.147-5.198 18.196-5.198 10.048 0 18.195 2.328 18.195 5.199z"
      ></path>
      <path
        fill="#818CF8"
        fillRule="evenodd"
        d="M67.245 62.13c10.048 0 18.195-2.328 18.195-5.199V48.45s-4.367 3.995-18.195 3.995c-13.829 0-18.196-3.776-18.196-3.776v8.263c0 2.871 8.147 5.199 18.196 5.199zm-2.52-5.582c-.309 0-.56.245-.56.547v.657c0 .302.251.547.56.547h5.15c.31 0 .56-.245.56-.547v-.657a.554.554 0 00-.56-.547h-5.15z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#818CF8"
        fillRule="evenodd"
        d="M67.245 73.95h5.766s.616-4.159 3.919-6.895c3.148-2.607 7.109-2.581 8.347-2.573h.06a90.799 90.799 0 01.103.001v-4.214s-4.367 3.995-18.195 3.995c-13.829 0-18.196-3.776-18.196-3.776v8.263c0 2.872 8.147 5.2 18.196 5.2zm-2.52-5.582c-.309 0-.56.245-.56.548v.656c0 .302.251.547.56.547h5.15c.31 0 .56-.245.56-.547v-.656a.554.554 0 00-.56-.548h-5.15zM67.245 85.77c6.83 0 9.965-.93 9.965-.93s-2.46-1.97-3.415-4.104c-.955-2.134-1.008-5.144-1.008-5.144s-1.4.492-5.543.492c-13.828 0-18.195-3.776-18.195-3.776v8.264c0 2.87 8.147 5.198 18.196 5.198zm-2.52-5.581c-.309 0-.56.245-.56.547v.656c0 .303.251.548.56.548h5.15c.31 0 .56-.245.56-.547v-.657a.554.554 0 00-.56-.547h-5.15z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#818CF8"
        fillRule="evenodd"
        d="M94.398 75.756c0 5.35-4.437 9.686-9.91 9.686s-9.91-4.337-9.91-9.686c0-5.35 4.437-9.686 9.91-9.686s9.91 4.337 9.91 9.686zm-16.889 0c0 3.767 3.125 6.822 6.98 6.822 3.853 0 6.978-3.055 6.978-6.822s-3.124-6.822-6.979-6.822c-3.854 0-6.979 3.055-6.979 6.822z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#818CF8"
        d="M91.88 85.58a1.077 1.077 0 01.048-1.547l1.304-1.201a1.138 1.138 0 011.582.046l7.439 7.715c.424.44.403 1.133-.047 1.547l-1.304 1.201a1.138 1.138 0 01-1.583-.045L91.88 85.58z"
      ></path>
    </svg>
  )
}
