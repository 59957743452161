import { FilterGroupStoreContext, getAllFilterGroupsFromStore, getDisplayToggleState, useFilterGroupStore } from "@/components/filters"
import { functionalUpdate, getPaginationRowModel, TableOptions, TableState, useReactTable } from "@tanstack/react-table"
import { isEqual } from "lodash"
import { useContext, useEffect, useState } from "react"

export function useDatatable<T>({ initialState = {}, ...opts }: TableOptions<T>) {
  const store = useContext(FilterGroupStoreContext)
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)

  const table = useReactTable<T>({
    getPaginationRowModel: getPaginationRowModel(),
    enableColumnPinning: true,
    enableSorting: true,
    manualPagination: true,
    manualSorting: true,
    defaultColumn: {
      size: 200,
    },
    ...opts,
  })

  const [tableState, setTableState] = useState<TableState>({
    ...table.initialState,
    ...(initialState as TableState),
  })

  useEffect(() => {
    const unsubscribe = store?.subscribe(getAllFilterGroupsFromStore, (state, prevState) => {
      const prevHideFilters = getDisplayToggleState(prevState.display?.selectedItems, "selected-filters")
      const hideFilters = getDisplayToggleState(state.display?.selectedItems, "selected-filters")
      if (!isEqual(state.datatable?.data, prevState.datatable?.data)) return
      if (!isEqual(prevHideFilters, hideFilters)) return

      setTableState((prevTableState) => ({ ...prevTableState, pagination: { ...prevTableState.pagination, pageIndex: 0 } }))
    })

    return unsubscribe
  }, [])

  table.setOptions((prev) => ({
    ...prev,
    state: tableState,
    onStateChange: (updater) => {
      const state = functionalUpdate(updater, tableState)
      const isPageIndexChange = state.pagination.pageIndex !== tableState.pagination.pageIndex
      const isPageSizeChange = state.pagination.pageSize !== tableState.pagination.pageSize
      const isSortChange = !isEqual(state.sorting, tableState.sorting)
      const pagination = isSortChange ? { pageSize: state.pagination.pageSize, pageIndex: 0 } : state.pagination

      setTableState({ ...state, pagination })

      if (isPageIndexChange && !isPageSizeChange) return

      updateFilterGroups({
        datatable: {
          name: "DataTable",
          hidden: true,
          selectedItems: [],
          data: { ...state, pagination: { pageSize: state.pagination.pageSize, pageIndex: 0 } },
        },
      })
    },
  }))

  return table
}
