import DashboardView from "@/views/dashboard-views"
import DashboardIndex from "@/views/dashboards"
import DataAnalyzer from "@/views/data-analyzer"
import AnalyzeCompany from "@/views/data-analyzer/company"
import AnalyzeIdeaGeneratorScreener from "@/views/data-analyzer/screeners/idea_generator"
import AnalyzeSurveyZScoreScreener from "@/views/data-analyzer/screeners/survey_z_score"
import AnalyzeSector from "@/views/data-analyzer/sector"
import AnalyzeSharedAccounts from "@/views/data-analyzer/shared_accounts"
import OfferingForm from "@/views/offerings/form"
import OfferingShowView from "@/views/offerings/show"
import OfferingResults from "@/views/offerings/results"

export default {
  AnalyzeCompany,
  AnalyzeIdeaGeneratorScreener,
  AnalyzeSurveyZScoreScreener,
  AnalyzeSector,
  AnalyzeSharedAccounts,
  DashboardView,
  DashboardIndex,
  DataAnalyzer,
  OfferingForm,
  OfferingShowView,
  OfferingResults,
}
