import { Badge } from "@/ui/badge"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/ui/card"
import { format } from "date-fns"
import React, { useEffect } from "react"
import { BsCalendarEvent } from "react-icons/bs"
import { IoLockClosed } from "react-icons/io5"
import { OfferingIcon } from "./icon"
import { Offering } from "./types"

interface OfferingResultsProps {
  offerings: Offering[]
}

const OfferingResults = (props: OfferingResultsProps) => {
  const { offerings } = props

  useEffect(() => {
    const pagination = document.querySelector("#offering_pagination")
    if (pagination) {
      pagination.classList.remove("hidden")
    }
  }, [])

  if (offerings.length === 0) {
    return <div className="text-center">No results found.</div>
  }

  return offerings.map((offering) => (
    <Card key={offering.id}>
      <CardHeader className="pb-3 pt-6">
        <CardTitle>
          <OfferingIcon offering={offering} textVariant="sm" />
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2">
        <CardTitle className="text-sm space-x-2">
          <a href={`/offerings/${offering.id}`} className="hover:text-primary">
            {offering.name}
          </a>
          {!offering.accessible ?
            <Badge className="px-0.5 bg-violet-100 hover:bg-violet-100">
              <IoLockClosed className="w-3 h-3 text-violet-600" />
            </Badge>
          : null}

          {offering.exclusive ?
            <Badge className="bg-violet-100 text-violet-600 hover:bg-violet-100">Exclusive</Badge>
          : null}
        </CardTitle>
        <CardDescription className="whitespace-pre-line">{offering.description}</CardDescription>
        {offering.offerable_type === "SurveyReport" && offering.offerable.company ?
          <CardDescription className="text-xs space-x-1">
            <span className="font-semibold italic">Companies:</span> <span className="text-sky-700">{offering.offerable.company.name}</span>
          </CardDescription>
        : null}
        {offering.offerable_type === "SurveyReport" && offering.offerable.sector ?
          <CardDescription className="text-xs space-x-1">
            <span className="font-semibold italic">Sectors:</span> <span className="text-sky-700">{offering.offerable.sector.name}</span>
          </CardDescription>
        : null}
        <div className="flex flex-row items-center gap-2 text-xs">
          <BsCalendarEvent className="text-primary" /> Released: {format(new Date(offering.published_at), "MMM d, yyyy")}
        </div>
      </CardContent>
    </Card>
  ))
}

export default OfferingResults
