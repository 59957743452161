import React from "react"

export default function AnalyzeSectorIcon({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="342" height="343" fill="none" viewBox="0 0 342 343" className={className}>
      <path
        fill="#592BB0"
        d="M314.56 185.568c0 31.137-9.225 61.575-26.507 87.465a157.31 157.31 0 01-70.585 57.983 157.14 157.14 0 01-90.872 8.959 157.23 157.23 0 01-80.53-43.086 157.479 157.479 0 01-43.044-80.607 157.573 157.573 0 018.95-90.96A157.399 157.399 0 0169.9 54.669a157.172 157.172 0 0187.38-26.532v157.431h157.28z"
      ></path>
      <path
        fill="#B498E6"
        d="M342 142.358c0-18.694-3.679-37.206-10.826-54.478a142.356 142.356 0 00-30.83-46.184 142.205 142.205 0 00-46.139-30.86A142.102 142.102 0 00199.779 0v142.358H342z"
      ></path>
    </svg>
  )
}
