import { getCuts } from "@/api/cuts"
import { getTsisProductMetrics } from "@/api/tsis_product_metrics"
import {
  FilterGroupKeys,
  FilterList,
  FilterListDropdown,
  FilterListItem,
  FilterRange,
  MetricList,
  SurveyDates,
  getSelectedRange,
  getSelectedMetrics,
} from "@/components/filters"
import { WIDGET_ASPECT_RATIO, Widget, WidgetPropsBase } from "@/components/widget"
import { WithAspectRatio } from "@/components/with_aspect_ratio"
import { useChartLoading } from "@/hooks/use_chart_loading"
import { useDefaultFilter, useFilter } from "@/hooks/use_filter"
import { useSurveyDates } from "@/hooks/use_survey_dates"
import { HighchartsReact as HC, Highcharts, defaultChartOptions } from "@/lib/highcharts"
import { ArrowRightIcon, BarChartIcon } from "@radix-ui/react-icons"
import { useQuery } from "@tanstack/react-query"
import { groupBy, round } from "lodash"
import React, { useEffect, useState } from "react"
import { useFilterGroupStore } from "../filters/filter_group_store_provider"

export const ProductAreaWidget = ({ ...props }: WidgetPropsBase) => {
  return (
    <Widget componentKey={"product_area"} ignoredGlobalFilters={["sectors"]} {...props}>
      <Widget.PrimaryFilters>
        <ProductAreaPrimaryFilters />
      </Widget.PrimaryFilters>

      <Widget.SecondaryFilters>
        <ProductAreaSecondaryFilters hiddenFilters={props.hiddenFilters} />
      </Widget.SecondaryFilters>

      <Widget.Body>
        <Content renderAspectRatioContainer={props.renderAspectRatioContainer} />
        {props.children}
      </Widget.Body>
    </Widget>
  )
}

const ProductAreaPrimaryFilters = () => {
  const companies = useFilter("companies", { returnType: "multiple" })
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)

  return (
    <>
      <FilterList
        name="Company"
        filterGroupId="companies"
        endpoint="/api/v1/companies"
        enableMultiRowSelection={false}
        onHandleFilterChange={(filterGroupId, items) => {
          updateFilterGroups({
            [filterGroupId]: {
              name: "Company",
              selectedItems: items,
            },
            products: {
              name: "Products",
              selectedItems: [],
            },
          })
        }}
      />
      <ArrowRightIcon className="hidden w-4 h-4 md:block text-muted-foreground align-self-center" />
      <FilterList
        name="Products"
        filterGroupId="products"
        endpoint="/api/v1/products"
        dependencies={["companies"]}
        params={{
          companies,
        }}
        formatter={(item) => ({
          ...item,
          subtitle: item.sector.name,
        })}
      />
    </>
  )
}

const ProductAreaSecondaryFilters = ({ hiddenFilters }: Pick<WidgetPropsBase, "hiddenFilters">) => {
  const filters: Array<[FilterGroupKeys, React.ReactNode]> = [
    ["survey_dates", <SurveyDates mode={SURVEY_DATE_MODE} size="sm" />],
    ["metrics", <MetricList items={METRIC_FILTER_OPTIONS} defaultMetrics={DEFAULT_METRICS} />],
    [
      "cuts",
      <FilterListDropdown
        className="w-full text-sm"
        name="Cut"
        text="Cut"
        size="sm"
        filterGroupId="cuts"
        endpoint="/api/v1/cuts"
        enableMultiRowSelection={false}
      />,
    ],
    ["citations", <FilterRange text="Citations" filterGroupId="citations" size="sm" />],
  ]

  const filterComponents = filters
    .filter(([key, _component]) => !hiddenFilters?.includes(key))
    .map(([key, component]) => <React.Fragment key={key}>{component}</React.Fragment>)

  return <div className="flex items-center flex-grow space-x-4">{filterComponents}</div>
}

const SURVEY_DATE_MODE = "multiple"
const METRIC_FILTER_OPTIONS: FilterListItem[] = [
  {
    name: "Adoption %",
    id: "adoption_percentage",
  },
  {
    name: "Increase %",
    id: "increase_percentage",
  },
  {
    name: "Flat %",
    id: "flat_percentage",
  },
  {
    name: "Decrease %",
    id: "decrease_percentage",
  },
  {
    name: "Replacing %",
    id: "replacing_percentage",
  },
  {
    name: "Net Score",
    id: "net_score",
  },
  {
    name: "Pervasion",
    id: "pervasion_of_sector",
  },
  {
    name: "Vendor NS / Sector NS",
    id: "vendor_sector_net_score_divided_sector_net_score",
  },
]
const DEFAULT_METRICS = METRIC_FILTER_OPTIONS.filter((item) => item.id == "net_score")

function Content(props: Pick<WidgetPropsBase, "renderAspectRatioContainer">) {
  const { renderAspectRatioContainer } = props
  const metricsFilterGroup = useFilterGroupStore((s) => s.metrics)
  const company = useFilter("companies", { returnType: "single" })
  const products = useFilter("products", { returnType: "multiple" })
  const citations = useFilter("citations", { returnType: "single" })
  const { value: cut } = useDefaultFilter("cuts", {
    queryFn: getCuts,
    select: (data: any) => data.filter((item: any) => item.name === "All Respondents"),
    returnType: "single",
  })

  const { value: surveys, isLoading: surveyIsLoading } = useSurveyDates(SURVEY_DATE_MODE)
  const metrics = getSelectedMetrics(metricsFilterGroup?.selectedItems, DEFAULT_METRICS)
  const citationsRange = getSelectedRange(citations, true)

  const widgetEnabled = !!company && !!cut && !!surveys && !surveyIsLoading
  const { isLoading, data } = useQuery({
    queryKey: ["tsis_product_metrics", { citationsRange, cut, company, products, surveys }],
    queryFn: () => {
      return getTsisProductMetrics({
        citations: citationsRange,
        company,
        cut,
        sector: undefined,
        products,
        surveys,
      })
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: widgetEnabled,
  })
  const metricsGroupedByProduct = groupBy(data, (item) => `${item.product.name} - ${item.sector.name}`)
  const metricsGroupedBySurveyDate = groupBy(data, "survey.short_name")

  if (!company) {
    return (
      <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
        <div className="h-full flex flex-col items-center justify-center">
          <BarChartIcon className="h-48 w-48 text-muted" />
          <p className="text-muted-foreground text-sm">Please select a company to view the product area graph</p>
        </div>
      </WithAspectRatio>
    )
  }

  if (data?.length === 0) return <Widget.Empty />

  return (
    <div className="relative flex-1">
      <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
        <ProductAreaChart
          metrics={metrics}
          metricsGroupedByProduct={metricsGroupedByProduct}
          metricsGroupedBySurveyDate={metricsGroupedBySurveyDate}
          loading={isLoading}
        />
      </WithAspectRatio>
    </div>
  )
}

interface ProductAreaChartProps {
  metrics: FilterListItem[]
  metricsGroupedByProduct: Record<string, any[]>
  metricsGroupedBySurveyDate: Record<string, any[]>
  loading?: boolean
}

function ProductAreaChart(props: ProductAreaChartProps) {
  const { metrics, metricsGroupedByProduct, metricsGroupedBySurveyDate, loading = true } = props

  const [options, setOptions] = useState<Highcharts.Options>({
    ...defaultChartOptions,
    legend: {
      reversed: true,
    },
    chart: {
      ...defaultChartOptions.chart,
      type: "column",
    },
    xAxis: {
      crosshair: true,
      reversedStacks: true,
      type: "category",
    },
    yAxis: {
      labels: {
        format: "{value}%",
      },
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          color: "#000000",
          crop: false,
          overflow: "allow",
          style: {
            fontWeight: "normal",
          },
          rotation: 270,
          y: -20,
          formatter: function () {
            return `${round(this.point.y || 0)}%`
          },
        },
      },
    },
    series: [],
    tooltip: {
      useHTML: true,
      formatter: function () {
        return `<p><b>Product - Sector: </b>${this.point.name}</p>
        <p><b>${this.series.name}: </b>${round(this.point.y || 0, 1)}%</p>
        <p><b>Citations: </b>${this.point.custom?.citations}</p>
        `
      },
    },
  })

  const chartRef = useChartLoading(loading)

  useEffect(() => {
    if (!metricsGroupedByProduct || !metricsGroupedBySurveyDate) return

    const sortedByLatestSurveyDate = Object.entries(metricsGroupedBySurveyDate).toReversed()
    const series = sortedByLatestSurveyDate.map(([surveyName, productMetrics], idx) => {
      const data = productMetrics.map((productMetric) => {
        return {
          name: `${productMetric?.product.name} - ${productMetric?.sector.name}`,
          y: metrics.reduce((acc, m) => acc + (m ? productMetric[m.id] : 0), 0) * 100,
          custom: {
            citations: productMetric?.citations,
          },
        }
      })

      return {
        name: surveyName,
        data: idx === 0 ? data.sort((a, b) => b.y - a.y) : data,
      }
    }) as Highcharts.SeriesOptionsType[]

    setOptions({
      ...options,
      yAxis: {
        title: {
          text: metrics.map((m) => m?.name).join(" + "),
        },
      },
      series,
    })
  }, [metricsGroupedByProduct, metricsGroupedBySurveyDate])

  return (
    <HC highcharts={Highcharts} options={options} containerProps={{ className: "absolute top-0 right-0 bottom-0 left-0" }} ref={chartRef} />
  )
}
