import request from "./client"

export function getDashboardViews(params) {
  const { dashboardId } = params
  const url = `/api/v1/dashboards/${dashboardId}/views`
  return request({
    url,
    method: "GET",
  })
}

export function deleteDashboardView(params) {
  const { dashboardId, dashboardViewId } = params
  const url = `/api/v1/dashboards/${dashboardId}/views/${dashboardViewId}`

  return request({
    url,
    method: "DELETE",
  })
}

export function createDashboardView(dashboardId, params) {
  const url = `/api/v1/dashboards/${dashboardId}/views`

  return request({
    url,
    method: "POST",
    data: {
      dashboard_view: params,
    },
  })
}

export function updateDashboardView(dashboardId, dashboardViewId, params) {
  const url = `/api/v1/dashboards/${dashboardId}/views/${dashboardViewId}`

  return request({
    url,
    method: "PATCH",
    data: {
      dashboard_view: params,
    },
  })
}
