import { Card, CardHeader } from "@/ui/card"
import { Tabs, TabsList, TabsTrigger } from "@radix-ui/react-tabs"
import { useQuery } from "@tanstack/react-query"
import { getWidgets } from "@/api"
import { Widget } from "@/components/widget"

import React from "react"

export function ScreenerTabs() {
  const { data: widgets } = useQuery({
    queryKey: ["widgets"],
    queryFn: () => getWidgets(),
  })

  const currentScreener = getCurrentScreener()

  const screenerName = (component_key: string) => (widgets as Widget[])?.find((widget) => widget.component_key === component_key)?.name

  return (
    <Card className="space-y-2">
      <Tabs value={screenerName(currentScreener.component_key)}>
        <CardHeader className="p-0">
          <div className="flex items-center space-x-4">
            <TabsList>
              {SCREENERS.map((screener) => {
                const name = screenerName(screener.component_key)
                return (
                  <TabsTrigger key={screener.component_key} value={screener.component_key} asChild>
                    <a
                      href={screener.path}
                      data-turbo="false"
                      className="inline-block border-b py-3 px-6 data-[state=inactive]:text-blue-500 data-[state=inactive]:border-white data-[state=active]:border-black"
                    >
                      {name}
                    </a>
                  </TabsTrigger>
                )
              })}
            </TabsList>
          </div>
        </CardHeader>
      </Tabs>
    </Card>
  )
}

const getCurrentScreener = () => {
  const path = window.location.pathname

  const currentScreener = SCREENERS.find(({ path: screenerPath }) => path === screenerPath)

  return currentScreener || SCREENERS[0]
}

const SCREENERS = [
  { component_key: "idea_generator", path: "/analyze/screeners/idea_generator" },
  { component_key: "survey_z_score", path: "/analyze/screeners/survey_z_score" },
] as const
