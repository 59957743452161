import { getSurveys } from "@/api/surveys"
import { useFilterGroupStore } from "@/components/filters"
import { useDefaultFilter } from "@/hooks/use_filter"
import { useCallback } from "react"

const SINGLE_MODE_SURVEY_COUNT = 1
const MULTIPLE_MODE_SURVEY_COUNT = 4

export function useSurveyDates(mode: "single" | "multiple" = "single") {
  const surveyDates = useFilterGroupStore((s) => s.survey_dates)
  const misc = useFilterGroupStore((s) => s.misc)
  const settings = misc?.selectedItems || []
  const shouldFetchLatestSurvey = Boolean(settings?.find((setting) => setting.id === "fetch_latest_survey")?.name)

  const surveySelector = useCallback(
    (data: any) => {
      const surveys = data?.map((survey: any) => ({ id: survey.date, name: survey.short_name }))

      return surveys?.slice(surveys?.length - (mode === "single" ? SINGLE_MODE_SURVEY_COUNT : MULTIPLE_MODE_SURVEY_COUNT))
    },
    [mode]
  )

  return useDefaultFilter("survey_dates", {
    enabled: shouldFetchLatestSurvey || !Boolean(surveyDates?.selectedItems.length),
    queryFn: () => getSurveys({ type: "tsis" }),
    select: surveySelector,
    name: "Surveys",
    permanent: true,
    returnType: "multiple",
  })
}
