import {
  FILTER_GROUP_PRIORITIES,
  FilterGroup,
  FilterGroupKeys,
  getAllFilterGroupsFromStore,
  getDisplayToggleState,
  getMaxPriority,
  useFilterGroupStore,
} from "@/components/filters"
import { Badge } from "@/ui/badge"
import { Popover, PopoverContent, PopoverTrigger } from "@/ui/popover"
import React from "react"

type FilterGroupTupleArray = [FilterGroupKeys, FilterGroup][]

export function SelectedFilters() {
  const appliedFilterGroups = useFilterGroupStore(getAllFilterGroupsFromStore)
  const isSelectedFiltersShowing = getDisplayToggleState(appliedFilterGroups?.display?.selectedItems, "selected-filters", true)

  const filterGroupsArray = Object.entries(appliedFilterGroups).filter(
    ([_, filterGroup]) => filterGroup?.selectedItems.length && !filterGroup.hidden
  ) as FilterGroupTupleArray

  if (!filterGroupsArray.length || !isSelectedFiltersShowing) {
    return null
  }

  return (
    <div className="flex-grow grid grid-flow-col text-nowrap text-muted-foreground gap-x-2 columns-2 md:auto-cols-min overflow-x-auto">
      {getSortedFilterGroups(filterGroupsArray).map(([filterGroupIdentifier, filterGroup]) => {
        const { selectedItems } = filterGroup
        const selectedItemCount = selectedItems.length

        return (
          <Badge className="bg-sky-50 text-sky-700 space-x-2 hover:bg-sky-50" key={filterGroupIdentifier}>
            <span>{filterGroup.name}:</span>

            <Popover>
              <PopoverTrigger>
                <Badge className="bg-sky-600 hover:bg-sky-600">
                  {selectedItemCount > 1 ? `${selectedItemCount}` : selectedItems[0]!.name}
                </Badge>
              </PopoverTrigger>

              {selectedItemCount > 1 && (
                <PopoverContent className="w-auto min-w-40 bg-slate-600 text-primary-foreground" sideOffset={8}>
                  <div className="flex flex-col gap-2">
                    <h4 className="text-lg font-medium">{filterGroup.name}</h4>

                    <ul className="list-none space-y-2 text-xs">
                      {selectedItems.map((item) => (
                        <li key={item.id}>{item.name}</li>
                      ))}
                    </ul>
                  </div>
                </PopoverContent>
              )}
            </Popover>
          </Badge>
        )
      })}
    </div>
  )
}

const getSortedFilterGroups = (filterGroupsArray: FilterGroupTupleArray): FilterGroupTupleArray => {
  return filterGroupsArray.toSorted((a, b) => {
    const aPermanent = a[1].permanent
    const bPermanent = b[1].permanent
    const aPriority = FILTER_GROUP_PRIORITIES[a[0]] || getMaxPriority() + 100
    const bPriority = FILTER_GROUP_PRIORITIES[b[0]] || getMaxPriority() + 100

    if ((aPermanent && !bPermanent) || aPriority < bPriority) {
      return -1
    } else if ((!aPermanent && bPermanent) || aPriority > bPriority) {
      return 1
    }

    return 0
  })
}
