import { AspectRatio } from "@/ui/aspect_ratio"
import { AspectRatioProps } from "@radix-ui/react-aspect-ratio"
import React, { PropsWithChildren } from "react"

interface WithAspectRatioProps extends AspectRatioProps {
  shouldWrap?: boolean
}

export function WithAspectRatio(props: WithAspectRatioProps) {
  const { shouldWrap = true, children, ...aspectRatioProps } = props

  if (shouldWrap) {
    return <AspectRatio {...aspectRatioProps}>{children}</AspectRatio>
  }

  return children
}
