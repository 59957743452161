import { useQuery } from "@tanstack/react-query"
import { CreateViewItemDialog } from "@/components/dialogs/create_view_item"
import { DeleteConfirmationDialog } from "@/components/dialogs/delete_confirmation"
import {
  DisplayFilters,
  DisplayFiltersProps,
  FilterGroupKeys,
  FilterGroupStoreProvider,
  FilterGroupStoreProviderProps,
  FilterGroups,
  SelectedFilters,
  getDisplayToggleState,
  getFilteredGroupsFromStore,
  useFilterGroupStore,
} from "@/components/filters"
import { WithAspectRatio } from "@/components/with_aspect_ratio"
import { cn, isReactElement } from "@/lib/utils"
import WidgetNoDataIcon from "@/svgs/widget_no_data_icon"
import { Button } from "@/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/ui/card"
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/ui/dialog"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/ui/dropdown_menu"
import { Spinner } from "@/ui/spinner"
import { Tabs } from "@/ui/tabs"
import { DotsVerticalIcon } from "@radix-ui/react-icons"
import React, { PropsWithChildren, useState } from "react"
import { IoEyeOffOutline, IoEyeOutline, IoFunnelOutline } from "react-icons/io5"
import { HiInformationCircle } from "react-icons/hi"
import { getWidgets } from "@/api/widgets"

export const WIDGET_ASPECT_RATIO = 16 / 9

export type Widget = {
  component_key: string
  name: string
  description: string
  helper_descriptions: string
}

export interface WidgetPropsBase extends FilterGroupStoreProviderProps {
  renderAspectRatioContainer?: boolean
  displayFilterToggles?: DisplayFiltersProps["toggleItems"]
  hideDisplayFilters?: boolean
  hidePrimaryFilters?: boolean
  hideSecondaryFilters?: boolean
  hiddenFilters?: FilterGroupKeys[]
  menuActions?: MenuAction[]
  defaultTab?: string
  className?: string
  loading?: boolean
}

export interface WidgetProps extends WidgetPropsBase {
  componentKey: string
}

type MENU_ACTION_KEYS = "addToDashboard" | "removeFromDashboard"

export type MenuAction = {
  id: MENU_ACTION_KEYS
  onSuccess?: () => void
}

const PrimaryFilters: React.FC<PropsWithChildren> = ({ children }) => children
const SecondaryFilters: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="flex justify-between flex-none space-x-4 overflow-x-auto flex-nowrap">{children}</div>
}
const WidgetBody: React.FC<PropsWithChildren> = ({ children }) => {
  return <CardContent className="flex flex-col flex-1 pt-2 overflow-y-auto">{children}</CardContent>
}

const WidgetEmpty: React.FC<PropsWithChildren & { text?: string }> = ({ children, text }) => {
  return (
    <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} className="flex items-center justify-center">
      <div className="flex flex-col items-center gap-3">
        <WidgetNoDataIcon className="w-32 h-32" />
        <h6 className="font-semibold">No Results Found</h6>
        <p className="text-sm text-center text-muted-foreground whitespace-pre-line">
          {text || "There are no results matching the combination of filters applied.\nTry adjusting your filter selections."}
        </p>
      </div>
    </WithAspectRatio>
  )
}

export const Widget = ({ ignoredGlobalFilters, defaultAppliedFilterGroups, onAppliedFiltersChange, ...props }: WidgetProps) => {
  return (
    <FilterGroupStoreProvider
      ignoredGlobalFilters={ignoredGlobalFilters}
      defaultAppliedFilterGroups={defaultAppliedFilterGroups}
      onAppliedFiltersChange={onAppliedFiltersChange}
    >
      <WidgetContent {...props} />
    </FilterGroupStoreProvider>
  )
}

Widget.PrimaryFilters = PrimaryFilters
Widget.SecondaryFilters = SecondaryFilters
Widget.Body = WidgetBody
Widget.Empty = WidgetEmpty

const WidgetContent = (props: WidgetProps) => {
  const {
    displayFilterToggles,
    hideDisplayFilters,
    hidePrimaryFilters,
    hideSecondaryFilters,
    menuActions = [{ id: "addToDashboard" }],
    defaultTab,
    children,
    className,
    loading,
    componentKey,
  } = props
  const appliedFilterGroups = useFilterGroupStore((s) => s)
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const resetFilterGroups = useFilterGroupStore((s) => s.resetFilterGroups)
  const display = useFilterGroupStore((s) => s.display)
  const [dialogTrigger, setDialogTrigger] = useState("")
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const isSelectedFiltersShowing = getDisplayToggleState(display?.selectedItems, "selected-filters", true)
  const selectedTab = (display?.selectedItems.find((item) => item.id === "selected-tab")?.name as string) || defaultTab
  const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement | SVGElement, MouseEvent>) => e.stopPropagation()
  const handleTabChange = (selectedTab: string) => {
    updateFilterGroups({
      display: {
        name: "Display",
        hidden: true,
        selectedItems: [
          ...(display?.selectedItems.filter((item) => item.id !== "selected-tab") || []),
          {
            id: "selected-tab",
            name: selectedTab,
          },
        ],
      },
    })
  }

  const handleDialogOpen = (trigger: string) => {
    setIsDialogOpen(true)
    setDialogTrigger(trigger)
  }

  const childrenArray = React.Children.toArray(children)
  const primaryFilters = !hidePrimaryFilters && childrenArray.find((child) => isReactElement(child) && child.type === PrimaryFilters)
  const secondaryFilters = !hideSecondaryFilters && childrenArray.find((child) => isReactElement(child) && child.type === SecondaryFilters)
  const widgetContent = childrenArray.find((child) => isReactElement(child) && child.type === WidgetBody)

  const { data: widgets } = useQuery({
    queryKey: ["widgets"],
    queryFn: () => getWidgets(),
  })

  const data = (widgets as Widget[])?.find((widget) => widget.component_key === componentKey)

  return (
    <>
      <Tabs defaultValue={selectedTab} onValueChange={handleTabChange} asChild>
        <Card className={cn("flex flex-col h-full", className)}>
          <CardHeader className="flex-row items-center pb-2 space-y-0 drag-handle cursor-grab hover:bg-slate-50">
            <CardTitle
              className="flex flex-grow mb-0 text-xl truncate text-[#404F69] items-center gap-2"
              aria-label={data?.name}
              title={data?.name}
            >
              {data?.name}
              {data?.helper_descriptions && (
                <HiInformationCircle
                  className="text-primary cursor-pointer"
                  onMouseDown={handleMouseDown}
                  onClick={() => handleDialogOpen("WIDGET_HELPER_DESCRIPTIONS")}
                />
              )}
            </CardTitle>
            <div className="flex-shrink-0 space-x-2">
              <Button
                variant="ghost"
                className="gap-1 px-2 hover:text-black border-transparent text-sky-700"
                onMouseDown={handleMouseDown}
                onClick={() => {
                  updateFilterGroups({
                    display: {
                      name: "Display",
                      hidden: true,
                      selectedItems: [
                        ...(display?.selectedItems.filter((item) => item.id !== "selected-filters") || []),
                        {
                          id: "selected-filters",
                          name: !isSelectedFiltersShowing,
                        },
                      ],
                    },
                  })
                }}
              >
                {isSelectedFiltersShowing ?
                  <>
                    <IoEyeOffOutline className="w-4 h-4" /> Hide Filters
                  </>
                : <>
                    <IoEyeOutline className="w-4 h-4" /> Show Filters
                  </>
                }
              </Button>

              {!hidePrimaryFilters ?
                <Button
                  onClick={() => handleDialogOpen("WIDGET_CONFIGURATION")}
                  variant="outline"
                  className="gap-1 px-2 border-transparent bg-slate-100 text-sky-700"
                  onMouseDown={handleMouseDown}
                >
                  <IoFunnelOutline className="w-4 h-4" /> Filter
                </Button>
              : null}

              {!hideDisplayFilters && displayFilterToggles ?
                <DisplayFilters toggleItems={displayFilterToggles} />
              : null}

              <DropdownMenu modal={false}>
                <DropdownMenuTrigger asChild>
                  <Button
                    className={cn("border-transparent bg-slate-100 text-sky-700", {
                      hidden: !menuActions.length,
                    })}
                    variant="outline"
                    size="icon"
                    onMouseDown={handleMouseDown}
                  >
                    <DotsVerticalIcon className="w-4 h-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  {menuActions?.find((action) => action.id === "addToDashboard") && (
                    <DropdownMenuItem onClick={() => handleDialogOpen("CREATE_VIEW_ITEM")}>Add to Dashboard</DropdownMenuItem>
                  )}
                  {menuActions?.find((action) => action.id === "removeFromDashboard") && (
                    <DropdownMenuItem className="text-red-500" onClick={() => handleDialogOpen("DELETE_VIEW_ITEM")}>
                      Remove
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </CardHeader>

          {loading ?
            <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={true}>
              <div className="flex flex-col items-center justify-center h-full gap-3">
                <Spinner className="w-24 h-24" />
                <p className="text-sm text-muted-foreground">Loading graph data...</p>
              </div>
            </WithAspectRatio>
          : <>
              <div className="px-6 space-y-2">
                {secondaryFilters}
                <SelectedFilters />
              </div>
              {widgetContent}
            </>
          }
        </Card>
      </Tabs>
      {isDialogOpen && dialogTrigger === "CREATE_VIEW_ITEM" && (
        <CreateViewItemDialog open={true} onOpenChange={setIsDialogOpen} widget={data?.name} componentKey={componentKey} />
      )}

      {isDialogOpen && dialogTrigger === "DELETE_VIEW_ITEM" && (
        <DeleteConfirmationDialog
          open={true}
          onOpenChange={setIsDialogOpen}
          onConfirm={menuActions?.find((action) => action.id === "removeFromDashboard")?.onSuccess}
        />
      )}

      {isDialogOpen && dialogTrigger === "WIDGET_CONFIGURATION" && (
        <Dialog open={true} onOpenChange={setIsDialogOpen}>
          <DialogContent className="max-w-min flex flex-col">
            <FilterGroupStoreProvider defaultAppliedFilterGroups={appliedFilterGroups} enableGlobalStoreSubscription={false}>
              <DialogHeader>
                <DialogTitle>{data?.name}</DialogTitle>
              </DialogHeader>

              <WidgetConfigurationDialogHeader setIsDialogOpen={setIsDialogOpen} onApply={updateFilterGroups} onReset={resetFilterGroups} />

              <DialogFooter>
                <div className="flex flex-col items-center justify-between space-y-6 md:flex-row md:space-x-2 md:space-y-0">
                  {primaryFilters}
                </div>
              </DialogFooter>
            </FilterGroupStoreProvider>
          </DialogContent>
        </Dialog>
      )}

      {isDialogOpen && dialogTrigger === "WIDGET_HELPER_DESCRIPTIONS" && (
        <Dialog open={true} onOpenChange={setIsDialogOpen}>
          <DialogContent className="w-full max-w-lg flex flex-col p-0">
            <DialogHeader>
              <DialogTitle className="px-6 py-3 border-b">{data?.name}</DialogTitle>
              <div
                className="text-sm text-muted-foreground p-6 pt-3 [&_strong]:text-primary"
                dangerouslySetInnerHTML={{ __html: data?.helper_descriptions || "" }}
              />
            </DialogHeader>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

interface WidgetConfigurationDialogHeaderProps {
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  onApply: (filterGroups: FilterGroups) => void
  onReset: () => void
}

const WidgetConfigurationDialogHeader = ({ onApply, onReset, setIsDialogOpen }: WidgetConfigurationDialogHeaderProps) => {
  const appliedFilterGroups = useFilterGroupStore((s) => s)

  return (
    <div className="flex border-y -mx-6 px-6 py-3">
      <Button
        size="sm"
        variant="outline"
        onClick={() => {
          setIsDialogOpen(false)
          onReset()
        }}
      >
        Clear
      </Button>
      <div className="flex-grow"></div>
      <div className="flex space-x-2">
        <Button onClick={() => setIsDialogOpen(false)} size="sm" variant="outline">
          Cancel
        </Button>
        <Button
          size="sm"
          onClick={() => {
            setIsDialogOpen(false)
            onApply(getFilteredGroupsFromStore(appliedFilterGroups))
          }}
        >
          Apply
        </Button>
      </div>
    </div>
  )
}
