import { getCuts } from "@/api/cuts"
import { getTsisProductMetrics } from "@/api/tsis_product_metrics"
import {
  FilterGroupKeys,
  FilterList,
  FilterListDropdown,
  FilterListItem,
  FilterRange,
  MetricList,
  SurveyDates,
  getSelectedRange,
  getSelectedMetrics,
  useFilterGroupStore,
} from "@/components/filters"
import { WIDGET_ASPECT_RATIO, Widget, WidgetPropsBase } from "@/components/widget"
import { WithAspectRatio } from "@/components/with_aspect_ratio"
import { useChartLoading } from "@/hooks/use_chart_loading"
import { useDefaultFilter, useFilter } from "@/hooks/use_filter"
import { useSurveyDates } from "@/hooks/use_survey_dates"
import { HighchartsReact as HC, Highcharts, defaultChartOptions } from "@/lib/highcharts"
import { toPercentString } from "@/lib/utils"
import { TabsContent, TabsList, TabsTrigger } from "@/ui/tabs"
import { ArrowRightIcon, BarChartIcon } from "@radix-ui/react-icons"
import { useQuery } from "@tanstack/react-query"
import { groupBy, round } from "lodash"
import React, { useEffect, useState } from "react"

export const RelativeSectorWidget = ({ ...props }: WidgetPropsBase) => {
  return (
    <Widget componentKey={"relative_sector"} defaultTab="trends" ignoredGlobalFilters={["products"]} {...props}>
      <Widget.PrimaryFilters>
        <RelativeSectorPrimaryFilters />
      </Widget.PrimaryFilters>

      <Widget.SecondaryFilters>
        <RelativeSectorSecondaryFilters hiddenFilters={props.hiddenFilters} />
        <TabsList>
          <TabsTrigger value="positions">Positions</TabsTrigger>
          <TabsTrigger value="trends">Trends</TabsTrigger>
        </TabsList>
      </Widget.SecondaryFilters>

      <Widget.Body>
        <Content renderAspectRatioContainer={props.renderAspectRatioContainer} />
        {props.children}
      </Widget.Body>
    </Widget>
  )
}

const RelativeSectorPrimaryFilters = () => {
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const companies = useFilter("companies", { returnType: "multiple" })
  const sectors = useFilter("sectors", { returnType: "multiple" })

  return (
    <>
      <FilterList
        name="Sector"
        filterGroupId="sectors"
        endpoint="/api/v1/sectors"
        params={{
          companies,
        }}
        enableMultiRowSelection={false}
        onHandleFilterChange={(filterGroupId, items) => {
          updateFilterGroups({
            [filterGroupId]: {
              name: "Sector",
              selectedItems: items,
            },
            products: {
              name: "Products",
              selectedItems: [],
            },
          })
        }}
      />
      <ArrowRightIcon className="hidden w-4 h-4 md:block text-muted-foreground align-self-center" />
      <FilterList
        name="Products"
        filterGroupId="products"
        endpoint="/api/v1/products"
        dependencies={["sectors"]}
        params={{
          sectors,
        }}
      />
    </>
  )
}

const RelativeSectorSecondaryFilters = ({ hiddenFilters }: Pick<WidgetPropsBase, "hiddenFilters">) => {
  const filters: Array<[FilterGroupKeys, React.ReactNode]> = [
    ["survey_dates", <SurveyDates mode={SURVEY_DATE_MODE} size="sm" />],
    ["metrics", <MetricList items={METRIC_FILTER_OPTIONS} defaultMetrics={DEFAULT_METRICS} />],
    [
      "cuts",
      <FilterListDropdown
        className="w-full text-sm"
        name="Cut"
        text="Cut"
        size="sm"
        filterGroupId="cuts"
        endpoint="/api/v1/cuts"
        enableMultiRowSelection={false}
      />,
    ],
    ["citations", <FilterRange text="Citations" filterGroupId="citations" size="sm" />],
  ]

  const filterComponents = filters
    .filter(([key, _component]) => !hiddenFilters?.includes(key))
    .map(([key, component]) => <React.Fragment key={key}>{component}</React.Fragment>)

  return <div className="flex items-center flex-grow space-x-4">{filterComponents}</div>
}

const SURVEY_DATE_MODE = "multiple"
const METRIC_FILTER_OPTIONS: FilterListItem[] = [
  {
    name: "Adoption %",
    id: "adoption_percentage",
  },
  {
    name: "Increase %",
    id: "increase_percentage",
  },
  {
    name: "Flat %",
    id: "flat_percentage",
  },
  {
    name: "Decrease %",
    id: "decrease_percentage",
  },
  {
    name: "Replacing %",
    id: "replacing_percentage",
  },
  {
    name: "Net Score",
    id: "net_score",
  },
  {
    name: "Pervasion",
    id: "pervasion_of_sector",
  },
  {
    name: "Vendor NS / Sector NS",
    id: "vendor_sector_net_score_divided_sector_net_score",
  },
]
const DEFAULT_METRICS = METRIC_FILTER_OPTIONS.filter((item) => item.id == "net_score")

function Content(props: Pick<WidgetPropsBase, "renderAspectRatioContainer">) {
  const { renderAspectRatioContainer } = props
  const metricsFilterGroup = useFilterGroupStore((s) => s.metrics)
  const sector = useFilter("sectors", { returnType: "single" })
  const products = useFilter("products", { returnType: "multiple" })
  const citations = useFilter("citations", { returnType: "single" })
  const { value: cut } = useDefaultFilter("cuts", {
    queryFn: getCuts,
    select: (data: any) => data.filter((item: any) => item.name === "All Respondents"),
    returnType: "single",
  })
  const { value: surveys, isLoading: surveyIsLoading } = useSurveyDates(SURVEY_DATE_MODE)
  const metrics = getSelectedMetrics(metricsFilterGroup?.selectedItems, DEFAULT_METRICS)
  const citationsRange = getSelectedRange(citations, true)

  const widgetEnabled = !!sector && !!cut && !!surveys && !surveyIsLoading
  const { data, isLoading } = useQuery({
    queryKey: ["tsis_product_metrics", JSON.stringify({ citationsRange, cut, sector, products, surveys })],
    queryFn: () => {
      return getTsisProductMetrics({
        citations: citationsRange,
        company: null,
        cut,
        sector,
        products,
        surveys,
      })
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: widgetEnabled,
  })
  const metricsGroupedByProduct = groupBy(data, "product.name")
  const metricsGroupedBySurveyDate = groupBy(data, "survey.short_name")

  if (!sector) {
    return (
      <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
        <div className="h-full flex flex-col items-center justify-center">
          <BarChartIcon className="h-48 w-48 text-muted" />
          <p className="text-muted-foreground text-sm">Please select a sector to view the relative sector graph</p>
        </div>
      </WithAspectRatio>
    )
  }

  if (data?.length === 0) return <Widget.Empty />

  return (
    <>
      <TabsContent value="positions" className="relative flex-1">
        <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
          <RelativeSectorPositionsChart metrics={metrics} metricsGroupedByProduct={metricsGroupedByProduct} loading={isLoading} />
        </WithAspectRatio>
      </TabsContent>

      <TabsContent value="trends" className="relative flex-1">
        <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
          <RelativeSectorTrendsChart
            metrics={metrics}
            metricsGroupedByProduct={metricsGroupedByProduct}
            metricsGroupedBySurveyDate={metricsGroupedBySurveyDate}
            loading={isLoading}
          />
        </WithAspectRatio>
      </TabsContent>
    </>
  )
}

interface RelativeSectorPositionsChartProps {
  metrics: FilterListItem[]
  metricsGroupedByProduct: Record<string, any[]>
  loading?: boolean
}

function RelativeSectorPositionsChart(props: RelativeSectorPositionsChartProps) {
  const { metrics, metricsGroupedByProduct, loading = true } = props

  const [options, setOptions] = useState<Highcharts.Options>({
    ...defaultChartOptions,
    chart: {
      ...defaultChartOptions.chart,
      type: "scatter",
      events: {
        click: function (e) {
          this.getSelectedPoints().forEach((point) => {
            point.select(false, false)
          })
        },
      },
    },
    xAxis: {
      title: {
        text: "Pervasion %",
      },
      labels: {
        format: "{value}%",
      },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
    },
    yAxis: {
      labels: {
        format: "{value}%",
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      scatter: {
        allowPointSelect: true,
        dataLabels: {
          enabled: true,
          format: "{point.name}",
          padding: 5,
          style: {
            fontWeight: "initial",
          },
        },
        events: {
          click: function (event) {
            const targetSeriesName = event.point.series.name.substring(0, event.point.series.name.indexOf(".trend"))
            const selectedSeries = this.chart.series.find((series) => series.name === targetSeriesName)
            const selectedSeriesTrend = this.chart.series.find((series) => series.name === `${targetSeriesName}.trend`)
            selectedSeries?.points[0]?.select(false, false)
            selectedSeriesTrend?.remove()
          },
        },
        point: {
          events: {
            unselect: function () {
              const selectedSeries = this.series.chart.series.find((series) => series.name === `${this.name}.trend`)

              selectedSeries?.remove()
            },
          },
        },
        stickyTracking: false,
      },
      series: {
        cursor: "pointer",
        marker: {
          radius: 4.5,
          symbol: "circle",
          states: {
            hover: {
              enabled: true,
              lineColor: "rgb(100,100,100)",
            },
          },
        },
      },
    },
    series: [],
  })

  const chartRef = useChartLoading(loading)

  useEffect(() => {
    if (!metricsGroupedByProduct) return

    setOptions({
      yAxis: {
        title: {
          text: metrics.map((m) => m?.name).join(" + "),
        },
      },
      tooltip: {
        formatter: function () {
          return `<p><b>Product: </b>${this.point.name}</p>
          <p><b>Pervasion: </b>${toPercentString(this.point.x / 100)}</p>
          <p><b>${metrics.map((m) => m.name).join(" + ")}: </b>${toPercentString((this.point.y || 0) / 100)}</p>
          <p><b>Survey Date: </b>${this.point.custom?.survey_name}</p>
          `
        },
      },
      plotOptions: {
        scatter: {
          point: {
            events: {
              select: function () {
                const product = this.name
                const data = metricsGroupedByProduct[product]

                if (!data || !product) return false

                this.series.chart.addSeries({
                  color: this.series.color,
                  showInLegend: false,
                  allowPointSelect: true,
                  type: "line",
                  name: `${product}.trend`,
                  data: data.map((item) => ({
                    id: item.id,
                    name: item.product?.name,
                    x: round(item.pervasion_of_sector * 100, 2),
                    y: round(metrics.reduce((acc, m) => acc + (item[m.id] || 0), 0) * 100, 2),
                    custom: {
                      survey_name: item.survey?.short_name,
                    },
                  })),
                })
              },
            },
          },
        },
      },
      series: Object.entries(metricsGroupedByProduct || {}).map(([key, value]) => ({
        name: key,
        allowPointSelect: true,
        data: [value?.[value.length - 1]].map((item: any) => ({
          name: item.product?.name,
          x: round(item.pervasion_of_sector * 100, 2),
          y: round(metrics.reduce((acc, m) => acc + (item[m.id] || 0), 0) * 100, 2),
          custom: {
            survey_name: item.survey?.short_name,
          },
        })),
      })) as Highcharts.SeriesOptionsType[],
    })
  }, [metricsGroupedByProduct])

  return (
    <HC highcharts={Highcharts} options={options} containerProps={{ className: "absolute top-0 right-0 bottom-0 left-0" }} ref={chartRef} />
  )
}

interface RelativeSectorTrendsChartProps {
  metrics: FilterListItem[]
  metricsGroupedByProduct: Record<string, any[]>
  metricsGroupedBySurveyDate: Record<string, any[]>
  loading?: boolean
}

function RelativeSectorTrendsChart(props: RelativeSectorTrendsChartProps) {
  const { metrics, metricsGroupedByProduct, metricsGroupedBySurveyDate, loading = true } = props

  const [options, setOptions] = useState<Highcharts.Options>({
    ...defaultChartOptions,
    legend: {
      reversed: true,
    },
    chart: {
      ...defaultChartOptions.chart,
      type: "column",
    },
    xAxis: {
      type: "category",
      crosshair: true,
      reversedStacks: true,
    },
    yAxis: {
      labels: {
        format: "{value}%",
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      useHTML: true,
      formatter: function () {
        return `<p><b>Product: </b>${this.point.name}</p>
          <p><b>${this.series.name}: </b>${round(this.point.y || 0, 1)}%</p>
          <p><b>Citations: </b>${this.point.custom?.citations}</p>
          `
      },
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          color: "#000000",
          crop: false,
          overflow: "allow",
          style: {
            fontWeight: "normal",
          },
          rotation: 270,
          y: -20,
          formatter: function () {
            return `${round(this.point.y || 0)}%`
          },
        },
      },
    },
    series: [],
  })

  const chartRef = useChartLoading(loading)

  useEffect(() => {
    if (!metricsGroupedBySurveyDate || !metricsGroupedByProduct) return

    const sortedByLatestSurveyDate = Object.entries(metricsGroupedBySurveyDate).toReversed()
    const series = sortedByLatestSurveyDate.map(([surveyName, productMetrics], idx) => {
      const data = productMetrics.map((productMetric) => {
        return {
          name: `${productMetric?.product.name}`,
          y: metrics.reduce((acc, m) => acc + (m ? productMetric[m.id] : 0), 0) * 100,
          custom: {
            citations: productMetric?.citations,
          },
        }
      })

      return {
        name: surveyName,
        data: idx === 0 ? data.sort((a, b) => b.y - a.y) : data,
      }
    }) as Highcharts.SeriesOptionsType[]

    setOptions({
      yAxis: {
        title: {
          text: metrics.map((m) => m?.name).join(" + "),
        },
      },
      series,
    })
  }, [metricsGroupedBySurveyDate, metricsGroupedByProduct])

  return (
    <HC highcharts={Highcharts} options={options} containerProps={{ className: "absolute top-0 right-0 bottom-0 left-0" }} ref={chartRef} />
  )
}
