import React from "react"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from "@/ui/breadcrumb"

interface BreadcrumbsProps {
  links: { href: string; text: string }[]
}

export default function Breadcrumbs(props: BreadcrumbsProps) {
  const { links } = props
  return (
    <Breadcrumb className="mb-2">
      <BreadcrumbList>
        {links.map(({ href, text }, idx) => (
          <React.Fragment key={idx}>
            <BreadcrumbItem>
              <BreadcrumbLink href={href}>{text}</BreadcrumbLink>
            </BreadcrumbItem>
            {idx < links.length - 1 && <BreadcrumbSeparator />}
          </React.Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  )
}
