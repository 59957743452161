import Grid from "./grid"
import Navbar from "./navbar"
import Footer from "./footer"
import PowerBi from "./power_bi"
import PdfViewer from "./pdf"
import Breadcrumbs from "./breadcrumbs"

export default {
  Grid,
  Navbar,
  Footer,
  PowerBi,
  PdfViewer,
  Breadcrumbs,
}
