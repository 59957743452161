import React from "react"

export default function AnalyzeSharedAccountsIcon({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="346" height="343" fill="none" viewBox="0 0 346 343" className={className}>
      <path
        fill="#2B6CB0"
        d="M177.01 1.335c0-.737.598-1.335 1.336-1.335h143.611c.738 0 1.336.598 1.336 1.335v32.03c0 .738-.598 1.335-1.336 1.335H178.346a1.335 1.335 0 01-1.336-1.334V1.335zM177.01 45.377c0-.737.598-1.334 1.336-1.334h166.322c.738 0 1.336.597 1.336 1.334V77.41c0 .737-.598 1.334-1.336 1.334H178.346a1.335 1.335 0 01-1.336-1.334V45.377zM177.01 89.42c0-.737.598-1.334 1.336-1.334h108.209c.738 0 1.336.597 1.336 1.334v32.031c0 .737-.598 1.335-1.336 1.335H178.346a1.336 1.336 0 01-1.336-1.335v-32.03zM177.01 133.463c0-.737.598-1.335 1.336-1.335h58.112c.738 0 1.336.598 1.336 1.335v32.031c0 .737-.598 1.335-1.336 1.335h-58.112a1.336 1.336 0 01-1.336-1.335v-32.031zM177.01 177.506c0-.737.598-1.335 1.336-1.335h166.322c.738 0 1.336.598 1.336 1.335v32.031c0 .737-.598 1.335-1.336 1.335H178.346a1.336 1.336 0 01-1.336-1.335v-32.031zM177.01 221.549c0-.737.598-1.335 1.336-1.335h108.209c.738 0 1.336.598 1.336 1.335v32.031c0 .737-.598 1.334-1.336 1.334H178.346a1.335 1.335 0 01-1.336-1.334v-32.031zM177.01 265.591c0-.737.598-1.334 1.336-1.334h136.932c.737 0 1.336.597 1.336 1.334v32.032c0 .737-.599 1.334-1.336 1.334H178.346a1.335 1.335 0 01-1.336-1.334v-32.032zM177.01 309.634c0-.737.598-1.334 1.336-1.334h166.322c.738 0 1.336.597 1.336 1.334v32.031c0 .737-.598 1.335-1.336 1.335H178.346a1.336 1.336 0 01-1.336-1.335v-32.031z"
      ></path>
      <path
        fill="#592BB0"
        d="M166.322 1.335c0-.737-.598-1.335-1.336-1.335H23.688c-.738 0-1.336.598-1.336 1.335v32.03c0 .738.598 1.335 1.335 1.335h141.299c.738 0 1.336-.597 1.336-1.334V1.335zM166.322 45.377c0-.737-.598-1.334-1.336-1.334H1.336C.598 44.043 0 44.64 0 45.377V77.41c0 .737.598 1.334 1.336 1.334h163.65c.738 0 1.336-.597 1.336-1.334V45.377zM166.322 89.42c0-.737-.598-1.334-1.336-1.334H30.058c-.738 0-1.336.597-1.336 1.334v32.031c0 .737.598 1.335 1.336 1.335h134.928c.738 0 1.336-.598 1.336-1.335v-32.03zM166.322 133.463c0-.737-.598-1.335-1.336-1.335H86.835c-.738 0-1.336.598-1.336 1.335v32.031c0 .737.598 1.335 1.336 1.335h78.151c.738 0 1.336-.598 1.336-1.335v-32.031zM166.322 177.506c0-.737-.598-1.335-1.336-1.335H1.336c-.738 0-1.336.598-1.336 1.335v32.031c0 .737.598 1.335 1.336 1.335h163.65c.738 0 1.336-.598 1.336-1.335v-32.031zM166.322 221.549c0-.737-.598-1.335-1.336-1.335H58.53c-.738 0-1.336.598-1.336 1.335v32.031c0 .737.598 1.334 1.336 1.334h106.456c.738 0 1.336-.597 1.336-1.334v-32.031zM166.322 265.591c0-.737-.598-1.334-1.336-1.334H30.262c-.738 0-1.336.597-1.336 1.334v32.032c0 .737.598 1.334 1.336 1.334h134.724c.738 0 1.336-.597 1.336-1.334v-32.032zM166.322 309.634c0-.737-.598-1.334-1.336-1.334H1.336c-.738 0-1.336.597-1.336 1.334v32.031C0 342.402.598 343 1.336 343h163.65c.738 0 1.336-.598 1.336-1.335v-32.031z"
      ></path>
    </svg>
  )
}
