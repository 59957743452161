import {
  FilterGroups,
  FilterGroupStoreProvider,
  FilterListDropdown,
  FilterRange,
  getAllFilterGroupsFromStore,
  GlobalStore,
  RadioList,
  SurveyDates,
  useFilterGroupStore,
} from "@/components/filters"
import { ScreenerTabs } from "@/views/data-analyzer/screeners"
import { WIDGET_ASPECT_RATIO } from "@/components/widget"
import { IdeaGeneratorWidget, MARKET_CAP_RADIO_ITEMS } from "@/components/widgets/screener_idea_generator"
import { useFilter } from "@/hooks/use_filter"
import { useSurveyDates } from "@/hooks/use_survey_dates"
import { AspectRatio } from "@/ui/aspect_ratio"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "@/ui/breadcrumb"
import { Card, CardContent, CardHeader } from "@/ui/card"
import { Spinner } from "@/ui/spinner"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@radix-ui/react-tabs"
import React, { useCallback, useState } from "react"

export default function AnalyzeIdeaGeneratorScreener() {
  const [loading, setLoading] = useState(true)

  const handleGlobalFilterChange = useCallback((state: FilterGroups) => {
    const surveys = state.survey_dates?.selectedItems?.length

    if (surveys) setLoading(false)

    GlobalStore.getState().updateFilterGroups(state)
  }, [])

  return (
    <div className="mb-[500px] space-y-2">
      <Breadcrumb className="mb-2">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/analyze">Data Analyzer</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Screeners</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="flex flex-col gap-3 lg:flex-row">
        <div className="flex-shrink space-y-3 md:min-w-[500px]">
          <ScreenerTabs />

          <FilterGroupStoreProvider onAppliedFiltersChange={handleGlobalFilterChange} enableGlobalStoreSubscription={false}>
            <GlobalFilters />
          </FilterGroupStoreProvider>
        </div>

        <div className="flex-1">
          {loading ?
            <AspectRatio ratio={WIDGET_ASPECT_RATIO}>
              <div className="flex flex-col items-center justify-center h-full gap-3">
                <Spinner className="w-24 h-24" />
                <p className="text-sm text-muted-foreground">Loading widgets...</p>
              </div>
            </AspectRatio>
          : <IdeaGeneratorWidget hidePrimaryFilters hiddenFilters={["survey_dates"]} />}
        </div>
      </div>
    </div>
  )
}

const TABS = ["Basic", "Spending Intention", "Financial Metrics", "All"] as const

const GlobalFilters = () => {
  useSurveyDates("single")

  return (
    <Card className="space-y-2">
      <Tabs defaultValue={TABS[0]}>
        <CardHeader>
          <div className="flex items-center space-x-4">
            <TabsList>
              {TABS.map((name) => (
                <TabsTrigger
                  key={name}
                  value={name}
                  className="border-b p-2 data-[state=inactive]:text-blue-500 data-[state=inactive]:border-white data-[state=active]:border-black"
                >
                  {name}
                </TabsTrigger>
              ))}
            </TabsList>
          </div>
        </CardHeader>
        <CardContent>
          <TabsContent value={TABS[0]}>
            <BasicFilters />
          </TabsContent>

          <TabsContent value={TABS[1]}>
            <SpendIntentionsFilters />
          </TabsContent>

          <TabsContent value={TABS[2]}>
            <FinancialMetricsFilters />
          </TabsContent>

          <TabsContent value={TABS[3]} className="space-y-4">
            <h6>Basic Filters</h6>
            <BasicFilters />
            <h6>Spend Intentions</h6>
            <SpendIntentionsFilters />
            <h6>Financial Metrics</h6>
            <FinancialMetricsFilters />
          </TabsContent>
        </CardContent>
      </Tabs>
    </Card>
  )
}

const BasicFilters = () => {
  const appliedFilterGroups = useFilterGroupStore(getAllFilterGroupsFromStore)
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const sectors = useFilter("sectors", { returnType: "multiple" })

  return (
    <div className="grid grid-cols-2 gap-2">
      <SurveyDates mode="single" className="items-start justify-between max-w-48" text="Survey" />

      <FilterListDropdown
        className="w-full text-sm"
        name="Sector"
        text="Sector"
        fallbackText="All"
        filterGroupId="sectors"
        endpoint="/api/v1/sectors"
        onHandleFilterChange={(filterGroupId, selectedItems) => {
          const products =
            appliedFilterGroups.products?.selectedItems.filter((product) => {
              return selectedItems.some((sector) => (product.name as string).includes(sector.name as string))
            }) || []

          updateFilterGroups({
            [filterGroupId]: {
              name: "Sectors",
              selectedItems,
            },
            products: {
              name: "Products",
              selectedItems: products,
            },
          })
        }}
      />

      <FilterListDropdown
        className="w-full text-sm"
        name="Companies"
        text="Company"
        fallbackText="All"
        filterGroupId="companies"
        endpoint="/api/v1/companies"
        params={{
          sectors,
        }}
        formatter={(item) => ({
          ...item,
          subtitle: item.ticker,
        })}
      />

      <FilterListDropdown
        className="w-full text-sm"
        name="Cut"
        text="Cut"
        fallbackText="All"
        filterGroupId="cuts"
        endpoint="/api/v1/cuts"
      />
    </div>
  )
}

const SpendIntentionsFilters = () => {
  return (
    <div className="grid grid-cols-2 gap-2">
      <FilterRange text="Adoption %" filterGroupId="adoption" />
      <FilterRange text="Adoption % Y/Y" filterGroupId="adoption_yoy" />
      <FilterRange text="Adoption % S/S" filterGroupId="adoption_sos" />
      <FilterRange text="Increase %" filterGroupId="increase" />
      <FilterRange text="Increase % Y/Y" filterGroupId="increase_yoy" />
      <FilterRange text="Increase % S/S" filterGroupId="increase_sos" />
      <FilterRange text="Flat %" filterGroupId="flat" />
      <FilterRange text="Flat % Y/Y" filterGroupId="flat_yoy" />
      <FilterRange text="Flat % S/S" filterGroupId="flat_sos" />
      <FilterRange text="Decrease %" filterGroupId="decrease" />
      <FilterRange text="Decrease % Y/Y" filterGroupId="decrease_yoy" />
      <FilterRange text="Decrease % S/S" filterGroupId="decrease_sos" />
      <FilterRange text="Replacing %" filterGroupId="replacing" />
      <FilterRange text="Replacing % Y/Y" filterGroupId="replacing_yoy" />
      <FilterRange text="Replacing % S/S" filterGroupId="replacing_sos" />
      <FilterRange text="Citations" filterGroupId="citations" />
      <FilterRange text="Citations Y/Y" filterGroupId="citations_yoy" />
      <FilterRange text="Citations S/S" filterGroupId="citations_sos" />
      <FilterRange text="Net Score" filterGroupId="net_score" />
      <FilterRange text="Net Score Y/Y" filterGroupId="net_score_yoy" />
      <FilterRange text="Net Score S/S" filterGroupId="net_score_sos" />
      <FilterRange text="Pervasion" filterGroupId="pervasion" />
      <FilterRange text="Pervasion Y/Y" filterGroupId="pervasion_yoy" />
      <FilterRange text="Pervasion S/S" filterGroupId="pervasion_sos" />
    </div>
  )
}

const FinancialMetricsFilters = () => {
  return (
    <div className="grid grid-cols-2 gap-2">
      <FilterRange text="CY/CY Revenue" filterGroupId="cy_cy_revenue" />
      <RadioList
        text="Market Cap"
        filterGroupId="market_capitalization"
        items={MARKET_CAP_RADIO_ITEMS}
        defaultSelection={MARKET_CAP_RADIO_ITEMS[0]!}
      />
      <FilterRange text="Short Ratio" filterGroupId="short_ratio" />
      <FilterRange text="Price to Earnings" filterGroupId="price_to_earnings" />
      <FilterRange text="EV to Sales TTM" filterGroupId="ev_to_sales_ttm" />
      <FilterRange text="EV to Sales NTM" filterGroupId="ev_to_sales_ntm" />
      <FilterRange text="YTD Stock Return" filterGroupId="return_ytd" />
      <FilterRange text="1mo Stock Return" filterGroupId="return_last_1_month" />
      <FilterRange text="3mo Stock Return" filterGroupId="return_last_3_month" />
      <FilterRange text="6mo Stock Return" filterGroupId="return_last_6_month" />
      <FilterRange text="12mo Stock Return" filterGroupId="return_last_12_month" />
    </div>
  )
}
