import request from "@/api/client"

export function getTsisCompanyMetrics({ citations, company, cut, cut_groups = [], surveys }) {
  return request({
    url: "/api/v1/tsis_company_metrics",
    method: "GET",
    params: {
      citations,
      company: company,
      cut,
      cut_groups,
      surveys,
    },
  })
}
