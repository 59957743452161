import { flexRender, Table as TableInstance } from "@tanstack/react-table"
import { useVirtualizer } from "@tanstack/react-virtual"
import React, { useRef } from "react"

import { cn } from "@/lib/utils"

interface DataListProps {
  className?: string
  table: TableInstance<any>
}

export function DataList(props: DataListProps) {
  const { className, table } = props

  const { enableMultiRowSelection } = table.options
  const { rows } = table.getRowModel()

  const parentRef = useRef<HTMLDivElement>(null)

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 50,
    overscan: 5,
  })

  const handleRowClick = (row: any) => {
    if (!enableMultiRowSelection && row.getIsSelected()) return

    row.toggleSelected()
  }

  return (
    <div className={cn("h-[350px] flex flex-col rounded-sm border inset", className)}>
      {table.options.enableMultiRowSelection &&
        table.getHeaderGroups().map((headerGroup) => (
          <div className="flex p-2 gap-x-2 items-center border-b" key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <React.Fragment key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </React.Fragment>
              )
            })}
          </div>
        ))}
      <div className="flex-1 overflow-auto" ref={parentRef}>
        <div style={{ height: `${virtualizer.getTotalSize()}px` }}>
          {!rows.length ?
            <div className="flex items-center p-2 space-x-2 border-b">
              <div className="flex-1 text-center">No results.</div>
            </div>
          : virtualizer.getVirtualItems().map((virtualRow, index) => {
              const row = rows[virtualRow.index] as any

              return (
                <div
                  className="cursor-pointer flex p-2 space-x-2 items-center border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted"
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                  }}
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onClick={() => handleRowClick(row)}
                >
                  {row.getVisibleCells().map((cell: any) =>
                    flexRender(cell.column.columnDef.cell, {
                      key: cell.id,
                      ...cell.getContext(),
                    })
                  )}
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
