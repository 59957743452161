import { getTsisCompanyMetrics } from "@/api/tsis_company_metrics"
import { getTsisProductMetrics } from "@/api/tsis_product_metrics"
import {
  FilterGroupKeys,
  FilterList,
  FilterListDropdown,
  FilterRange,
  SurveyDates,
  getSelectedRange,
  useFilterGroupStore,
} from "@/components/filters"
import { WIDGET_ASPECT_RATIO, Widget, WidgetPropsBase } from "@/components/widget"
import { WithAspectRatio } from "@/components/with_aspect_ratio"
import { useChartLoading } from "@/hooks/use_chart_loading"
import { useFilter } from "@/hooks/use_filter"
import { useSurveyDates } from "@/hooks/use_survey_dates"
import { HighchartsReact as HC, Highcharts, defaultChartOptions } from "@/lib/highcharts"
import { BarChartIcon } from "@radix-ui/react-icons"
import { useQuery } from "@tanstack/react-query"
import { Point } from "highcharts"
import { groupBy, round } from "lodash"
import React, { useEffect, useMemo, useState } from "react"

export const SubsampleCandlestickWidget = ({ ...props }: WidgetPropsBase) => {
  return (
    <Widget componentKey={"subsample_candlestick"} ignoredGlobalFilters={["cuts", "products", "sectors"]} {...props}>
      <Widget.PrimaryFilters>
        <SubsamplePrimaryFilters />
      </Widget.PrimaryFilters>

      <Widget.SecondaryFilters>
        <SubsampleSecondaryFilters hiddenFilters={props.hiddenFilters} />
      </Widget.SecondaryFilters>

      <Widget.Body>
        <Content renderAspectRatioContainer={props.renderAspectRatioContainer} />
        {props.children}
      </Widget.Body>
    </Widget>
  )
}

const SubsamplePrimaryFilters = () => {
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const companies = useFilter("companies", { returnType: "multiple" })

  return (
    <>
      <FilterList
        name="Sector"
        filterGroupId="sectors"
        endpoint="/api/v1/sectors"
        params={{
          companies,
        }}
        enableMultiRowSelection={false}
        onHandleFilterChange={(filterGroupId, items) => {
          updateFilterGroups({
            [filterGroupId]: {
              name: "Sector",
              selectedItems: items,
            },
            products: {
              name: "Products",
              selectedItems: [],
            },
          })
        }}
      />
    </>
  )
}

const SubsampleSecondaryFilters = ({ hiddenFilters }: Pick<WidgetPropsBase, "hiddenFilters">) => {
  const companies = useFilter("companies", { returnType: "multiple" })

  const filters: Array<[FilterGroupKeys, React.ReactNode]> = [
    ["survey_dates", <SurveyDates mode={SURVEY_DATE_MODE} size="sm" />],
    [
      "products",
      <FilterListDropdown
        className="w-full text-sm"
        name="Products"
        text="Products"
        fallbackText="All"
        size="sm"
        filterGroupId="products"
        endpoint="/api/v1/products"
        enableMultiRowSelection={false}
        params={{ companies }}
        formatter={(item) => ({
          ...item,
          subtitle: item.sector.name,
        })}
      />,
    ],
    [
      "cut_groups",
      <FilterListDropdown
        className="w-full text-sm"
        name="Subsample Type"
        text="Subsample Type"
        size="sm"
        filterGroupId="cut_groups"
        endpoint="/api/v1/cut_groups"
        params={{
          exclude: ["All Respondents"],
        }}
        enableMultiRowSelection={true}
      />,
    ],
    ["citations", <FilterRange text="Citations" filterGroupId="citations" size="sm" />],
  ]

  const filterComponents = filters
    .filter(([key, _component]) => !hiddenFilters?.includes(key))
    .map(([key, component]) => <React.Fragment key={key}>{component}</React.Fragment>)

  return <div className="flex items-center flex-grow space-x-4">{filterComponents}</div>
}

const SURVEY_DATE_MODE = "single"

function Content(props: Pick<WidgetPropsBase, "renderAspectRatioContainer">) {
  const { renderAspectRatioContainer } = props
  const company = useFilter("companies", { returnType: "single" })
  const product = useFilter("products", { returnType: "single" })
  const cutGroups = useFilter("cut_groups", { returnType: "multiple" })
  const citations = useFilter("citations", { returnType: "single" })
  const { value: survey, isLoading: surveyIsLoading } = useSurveyDates(SURVEY_DATE_MODE)
  const citationsRange = getSelectedRange(citations, true)
  const hasSurvey = Boolean(survey) && !surveyIsLoading
  const shouldFetchCompanyMetrics = Boolean(company) && !Boolean(product) && hasSurvey
  const shouldFetchProductMetrics = Boolean(product) && hasSurvey
  const widgetEnabled = shouldFetchCompanyMetrics || shouldFetchProductMetrics
  const { isLoading: isLoadingCompanyMetrics, data: companyMetrics } = useQuery({
    queryKey: ["tsis_company_metrics", { citationsRange, company, cutGroups, survey }],
    queryFn: () => {
      return getTsisCompanyMetrics({
        citations: citationsRange,
        company,
        cut: null,
        cut_groups: cutGroups,
        surveys: survey,
      })
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: shouldFetchCompanyMetrics,
  })

  const { isLoading: isLoadingProductMetrics, data: productMetrics } = useQuery({
    queryKey: ["tsis_product_metrics", { citationsRange, product, cutGroups, survey }],
    queryFn: () => {
      return getTsisProductMetrics({
        citations: citationsRange,
        company: null,
        cut: null,
        cut_groups: cutGroups,
        sector: null,
        products: product,
        surveys: survey,
      })
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: shouldFetchProductMetrics,
  })

  const isLoading = shouldFetchCompanyMetrics ? isLoadingCompanyMetrics : isLoadingProductMetrics
  const data = shouldFetchCompanyMetrics ? companyMetrics : productMetrics
  const metricsGroupedByCut = groupBy(data, "cut.name")
  const series = useMemo(() => {
    return [...METRIC_SERIES, ...(product ? PRODUCT_METRIC_SERIES : COMPANY_METRIC_SERIES)]
  }, [product])

  if (!widgetEnabled) {
    return (
      <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
        <div className="h-full flex flex-col items-center justify-center">
          <BarChartIcon className="h-48 w-48 text-muted" />
          <p className="text-muted-foreground text-sm">Please select a sector and a product to view the subsample candlestick</p>
        </div>
      </WithAspectRatio>
    )
  }

  if (data?.length === 0) return <Widget.Empty />

  return (
    <div className="relative flex-1">
      <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
        <SubsampleCandlestickChart metricsGroupedByCut={metricsGroupedByCut} series={series} loading={isLoading} />
      </WithAspectRatio>
    </div>
  )
}

const METRIC_SERIES: Highcharts.SeriesOptionsType[] = [
  {
    id: "adoption_percentage",
    name: "Adoption",
    color: "#2E7D32",
    type: "column",
  },
  {
    id: "increase_percentage",
    name: "Increase",
    color: "#C8E6C9",
    type: "column",
  },
  {
    id: "flat_percentage",
    name: "Flat",
    color: "#BDBDBD",
    type: "column",
  },
  {
    id: "decrease_percentage",
    name: "Decrease",
    color: "#FFCDD2",
    type: "column",
  },
  {
    id: "replacing_percentage",
    name: "Replace",
    color: "#C62828",
    type: "column",
  },
  {
    id: "net_score",
    name: "Net Score",
    color: "#2196F3",
    type: "scatter",
    yAxis: 1,
  },
]

const COMPANY_METRIC_SERIES: Highcharts.SeriesOptionsType[] = [
  {
    id: "pervasion_of_survey",
    name: "Pervasion",
    color: "#FFEB3B",
    type: "scatter",
    yAxis: 1,
  },
]

const PRODUCT_METRIC_SERIES: Highcharts.SeriesOptionsType[] = [
  {
    id: "pervasion_of_sector",
    name: "Pervasion",
    color: "#FFEB3B",
    type: "scatter",
    yAxis: 1,
  },
]

interface SubsampleCandlestickChartProps {
  metricsGroupedByCut: Record<string, any[]>
  series: Highcharts.SeriesOptionsType[]
  loading?: boolean
}

function SubsampleCandlestickChart(props: SubsampleCandlestickChartProps) {
  const { metricsGroupedByCut, series, loading = true } = props

  const [options, setOptions] = useState<Highcharts.Options>({
    ...defaultChartOptions,
    xAxis: {
      type: "category",
    },
    yAxis: [
      {
        minorGridLineWidth: 0,
        labels: {
          format: "{value}%",
        },
        title: {
          text: "Net Score Candelstick",
        },
        min: 0,
        max: 100,
        tickAmount: 6,
        tickInterval: 20,
        opposite: true,
      },
      {
        gridLineWidth: 0,
        labels: {
          format: "{value}%",
        },
        endOnTick: false,
        startOnTick: false,
        minTickInterval: 5,
        tickLength: 10,
        tickWidth: 1,
        tickPosition: "outside",
        tickPixelInterval: 100,
        title: {
          text: "Net Score and Pervasion",
        },
      },
    ],
    plotOptions: {
      column: {
        stacking: "percent",
        groupPadding: 0.05,
        pointPadding: 0.05,
      },
      scatter: {
        marker: {
          symbol: "circle",
        },
      },
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    series: [],
    tooltip: {
      enabled: true,
      useHTML: true,
      followPointer: true,
      snap: 1,
      formatter: function () {
        let label = `<p><b>Respondent Cut: </b>${this.point.name}</p>
        <p><b>${this.series.name}: </b>${this.point.y}%</p>
        `

        if (this.series.type === "column") {
          label += `<p><b>Citations: </b>${(this.point as Point & { custom: { citations: string } }).custom?.citations}</p>`
        }

        return label
      },
    },
  })

  const chartRef = useChartLoading(loading)

  useEffect(() => {
    if (!metricsGroupedByCut) return

    const sortedByNetScore = Object.entries(metricsGroupedByCut).sort((a, b) => {
      return b[1][0].net_score - a[1][0].net_score
    })

    const seriesData = series.map((metric) => ({
      ...metric,
      data: sortedByNetScore.map(([key, value]) => {
        return {
          name: key,
          y: round(value?.reduce((acc, item: any) => acc + ((!!metric.id && item[metric.id as keyof typeof item]) || 0), 0) * 100, 2),
          custom: {
            citations: (value as any[])?.reduce((acc, item) => acc + (item.citations ?? item.unique_respondents ?? 0), 0),
          },
        }
      }),
    })) as Highcharts.SeriesOptionsType[]

    setOptions({
      series: seriesData,
    })
  }, [metricsGroupedByCut, series])

  return (
    <HC highcharts={Highcharts} options={options} containerProps={{ className: "absolute top-0 right-0 bottom-0 left-0" }} ref={chartRef} />
  )
}
