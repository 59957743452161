import { HighchartsReactRefObject } from "highcharts-react-official"
import { useEffect, useRef } from "react"

export function useChartLoading(loading: boolean) {
  const chartRef = useRef<HighchartsReactRefObject | null>(null)

  useEffect(() => {
    if (loading) {
      chartRef.current?.chart?.showLoading()
    } else {
      chartRef.current?.chart?.hideLoading()
    }
  }, [loading])

  return chartRef
}
