import { getCuts } from "@/api/cuts"
import { getSharedAccountsTabularSeries } from "@/api/shared_accounts/tabular"
import { DataTable } from "@/components/data_table"
import {
  FilterGroupKeys,
  FilterList,
  FilterListDropdown,
  FilterRange,
  RadioList,
  SurveyDates,
  getSelectedRange,
  useFilterGroupStore,
} from "@/components/filters"
import { WIDGET_ASPECT_RATIO, Widget, WidgetPropsBase } from "@/components/widget"
import { WithAspectRatio } from "@/components/with_aspect_ratio"
import { useDefaultFilter, useFilter } from "@/hooks/use_filter"
import { useSurveyDates } from "@/hooks/use_survey_dates"
import { SURVEY_NS_COMPARISON_ITEMS } from "@/lib/utils"
import { Button } from "@/ui/button"
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from "@/ui/dropdown_menu"
import { ArrowRightIcon, BarChartIcon, ChevronDownIcon } from "@radix-ui/react-icons"
import { useQuery } from "@tanstack/react-query"
import { getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table"
import React from "react"

export const SharedAccountsTabularWidget = ({ ...props }: WidgetPropsBase) => {
  return (
    <Widget componentKey={"tabular"} {...props}>
      <Widget.PrimaryFilters>
        <SharedAccountsTabularWidgetPrimaryFilters />
      </Widget.PrimaryFilters>

      <Widget.SecondaryFilters>
        <SharedAccountsTabularWidgetSecondaryFilters hiddenFilters={props.hiddenFilters} />
      </Widget.SecondaryFilters>

      <Widget.Body>
        <Content renderAspectRatioContainer={props.renderAspectRatioContainer} />
        {props.children}
      </Widget.Body>
    </Widget>
  )
}

const SharedAccountsTabularWidgetPrimaryFilters = () => {
  const productFilterGroup = useFilterGroupStore((s) => s.products)
  const sectors = useFilter("sectors", { returnType: "multiple" })
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)

  return (
    <>
      <FilterList
        name="Sector"
        filterGroupId="sectors"
        endpoint="/api/v1/sectors"
        onHandleFilterChange={(filterGroupId, items) => {
          const products =
            productFilterGroup?.selectedItems.filter((product) => {
              return items.some((sector) => (product.name as string).includes(sector.name as string))
            }) || []

          updateFilterGroups({
            [filterGroupId]: {
              name: "Sectors",
              selectedItems: items,
            },
            products: {
              name: "Products",
              selectedItems: products,
            },
          })
        }}
      />
      <ArrowRightIcon className="hidden w-4 h-4 md:block text-muted-foreground align-self-center" />
      <FilterList
        name="Products"
        filterGroupId="products"
        endpoint="/api/v1/products"
        params={{
          sectors,
        }}
        formatter={(item) => ({
          ...item,
          subtitle: item.sector.name,
        })}
      />
    </>
  )
}

const SharedAccountsTabularWidgetSecondaryFilters = ({ hiddenFilters }: Pick<WidgetPropsBase, "hiddenFilters">) => {
  const displaySectorFilterGroup = useFilterGroupStore((s) => s.display_sectors)
  const displayProductFilterGroup = useFilterGroupStore((s) => s.display_products)
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)

  const filters: Array<[FilterGroupKeys, React.ReactNode]> = [
    ["survey_dates", <SurveyDates mode={SURVEY_DATE_MODE} size="sm" />],
    [
      "cuts",
      <FilterListDropdown
        className="w-full text-sm"
        name="Cut"
        text="Cut"
        fallbackText="All Respondents"
        filterGroupId="cuts"
        endpoint="/api/v1/cuts"
        enableMultiRowSelection={false}
        size="sm"
      />,
    ],
    [
      "metrics",
      <FilterListDropdown
        className="w-full text-sm"
        name="Metrics"
        text="Metrics"
        fallbackText="All"
        filterGroupId="metrics"
        endpoint="/api/v1/metrics"
        size="sm"
      />,
    ],
    [
      "display_sectors",
      <FilterListDropdown
        className="w-full text-sm"
        name="Display Sector"
        text="Sector"
        fallbackText="All"
        filterGroupId="display_sectors"
        endpoint="/api/v1/sectors"
        enableMultiRowSelection={false}
        size="sm"
        onHandleFilterChange={(filterGroupId, selectedItems) => {
          updateFilterGroups({
            [filterGroupId]: {
              name: "Display Sector",
              ...displaySectorFilterGroup,
              selectedItems,
            },
            display_products: {
              name: "Display Products",
              ...displayProductFilterGroup,
              selectedItems: [],
            },
          })
        }}
      />,
    ],
    [
      "display_products",
      <FilterListDropdown
        className="w-full text-sm"
        name="Display Products"
        text="Products"
        fallbackText="All"
        filterGroupId="display_products"
        endpoint="/api/v1/products"
        params={{ sectors: displaySectorFilterGroup?.selectedItems.map((item) => item.id) }}
        dependencies={["display_sectors"]}
        formatter={(item) => ({
          ...item,
          subtitle: item.sector.name,
        })}
        size="sm"
      />,
    ],
    ["citations", <FilterRange text="Min Market Share(n)" filterGroupId="citations" size="sm" />],
    [
      "shared_ns_comparison",
      <RadioList
        text="Shared NS"
        filterGroupId="shared_ns_comparison"
        items={SURVEY_NS_COMPARISON_ITEMS}
        defaultSelection={SURVEY_NS_COMPARISON_ITEMS[0]!}
        size="sm"
      />,
    ],
  ]

  const filterComponents = filters
    .filter(([key, _component]) => !hiddenFilters?.includes(key))
    .map(([key, component]) => <React.Fragment key={key}>{component}</React.Fragment>)

  return <div className="flex items-center flex-grow space-x-4">{filterComponents}</div>
}

const SURVEY_DATE_MODE = "multiple"

function Content(props: Pick<WidgetPropsBase, "renderAspectRatioContainer">) {
  const { renderAspectRatioContainer } = props

  const targetSectors = useFilter("sectors", { returnType: "multiple" })
  const targetProducts = useFilter("products", { returnType: "multiple" })
  const metrics = useFilter("metrics", { returnType: "multiple" })
  const displaySectors = useFilter("display_sectors", { returnType: "multiple" })
  const displayProducts = useFilter("display_products", { returnType: "multiple" })
  const citations = useFilter("citations", { returnType: "single" })
  const marketShareRange = getSelectedRange(citations, true)
  const sharedNSComparison = useFilter("shared_ns_comparison", { returnType: "single" })
  const { value: cut } = useDefaultFilter("cuts", {
    queryFn: getCuts,
    select: (data: any) => data.filter((item: any) => item.name === "All Respondents"),
    returnType: "single",
  })

  const { value: surveys, isLoading: surveyIsLoading } = useSurveyDates(SURVEY_DATE_MODE)
  const widgetEnabled = !!cut && !!surveys.length && !surveyIsLoading && !!displaySectors.length

  const { isLoading, data } = useQuery({
    queryKey: [
      "shared_accounts_tabular",
      { cut, metrics, targetSectors, targetProducts, displaySectors, displayProducts, sharedNSComparison, marketShareRange, surveys },
    ],
    queryFn: () => {
      return getSharedAccountsTabularSeries({
        cut,
        metrics,
        sectors: targetSectors,
        products: targetProducts,
        surveys: surveys,
        display_products: displayProducts,
        display_sectors: displaySectors,
        shared_ns_comparison: sharedNSComparison,
        market_share_range: marketShareRange,
      } as any)
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: widgetEnabled,
  })

  if (!widgetEnabled) {
    return (
      <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
        <div className="h-full flex flex-col items-center justify-center">
          <BarChartIcon className="h-48 w-48 text-muted" />
          <p className="text-muted-foreground text-sm">Please select a display sector to view this visual.</p>
        </div>
      </WithAspectRatio>
    )
  }

  if (data?.length === 0) return <Widget.Empty />

  return (
    <div className="relative flex-1">
      <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
        <SharedAccountsTabularTable series={data} loading={isLoading} />
      </WithAspectRatio>
    </div>
  )
}

interface SharedAccountsTabularTableProps {
  series: any
  loading?: boolean
}

const getNetScoreColor = (value: number) => {
  if (value >= 75) return "bg-green-300 text-green-700"
  if (value >= 50) return "bg-green-200 text-green-700"
  if (value >= 25) return "bg-green-100 text-green-700"
  if (value >= -25) return "bg-red-50 text-red-700"
  if (value >= -50) return "bg-red-100 text-red-700"
  if (value >= -75) return "bg-red-200 text-red-700"
  return "bg-red-300 text-red-700"
}

const getSharedNColor = (value: number) => {
  if (value >= 500) return "bg-gray-500"
  if (value >= 300) return "bg-gray-400"
  if (value >= 100) return "bg-gray-300"
  if (value >= 75) return "bg-gray-200"
  if (value >= 50) return "bg-gray-100"
  if (value >= 25) return "bg-gray-50"
  return ""
}

function SharedAccountsTabularTable(props: SharedAccountsTabularTableProps) {
  const { series, loading = true } = props

  const table = useReactTable<any>({
    columns: series?.columns.map((col: any) => {
      if (col.accessorKey.includes("net_score")) {
        return {
          ...col,
          cell: (context) => (context.getValue() ? `${context.getValue()}%` : "-"),
          meta: {
            getCellProps: (context) => {
              return {
                className: `text-center font-medium ${getNetScoreColor(context.getValue())}`,
              }
            },
          },
        }
      }

      if (col.accessorKey.includes("_shared_n")) {
        return {
          ...col,
          cell: (context) => context.getValue() ?? "-",
          meta: {
            getCellProps: (context) => {
              return {
                className: `text-center font-medium ${getSharedNColor(context.getValue())}`,
              }
            },
          },
        }
      }

      return {
        ...col,
        meta: {
          cellProps: {
            className: "truncate",
          },
        },
      }
    }),
    data: series?.data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => row.id.toString(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    enableColumnPinning: true,
    enableSorting: true,
    initialState: {
      columnPinning: {
        left: ["name"],
      },
    },
  })

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div
          className="highcharts-loading"
          aria-hidden="true"
          style={{
            backgroundColor: "rgb(255, 255, 255)",
            opacity: 0.5,
            textAlign: "center",
            zIndex: 10,
            width: "196px",
            height: "18px",
          }}
        >
          <span className="highcharts-loading-inner" style={{ fontWeight: "bold", position: "relative", top: "45%" }}>
            Loading...
          </span>
        </div>
      </div>
    )
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="ml-auto">
            Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {table
            .getAllColumns()
            .filter((column) => column.getCanHide())
            .map((column) => (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
                onSelect={(e) => e.preventDefault()}
              >
                {series?.columns.find((col: any) => col.accessorKey === column.id)?.header}
              </DropdownMenuCheckboxItem>
            ))}
        </DropdownMenuContent>
      </DropdownMenu>

      <div className="absolute top-12 right-0 bottom-0 left-0">
        <DataTable table={table} />
      </div>
    </>
  )
}
