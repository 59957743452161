import { FilterGroupKeys, FilterListItem, useFilterGroupStore } from "@/components/filters"
import { cn, getFilterSelectionText } from "@/lib/utils"
import { Button, ButtonProps } from "@/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuTrigger } from "@/ui/dropdown_menu"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import React from "react"

interface RadioListProps extends ButtonProps {
  className?: string
  text?: string
  fallbackText?: string
  filterGroupId: FilterGroupKeys
  items: FilterListItem[]
  defaultSelection: FilterListItem
}

export function RadioList({ className, fallbackText, filterGroupId, items, defaultSelection, text, ...props }: RadioListProps) {
  const filterGroup = useFilterGroupStore((s) => s[filterGroupId])
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const selectedValue = getSelectedRadioValue(filterGroup?.selectedItems, defaultSelection)
  const selectedValueText = getFilterSelectionText([selectedValue], { fallbackText: fallbackText || "None" })

  const handleChange = (value: string) => {
    updateFilterGroups({
      [filterGroupId]: {
        name: text,
        ...filterGroup,
        selectedItems: [
          {
            name: items.find((m) => m.id == value)!.name,
            id: value,
          },
        ],
      },
    })
  }

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className={cn("bg-slate-100 border-transparent px-3 flex justify-between", className)} {...props}>
          <span className="truncate">
            {text}: <span className="text-sky-700 font-medium">{selectedValueText}</span>
          </span>
          <ChevronDownIcon className="w-4 h-4 ml-2" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="overflow-y-auto" align="start">
        <DropdownMenuRadioGroup value={selectedValue.id as string} onValueChange={handleChange}>
          {items.map((item) => (
            <DropdownMenuRadioItem key={item.id} value={item.id as string}>
              {item.name}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const getSelectedRadioValue = (selectedItems: FilterListItem[] = [], defaultMetric: FilterListItem): FilterListItem => {
  return selectedItems.length ? selectedItems[0]! : defaultMetric
}
