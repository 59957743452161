import axios from "axios"

export default async function request(options) {
  const client = axios.create({
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
    },
  })

  const onSuccess = (response) => {
    return response.data
  }

  const onError = (error) => {
    return Promise.reject(error.response)
  }

  return client(options).then(onSuccess).catch(onError)
}
