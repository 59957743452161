import { FilterGroupKeys, FilterGroups, FilterListItem } from "@/components/filters"
import { omitBy } from "lodash"
import React, { type ReactNode, createContext, useContext, useEffect, useRef } from "react"
import { useDebouncedCallback } from "use-debounce"
import { createStore, useStore } from "zustand"
import { subscribeWithSelector } from "zustand/middleware"
import { useShallow } from "zustand/react/shallow"

export type FilterGroupState = Partial<FilterGroups>

export type FilterGroupActions = {
  updateFilterGroups: (filterGroups: FilterGroups) => void
  removeItemFromFilterGroup: (filterGroupId: FilterGroupKeys, id: FilterListItem["id"]) => void
  resetFilterGroups: () => void
}

export type FilterGroupStore = FilterGroupState & FilterGroupActions

export const defaultInitState: FilterGroupState = {
  misc: {
    name: "Misc",
    hidden: true,
    selectedItems: [
      {
        id: "fetch_latest_survey",
        name: true,
      },
    ],
  },
}

export const createFilterGroupStore = (initState: FilterGroupState = defaultInitState) => {
  return createStore<FilterGroupStore>()(
    subscribeWithSelector((set) => ({
      ...initState,
      updateFilterGroups: (filterGroups) => set((state) => ({ ...state, ...filterGroups })),
      removeItemFromFilterGroup: (filterGroupId, id) =>
        set((state) => ({
          ...state,
          [filterGroupId]: {
            ...state[filterGroupId],
            selectedItems: state[filterGroupId]?.selectedItems.filter((item) => item.id !== id) || [],
          },
        })),
      resetFilterGroups: () =>
        set(
          (state) => ({
            misc: state.misc,
            datatable: state.datatable,
            display: state.display,
            survey_dates: state.survey_dates,
            updateFilterGroups: state.updateFilterGroups,
            removeItemFromFilterGroup: state.removeItemFromFilterGroup,
            resetFilterGroups: state.resetFilterGroups,
          }),
          true
        ),
    }))
  )
}

export const GlobalStore = createFilterGroupStore()

export type FilterGroupStoreApi = ReturnType<typeof createFilterGroupStore>

export const FilterGroupStoreContext = createContext<FilterGroupStoreApi | undefined>(undefined)

export interface FilterGroupStoreProviderProps {
  ignoredGlobalFilters?: FilterGroupKeys[]
  enableGlobalStoreSubscription?: boolean
  defaultAppliedFilterGroups?: FilterGroups
  onAppliedFiltersChange?: (filterGroups: FilterGroups) => void
  children?: ReactNode
}

export const FilterGroupStoreProvider = (props: FilterGroupStoreProviderProps) => {
  const {
    ignoredGlobalFilters = [],
    enableGlobalStoreSubscription = true,
    defaultAppliedFilterGroups,
    onAppliedFiltersChange,
    children,
  } = props
  const storeRef = useRef<FilterGroupStoreApi>()

  if (!storeRef.current) {
    storeRef.current = createFilterGroupStore(
      defaultAppliedFilterGroups ?? getFilteredGroupsFromStore(GlobalStore.getState(), ignoredGlobalFilters)
    )
  }

  const handleLocalStoreStateChange = useDebouncedCallback((state: FilterGroupState, _prevState: FilterGroupState) => {
    onAppliedFiltersChange?.(state)
  }, 750)

  const handleGlobalStoreStateChange = useDebouncedCallback((state: FilterGroupState, _prevState: FilterGroupState) => {
    storeRef.current?.getState().updateFilterGroups(state)
  }, 750)

  useEffect(() => {
    if (!onAppliedFiltersChange) return

    const unsubscribe = storeRef.current?.subscribe(getAllFilterGroupsFromStore, handleLocalStoreStateChange)

    return unsubscribe
  }, [])

  useEffect(() => {
    if (!GlobalStore || !enableGlobalStoreSubscription) return

    const unsubscribe = GlobalStore.subscribe(
      (state) => getFilteredGroupsFromStore(state, ignoredGlobalFilters),
      handleGlobalStoreStateChange,
      {
        equalityFn: (a, b) => JSON.stringify(a) === JSON.stringify(b),
      }
    )

    return unsubscribe
  }, [])

  return <FilterGroupStoreContext.Provider value={storeRef.current}>{children}</FilterGroupStoreContext.Provider>
}

export const useFilterGroupStore = <T,>(selector: (store: FilterGroupStore) => T): T => {
  const filterGroupStoreContext = useContext(FilterGroupStoreContext)

  if (!filterGroupStoreContext) {
    return useStore(GlobalStore, useShallow(selector))
  }

  return useStore(filterGroupStoreContext, useShallow(selector))
}

export const getAllFilterGroupsFromStore = (state: FilterGroupState) => {
  return omitBy(
    {
      adoption: state.adoption,
      adoption_sos: state.adoption_sos,
      adoption_yoy: state.adoption_yoy,
      increase: state.increase,
      increase_sos: state.increase_sos,
      increase_yoy: state.increase_yoy,
      flat: state.flat,
      flat_sos: state.flat_sos,
      flat_yoy: state.flat_yoy,
      decrease: state.decrease,
      decrease_sos: state.decrease_sos,
      decrease_yoy: state.decrease_yoy,
      replacing: state.replacing,
      replacing_sos: state.replacing_sos,
      replacing_yoy: state.replacing_yoy,
      net_score: state.net_score,
      net_score_sos: state.net_score_sos,
      net_score_yoy: state.net_score_yoy,
      pervasion: state.pervasion,
      pervasion_sos: state.pervasion_sos,
      pervasion_yoy: state.pervasion_yoy,
      citations_sos: state.citations_sos,
      citations_yoy: state.citations_yoy,
      cy_cy_revenue: state.cy_cy_revenue,
      market_capitalization: state.market_capitalization,
      short_ratio: state.short_ratio,
      price_to_earnings: state.price_to_earnings,
      ev_to_sales_ttm: state.ev_to_sales_ttm,
      ev_to_sales_ntm: state.ev_to_sales_ntm,
      return_ytd: state.return_ytd,
      return_last_1_month: state.return_last_1_month,
      return_last_3_month: state.return_last_3_month,
      return_last_6_month: state.return_last_6_month,
      return_last_12_month: state.return_last_12_month,
      companies: state.companies,
      citations: state.citations,
      cuts: state.cuts,
      cut_groups: state.cut_groups,
      datatable: state.datatable,
      display: state.display,
      display_sectors: state.display_sectors,
      display_products: state.display_products,
      metrics: state.metrics,
      misc: state.misc,
      public_private: state.public_private,
      products: state.products,
      sectors: state.sectors,
      shared_ns_comparison: state.shared_ns_comparison,
      survey_dates: state.survey_dates,
      y_axis: state.y_axis,
    },
    (value) => value === undefined
  )
}

export const getFilteredGroupsFromStore = (state: FilterGroupState, ignoredFilterGroups: FilterGroupKeys[] = []) => {
  const selector = getAllFilterGroupsFromStore(state) as FilterGroups

  ignoredFilterGroups.forEach((filterGroup) => {
    delete selector[filterGroup]
  })

  return selector
}
