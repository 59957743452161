import { getUsers } from "@/api/users"
import { Combobox } from "@/components/combobox"
import { Button } from "@/ui/button"
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/ui/dialog"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/ui/form"
import { Spinner } from "@/ui/spinner"
import { Dashboard } from "@/views/dashboards/types"
import { zodResolver } from "@hookform/resolvers/zod"
import { DialogDescription } from "@radix-ui/react-dialog"
import { useQuery } from "@tanstack/react-query"
import React from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"

const FormSchema = z.object({
  user: z.string().min(1, "Please select a user."),
})

export interface TransferDashboardDialogProps {
  dashboard: Dashboard | null
  open: boolean
  onOpenChange: (open: boolean) => void
  onConfirm: (data: z.infer<typeof FormSchema>) => void
}

export function TransferDashboardDialog(props: TransferDashboardDialogProps) {
  const { open, onOpenChange } = props

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="w-96">
        <DialogForm {...props} />
      </DialogContent>
    </Dialog>
  )
}

function DialogForm({ onConfirm, dashboard }: TransferDashboardDialogProps) {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  })

  const { data: users, isLoading } = useQuery({
    queryKey: ["users"],
    queryFn: () => getUsers(),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  })

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onConfirm)} className="space-y-6">
        <DialogHeader>
          <DialogTitle>Transfer Ownership</DialogTitle>
          <DialogDescription className="text-sm text-muted-foreground text-left">
            Transfer the ownership of "{dashboard?.name}" to another user.
          </DialogDescription>
        </DialogHeader>
        <FormField
          control={form.control}
          name="user"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center gap-1">
                <span>User</span>
                {isLoading && <Spinner className="h-4 w-4 animate-spin" />}
              </FormLabel>
              <FormControl>
                <Combobox
                  options={users?.map((user: any) => ({
                    label: user.full_name,
                    value: user.id,
                  }))}
                  text="Select a user"
                  placeholder="Search for a user..."
                  onValueChange={field.onChange}
                  value={field.value}
                  disabled={isLoading}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="ghost">Cancel</Button>
          </DialogClose>
          <Button type="submit">Transfer</Button>
        </DialogFooter>
      </form>
    </Form>
  )
}
