import { FilterListItem } from "@/components/filters"
import { Column } from "@tanstack/react-table"
import { clsx, type ClassValue } from "clsx"
import { CSSProperties } from "react"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function toPercentString(value: number, decimals: number = 2): string {
  return value.toLocaleString("en", {
    style: "percent",
    minimumFractionDigits: decimals,
  })
}

export function isReactElement(arg: any): arg is React.ReactElement {
  return arg.hasOwnProperty("type")
}

interface GlobalFilterSelectionTextOpts {
  multipleSelectionText?: string
  fallbackText?: string
}

export function getFilterSelectionText(selectedItems: FilterListItem[] | undefined, opts: GlobalFilterSelectionTextOpts = {}) {
  const { multipleSelectionText = "Multiple", fallbackText = "Multiple" } = opts

  if (!selectedItems || !selectedItems.length) return fallbackText
  if (selectedItems.length === 1) return selectedItems[0]!.name.toString()
  return multipleSelectionText
}

export const SURVEY_NS_COMPARISON_ITEMS: FilterListItem[] = [
  { id: "off", name: "Off" },
  { id: "gte", name: ">= Survey NS" },
  { id: "lt", name: "< Survey NS" },
]

export const DEFAULT_PAGE_SIZE = 25

export const getCommonPinningStyles = (column: Column<any>): CSSProperties => {
  const isPinned = column.getIsPinned()
  const isLastLeftPinnedColumn = isPinned === "left" && column.getIsLastColumn("left")
  const isFirstRightPinnedColumn = isPinned === "right" && column.getIsFirstColumn("right")

  return {
    boxShadow:
      isLastLeftPinnedColumn ? "-4px 0 4px -4px #cccccc inset"
      : isFirstRightPinnedColumn ? "4px 0 4px -4px #cccccc inset"
      : undefined,
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? "sticky" : "relative",
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
  }
}
