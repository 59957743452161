import request from "@/api/client"

export function getScreenerIdeaGeneratorSeries(data) {
  return request({
    url: "/api/v1/screeners/idea_generator/tabular",
    method: "POST",
    data,
  })
}

export function getScreenerSurveyZScoreSeries(data) {
  return request({
    url: "/api/v1/screeners/survey_z_score/tabular",
    method: "POST",
    data,
  })
}
