import request from "./client"

export function getUsers({ searchTerm = "" } = {}) {
  return request({
    url: "/api/v1/users",
    method: "GET",
    params: {
      search: searchTerm,
    },
  })
}
