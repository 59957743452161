import React from "react"
import AiProductSeriesIcon from "@/svgs/ai_product_series"
import DrilldownIcon from "@/svgs/drilldown_icon"
import InsightsIcon from "@/svgs/insights_icon"
import ObservatoryIcon from "@/svgs/observatory_icon"
import TsisIcon from "@/svgs/tsis_icon"
import { Offering } from "./types"
import { cn } from "@/lib/utils"

const icons = {
  survey_report: {
    tsis: TsisIcon,
    drilldown: DrilldownIcon,
    observatory: ObservatoryIcon,
    ai_product_series: AiProductSeriesIcon,
  },
  insight: InsightsIcon,
} as const

const IconContainer = ({ Icon, label, textVariant }: { Icon: React.ComponentType<any>; label: string; textVariant?: "sm" }) => (
  <div className="flex gap-2 items-center">
    <span className="inline-block bg-slate-200 rounded-full p-2">
      <Icon className="w-4 h-4" />
    </span>
    <span className={cn("text-slate-600", { "text-sm": textVariant == "sm" })}>{label}</span>
  </div>
)

export const OfferingIcon = ({ offering, textVariant }: { offering: Offering; textVariant?: "sm" }) => {
  const Icon =
    offering.offerable_type === "SurveyReport" ? icons.survey_report[offering.offerable.subtype]
    : offering.offerable_type === "Insight" ? icons.insight
    : null

  if (!Icon) return null

  return <IconContainer Icon={Icon} label={offering.offerable.human_subtype} textVariant={textVariant} />
}
