import request from "./client"

export function getSurveys({ type = "" }) {
  const url = `/api/v1/surveys`
  return request({
    url,
    method: "GET",
    params: { survey_type: type },
  })
}
