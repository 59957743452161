import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogContent,
  AlertDialogFooter,
} from "@/ui/alert_dialog"
import { buttonVariants } from "@/ui/button"
import React, { useState } from "react"
import { createDashboardView } from "@/api/dashboard_views"
import { Input } from "@/ui/input"

interface CreateDashboardViewDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  dashboardId: string
}

export function CreateDashboardViewDialog(props: CreateDashboardViewDialogProps) {
  const { open, onOpenChange, dashboardId } = props
  const [viewName, setViewName] = useState("")

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent className="w-[50%]">
        <AlertDialogHeader>
          <AlertDialogTitle>Create New View</AlertDialogTitle>
        </AlertDialogHeader>
        <Input placeholder="New View Name (Required)" onChange={(e) => setViewName(e.target.value)} />
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => onOpenChange(false)}>Cancel</AlertDialogCancel>
          <AlertDialogAction
            disabled={!viewName}
            className={buttonVariants({ variant: "default" })}
            onClick={async () => {
              const response = await createDashboardView(dashboardId, { name: viewName })

              if (response?.id) {
                window.location.href = `/dashboards/${dashboardId}/views/${response.id}`
              }
            }}
          >
            Add View
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
