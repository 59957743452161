import React, { useCallback, useState } from "react"
import { useResizeObserver } from "@wojtekmaj/react-hooks"
import { Document, Page, pdfjs } from "react-pdf"
import { cn } from "@/lib/utils"
import { Spinner } from "@/ui/spinner"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

const resizeObserverOptions = {}

export default function PdfViewer({ url }: { url: string }) {
  const [numPages, setNumPages] = useState<number>(0)
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null)
  const [containerWidth, setContainerWidth] = useState<number>()

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries

    if (entry) {
      setContainerWidth(entry.contentRect.width)
    }
  }, [])

  useResizeObserver(containerRef, resizeObserverOptions, onResize)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  const isLastPage = (pageIndex: number) => pageIndex + 1 === numPages

  return (
    <div className="flex flex-col items-center">
      <div ref={setContainerRef} className="flex flex-col items-center w-full max-w-[calc(100%-2rem)]">
        <Document loading={<Spinner className="w-24 h-24" />} file={url} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (_el, index) => (
            <Page
              key={`page_${index + 1}`}
              loading={null}
              pageNumber={index + 1}
              width={containerWidth}
              className={cn(isLastPage(index) && "mb-2")}
            />
          ))}
        </Document>
      </div>
    </div>
  )
}
