import { createDashboardView, getDashboardViews } from "@/api/dashboard_views"
import { createDashboard, getDashboards } from "@/api/dashboards"
import { createViewItem } from "@/api/view_items"
import { Combobox } from "@/components/combobox"
import { FilterGroupKeys, getAllFilterGroupsFromStore, useFilterGroupStore } from "@/components/filters"
import { Button } from "@/ui/button"
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/ui/dialog"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/ui/form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { toast } from "sonner"
import { z } from "zod"

const FormSchema = z.object({
  dashboard: z.string().min(1, "Please select a dashboard."),
  view: z.string().min(1, "Please select a view to display."),
})

interface CreateViewItemDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  widget: string
  componentKey: string
}

export function CreateViewItemDialog(props: CreateViewItemDialogProps) {
  const { open, onOpenChange } = props

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="w-96">
        <DialogForm {...props} />
      </DialogContent>
    </Dialog>
  )
}

function DialogForm({ onOpenChange, widget, componentKey }: CreateViewItemDialogProps) {
  const appliedFilterGroups = useFilterGroupStore(getAllFilterGroupsFromStore)
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  })
  const dashboardId = form.watch("dashboard")

  const queryClient = useQueryClient()
  const { data: dashboards } = useQuery({
    queryKey: ["dashboards"],
    queryFn: () => getDashboards({ editable: true }),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  })
  const { data: views } = useQuery({
    queryKey: ["views", dashboardId],
    queryFn: () => getDashboardViews({ dashboardId }),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!dashboardId,
  })

  const handleCreateDashboard = (name: string) => {
    createDashboard({ name }).then((response) => {
      queryClient.invalidateQueries({ queryKey: ["dashboards"] })
      queryClient.invalidateQueries({
        queryKey: ["views", form.getValues("dashboard")],
      })

      form.setValue("dashboard", response.id.toString())
    })
  }

  const handleCreateView = (name: string) => {
    createDashboardView(form.getValues("dashboard"), { name }).then((response) => {
      queryClient.invalidateQueries({
        queryKey: ["views", form.getValues("dashboard")],
      })

      form.setValue("view", response.id.toString())
    })
  }

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    const filters = { ...appliedFilterGroups }
    const keys = Object.keys(filters) as FilterGroupKeys[]

    keys.forEach((key) => {
      if (!filters[key]) {
        delete filters[key]
      }
    })

    createViewItem(data.view, {
      w: 5,
      h: 4,
      widget_type: componentKey,
      widget_settings: { filters },
    })
      .then(() => {
        onOpenChange(false)
        toast.success(`Added ${widget} to dashboard`, {
          description: "You can now view it in the dashboard.",
          action: {
            label: "View Dashboard",
            onClick: () => {
              window.location.href = `/dashboards/${data.dashboard}/views/${data.view}`
            },
          },
        })
      })
      .catch((error) => {
        form.setError("view", {
          type: "custom",
          message: error.data?.errors?.[0] || "Uh oh, something went wrong!",
        })
      })
  }

  useEffect(() => {
    if (!dashboards) return

    if (dashboards.length == 1 && !dashboardId) {
      form.setValue("dashboard", dashboards[0].id)
    }

    form.resetField("view")
  }, [dashboards, dashboardId])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <DialogHeader>
          <DialogTitle>Add {widget} to Dashboard</DialogTitle>
        </DialogHeader>
        <FormField
          control={form.control}
          name="dashboard"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Dashboard</FormLabel>
              <FormControl>
                <Combobox
                  options={dashboards?.map((dashboard) => ({
                    label: dashboard.name,
                    value: dashboard.id.toString(),
                  }))}
                  text="Select or create a dashboard"
                  placeholder="Search or create new dashboard..."
                  enableCreate={true}
                  onCreate={handleCreateDashboard}
                  onValueChange={field.onChange}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="view"
          disabled={!form.watch("dashboard")}
          render={({ field }) => (
            <FormItem>
              <FormLabel>View</FormLabel>
              <FormControl>
                <Combobox
                  options={views?.map((view) => ({
                    label: view.name,
                    value: view.id.toString(),
                  }))}
                  disabled={field.disabled}
                  text="Select or create a view"
                  placeholder="Search or create new view..."
                  enableCreate={true}
                  onCreate={handleCreateView}
                  onValueChange={field.onChange}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="ghost">Cancel</Button>
          </DialogClose>
          <Button type="submit">Add to Dashboard</Button>
        </DialogFooter>
      </form>
    </Form>
  )
}
