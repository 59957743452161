import { getCuts } from "@/api/cuts"
import { getProducts } from "@/api/products"
import { getSectors } from "@/api/sectors"
import { getTsisUniqueRespondents } from "@/api/tsis_unique_respondents"
import {
  FilterGroups,
  FilterGroupStoreProvider,
  FilterListDropdown,
  FilterRange,
  getAllFilterGroupsFromStore,
  GlobalStore,
  RadioList,
  SurveyDates,
  useFilterGroupStore,
} from "@/components/filters"
import { WIDGET_ASPECT_RATIO } from "@/components/widget"
import {
  SampleWidget,
  SharedAccountsPeerPositionWidget,
  SharedAccountsSnapshotWidget,
  SharedAccountsTrendsWidget,
  SharedAccountsTabularWidget,
} from "@/components/widgets"
import { SharedAccountsTimeSeriesWidget } from "@/components/widgets/shared_accounts_time_series"
import { useDefaultFilter, useFilter } from "@/hooks/use_filter"
import { useSurveyDates } from "@/hooks/use_survey_dates"
import { SURVEY_NS_COMPARISON_ITEMS } from "@/lib/utils"
import { AspectRatio } from "@/ui/aspect_ratio"
import { Card, CardContent, CardHeader, CardTitle } from "@/ui/card"
import { Spinner } from "@/ui/spinner"
import { useQuery } from "@tanstack/react-query"
import { last } from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import { getWidgets } from "@/api/widgets"

export default function AnalyzeSharedAccounts() {
  const [loading, setLoading] = useState(true)

  const handleGlobalFilterChange = useCallback((state: FilterGroups) => {
    const cuts = state.cuts?.selectedItems?.length
    const surveys = state.survey_dates?.selectedItems?.length

    if (cuts && surveys) setLoading(false)

    GlobalStore.getState().updateFilterGroups(state)
  }, [])

  useQuery({
    queryKey: ["widgets"],
    queryFn: () => getWidgets(),
  })

  return (
    <div className="mb-[500px] space-y-2">
      <FilterGroupStoreProvider onAppliedFiltersChange={handleGlobalFilterChange} enableGlobalStoreSubscription={false}>
        <div className="sticky z-10 bg-white top-12 md:top-16">
          <GlobalTargetFilters />
        </div>

        <div className="container">
          <h3 className="text-lg font-semibold md:text-xl py-2">
            Market Share(n) = <MarketShareCount />
          </h3>
          <GlobalDisplayFilters />
        </div>
      </FilterGroupStoreProvider>

      <div className="container">
        {loading ?
          <AspectRatio ratio={WIDGET_ASPECT_RATIO}>
            <div className="flex flex-col items-center justify-center h-full gap-3">
              <Spinner className="w-24 h-24" />
              <p className="text-sm text-muted-foreground">Loading widgets...</p>
            </div>
          </AspectRatio>
        : <div className="grid grid-cols-2 gap-2 mt-2">
            <SharedAccountsSnapshotWidget
              hidePrimaryFilters
              hiddenFilters={["cuts", "metrics", "display_sectors", "display_products", "citations", "shared_ns_comparison"]}
            />
            <SharedAccountsTrendsWidget hidePrimaryFilters hideSecondaryFilters />
            <SharedAccountsPeerPositionWidget hidePrimaryFilters hideSecondaryFilters />
            <SharedAccountsTimeSeriesWidget
              hidePrimaryFilters
              ignoredGlobalFilters={["shared_ns_comparison"]}
              hiddenFilters={["survey_dates", "cuts", "metrics", "display_sectors", "display_products", "citations"]}
            />
            <div className="col-span-2">
              <SharedAccountsTabularWidget hidePrimaryFilters hideSecondaryFilters />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

const GlobalTargetFilters = () => {
  const appliedFilterGroups = useFilterGroupStore(getAllFilterGroupsFromStore)
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const queryParams = new URLSearchParams(window.location.search)
  const selectedSectors = (queryParams.get("sectors")?.split(",") || []).map((id: string) => parseInt(id))
  const selectedProducts = (queryParams.get("products")?.split(",") || []).map((id: string) => parseInt(id))
  const [firstLoad, setFirstLoad] = useState(true)

  const { value: sectors } = useDefaultFilter("sectors", {
    enabled: firstLoad,
    queryFn: getSectors,
    select: (data: any) =>
      data
        .filter((item: any) => selectedSectors?.includes(item.id))
        .map((item: any) => ({
          id: item.id,
          name: item.name,
        })),
    returnType: "multiple",
  })

  const { value: products } = useDefaultFilter("products", {
    enabled: firstLoad,
    queryFn: getProducts,
    select: (data: any) =>
      data
        .filter((item: any) => selectedProducts?.includes(item.id))
        .map((item: any) => ({
          id: item.id,
          name: `${item.name} - ${item.sector.name}`,
        })),
    returnType: "multiple",
  })

  useDefaultFilter("cuts", {
    queryFn: getCuts,
    select: (data: any) => data.filter((item: any) => item.name === "All Respondents"),
    returnType: "single",
    permanent: true,
  })

  useSurveyDates("multiple")

  useEffect(() => {
    if (firstLoad && sectors.length && products.length) {
      setFirstLoad(false)
    }
  }, [sectors, products])

  return (
    <div className="container flex flex-wrap gap-2 py-2">
      <FilterListDropdown
        className="w-full text-sm"
        name="Sector"
        text="Sector"
        fallbackText="All"
        filterGroupId="sectors"
        endpoint="/api/v1/sectors"
        onHandleFilterChange={(filterGroupId, selectedItems) => {
          const products =
            appliedFilterGroups.products?.selectedItems.filter((product) => {
              return selectedItems.some((sector) => (product.name as string).includes(sector.name as string))
            }) || []

          updateFilterGroups({
            [filterGroupId]: {
              name: "Sectors",
              selectedItems,
            },
            products: {
              name: "Products",
              selectedItems: products,
            },
          })
        }}
      />

      <FilterListDropdown
        className="w-full text-sm"
        name="Products"
        text="Products"
        fallbackText="All"
        filterGroupId="products"
        endpoint="/api/v1/products"
        params={{
          sectors,
        }}
        formatter={(item) => ({
          ...item,
          subtitle: item.sector.name,
        })}
      />

      <FilterListDropdown
        buttonClassName="min-w-56"
        className="w-full text-sm"
        name="Cut"
        text="Cut"
        fallbackText="All Respondents"
        filterGroupId="cuts"
        endpoint="/api/v1/cuts"
        enableMultiRowSelection={false}
      />

      <FilterListDropdown
        className="w-full text-sm"
        name="Metrics"
        text="Metrics"
        fallbackText="All"
        filterGroupId="metrics"
        endpoint="/api/v1/metrics"
      />

      <SurveyDates mode="multiple" />
    </div>
  )
}

const GlobalDisplayFilters = () => {
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const displaySectorFilterGroup = useFilterGroupStore((s) => s.display_sectors)
  const displayProductFilterGroup = useFilterGroupStore((s) => s.display_products)

  return (
    <Card className="space-y-2">
      <CardHeader className="pb-0">
        <CardTitle className="text-xl text-[#404F69]">Shared Accounts Filters</CardTitle>
      </CardHeader>

      <CardContent className="flex gap-2">
        <FilterListDropdown
          className="w-full text-sm"
          name="Display Sector"
          text="Sector"
          fallbackText="All"
          filterGroupId="display_sectors"
          endpoint="/api/v1/sectors"
          enableMultiRowSelection={false}
          onHandleFilterChange={(filterGroupId, selectedItems) => {
            updateFilterGroups({
              [filterGroupId]: {
                name: "Display Sector",
                ...displaySectorFilterGroup,
                selectedItems,
              },
              display_products: {
                name: "Display Products",
                ...displayProductFilterGroup,
                selectedItems: [],
              },
            })
          }}
        />

        <FilterListDropdown
          className="w-full text-sm"
          name="Display Products"
          text="Products"
          fallbackText="All"
          filterGroupId="display_products"
          endpoint="/api/v1/products"
          params={{ sectors: displaySectorFilterGroup?.selectedItems.map((item) => item.id) }}
          dependencies={["display_sectors"]}
          formatter={(item) => ({
            ...item,
            subtitle: item.sector.name,
          })}
        />

        <FilterRange text="Min Market Share(n)" filterGroupId="citations" />

        <RadioList
          text="Shared NS"
          filterGroupId="shared_ns_comparison"
          items={SURVEY_NS_COMPARISON_ITEMS}
          defaultSelection={SURVEY_NS_COMPARISON_ITEMS[0]!}
        />
      </CardContent>
    </Card>
  )
}

const MarketShareCount = () => {
  const cut = useFilter("cuts", { returnType: "single" })
  const sectors = useFilter("sectors", { returnType: "multiple" })
  const products = useFilter("products", { returnType: "multiple" })
  const metrics = useFilter("metrics", { returnType: "multiple" })
  const { value: surveys, isLoading: surveyIsLoading } = useSurveyDates("multiple")
  const lastSurvey = last(surveys as string[])
  const enabled = !!cut && !!surveys.length && !surveyIsLoading

  const { data, isLoading } = useQuery<[{ survey_date: string; unique_respondents: number }]>({
    queryKey: ["tsis_unique_respondents", cut, sectors, products, metrics, surveys],
    queryFn: () => {
      return getTsisUniqueRespondents({
        cut,
        companies: null,
        metrics,
        products,
        sectors,
        surveys: lastSurvey,
      })
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled,
  })

  const marketShareCount = last(data)?.unique_respondents || (isLoading ? "" : 0)
  return <span>{marketShareCount}</span>
}
