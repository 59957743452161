import { useState, useRef, useEffect, useCallback } from "react"

export function useHover() {
  const ref = useRef<HTMLElement>(null)
  const [isHovered, setIsHovered] = useState(false)
  const handleMouseHover = useCallback(() => {
    setIsHovered((prevIsHovered) => !prevIsHovered)
  }, [])

  useEffect(() => {
    const element = ref?.current

    if (!element) return

    element.addEventListener("mouseenter", handleMouseHover)
    element.addEventListener("mouseleave", handleMouseHover)

    return () => {
      element.removeEventListener("mouseenter", handleMouseHover)
      element.removeEventListener("mouseleave", handleMouseHover)
    }
  }, [])

  return [ref, isHovered]
}
