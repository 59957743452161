import request from "@/api/client"
import { getCompanies } from "@/api/companies"
import { getSectors } from "@/api/sectors"
import { Button } from "@/ui/button"
import { Card, CardContent, CardFooter, CardTitle } from "@/ui/card"
import { Checkbox } from "@/ui/checkbox"
import { DateRangePicker, PresetRange } from "@/ui/date_range_picker"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/ui/form"
import { Input } from "@/ui/input"
import MultipleSelector from "@/ui/multiple_selector"
import { zodResolver } from "@hookform/resolvers/zod"
import { useQuery } from "@tanstack/react-query"
import { format, isValid, startOfMonth, subMonths } from "date-fns"
import queryString from "query-string"
import React, { useMemo } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { DateRange } from "react-day-picker"
import { Badge } from "@/ui/badge"
import { LuX } from "react-icons/lu"

const optionSchema = z.object({
  label: z.string(),
  value: z.string(),
  disable: z.boolean().optional(),
})

const FormSchema = z.object({
  keyword: z.string().optional(),
  dateRange: z.custom<DateRange>().optional(),
  companies: z.array(optionSchema),
  sectors: z.array(optionSchema),
  items: z.array(z.string()).refine((value) => value.some((item) => item), {
    message: "You have to select at least one item.",
  }),
  survey: z.object({ id: z.number(), name: z.string(), short_name: z.string(), date: z.string() }).nullable().optional(),
})

const offeringCheckboxOptions = [
  {
    id: "insight",
    label: "Insights",
    children: [
      {
        id: "panel",
        label: "Panel",
      },
      {
        id: "vendor_focus",
        label: "Vendor Focus",
      },
      {
        id: "industry_analysis",
        label: "Industry Analysis",
      },
    ],
  },
  {
    id: "survey_report",
    label: "Survey Reports",
    children: [
      { id: "tsis", label: "Technology Spending Intentions" },
      { id: "drilldown", label: "Drill Down" },
      { id: "observatory", label: "Observatory Bundle" },
      { id: "ai_product_series", label: "AI Product Series" },
    ],
  },
] as const

type OfferableScope = (typeof offeringCheckboxOptions)[number]["children"][number]["id"]

export default function OfferingForm(props: any): React.JSX.Element {
  const presetRanges: PresetRange[] = [
    {
      label: "This Month",
      value: {
        from: startOfMonth(new Date()),
        to: new Date(),
      },
    },
    {
      label: "Last 3 months",
      value: {
        from: subMonths(startOfMonth(new Date()), 3),
        to: new Date(),
      },
    },
    {
      label: "Last 6 months",
      value: {
        from: subMonths(startOfMonth(new Date()), 6),
        to: new Date(),
      },
    },
    {
      label: "Last Year",
      value: {
        from: startOfMonth(subMonths(new Date(), 12)),
        to: new Date(),
      },
    },
  ]

  const { data: companies } = useQuery({
    queryKey: ["companies"],
    queryFn: () => getCompanies(),
  })

  const { data: sectors } = useQuery({
    queryKey: ["sectors"],
    queryFn: () => getSectors(),
  })

  const defaultItems = offeringCheckboxOptions.flatMap((checkbox) => [
    checkbox.id,
    ...checkbox.children.map((dependentCheckbox) => dependentCheckbox.id),
  ])
  const search = queryString.parse(location.search, { arrayFormat: "bracket" })

  const parseDateRange = (from: string, to?: string): DateRange | undefined => {
    const fromDate = new Date(from)
    const toDate = to ? new Date(to) : undefined
    if (!isValid(fromDate) || (toDate !== undefined && !isValid(toDate))) {
      return
    }
    return { from: fromDate, to: toDate }
  }

  const defaultValues = useMemo(() => {
    const selectedDateRange = parseDateRange(search.from as string, search.to as string)
    const checkboxOptions = offeringCheckboxOptions.filter((checkbox) => search.types?.includes(checkbox.id))
    const selectedItems = checkboxOptions.flatMap((checkbox) => {
      const dependentCheckboxOptions = checkbox.children.map((dependentCheckbox) => dependentCheckbox.id)
      const selectedSubTypes = dependentCheckboxOptions.filter((id) => search.subtypes?.includes(id))

      return [checkbox.id, ...(selectedSubTypes.length ? selectedSubTypes : dependentCheckboxOptions)]
    })

    return {
      keyword: (search.keyword as string) ?? "",
      dateRange: selectedDateRange,
      companies: props.companies.map((company: any) => ({ value: company.id.toString(), label: company.name })),
      sectors: props.sectors.map((sector: any) => ({ value: sector.id.toString(), label: sector.name })),
      items: selectedItems.length ? selectedItems : defaultItems,
      survey: props.survey,
    }
  }, [])

  const form = useForm<z.infer<typeof FormSchema>>({
    defaultValues,
    resolver: zodResolver(FormSchema),
  })

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    try {
      const types = data.items.filter((item) => ["insight", "survey_report"].includes(item))
      const subtypes = data.items.filter((item) => !["insight", "survey_report"].includes(item))

      const url = queryString.stringifyUrl(
        {
          url: "/offerings",
          query: {
            keyword: data.keyword,
            from: data.dateRange?.from ? format(data.dateRange.from!, "yyyy-MM-dd") : undefined,
            to: data.dateRange?.to ? format(data.dateRange.to!, "yyyy-MM-dd") : undefined,
            companies: data.companies.map((company) => company.value),
            sectors: data.sectors.map((sector) => sector.value),
            types,
            subtypes,
            survey: data.survey?.id || undefined,
          },
        },
        { arrayFormat: "bracket" }
      )

      const html = await request({
        url,
        headers: { Accept: "text/vnd.turbo-stream.html" },
      })
      Turbo.navigator.history.replace({ href: url })
      Turbo.renderStreamMessage(html)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="basis-1/4 lg:w-[350px]">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <Card className="text-xs text-slate-600">
            <CardContent className="flex flex-col gap-3 pt-6">
              <FormField
                control={form.control}
                name="keyword"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input placeholder="Search" type="search" {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />

              <CardTitle className="text-sm font-normal">Filter By</CardTitle>
              <hr />
              <FormField
                control={form.control}
                name="dateRange"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-semibold">Publish Date</FormLabel>

                    <FormControl>
                      <DateRangePicker
                        initialDateRange={field.value}
                        onDateRangeChange={(newDateRange) => field.onChange(newDateRange)}
                        presetRanges={presetRanges}
                        numberOfMonths={2}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="companies"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-semibold">Companies</FormLabel>

                    <FormControl>
                      <MultipleSelector
                        {...field}
                        options={companies?.map((company: any) => ({ value: company.id.toString(), label: company.name })) ?? []}
                        placeholder="Please select"
                        hidePlaceholderWhenSelected
                        emptyIndicator={<p className="w-full text-center leading-10 text-muted-foreground">no results found.</p>}
                        className="max-h-[200px] overflow-y-auto"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="sectors"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-semibold">Sectors</FormLabel>

                    <FormControl>
                      <MultipleSelector
                        {...field}
                        options={sectors?.map((sector: any) => ({ value: sector.id.toString(), label: sector.name })) ?? []}
                        placeholder="Please select"
                        hidePlaceholderWhenSelected
                        emptyIndicator={<p className="w-full text-center leading-10 text-muted-foreground">no results found.</p>}
                        className="max-h-[200px] overflow-y-auto"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="items"
                render={() => (
                  <FormItem>
                    <FormLabel className="font-semibold">Offering</FormLabel>
                    {offeringCheckboxOptions.map((checkboxItem) => (
                      <div key={checkboxItem.id} className="mb-4">
                        <FormField
                          control={form.control}
                          name="items"
                          render={({ field }) => {
                            return (
                              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                                <FormControl>
                                  <Checkbox
                                    checked={field.value?.includes(checkboxItem.id)}
                                    onCheckedChange={(checked) => {
                                      const updatedValue =
                                        checked ?
                                          [...(field.value || []), checkboxItem.id, ...checkboxItem.children.map((child) => child.id)]
                                        : field.value?.filter(
                                            (value) =>
                                              value !== checkboxItem.id &&
                                              !checkboxItem.children.map((child) => child.id).includes(value as OfferableScope)
                                          )
                                      field.onChange(updatedValue)
                                    }}
                                  />
                                </FormControl>
                                <FormLabel className="font-normal">{checkboxItem.label}</FormLabel>
                              </FormItem>
                            )
                          }}
                        />
                        <div className="ml-6 mt-2">
                          {checkboxItem.children.map((dependentCheckbox) => (
                            <FormField
                              key={dependentCheckbox.id}
                              control={form.control}
                              name="items"
                              render={({ field }) => {
                                return (
                                  <FormItem className="flex flex-row items-start space-x-3 space-y-0 mt-2">
                                    <FormControl>
                                      <Checkbox
                                        checked={field.value?.includes(dependentCheckbox.id)}
                                        onCheckedChange={(checked) => {
                                          let updatedValue =
                                            checked ?
                                              [...field.value, dependentCheckbox.id]
                                            : field.value?.filter((value) => value !== dependentCheckbox.id)

                                          // Are any children checked?
                                          if (checkboxItem.children.some((child) => updatedValue?.includes(child.id))) {
                                            // if all children are checked, check the parent
                                            updatedValue.push(checkboxItem.id)
                                          }

                                          // Are all children unchecked?
                                          if (checkboxItem.children.every((child) => !updatedValue?.includes(child.id))) {
                                            // remove parent if all children are unchecked
                                            updatedValue = updatedValue.filter((value) => value !== checkboxItem.id)
                                          }

                                          const uniqueValues = new Set(updatedValue)

                                          field.onChange(Array.from(uniqueValues))
                                        }}
                                      />
                                    </FormControl>
                                    <FormLabel className="font-normal">{dependentCheckbox.label}</FormLabel>
                                  </FormItem>
                                )
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    ))}
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="survey"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      {field.value ?
                        <Badge className="text-xs cursor-pointer">
                          <div className="flex items-center space-x-1 select-none">
                            <span>{field.value.survey_type.name}</span>
                            <span>-</span>
                            <span>{field.value.short_name}</span>
                            <LuX onClick={() => field.onChange(null)} className="w-4 h-4 ml-1 hover:text-black" />
                          </div>
                        </Badge>
                      : null}
                    </FormControl>
                  </FormItem>
                )}
              />
            </CardContent>
            <CardFooter>
              <Button className="w-full" type="submit" disabled={form.formState.isSubmitting}>
                Apply
              </Button>
            </CardFooter>
          </Card>
        </form>
      </Form>
    </div>
  )
}
