import { FilterListItem, useFilterGroupStore } from "@/components/filters"
import { Button } from "@/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/ui/dropdown_menu"
import { Switch } from "@/ui/switch"
import React from "react"
import { IoOptionsOutline } from "react-icons/io5"

export interface DisplayFiltersProps {
  toggleItems: FilterListItem[]
}

export function DisplayFilters(props: DisplayFiltersProps) {
  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          className="border-transparent px-2 gap-1 bg-slate-100 text-sky-700"
          onMouseDown={(e) => e.stopPropagation()}
        >
          <IoOptionsOutline className="h-4 w-4" /> Display
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DisplayFiltersDropdownContent {...props} />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

function DisplayFiltersDropdownContent(props: DisplayFiltersProps) {
  const display = useFilterGroupStore((s) => s.display)
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)

  const { toggleItems } = props

  const handleToggleCheck = (id: string, value: boolean) => {
    const toggle = display?.selectedItems?.filter((i) => i.id === id)

    const getUpdatedToggles = () => {
      if (toggle?.length) {
        return display?.selectedItems?.map((i) => {
          if (i.id === id) {
            return { ...i, name: value }
          }

          return i
        })
      }

      return [...(display?.selectedItems || []), { id, name: value }]
    }

    updateFilterGroups({
      display: {
        name: "Display",
        hidden: true,
        selectedItems: getUpdatedToggles() || [],
      },
    })
  }

  return (
    <div className="m-3">
      <p>Display</p>
      <p className="text-sm">Show or hide sections of this widget</p>
      <div className="flex flex-col gap-3 mt-3">
        {toggleItems.map((toggle) => {
          return (
            <div key={toggle.id} className="border-b-2 flex flex-row pb-2">
              <p className="flex-1 m-0">{toggle.name}</p>
              <Switch
                className="flex-none"
                id={toggle.id as string}
                defaultChecked={getDisplayToggleState(display?.selectedItems, toggle.id as string, true)}
                onCheckedChange={(value) => {
                  handleToggleCheck(toggle.id as string, value)
                }}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const getDisplayToggleState = (items: FilterListItem[] | undefined, id: string, defaultDisplayToggleState?: boolean) => {
  const toggle = items?.find((i) => i?.id === id)

  const checked = toggle ? toggle.name : defaultDisplayToggleState

  return !!checked
}
