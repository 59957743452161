import request from "@/api/client"

export function getSurveyEnterpriseSizes({ survey, company, products, metric }) {
  return request({
    url: `/api/v1/surveys/${survey}/enterprise_sizes`,
    method: "GET",
    params: {
      company,
      products,
      metric,
    },
  })
}
