import { AdoptionReplacingReasoningWidget } from "@/components/widgets/adoption_replacing_reasoning"
import { NetScoreAndAnswerDistributionWidget } from "@/components/widgets/net_score_and_answer_distribution"
import { ProductAreaWidget } from "@/components/widgets/product_area"
import { RelativeSectorWidget } from "@/components/widgets/relative_sector"
import { RespondentCompositionWidget } from "@/components/widgets/respondent_composition"
import { RespondentSubsampleWidget } from "@/components/widgets/respondent_subsample"
import { SampleWidget } from "@/components/widgets/sample"
import { IdeaGeneratorWidget } from "@/components/widgets/screener_idea_generator"
import { SurveyZScoreWidget } from "@/components/widgets/screener_survey_z_score"
import { SectorNetScoreAndAnswerDistributionWidget } from "@/components/widgets/sector_net_score_and_answer_distribution"
import { SectorRespondentSubsampleWidget } from "@/components/widgets/sector_respondent_subsample"
import { SharedAccountsPeerPositionWidget } from "@/components/widgets/shared_accounts_peer_position"
import { SharedAccountsSnapshotWidget } from "@/components/widgets/shared_accounts_snapshot"
import { SharedAccountsTabularWidget } from "@/components/widgets/shared_accounts_tabular"
import { SpendFocusWidget } from "@/components/widgets/spend_focus"
import { SubsampleCandlestickWidget } from "@/components/widgets/subsample_candlestick"
import { SharedAccountsTrendsWidget } from "@/components/widgets/shared_accounts_trends"
import { SharedAccountsTimeSeriesWidget } from "@/components/widgets/shared_accounts_time_series"

export { AdoptionReplacingReasoningWidget } from "@/components/widgets/adoption_replacing_reasoning"
export { NetScoreAndAnswerDistributionWidget } from "@/components/widgets/net_score_and_answer_distribution"
export { ProductAreaWidget } from "@/components/widgets/product_area"
export { RelativeSectorWidget } from "@/components/widgets/relative_sector"
export { RespondentCompositionWidget } from "@/components/widgets/respondent_composition"
export { RespondentSubsampleWidget } from "@/components/widgets/respondent_subsample"
export { SampleWidget } from "@/components/widgets/sample"
export { IdeaGeneratorWidget } from "@/components/widgets/screener_idea_generator"
export { SurveyZScoreWidget } from "@/components/widgets/screener_survey_z_score"
export { SectorNetScoreAndAnswerDistributionWidget } from "@/components/widgets/sector_net_score_and_answer_distribution"
export { SectorRespondentSubsampleWidget } from "@/components/widgets/sector_respondent_subsample"
export { SharedAccountsPeerPositionWidget } from "@/components/widgets/shared_accounts_peer_position"
export { SharedAccountsSnapshotWidget } from "@/components/widgets/shared_accounts_snapshot"
export { SharedAccountsTabularWidget } from "@/components/widgets/shared_accounts_tabular"
export { SpendFocusWidget } from "@/components/widgets/spend_focus"
export { SubsampleCandlestickWidget } from "@/components/widgets/subsample_candlestick"
export { SharedAccountsTrendsWidget } from "@/components/widgets/shared_accounts_trends"
export { SharedAccountsTimeSeriesWidget } from "@/components/widgets/shared_accounts_time_series"

export const WIDGETS = {
  adoption_replacing_reasoning: AdoptionReplacingReasoningWidget,
  net_score_answer_distribution: NetScoreAndAnswerDistributionWidget,
  product_area: ProductAreaWidget,
  relative_sector: RelativeSectorWidget,
  respondent_composition: RespondentCompositionWidget,
  respondent_subsample: RespondentSubsampleWidget,
  sample: SampleWidget,
  idea_generator: IdeaGeneratorWidget,
  survey_z_score: SurveyZScoreWidget,
  sector_net_score_answer_distribution: SectorNetScoreAndAnswerDistributionWidget,
  sector_respondent_subsample: SectorRespondentSubsampleWidget,
  peer_position: SharedAccountsPeerPositionWidget,
  snapshot: SharedAccountsSnapshotWidget,
  tabular: SharedAccountsTabularWidget,
  spend_focus: SpendFocusWidget,
  subsample_candlestick: SubsampleCandlestickWidget,
  trends: SharedAccountsTrendsWidget,
  time_series: SharedAccountsTimeSeriesWidget,
}

export type WidgetTypes = keyof typeof WIDGETS
