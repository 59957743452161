import React from "react"

export default function Footer() {
  const currentYear = new Date().getFullYear()

  return (
    <div className="h-[55px] mt-5 w-full mx-auto p-4 md:flex md:items-center md:justify-between border-t">
      <div className="text-sm sm:text-center">© {currentYear}. ETR™ All Rights Reserved.</div>
    </div>
  )
}
