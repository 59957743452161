import request from "./client"

export function getDashboardUsers(dashboardId) {
  const url = `/api/v1/dashboards/${dashboardId}/users`
  return request({
    url,
    method: "GET",
  })
}

export function createDashboardUser(params) {
  const { dashboardId, dashboardUser } = params
  const url = `/api/v1/dashboards/${dashboardId}/users`

  return request({
    url,
    method: "POST",
    data: {
      dashboard_user: {
        user_id: dashboardUser.userId,
        role: dashboardUser.role,
      },
    },
  })
}

export function putDashboardUser(params) {
  const { dashboardId, id, dashboardUser } = params
  const url = `/api/v1/dashboards/${dashboardId}/users/${id}`

  return request({
    url,
    method: "PUT",
    data: {
      dashboard_user: {
        role: dashboardUser.role,
      },
    },
  })
}

export function deleteDashboardUser(params) {
  const { dashboardId, id } = params
  const url = `/api/v1/dashboards/${dashboardId}/users/${id}`

  return request({
    url,
    method: "DELETE",
  })
}
