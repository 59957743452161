import React from "react"

export default function DrilldownIcon({ className }: { className?: string }) {
  return (
    <svg width="340" height="340" viewBox="0 0 340 340" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M77.113 218.78V131.501L-0.000976562 93.3169V258.176L162.639 340V261.813L77.113 218.78Z" fill="#4FD1C5" />
      <path d="M168.244 0L91.1299 38.7905L262.183 124.857L339.998 86.0664L168.244 0Z" fill="#4FD1C5" />
      <path d="M339.999 86.0537L262.184 124.844V296.977L339.298 258.187L339.999 86.0537Z" fill="#2C7A7B" />
      <path d="M78.5115 43.6343L1.39746 82.4248L172.45 168.491L250.265 129.701L78.5115 43.6343Z" fill="#4FD1C5" />
      <path d="M250.265 129.688L172.45 168.479V339.4L249.564 300.609L250.265 129.688Z" fill="#2C7A7B" />
    </svg>
  )
}
