import request from "@/api/client"

export function getTsisReasonings({ company, cut, products, metric }) {
  return request({
    url: "/api/v1/tsis_reasonings",
    method: "GET",
    params: {
      company,
      cut,
      products,
      metric,
    },
  })
}
