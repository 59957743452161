import { getScreenerSurveyZScoreSeries } from "@/api/screeners"
import { DataTable, DataTablePagination, DataTableProps } from "@/components/data_table"
import { FilterGroupKeys, FilterListDropdown, RadioList, SurveyDates, useFilterGroupStore } from "@/components/filters"
import { WIDGET_ASPECT_RATIO, Widget, WidgetPropsBase } from "@/components/widget"
import { WithAspectRatio } from "@/components/with_aspect_ratio"
import { useDatatable } from "@/hooks/use_datatable"
import { useEffectExceptOnMount } from "@/hooks/use_effect_except_on_mount"
import { useDefaultFilter, useFilter } from "@/hooks/use_filter"
import { useSurveyDates } from "@/hooks/use_survey_dates"
import { DEFAULT_PAGE_SIZE, cn } from "@/lib/utils"
import { Accordion, AccordionContent, AccordionHeader, AccordionItem, AccordionTrigger } from "@/ui/accordion"
import { Button } from "@/ui/button"
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from "@/ui/dropdown_menu"
import { TabsList, TabsTrigger } from "@/ui/tabs"
import { DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import { useQuery } from "@tanstack/react-query"
import { ColumnDef, getCoreRowModel, getPaginationRowModel } from "@tanstack/react-table"
import React, { useRef } from "react"

export const SurveyZScoreWidget = ({ ...props }: WidgetPropsBase) => {
  return (
    <Widget componentKey={"survey_z_score"} defaultTab={DEFAULT_SCREENER_TAB} {...props}>
      <Widget.PrimaryFilters>
        <SurveyZScoreWidgetPrimaryFilters />
      </Widget.PrimaryFilters>

      <Widget.SecondaryFilters>
        <SurveyZScoreWidgetSecondaryFilters hiddenFilters={props.hiddenFilters} />

        <TabsList>
          <TabsTrigger value="single">Single</TabsTrigger>
          <TabsTrigger value="aggregate">Aggregate</TabsTrigger>
        </TabsList>
      </Widget.SecondaryFilters>

      <Widget.Body>
        <Content renderAspectRatioContainer={props.renderAspectRatioContainer} />
        {props.children}
      </Widget.Body>
    </Widget>
  )
}

const SurveyZScoreWidgetPrimaryFilters = () => {
  const sectors = useFilter("sectors", { returnType: "multiple" })

  return (
    <Accordion type="multiple" className="w-[900px] space-y-4" defaultValue={["basic"]}>
      <AccordionItem value="basic" className="rounded border overflow-hidden">
        <AccordionHeader className="px-3 bg-secondary">
          <AccordionTrigger className="text-lg font-semibold">Basic</AccordionTrigger>
        </AccordionHeader>

        <AccordionContent className="grid grid-cols-4 gap-2 p-2">
          <FilterListDropdown
            buttonClassName="max-w-none"
            className="w-full text-sm"
            name="Sector"
            text="Sector"
            fallbackText="All"
            filterGroupId="sectors"
            endpoint="/api/v1/sectors"
          />

          <FilterListDropdown
            buttonClassName="max-w-none"
            className="w-full text-sm"
            name="Companies"
            text="Companies"
            fallbackText="All"
            filterGroupId="companies"
            endpoint="/api/v1/companies"
            params={{
              sectors,
            }}
            formatter={(item) => ({
              ...item,
              subtitle: item.ticker,
            })}
          />

          <RadioList
            className="max-w-48"
            text="Metric"
            filterGroupId="metrics"
            items={METRIC_FILTER_OPTIONS}
            defaultSelection={METRIC_FILTER_OPTIONS.find((o) => o.id == "net_score")!}
          />

          <RadioList
            className="max-w-48"
            text="Public / Private"
            filterGroupId="public_private"
            items={PUBLIC_PRIVATE_OPTIONS}
            defaultSelection={PUBLIC_PRIVATE_OPTIONS.find((o) => o.id == "all")!}
          />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

const SurveyZScoreWidgetSecondaryFilters = ({ hiddenFilters }: Pick<WidgetPropsBase, "hiddenFilters">) => {
  const filters: Array<[FilterGroupKeys, React.ReactNode]> = [["survey_dates", <SurveyDates mode={SURVEY_DATE_MODE} size="sm" />]]

  const filterComponents = filters
    .filter(([key, _component]) => !hiddenFilters?.includes(key))
    .map(([key, component]) => <React.Fragment key={key}>{component}</React.Fragment>)

  return <div className="flex items-center flex-grow space-x-4">{filterComponents}</div>
}

export const METRIC_FILTER_OPTIONS = [
  { name: "Adoption %", id: "adoption_percentage" },
  { name: "Flat %", id: "flat_percentage" },
  { name: "Increase %", id: "increase_percentage" },
  { name: "Net Score", id: "net_score" },
  { name: "Pervasion", id: "pervasion_of_survey" },
  { name: "Replacing %", id: "replacing_percentage" },
]

export const PUBLIC_PRIVATE_OPTIONS = [
  { name: "All", id: "all" },
  { name: "Public", id: "public" },
  { name: "Private", id: "private" },
]

const DEFAULT_SCREENER_TAB = "single"
const SURVEY_DATE_MODE = "single"

function Content(props: Pick<WidgetPropsBase, "renderAspectRatioContainer">) {
  const { renderAspectRatioContainer } = props
  const datatableFilterGroup = useFilterGroupStore((s) => s.datatable)
  const displayFilterGroup = useFilterGroupStore((s) => s.display)
  const { value: surveys, isLoading: surveyIsLoading } = useSurveyDates(SURVEY_DATE_MODE)
  const companies = useFilter("companies", { returnType: "multiple" })
  const sectors = useFilter("sectors", { returnType: "multiple" })
  const { value: metric } = useDefaultFilter("metrics", {
    returnType: "single",
    queryFn: () => [{ id: "net_score", name: "Net Score" }],
  })

  const tickerType = useFilter("public_private", { returnType: "single" })
  const currentTab = (displayFilterGroup?.selectedItems.find((item) => item.id === "selected-tab")?.name as string) || DEFAULT_SCREENER_TAB
  const currentTabKey = useRef(currentTab)
  const table = useDatatable<ScreenerMetric>({
    columns,
    data: [],
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id.toString(),
    getPaginationRowModel: getPaginationRowModel(),
    enableColumnPinning: true,
    enableSorting: true,
    manualPagination: true,
    manualSorting: true,
    defaultColumn: {
      size: 200,
    },
    initialState: datatableFilterGroup?.data || {
      columnOrder: columns.map((c) => c.id!),
      columnPinning: {
        left: ["ticker", "company", "product", "sector"],
      },
      pagination: { pageIndex: 0, pageSize: DEFAULT_PAGE_SIZE },
    },
  })

  const page = table.getState().pagination?.pageIndex + 1
  const limit = table.getState().pagination?.pageSize
  const sort = datatableFilterGroup?.data?.sorting

  const widgetEnabled = !!surveys.length && !surveyIsLoading

  const { isLoading, data: queryData } = useQuery({
    queryKey: [
      "screener_survey_zscore_tabular",
      {
        currentTab: currentTabKey.current,
        companies,
        sectors,
        surveys,
        tickerType,
        metric,
        page,
        limit,
        sort,
      },
    ],
    queryFn: () => {
      return getScreenerSurveyZScoreSeries({
        aggregate: currentTab === "aggregate",
        companies,
        sectors,
        surveys,
        ticker_type: tickerType,
        metric,
        page,
        limit,
        sort,
      })
    },
    placeholderData: (prevData, query: any) => {
      return query?.queryKey?.[1]?.currentTab === currentTab ? prevData : undefined
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: widgetEnabled,
  })

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (active && over && active.id !== over.id) {
      const columnOrder = table.getState().columnOrder

      const oldIndex = columnOrder.indexOf(active.id as string)
      const newIndex = columnOrder.indexOf(over.id as string)

      table.setColumnOrder(arrayMove(columnOrder, oldIndex, newIndex))
    }
  }

  useEffectExceptOnMount(() => {
    const isViewingSingle = currentTab === "single"
    const columnVisibility = { company: !isViewingSingle, product: isViewingSingle, sector: isViewingSingle }
    currentTabKey.current = currentTab
    table.setState((prev) => ({
      ...prev,
      columnVisibility: { ...prev.columnVisibility, ...columnVisibility },
      pagination: { ...prev.pagination, pageIndex: 0 },
    }))
  }, [currentTab])

  const data = queryData?.data || []
  const total = queryData?.total
  table.setOptions((prev) => ({ ...prev, data, rowCount: total }))

  if ((data?.length === 0 || !widgetEnabled) && !isLoading) return <Widget.Empty />

  return (
    <div className="relative flex-1">
      <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
        <ScreenerTable table={table} loading={isLoading} handleDragEnd={handleDragEnd} enableDragAndDropHeader />
      </WithAspectRatio>
    </div>
  )
}

interface ScreenerTableProps<TData> extends DataTableProps<TData> {}

function ScreenerTable<TData>({ table, ...props }: ScreenerTableProps<TData>) {
  return (
    <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-col space-y-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="ml-auto">
            Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {table
            .getAllColumns()
            .filter((column) => column.getCanHide())
            .map((column) => (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
                onSelect={(e) => e.preventDefault()}
              >
                {column.columnDef.header as string}
              </DropdownMenuCheckboxItem>
            ))}
        </DropdownMenuContent>
      </DropdownMenu>

      <DataTable table={table} {...props} className={cn("table-fixed", props.className)} />
      <DataTablePagination table={table} />
    </div>
  )
}

const getCellColor = (value?: number) => {
  if (!value) return "bg-white text-black"

  if (value >= 30) return "bg-green-300 text-green-700"
  if (value >= 20) return "bg-green-200 text-green-700"
  if (value >= 10) return "bg-green-100 text-green-700"
  if (value > 0) return "bg-green-50 text-green-700"
  if (value === 0) return "bg-white text-black"
  if (value >= -25) return "bg-red-50 text-red-700"
  if (value >= -50) return "bg-red-100 text-red-700"
  if (value >= -75) return "bg-red-200 text-red-700"
  return "bg-red-300 text-red-700"
}

const percentageFormatter = (value?: number, opts?: Intl.NumberFormatOptions) => {
  return value ? new Intl.NumberFormat("en-US", { style: "percent", ...opts }).format(value / 100) : "-"
}
const numberFormatter = (value?: number, opts?: Intl.NumberFormatOptions) =>
  value ? new Intl.NumberFormat("en-US", opts).format(value) : "-"

const columns: ColumnDef<ScreenerMetric>[] = [
  {
    id: "ticker",
    accessorKey: "company.ticker",
    header: "Ticker",
    enableHiding: false,
    size: 100,
    meta: {
      cellProps: {
        className: "truncate bg-slate-50",
      },
    },
  },
  {
    id: "company",
    accessorKey: "company.name",
    header: "Company",
    enableHiding: false,
    size: 150,
    meta: {
      cellProps: {
        className: "truncate bg-slate-50",
      },
    },
  },
  {
    id: "product",
    accessorFn: (row) => row.product?.name,
    header: "Product",
    enableHiding: false,
    size: 150,
    meta: {
      cellProps: {
        className: "truncate bg-slate-50",
      },
    },
  },
  {
    id: "citations",
    accessorKey: "citations",
    header: "Citations",
    size: 150,
    meta: {
      cellProps: {
        className: "text-center",
      },
    },
  },
  {
    id: "metric_value",
    accessorKey: "metric_value",
    header: "Metric Value",
    size: 170,
    cell: (context) => percentageFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "metric_delta_sos",
    accessorKey: "metric_delta_sos",
    header: "Q/Q Change",
    size: 170,
    cell: (context) =>
      percentageFormatter(context.getValue<number>(), {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "metric_delta_yoy",
    accessorKey: "metric_delta_yoy",
    header: "Y/Y Change",
    size: 170,
    cell: (context) => numberFormatter(context.getValue<number>()),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "metric_zscore_qoq",
    accessorKey: "metric_zscore_qoq",
    header: "Q/Q Z-Score",
    size: 170,
    cell: (context) =>
      numberFormatter(context.getValue<number>(), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
  {
    id: "metric_zscore_yoy",
    accessorKey: "metric_zscore_yoy",
    header: "Y/Y Z-Score",
    size: 170,
    cell: (context) =>
      numberFormatter(context.getValue<number>(), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    meta: {
      getCellProps: (context) => {
        return {
          className: `text-center ${getCellColor(context.getValue<number>())}`,
        }
      },
    },
  },
]

type ScreenerMetric = {
  id: number
  company: {
    name: string
    ticker: string
  }
  product: {
    name: string
  }
  sector: {
    name: string
  }
  adoption_percentage: number
  adoption_percentage_delta_yoy: number
  adoption_percentage_delta_sos: number
  increase_percentage: number
  increase_percentage_delta_yoy: number
  increase_percentage_delta_sos: number
  flat_percentage: number
  flat_percentage_delta_yoy: number
  flat_percentage_delta_sos: number
  decrease_percentage: number
  decrease_percentage_delta_yoy: number
  decrease_percentage_delta_sos: number
  replacing_percentage: number
  replacing_percentage_delta_yoy: number
  replacing_percentage_delta_sos: number
  citations: number
  citations_yoy: number
  citations_sos: number
  net_score: number
  net_score_delta_yoy: number
  net_score_delta_sos: number
  pervasion: number
  pervasion_delta_yoy: number
  pervasion_delta_sos: number
}
