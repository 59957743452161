import { Controller } from "@hotwired/stimulus"
import React from "react"
import ReactDOM from "react-dom/client"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { Toaster } from "@/ui/sonner"
import components from "../components"
import views from "../views"

const queryClient = new QueryClient()

export default class extends Controller {
  static values = {
    name: String,
    props: { type: Object, default: {} },
    type: { type: String, default: "components" },
  }

  connect() {
    // This prevents ReactDOM from mounting twice because connect gets call
    // on turbo's preload of the page.
    if (this.isPreview) return

    const Component =
      this.typeValue === "component" ? components[this.nameValue]
      : this.typeValue === "view" ? views[this.nameValue]
      : null

    if (!Component) {
      throw new Error(`Unable to find component or view with name '${this.nameValue}'. Double check the 'name' and 'type' values.`)
    }

    this.root = ReactDOM.createRoot(this.element)
    this.root.render(
      <QueryClientProvider client={queryClient}>
        <Component {...this.propsValue} />
        {this.typeValue === "view" && <Toaster />}
      </QueryClientProvider>
    )
  }

  disconnect() {
    if (!this.root) return
    this.root.unmount()
  }

  get isPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview")
  }
}
