import { FilterGroupStoreProvider, FilterList, useFilterGroupStore } from "@/components/filters"
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogFooter } from "@/ui/alert_dialog"
import { buttonVariants } from "@/ui/button"
import React from "react"

interface SelectCompanyDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
}

export function SelectCompanyDialog(props: SelectCompanyDialogProps) {
  const { open, onOpenChange } = props

  return (
    <FilterGroupStoreProvider>
      <SelectCompanyDialogContent open={open} onOpenChange={onOpenChange} />
    </FilterGroupStoreProvider>
  )
}

function SelectCompanyDialogContent(props: SelectCompanyDialogProps) {
  const { open, onOpenChange } = props
  const companies = useFilterGroupStore((s) => s.companies)
  const selectedCompany = companies?.selectedItems?.[0]

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent className="w-fit">
        <div className="mt-2">
          <FilterList name="Select a company" endpoint="/api/v1/companies" filterGroupId="companies" enableMultiRowSelection={false} />
        </div>

        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            disabled={!selectedCompany?.name}
            className={buttonVariants({ variant: "default" })}
            onClick={() => {
              if (selectedCompany?.name) {
                window.location.href = `/analyze/company?name=${encodeURIComponent(selectedCompany.name)}`
              }
            }}
          >
            Analyze
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
