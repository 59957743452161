import { FilterListItem, useFilterGroupStore } from "@/components/filters"
import { getFilterSelectionText } from "@/lib/utils"
import { Button } from "@/ui/button"
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from "@/ui/dropdown_menu"
import { DropdownMenuCheckboxItemProps } from "@radix-ui/react-dropdown-menu"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import React from "react"

type Checked = DropdownMenuCheckboxItemProps["checked"]

interface MetricListProps {
  items?: FilterListItem[]
  defaultMetrics: FilterListItem[]
}

export function MetricList(props: MetricListProps) {
  const { items, defaultMetrics } = props
  const metrics = useFilterGroupStore((s) => s.metrics)
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const selectedItems = getSelectedMetrics(metrics?.selectedItems, defaultMetrics)
  const selectedValueText = getFilterSelectionText(selectedItems, { fallbackText: "All" })

  const handleChange = (item: FilterListItem, checked: Checked) => {
    const newSelectedItems = checked ? [...selectedItems, item] : selectedItems.filter((i) => i.id !== item.id)

    updateFilterGroups({
      metrics: {
        name: "Metric",
        selectedItems: newSelectedItems,
      },
    })
  }

  if (!items) return null

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm" className="bg-slate-100 border-transparent">
          <span className="truncate">
            Metrics: <span className="text-sky-700 font-medium">{selectedValueText}</span>
          </span>
          <ChevronDownIcon className="w-4 h-4 ml-2" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        {items.map((item) => (
          <DropdownMenuCheckboxItem
            key={item.id}
            checked={selectedItems.some((i) => i.id === item.id)}
            onCheckedChange={(checked) => handleChange(item, checked)}
            onSelect={(e) => e.preventDefault()}
          >
            {item.name}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const getSelectedMetrics = (selectedItems: FilterListItem[] = [], defaultMetrics: FilterListItem[]) => {
  return selectedItems.length ? selectedItems : defaultMetrics
}
