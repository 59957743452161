import request from "@/api/client"

export function getTsisRespondentDemographics({ companies, metrics, products, sectors, survey }) {
  return request({
    url: "/api/v1/tsis_respondent_demographics",
    method: "GET",
    params: {
      companies,
      metrics,
      products,
      sectors,
      survey,
    },
  })
}
