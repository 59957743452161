import React, { createContext, useMemo, useState, useEffect, useCallback, useRef } from "react"
import RGL, { WidthProvider } from "react-grid-layout"
import { debounce } from "lodash"
import { WIDGETS, WidgetTypes } from "@/components/widgets"
import { updateDashboardView } from "@/api/dashboard_views"
import { deleteViewItem, updateViewItem } from "@/api/view_items"
import { toast } from "sonner"
import { MenuAction } from "@/components/widget"

const ReactGridLayout = WidthProvider(RGL)
const GRID_COLS = 9
const GRID_MARGIN = 8
interface GridContextValue {
  addItem: (data: LayoutItemData) => Promise<boolean>
  items: LayoutItem[]
}
export const GridContext = createContext<GridContextValue>({} as GridContextValue)

type LayoutItemData = { widgetType: string; filters?: any }

interface LayoutItem extends RGL.Layout {
  data?: LayoutItemData
}

interface GridProps {
  items: any[]
  dashboardId: string
  dashboardViewId: string
  isDraggable: boolean
  isResizable: boolean
}

const convertToLayoutItem = (viewItem: any): LayoutItem => {
  const availableResizeHandles: RGL.Layout["resizeHandles"] = ["sw", "se"]

  return {
    i: viewItem.id,
    x: viewItem.x,
    y: viewItem.y,
    w: viewItem.w,
    h: viewItem.h,
    minW: 2,
    minH: 2,
    resizeHandles: availableResizeHandles,
    data: {
      widgetType: viewItem.widget_type,
      ...viewItem.widget_settings,
    },
  }
}

const Grid = (props: GridProps) => {
  const [items, setItems] = useState<RGL.Layout[]>(props.items.map(convertToLayoutItem))
  const [gridWidth, setGridWidth] = useState<number | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const debouncedSaveLayout = useCallback(debounce(updateDashboardView, 1000), [])

  const handleLayoutChange = (layout: RGL.Layout[]) => {
    debouncedSaveLayout(props.dashboardId, props.dashboardViewId, {
      view_items_attributes: layout.map((item) => ({
        id: item.i,
        x: item.x,
        y: item.y,
        w: item.w,
        h: item.h,
      })),
    })?.then(() => toast.success("Your changes to the view have been saved"))
  }

  const handleAppliedFilterChange = (viewItemId: string, appliedFilterGroups: any) => {
    updateViewItem(props.dashboardViewId, viewItemId, {
      widget_settings: {
        filters: appliedFilterGroups,
      },
    }).then(() => toast.success("Your changes to the widget have been saved"))
  }

  const onRemoveItem = async (viewItemId: string) => {
    await deleteViewItem(props.dashboardViewId, viewItemId)

    setItems(items.filter((item) => item.i !== viewItemId))

    toast.success("Removed from dashboard", {
      description: "This item was successfully deleted",
    })
  }

  const debouncedHandleAppliedFilterChange = useCallback(debounce(handleAppliedFilterChange, 500), [])

  const gridItems = useMemo(
    () =>
      items.map((el: LayoutItem) => {
        const WidgetComponent = WIDGETS[el.data?.widgetType as WidgetTypes] || null

        if (!WidgetComponent) return null

        const actions: MenuAction[] = [{ id: "addToDashboard" }]

        if (props.isDraggable) {
          actions.push({
            id: "removeFromDashboard",
            onSuccess: () => onRemoveItem(el.i),
          })
        }

        return (
          <div key={el.i} data-grid={el}>
            <WidgetComponent
              className="overflow-hidden hover:shadow-lg hover:border-blue-500 shadow-blue-600/50"
              defaultAppliedFilterGroups={el.data?.filters}
              renderAspectRatioContainer={false}
              hideDisplayFilters={!props.isDraggable}
              hidePrimaryFilters={!props.isDraggable}
              hideSecondaryFilters={!props.isDraggable}
              onAppliedFiltersChange={(appliedFilterGroups) => debouncedHandleAppliedFilterChange(el.i, appliedFilterGroups)}
              menuActions={actions}
            />
          </div>
        )
      }),
    [items]
  )

  useEffect(() => {
    if (!containerRef.current) return

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target.id === "grid-container") {
          setGridWidth(entry.contentRect.width)
        }
      }
    })

    resizeObserver.observe(containerRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <div
      id="grid-container"
      style={{
        backgroundImage: `url("data:image/svg+xml;utf8,${generateGraphBackgroundImage(gridWidth || 0)}")`,
        paddingBottom: calculateRowHeight(gridWidth || 0) + GRID_MARGIN,
      }}
      ref={containerRef}
    >
      <ReactGridLayout
        className="mt-2"
        rowHeight={gridWidth ? calculateRowHeight(gridWidth) : 200}
        cols={GRID_COLS}
        onLayoutChange={handleLayoutChange}
        containerPadding={[0, 0]}
        margin={[GRID_MARGIN, GRID_MARGIN]}
        isDraggable={props.isDraggable}
        isResizable={props.isResizable}
        draggableHandle=".drag-handle"
      >
        {gridItems}
      </ReactGridLayout>
    </div>
  )
}

export default Grid

const generateGraphBackgroundImage = (width: number) => {
  const rowHeight = calculateRowHeight(width)
  const colWidth = rowHeight
  const svgHeight = rowHeight + GRID_MARGIN
  const cols = Array.from({ length: GRID_COLS }, (_, i) => i)
  const BORDER_OFFSET = 4

  return encodeURIComponent(`
    <svg xmlns='http://www.w3.org/2000/svg' width='${width}' height='${svgHeight}'>
      ${cols.map(
        (value) =>
          `<rect
            fill='#eaf0f6'
            stroke='#dfe3eb'
            rx="4"
            x='${(colWidth + GRID_MARGIN) * value + BORDER_OFFSET}'
            y='${BORDER_OFFSET / 2}'
            width='${colWidth - BORDER_OFFSET}'
            height='${rowHeight - BORDER_OFFSET}'
          />`
      )}
    </svg>
  `)
}

const calculateRowHeight = (width: number) => {
  return (width - GRID_MARGIN * (GRID_COLS - 1)) / GRID_COLS
}
