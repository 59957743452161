import React from "react"
import { PowerBIEmbed } from "powerbi-client-react"
import { models } from "powerbi-client"

interface PowerBiProps {
  report_id: string
  access_token: string
}

const PowerBi = (props: PowerBiProps) => {
  const { report_id, access_token } = props

  const embedConfig = {
    accessToken: access_token,
    type: "report",
    id: report_id,
    embedUrl: "https://app.powerbi.com/reportEmbed",
    tokenType: models.TokenType.Embed,
    permissions: models.Permissions.Read,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      layoutType: models.LayoutType.MobilePortrait,
    },
  }

  return (
    <PowerBIEmbed
      embedConfig={embedConfig}
      eventHandlers={
        new Map([
          [
            "loaded",
            function () {
              console.log("Report loaded")
            },
          ],
          [
            "rendered",
            function () {
              console.log("Report rendered")
            },
          ],
          [
            "error",
            function (event) {
              console.log(event?.detail)
            },
          ],
        ])
      }
      cssClassName={"absolute w-full h-full"}
    />
  )
}

export default PowerBi
