import request from "./client"

export function createDashboard({ name }) {
  const url = "/api/v1/dashboards"
  return request({
    url,
    method: "POST",
    data: {
      dashboard: {
        name,
      },
    },
  })
}

export function updateDashboard({ id, name }) {
  const url = `/api/v1/dashboards/${id}`
  return request({
    url,
    method: "PATCH",
    data: {
      dashboard: {
        name,
      },
    },
  })
}

export function duplicateDashboard(params) {
  const { id } = params
  const url = `/api/v1/dashboards/${id}/duplicate`

  return request({
    url,
    method: "POST",
  })
}

export function leaveDashboard(params) {
  const { id } = params
  const url = `/api/v1/dashboards/${id}/leave`

  return request({
    url,
    method: "POST",
  })
}

export function transferDashboard(params) {
  const { id, userId } = params
  const url = `/api/v1/dashboards/${id}/transfer`

  return request({
    url,
    method: "POST",
    data: {
      user_id: userId,
    },
  })
}

export function deleteDashboard(params) {
  const { id } = params
  const url = `/api/v1/dashboards/${id}`

  return request({
    url,
    method: "DELETE",
  })
}

export function getDashboards(params) {
  const url = "/api/v1/dashboards"
  return request({
    url,
    method: "GET",
    params,
  })
}

export function getDashboardsTabular(params) {
  const url = "/api/v1/dashboards/tabular"
  return request({
    url,
    method: "POST",
    params,
  })
}
