"use client"

import * as React from "react"
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons"

import { cn } from "@/lib/utils"
import { Button } from "@/ui/button"
import { Command, CommandCreateItem, CommandEmpty, CommandInput, CommandItem, CommandList } from "@/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/ui/popover"
import { useDebouncedCallback } from "use-debounce"

interface ComboboxProps extends React.ComponentPropsWithoutRef<typeof Command> {
  options: { label: string; value: string }[] | undefined
  disabled?: boolean
  text?: string
  placeholder?: string
  enableCreate?: boolean
  onCreate?: (value: string) => void
  onSearchChange?: (value: string) => void
}

export function Combobox(props: ComboboxProps) {
  const {
    enableCreate = false,
    children,
    disabled,
    options,
    placeholder,
    value,
    text,
    onCreate,
    onValueChange,
    onSearchChange,
    ...commandProps
  } = props
  const [open, setOpen] = React.useState(false)

  const handleCreate = (value: string) => {
    onCreate?.(value)
    setOpen(false)
  }

  const handleOnValueChange = (value: string) => {
    onValueChange?.(value)
    setOpen(false)
  }

  const handleOnSearchChange = useDebouncedCallback((value: string) => {
    onSearchChange?.(value)
  }, 500)

  const shouldFilter = onSearchChange ? false : true

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          aria-disabled={disabled}
          disabled={disabled}
          className={cn("w-full justify-between px-3", !value && "text-muted-foreground")}
        >
          {value ? options?.find((option) => option.value === value)?.label ?? text : text}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80 p-0" onWheel={(e) => e.stopPropagation()} onTouchMove={(e) => e.stopPropagation()}>
        <Command {...commandProps} defaultValue={value} shouldFilter={shouldFilter}>
          <CommandInput placeholder={placeholder} className="h-9" onValueChange={handleOnSearchChange} />
          <CommandList>
            {enableCreate ?
              <CommandCreateItem onSelect={handleCreate} />
            : <CommandEmpty>No results found.</CommandEmpty>}

            {options?.map((option) => (
              <CommandItem key={option.value} keywords={[option.label]} value={option.value} onSelect={handleOnValueChange}>
                {option.label}

                <CheckIcon className={cn("ml-auto h-4 w-4", option.value === value ? "opacity-100" : "opacity-0")} />
              </CommandItem>
            ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
