import {
  deleteDashboard,
  duplicateDashboard,
  getDashboardsTabular,
  leaveDashboard,
  transferDashboard,
  updateDashboard,
} from "@/api/dashboards"
import { DataTable, DataTablePagination } from "@/components/data_table"
import { CreateDashboardDialog } from "@/components/dialogs/create_dashboard"
import { DeleteConfirmationDialog } from "@/components/dialogs/delete_confirmation"
import { PendingConfirmationDialog } from "@/components/dialogs/pending_confirmation"
import { RenameResourceDialog } from "@/components/dialogs/rename_resource"
import { ShareDashboardDialog } from "@/components/dialogs/share_dashboard"
import { TransferDashboardDialog, TransferDashboardDialogProps } from "@/components/dialogs/transfer_dashboard"
import { Button } from "@/ui/button"
import { Card, CardContent, CardHeader } from "@/ui/card"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/ui/dropdown_menu"
import { Input } from "@/ui/input"
import { Spinner } from "@/ui/spinner"
import { DotsVerticalIcon, PlusIcon } from "@radix-ui/react-icons"
import { Tabs, TabsList, TabsTrigger } from "@radix-ui/react-tabs"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import {
  ColumnDef,
  functionalUpdate,
  getCoreRowModel,
  getPaginationRowModel,
  OnChangeFn,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table"
import axios from "axios"
import { format, parseISO } from "date-fns"
import queryString from "query-string"
import React, { createContext, useContext, useEffect, useState } from "react"
import { FiLayout } from "react-icons/fi"
import { toast } from "sonner"
import { useDebouncedCallback } from "use-debounce"
import { Dashboard } from "./types"

interface DashboardContextProps {
  selectedDashboard: Dashboard | null
  setSelectedDashboard: React.Dispatch<React.SetStateAction<Dashboard | null>>
  isDialogOpen: boolean
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  dialogTrigger: string
  setDialogTrigger: React.Dispatch<React.SetStateAction<string>>
  handleDialogOpen: HandleDialogOpenFn
}

const DashboardContext = createContext<DashboardContextProps>({} as DashboardContextProps)

type DashboardParams = {
  query: string
  type: "all" | "owned" | "shared"
  sort_by: "updated_at" | "owner" | "name"
  sort_dir: "asc" | "desc"
  page: number
  limit: number
}

type HandleDialogOpenFn = (trigger: string) => void

const TABS = {
  all: "All",
  owned: "My Dashboards",
  shared: "Shared Dashboards",
} as const

export default function DashboardIndex(props: any) {
  const params = queryString.parse(window.location.search)
  const searchInputRef = React.useRef<HTMLInputElement>(null)
  const [searching, setSearching] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [dialogTrigger, setDialogTrigger] = useState("")
  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard | null>(null)
  const [queryParams, setQueryParams] = useState<DashboardParams>({
    query: (params.query as string) || "",
    type: (params.type as DashboardParams["type"]) || "all",
    sort_by: (params.sort_by as DashboardParams["sort_by"]) || "updated_at",
    sort_dir: (params.sort_dir as DashboardParams["sort_dir"]) || "desc",
    page: parseInt(params.page as string) || 1,
    limit: parseInt(params.limit as string) || 25,
  })

  const [sorting, setSorting] = useState<SortingState>(() => {
    const allowedSortBy = columns.filter((column) => column.enableSorting).map((column) => column.id)
    const sortBy = allowedSortBy.includes(queryParams.sort_by) ? queryParams.sort_by : "updated_at"
    const sortDir = queryParams.sort_dir === "asc" ? "asc" : "desc"

    return [{ id: sortBy, desc: sortDir === "desc" }]
  })

  const [pagination, setPagination] = useState<PaginationState>(() => ({
    pageIndex: queryParams.page - 1,
    pageSize: queryParams.limit,
  }))

  const handleSortingChange: OnChangeFn<SortingState> = (sortChangeUpdater) => {
    const newSortingState = functionalUpdate(sortChangeUpdater, sorting)
    const sortBy = newSortingState.length > 0 ? newSortingState[0]!.id : "updated_at"
    const sortDir = newSortingState.length > 0 && newSortingState[0]!.desc === false ? "asc" : "desc"

    setSorting(newSortingState)
    setQueryParams({
      ...queryParams,
      sort_by: sortBy as DashboardParams["sort_by"],
      sort_dir: sortDir as DashboardParams["sort_dir"],
    })
  }

  const handlePaginationChange: OnChangeFn<PaginationState> = (paginationUpdater) => {
    const newPaginationState = functionalUpdate(paginationUpdater, pagination)

    setPagination(newPaginationState)
    setQueryParams({ ...queryParams, page: newPaginationState.pageIndex + 1, limit: newPaginationState.pageSize })
  }

  const queryClient = useQueryClient()

  const { isLoading, data: queryData } = useQuery<{ data: Dashboard[]; total: number }>({
    queryKey: ["dashboards", queryParams],
    queryFn: () => getDashboardsTabular(queryParams),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const table = useReactTable<Dashboard>({
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    columns,
    data: queryData?.data || [],
    rowCount: queryData?.total || 0,
    state: {
      pagination,
      sorting,
    },
    onSortingChange: handleSortingChange,
    onPaginationChange: handlePaginationChange,
  })

  const getCurrentTab = () => {
    if (!queryParams.type) return "all"

    return queryParams.type
  }

  const handleDialogOpen: HandleDialogOpenFn = (trigger) => {
    setIsDialogOpen(true)
    setDialogTrigger(trigger)
  }

  const handleTabChange = (tab: string) => {
    if (searchInputRef.current) searchInputRef.current.value = ""

    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
    setQueryParams({ ...queryParams, type: tab as DashboardParams["type"], page: 1 })
  }

  const handleError = (title: string, error: unknown) => {
    const description = (axios.isAxiosError(error) && error.response?.data?.errors?.[0]) || "Uh oh, something went wrong!"

    toast.error(title, { description })
  }

  const handleRenameDashboard = async (name: string) => {
    if (!selectedDashboard?.id) return

    try {
      await updateDashboard({ id: selectedDashboard.id, name })

      queryClient.invalidateQueries({ queryKey: ["dashboards", queryParams] })

      toast.success("Dashboard successfully renamed")
    } catch (error) {
      handleError("Error renaming dashboard", error)
    }
  }

  const handleDuplicateDashboard = async () => {
    if (!selectedDashboard?.id) return

    try {
      await duplicateDashboard({ id: selectedDashboard.id })

      queryClient.invalidateQueries({ queryKey: ["dashboards", queryParams] })

      toast.success("Dashboard successfully duplicated")
    } catch (error) {
      handleError("Error duplicating dashboard", error)
    }
  }

  const handleTransferDashboard: TransferDashboardDialogProps["onConfirm"] = async (data) => {
    if (!selectedDashboard?.id) return

    try {
      await transferDashboard({ id: selectedDashboard.id, userId: data.user })

      setIsDialogOpen(false)
      setSelectedDashboard(null)

      queryClient.invalidateQueries({ queryKey: ["dashboards", queryParams] })

      toast.success("Dashboard ownership successfully transferred")
    } catch (error) {
      handleError("Error transferring ownership", error)
    }
  }

  const handleDeleteDashboard = async () => {
    if (!selectedDashboard?.id) return

    try {
      await deleteDashboard({ id: selectedDashboard.id })

      queryClient.invalidateQueries({ queryKey: ["dashboards", queryParams] })

      toast.success("Dashboard successfully deleted")
    } catch (error) {
      handleError("Error deleting dashboard", error)
    }
  }

  const handleLeaveDashboard = async () => {
    if (!selectedDashboard?.id) return

    try {
      await leaveDashboard({ id: selectedDashboard.id })

      queryClient.invalidateQueries({ queryKey: ["dashboards", queryParams] })

      toast.success("You have left the dashboard")
    } catch (error) {
      handleError("Error leaving dashboard", error)
    }
  }

  const handleDebouncedSearch = useDebouncedCallback((value: string) => {
    setSearching(false)
    setQueryParams({ ...queryParams, query: value })
  }, 1000)

  useEffect(() => {
    const url = queryString.stringifyUrl({
      url: "/dashboards",
      query: {
        type: queryParams.type,
        query: queryParams.query,
        sort_by: queryParams.sort_by,
        sort_dir: queryParams.sort_dir,
        page: queryParams.page,
        limit: queryParams.limit,
      },
    })

    Turbo.navigator.history.replace({ href: url })
  }, [queryParams])

  return (
    <DashboardContext.Provider
      value={{
        selectedDashboard,
        setSelectedDashboard,
        isDialogOpen,
        setIsDialogOpen,
        dialogTrigger,
        setDialogTrigger,
        handleDialogOpen,
      }}
    >
      <Card>
        <CardHeader className="space-y-3">
          <div className="flex items-center justify-between">
            <Tabs value={getCurrentTab()} onValueChange={handleTabChange}>
              <TabsList className="flex flex-col items-start gap-2 sm:flex-row">
                {Object.entries(TABS).map(([key, value]) => (
                  <TabsTrigger key={key} value={key} asChild>
                    <Button
                      variant="secondary"
                      size="sm"
                      className="border border-transparent font-medium py-1.5 data-[state=active]:border-primary data-[state=active]:text-primary"
                    >
                      {value}
                    </Button>
                  </TabsTrigger>
                ))}
              </TabsList>
            </Tabs>
            <Input
              type="text"
              placeholder="Search dashboards"
              className="w-60 self-start"
              defaultValue={queryParams.query}
              icon={searching ? <Spinner className="w-4 h-4" /> : null}
              onChange={(e) => {
                setSearching(true)
                handleDebouncedSearch(e.target.value)
              }}
              ref={searchInputRef}
            />
          </div>
          <div className="flex justify-end space-x-4">
            <Button onClick={() => handleDialogOpen("CREATE_DASHBOARD")} size="sm">
              <PlusIcon className="mr-2 h-4 w-4" /> Create Dashboard
            </Button>
          </div>
        </CardHeader>
        <CardContent className="flex flex-col min-h-[500px]">
          <DataTable table={table} loading={isLoading} className="table-fixed" />
          <DataTablePagination table={table} />
        </CardContent>
      </Card>

      <CreateDashboardDialog open={isDialogOpen && dialogTrigger === "CREATE_DASHBOARD"} onOpenChange={setIsDialogOpen} />
      <ShareDashboardDialog
        dashboardId={selectedDashboard?.id}
        open={isDialogOpen && dialogTrigger === "SHARE_DASHBOARD"}
        onOpenChange={(value) => {
          setIsDialogOpen(value)

          if (!value) setSelectedDashboard(null)
        }}
      />
      <TransferDashboardDialog
        dashboard={selectedDashboard}
        open={isDialogOpen && dialogTrigger === "TRANSFER_DASHBOARD"}
        onOpenChange={setIsDialogOpen}
        onConfirm={handleTransferDashboard}
      />
      <RenameResourceDialog
        open={isDialogOpen && dialogTrigger === "RENAME_DASHBOARD"}
        onConfirm={handleRenameDashboard}
        onOpenChange={setIsDialogOpen}
        defaultValue={selectedDashboard?.name}
        onCancel={() => setSelectedDashboard(null)}
      />
      <PendingConfirmationDialog
        open={isDialogOpen && dialogTrigger === "DUPLICATE_DASHBOARD"}
        onConfirm={handleDuplicateDashboard}
        onOpenChange={setIsDialogOpen}
        message="This will duplicate the dashboard and all its associated widgets. Users with access to the original dashboard will not have access to the duplicated dashboard."
        onCancel={() => setSelectedDashboard(null)}
      />
      <DeleteConfirmationDialog
        open={isDialogOpen && dialogTrigger === "DELETE_DASHBOARD"}
        onConfirm={handleDeleteDashboard}
        onOpenChange={setIsDialogOpen}
        message="This action cannot be undone. This will permanently delete this dashboard and all its associated widgets."
        onCancel={() => setSelectedDashboard(null)}
      />
      <DeleteConfirmationDialog
        open={isDialogOpen && dialogTrigger === "LEAVE_DASHBOARD"}
        onConfirm={handleLeaveDashboard}
        onOpenChange={setIsDialogOpen}
        confirmButtonText="Leave"
        message="This action cannot be undone. You will no longer have access to this dashboard and will need to be re-invited to access it again."
        onCancel={() => setSelectedDashboard(null)}
      />
    </DashboardContext.Provider>
  )
}

const columns: ColumnDef<Dashboard>[] = [
  {
    id: "name",
    accessorKey: "name",
    header: "Name",
    enableSorting: true,
    cell: ({ row }) => (
      <a href={`/dashboards/${row.original.id}`} className="flex items-center space-x-2 group-hover:underline">
        <FiLayout className="text-primary w-4 h-4" />
        <span>{row.original.name}</span>
      </a>
    ),
  },
  {
    id: "updated_at",
    accessorKey: "updated_at",
    header: "Modified",
    enableSorting: true,
    cell: ({ row }) => (
      <a href={`/dashboards/${row.original.id}`} className="flex items-center space-x-2">
        <span>{format(parseISO(row.original.updated_at), "eee, MMM d, yyyy")}</span>
      </a>
    ),
    enableHiding: false,
    meta: {
      getCellProps(context) {
        return {
          title: format(parseISO(context.row.original.updated_at), "eee, MMM d, yyyy h:mm a"),
        }
      },
    },
  },
  {
    id: "owner",
    accessorKey: "owner",
    header: "Owner",
    enableSorting: true,
    cell: ({ row }) => (
      <a href={`/dashboards/${row.original.id}`} className="flex items-center space-x-2">
        <span>{row.original.owner}</span>
      </a>
    ),
  },
  {
    id: "actions",
    header: "Actions",
    enableSorting: false,
    cell: ({ row }) => {
      const dashboard = row.original as Dashboard

      const { handleDialogOpen, setSelectedDashboard } = useContext(DashboardContext)

      const handleDialogOpenAction = (trigger: string) => {
        setSelectedDashboard(dashboard)
        handleDialogOpen?.(trigger)
      }

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon" className="outline-none">
              <DotsVerticalIcon className="w-4 h-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={() => handleDialogOpenAction("DUPLICATE_DASHBOARD")}>Duplicate</DropdownMenuItem>

            {dashboard.is_owner ?
              <>
                <DropdownMenuItem onClick={() => handleDialogOpenAction("RENAME_DASHBOARD")}>Rename</DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleDialogOpenAction("SHARE_DASHBOARD")}>Share</DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleDialogOpenAction("TRANSFER_DASHBOARD")}>Transfer Ownership</DropdownMenuItem>

                <DropdownMenuItem className="text-red-500" onClick={() => handleDialogOpenAction("DELETE_DASHBOARD")}>
                  Delete
                </DropdownMenuItem>
              </>
            : <DropdownMenuItem onClick={() => handleDialogOpenAction("LEAVE_DASHBOARD")}>Leave</DropdownMenuItem>}
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
    size: 25,
    meta: {
      cellProps: {
        className: "text-center",
      },
    },
  },
]
