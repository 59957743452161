import request from "@/api/client"

export function getTsisProductMetrics({ citations, company, cut, cut_groups = [], sector, products, surveys }) {
  return request({
    url: "/api/v1/tsis_product_metrics",
    method: "GET",
    params: {
      citations,
      company,
      cut,
      cut_groups,
      sector,
      products,
      surveys,
    },
  })
}
