import request from "@/api/client"

export function getTsisSectorMetrics({ citations, cut, cut_groups = [], sectors, surveys }) {
  return request({
    url: "/api/v1/tsis_sector_metrics",
    method: "GET",
    params: {
      citations,
      cut,
      cut_groups,
      sectors,
      surveys,
    },
  })
}
