import { FilterGroupStoreProvider, FilterList, useFilterGroupStore } from "@/components/filters"
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogFooter } from "@/ui/alert_dialog"
import { buttonVariants } from "@/ui/button"
import React from "react"

interface SelectSectorDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
}

export function SelectSectorDialog(props: SelectSectorDialogProps) {
  const { open, onOpenChange } = props

  return (
    <FilterGroupStoreProvider>
      <SelectSectorDialogContent open={open} onOpenChange={onOpenChange} />
    </FilterGroupStoreProvider>
  )
}

function SelectSectorDialogContent(props: SelectSectorDialogProps) {
  const { open, onOpenChange } = props
  const sectors = useFilterGroupStore((s) => s.sectors)
  const selectedSector = sectors?.selectedItems?.[0]

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent className="w-fit">
        <div className="mt-2">
          <FilterList name="Select a sector" endpoint="/api/v1/sectors" filterGroupId="sectors" enableMultiRowSelection={false} />
        </div>

        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            disabled={!selectedSector?.name}
            className={buttonVariants({ variant: "default" })}
            onClick={() => {
              if (selectedSector?.name) {
                window.location.href = `/analyze/sector?name=${encodeURIComponent(selectedSector.name)}`
              }
            }}
          >
            Analyze
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
