import request from "@/api/client"
import { DataList } from "@/components/data_list"
import { DataSearch } from "@/components/data_table"
import { FilterGroup, FilterGroupKeys, FilterListItem, getAllFilterGroupsFromStore, useFilterGroupStore } from "@/components/filters"
import { cn, getFilterSelectionText } from "@/lib/utils"
import { Badge } from "@/ui/badge"
import { Button, ButtonProps } from "@/ui/button"
import { Checkbox, CheckboxRadio } from "@/ui/checkbox"
import { Popover, PopoverContent, PopoverTrigger } from "@/ui/popover"
import { Skeleton } from "@/ui/skeleton"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import { useQuery } from "@tanstack/react-query"
import {
  ColumnDef,
  OnChangeFn,
  RowSelectionState,
  functionalUpdate,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import React, { useState } from "react"

interface FilterProps {
  className?: string
  dependencies?: Array<FilterGroupKeys>
  enableMultiRowSelection?: boolean
  endpoint?: string
  name: string
  filterGroupId: FilterGroupKeys
  params?: Record<string, any>
  onHandleFilterChange?: (filterGroupId: FilterGroupKeys, selectedItems: FilterListItem[]) => void
  formatter?: (data: any) => any
}

export function FilterList(props: FilterProps) {
  const {
    className,
    name,
    filterGroupId,
    endpoint,
    params,
    dependencies,
    enableMultiRowSelection = true,
    onHandleFilterChange,
    formatter,
  } = props
  const filterGroups = useFilterGroupStore(getAllFilterGroupsFromStore)
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const filter = filterGroups[filterGroupId]
  const rowSelection = transformFilterGroupToRowSelection(filter)

  const { isLoading, data } = useQuery({
    queryKey: [endpoint, params],
    queryFn: () => {
      if (!endpoint) return { data: [] }
      return request({
        url: endpoint,
        method: "GET",
        params,
      })
    },
    refetchOnMount: false,
    enabled: !dependencies?.length || dependencies.every((dep) => filterGroups[dep]?.selectedItems.length),
  })

  const handleFilterChange: OnChangeFn<RowSelectionState> = (rowSelectionUpdater) => {
    const selectedRowIds = functionalUpdate(rowSelectionUpdater, transformFilterGroupToRowSelection(filter))
    const selectedItems = Object.keys(selectedRowIds).map((id) => {
      const row = table.getRow(id)
      const name = row.original.subtitle ? `${table.getRow(id).original.name} - ${row.original.subtitle}` : table.getRow(id).original.name

      return {
        id,
        name,
      }
    })

    if (onHandleFilterChange) return onHandleFilterChange(filterGroupId, selectedItems)

    updateFilterGroups({
      [filterGroupId]: {
        name,
        selectedItems,
      },
    })
  }

  const table = useReactTable({
    columns,
    data: formatter ? data?.map(formatter) : data,
    enableMultiRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => row.id.toString(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    onRowSelectionChange: handleFilterChange,
    state: {
      rowSelection,
    },
  })

  const hasMissingDependencies = !!dependencies?.length && !dependencies.every((dep) => filterGroups[dep]?.selectedItems.length)

  return (
    <div className={cn("flex flex-col space-y-2 w-[350px]", className)}>
      <div className="flex items-center">
        <h6 className="flex-grow m-0 py-0.5">{name}</h6>
        {filter?.selectedItems.length ?
          <Badge variant="outline" className="flex-end">
            {filter?.selectedItems.length} selected
          </Badge>
        : null}
      </div>
      {isLoading ?
        <>
          <Skeleton className="h-10" />
          <Skeleton className="h-[350px]" />
        </>
      : <>
          <DataSearch table={table} searchKey="name" disabled={hasMissingDependencies} />
          {hasMissingDependencies ?
            <div className="h-[350px] rounded-sm border inset">
              <div className="text-center text-dark p-8">
                Please select {dependencies.map((s) => s.replace("_", " ")).join(" or ")} to view a list of {name.toLowerCase()}.
              </div>
            </div>
          : <DataList table={table} />}
        </>
      }
    </div>
  )
}

interface FilterListDropdownProps extends FilterProps {
  buttonClassName?: string
  size?: ButtonProps["size"]
  text: string
  fallbackText?: string
}

export function FilterListDropdown({
  onHandleFilterChange,
  buttonClassName,
  size = "default",
  text,
  fallbackText,
  enableMultiRowSelection = true,
  ...props
}: FilterListDropdownProps) {
  const [open, setOpen] = useState(false)
  const filterGroup = useFilterGroupStore((s) => s[props.filterGroupId])
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const selectedValueText = getFilterSelectionText(filterGroup?.selectedItems, { fallbackText: fallbackText || "None" })

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn("border-transparent bg-slate-100 px-3 flex justify-between max-w-48", buttonClassName)}
          size={size}
        >
          <span className="truncate">
            {text}
            {": "}

            <span className="text-sky-700 font-medium">{selectedValueText}</span>
          </span>
          <ChevronDownIcon className="ml-2 h-4 w-4 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="p-2" onWheel={(e) => e.stopPropagation()} onTouchMove={(e) => e.stopPropagation()}>
        <FilterList
          {...props}
          enableMultiRowSelection={enableMultiRowSelection}
          onHandleFilterChange={(filterGroupId, items) => {
            if (onHandleFilterChange) {
              onHandleFilterChange(filterGroupId, items)
            } else {
              updateFilterGroups({
                [filterGroupId]: {
                  name: props.name,
                  selectedItems: items,
                },
              })
            }

            if (enableMultiRowSelection) return

            setOpen(false)
          }}
        />
      </PopoverContent>
    </Popover>
  )
}

type TableData = { subtitle?: string } & FilterListItem

const columns: ColumnDef<TableData>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) =>
      row.getCanMultiSelect() ?
        <Checkbox checked={row.getIsSelected()} aria-label="Select row" />
      : <CheckboxRadio checked={row.getIsSelected()} aria-label="Select row" />,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "name",
    header: "Select All",
    enableResizing: false,
    cell: ({ row }) => (
      <div className="min-w-0 flex-auto">
        <p className="truncate text-sm font-semibold leading-6 text-gray-900 m-0">{row.getValue("name")}</p>

        {row.original.subtitle ?
          <p className="truncate text-xs leading-5 text-gray-500 m-0">{row.original.subtitle}</p>
        : null}
      </div>
    ),
  },
]

const transformFilterGroupToRowSelection = (filter?: FilterGroup) => {
  if (!filter) return {}
  return Object.fromEntries(filter.selectedItems.map((item) => [item.id.toString(), true]))
}
