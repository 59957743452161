import React from "react"
import { ExitIcon } from "@radix-ui/react-icons"
import { LuLayoutDashboard, LuPieChart, LuShield } from "react-icons/lu"
import TsisIcon from "@/svgs/tsis_icon"
import InsightsIcon from "@/svgs/insights_icon"
import ObservatoryIcon from "@/svgs/observatory_icon"
import AiProductSeriesIcon from "@/svgs/ai_product_series"
import DrilldownsIcon from "@/svgs/drilldown_icon"
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/ui/navigation_menu"
import { Card, CardContent, CardDescription, CardTitle } from "@/ui/card"
import { HiOutlineDocumentReport } from "react-icons/hi"
import { RiPieChart2Fill } from "react-icons/ri"
import { HiMiniMagnifyingGlass } from "react-icons/hi2"
import { NavbarProps } from "../navbar"
import { cn } from "@/lib/utils"

export default function DesktopMainNav(props: NavbarProps) {
  const activeLink = window.location.pathname
  const url = window.location.href
  const isTsisLink =
    ["/analyze", "/analyze/screeners"].includes(activeLink) || url.includes("/offerings?types[]=survey_report&subtypes[]=tsis")
  const isTsisReportLink = url.includes("/offerings?types[]=survey_report&subtypes[]=tsis")
  const isObservatoryLink = url.includes("/offerings?types[]=survey_report&subtypes[]=observatory")
  const isInsightsLink = url.includes("/offerings?types[]=insight")
  const isDrilldownsLink = url.includes("/offerings?types[]=survey_report&subtypes[]=drilldown")
  const isAiProductSeriesLink = url.includes("/offerings?types[]=survey_report&subtypes[]=ai_product_series")

  return (
    <NavigationMenu>
      <NavigationMenuList className="gap-6 hidden md:flex">
        <NavigationMenuItem>
          <NavigationMenuLink href={props.rootPath}>
            <img aria-label="ETR brand logo" src={props.logo}></img>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink
            className="items-center hidden font-bold text-sm text-white no-underline hover:underline md:flex"
            href={props.rootPath}
          >
            <LuLayoutDashboard className="inline mr-2 text-[#9DECF9]" />
            Dashboards
          </NavigationMenuLink>
        </NavigationMenuItem>

        <NavigationMenuItem>
          <NavigationMenuTrigger className="focus:bg-transparent focus:text-white focus:text-underline bg-transparent px-0 py-0 hover:text-white hover:bg-transparent data-[state=open]:bg-transparent data-[focus]:bg-transparent items-center hidden font-bold text-sm text-white no-underline hover:underline md:flex">
            <LuPieChart className="inline mr-2 text-[#9DECF9]" />
            Research
          </NavigationMenuTrigger>
          <NavigationMenuContent className="text-sm">
            <Card className="flex flex-row gap-3 p-3">
              <Card
                className={cn(
                  "bg-slate-100 p-4 min-w-[450px] flex flex-col gap-2 border-transparent hover:border-primary hover:bg-[#F0F7FE] ",
                  { "bg-[#F0F7FE] border-primary": isTsisLink }
                )}
              >
                <CardTitle className="flex flex-row items-center gap-2">
                  <TsisIcon className="w-7 h-7 bg-white p-1 rounded drop-shadow-md" />
                  <span>TSIS Spending Intentions</span>
                </CardTitle>
                <CardDescription>
                  Quarterly survey of IT decision makers capturing forward-looking spending intentions publicly.
                </CardDescription>
                <CardContent className="p-0 flex flex-col gap-1">
                  <NavigationMenuLink href="/offerings?types[]=survey_report&subtypes[]=tsis">
                    <Card
                      className={cn("p-2 text-primary flex flex-row items-center gap-1 border-transparent hover:border-primary ", {
                        "border-primary": isTsisReportLink,
                      })}
                    >
                      <span className="rounded-full p-2 bg-slate-100">
                        <HiOutlineDocumentReport />
                      </span>
                      <span>Reports</span>
                    </Card>
                  </NavigationMenuLink>
                  <NavigationMenuLink href="/analyze">
                    <Card
                      className={cn("p-2 text-primary flex flex-row items-center gap-1 border-transparent hover:border-primary", {
                        "border-primary": activeLink === "/analyze",
                      })}
                    >
                      <span className="rounded-full p-2 bg-slate-100">
                        <RiPieChart2Fill />
                      </span>
                      <span>Data Analyzer</span>
                    </Card>
                  </NavigationMenuLink>
                  <NavigationMenuLink href="/analyze/screeners">
                    <Card
                      className={cn("p-2 text-primary flex flex-row items-center gap-1 hover:border-primary border-transparent", {
                        "border-primary": activeLink === "/analyze/screeners",
                      })}
                    >
                      <span className="rounded-full p-2 bg-slate-100">
                        <HiMiniMagnifyingGlass />
                      </span>
                      <span>Screeners</span>
                    </Card>
                  </NavigationMenuLink>
                </CardContent>
              </Card>
              <div className="min-w-[300px] flex flex-col gap-3">
                <NavigationMenuLink href="/offerings?types[]=survey_report&subtypes[]=observatory">
                  <Card
                    className={cn(
                      "bg-slate-100 p-4 min-w-[300px] flex flex-col gap-2 border-transparent hover:border-primary hover:bg-[#F0F7FE]",
                      { "bg-[#F0F7FE] border-primary": isObservatoryLink }
                    )}
                  >
                    <CardTitle className="flex flex-row items-center gap-2">
                      <ObservatoryIcon className="w-7 h-7 bg-white p-1 rounded drop-shadow-md" />
                      <span>Observatory</span>
                    </CardTitle>
                    <CardDescription>
                      IT decision-maker usage of a vendor’s product and services within a specific sub-sector.
                    </CardDescription>
                  </Card>
                </NavigationMenuLink>
                <NavigationMenuLink href="/offerings?types[]=insight">
                  <Card
                    className={cn(
                      "bg-slate-100 p-4 min-w-[300px] flex flex-col gap-2 border-transparent hover:border-primary hover:bg-[#F0F7FE]",
                      { "bg-[#F0F7FE] border-primary": isInsightsLink }
                    )}
                  >
                    <CardTitle className="flex flex-row items-center gap-2">
                      <InsightsIcon className="w-7 h-7 bg-white p-1 rounded drop-shadow-md" /> <span>Insights</span>
                    </CardTitle>
                    <CardDescription>
                      Curated events and discussions designed to complement quantitative findings with qualitative reasoning.
                    </CardDescription>
                  </Card>
                </NavigationMenuLink>
              </div>
              <div className="min-w-[300px] flex flex-col gap-3">
                <NavigationMenuLink href="/offerings?types[]=survey_report&subtypes[]=drilldown">
                  <Card
                    className={cn(
                      "bg-slate-100 p-4 min-w-[300px] flex flex-col gap-2 border-transparent hover:border-primary hover:bg-[#F0F7FE]",
                      { "bg-[#F0F7FE] border-primary": isDrilldownsLink }
                    )}
                  >
                    <CardTitle className="flex flex-row items-center gap-2">
                      <DrilldownsIcon className="w-7 h-7 bg-white p-1 rounded drop-shadow-md" />
                      Drill Downs
                    </CardTitle>
                    <CardDescription>
                      Logic-triggered, or client commissioned studies with a targeted group of our IT decision makers.
                    </CardDescription>
                  </Card>
                </NavigationMenuLink>
                <NavigationMenuLink href="/offerings?types[]=survey_report&subtypes[]=ai_product_series">
                  <Card
                    className={cn(
                      "bg-slate-100 p-4 min-w-[300px] flex flex-col gap-2 border-transparent hover:border-primary hover:bg-[#F0F7FE]",
                      {
                        "bg-[#F0F7FE] border-primary": isAiProductSeriesLink,
                      }
                    )}
                  >
                    <CardTitle className="flex flex-row items-center gap-2">
                      <AiProductSeriesIcon className="w-7 h-7 bg-white p-1 rounded drop-shadow-md" />
                      <span>AI Product Series</span>
                    </CardTitle>
                    <CardDescription>
                      Analyzes the trajectory of AI spending and initial ROI assessments among enterprise IT buyers.
                    </CardDescription>
                  </Card>
                </NavigationMenuLink>
              </div>
            </Card>
          </NavigationMenuContent>
        </NavigationMenuItem>

        {props.adminPath && (
          <NavigationMenuItem>
            <NavigationMenuLink
              className="items-center hidden font-bold text-sm text-white no-underline hover:underline md:flex"
              href={props.adminPath}
            >
              <LuShield className="inline mr-2 text-[#9DECF9]" />
              Admin
            </NavigationMenuLink>
          </NavigationMenuItem>
        )}
        <NavigationMenuItem className="ml-auto">
          <NavigationMenuLink
            className="items-center ml-auto hidden font-bold text-sm text-white no-underline hover:underline md:flex"
            data-turbo-method="delete"
            href={props.destroyUserSessionPath}
          >
            Logout <ExitIcon className="ml-2" />
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  )
}
