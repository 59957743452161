import { getCuts } from "@/api/cuts"
import { getSurveyEnterpriseSizes } from "@/api/surveys/enterprise_sizes"
import { getTsisReasonings } from "@/api/tsis_reasonings"
import {
  FilterGroupKeys,
  FilterList,
  FilterListDropdown,
  RadioList,
  getSelectedRadioValue,
  useFilterGroupStore,
} from "@/components/filters"
import { WIDGET_ASPECT_RATIO, Widget, WidgetPropsBase } from "@/components/widget"
import { WithAspectRatio } from "@/components/with_aspect_ratio"
import { useChartLoading } from "@/hooks/use_chart_loading"
import { useDefaultFilter, useFilter } from "@/hooks/use_filter"
import { HighchartsReact as HC, Highcharts, defaultChartOptions } from "@/lib/highcharts"
import { Skeleton } from "@/ui/skeleton"
import { ArrowRightIcon, BarChartIcon } from "@radix-ui/react-icons"
import { useQuery } from "@tanstack/react-query"
import { parseISO } from "date-fns"
import { PointLabelObject } from "highcharts"
import { groupBy, round, uniqBy } from "lodash"
import React, { useEffect, useState } from "react"
import { IoMdArrowDown, IoMdArrowUp } from "react-icons/io"

const sortDateStrings = (a: string, b: string) => a.localeCompare(b)

export const AdoptionReplacingReasoningWidget = ({ ...props }: WidgetPropsBase) => {
  return (
    <Widget componentKey={"adoption_replacing_reasoning"} ignoredGlobalFilters={["sectors", "survey_dates"]} {...props}>
      <Widget.PrimaryFilters>
        <AdoptionReplacingReasoningPrimaryFilters />
      </Widget.PrimaryFilters>

      <Widget.SecondaryFilters>
        <AdoptionReplacingReasoningSecondaryFilters hiddenFilters={props.hiddenFilters} />
      </Widget.SecondaryFilters>

      <Widget.Body>
        <Content renderAspectRatioContainer={props.renderAspectRatioContainer} />
        {props.children}
      </Widget.Body>
    </Widget>
  )
}

const AdoptionReplacingReasoningPrimaryFilters = () => {
  const updateFilterGroups = useFilterGroupStore((s) => s.updateFilterGroups)
  const companies = useFilter("companies", { returnType: "multiple" })

  return (
    <>
      <FilterList
        name="Company"
        filterGroupId="companies"
        endpoint="/api/v1/companies"
        enableMultiRowSelection={false}
        onHandleFilterChange={(filterGroupId, items) => {
          updateFilterGroups({
            [filterGroupId]: {
              name: "Company",
              selectedItems: items,
            },
            products: {
              name: "Products",
              selectedItems: [],
            },
          })
        }}
      />
      <ArrowRightIcon className="hidden w-4 h-4 md:block text-muted-foreground align-self-center" />
      <FilterList
        name="Products"
        filterGroupId="products"
        endpoint="/api/v1/products"
        dependencies={["companies"]}
        params={{
          companies,
        }}
        formatter={(item) => ({
          ...item,
          subtitle: item.sector.name,
        })}
      />
    </>
  )
}

const AdoptionReplacingReasoningSecondaryFilters = ({ hiddenFilters }: Pick<WidgetPropsBase, "hiddenFilters">) => {
  const filters: Array<[FilterGroupKeys, React.ReactNode]> = [
    [
      "metrics",
      <RadioList
        size="sm"
        text="Metric"
        filterGroupId="metrics"
        items={METRIC_FILTER_OPTIONS}
        defaultSelection={METRIC_FILTER_OPTIONS.find((o) => o.id == METRICS.ADOPTION)!}
      />,
    ],
    [
      "cuts",
      <FilterListDropdown
        className="w-full text-sm"
        name="Cut"
        text="Cut"
        size="sm"
        filterGroupId="cuts"
        endpoint="/api/v1/cuts"
        enableMultiRowSelection={false}
      />,
    ],
  ]

  const filterComponents = filters
    .filter(([key, _component]) => !hiddenFilters?.includes(key))
    .map(([key, component]) => <React.Fragment key={key}>{component}</React.Fragment>)

  return <div className="flex items-center flex-grow space-x-4">{filterComponents}</div>
}

const METRICS = { ADOPTION: "ADOPTION", REPLACING: "REPLACING" }

const METRIC_FILTER_OPTIONS = [
  { name: "Adoption", id: METRICS.ADOPTION },
  { name: "Replacing", id: METRICS.REPLACING },
]

const REASON_NAMES = [
  "Feature Breadth",
  "Implementation Cost / Time to Value",
  "IT Skills / Talent Availability",
  "Other",
  "Product Cost / ROI",
  "Product Security",
  "Product Technical Capabilities",
  "Stack Alignment / Integrations",
  "Technological Lead / Lag",
  "Vendor Relationship / Support",
  "Vendor Roadmap / Vision",
]

interface SurveyReasonMap {
  [survey: string]: [string, number][]
}

type EnterpriseSizes = Map<string, number>

const initialReasonValues: [string, number][] = REASON_NAMES.map((r) => [r, 0])

const calculateReasonMetrics = (reasonsGrouped: SurveyReasonMap, enterpriseSizes: EnterpriseSizes) => {
  const surveys: {
    [k: string]: Map<string, number>
  } = {}

  for (let [survey, reasons] of Object.entries(reasonsGrouped)) {
    const reasonsMap = new Map(initialReasonValues)

    for (let [key, val] of reasonsMap) {
      const [_, rValue] = reasons.find(([k]) => k === key) || []
      const surveyN = enterpriseSizes.get(survey) || 0

      if (!rValue || !surveyN) {
        reasonsMap.set(key, val)
        continue
      }

      reasonsMap.set(key, round((rValue / surveyN) * 100, 2))
    }

    surveys[survey] = reasonsMap
  }

  return surveys
}

interface BreakdownProps {
  survey: {
    currentCount: number
    change: number
    enterpriseSizes: Record<string, any>
  }
  loading?: boolean
}

function Breakdown(props: BreakdownProps) {
  const { survey, loading = true } = props
  const changeIcon =
    survey.change > 0 ? <IoMdArrowUp />
    : survey.change < 0 ? <IoMdArrowDown />
    : null

  return (
    <div className="flex-none flex flex-col md:flex-row gap-3 mt-2">
      {loading ?
        Array.from({ length: 2 }).map((_, index) => (
          <Skeleton key={index} className="flex-1 rounded bg-slate-100 p-3 text-sm space-y-2 h-28" />
        ))
      : <>
          <div className="rounded bg-slate-100 w-full p-3 text-sm">
            <p className="text-slate-500">Participants</p>
            <p className="text-black text-xl font-semibold">N = {survey.currentCount}</p>
            <p className="flex items-center">
              <span className="mr-1 flex items-center">
                {survey.change.toFixed(1)}%{changeIcon}
              </span>{" "}
              From Previous
            </p>
          </div>
          <div className="rounded bg-slate-100 w-full p-3 text-sm">
            <p className="text-slate-500">Survey Composition</p>
            <div className="flex flex-col lg:flex-row lg:gap-2 text-slate-800">
              <div className="w-full">
                <div>
                  <b>{survey.enterpriseSizes?.fortune100}</b> Fortune 100(N)
                </div>
                <div>
                  <b>{survey.enterpriseSizes?.fortune500}</b> Fortune 500(N)
                </div>
                <div>
                  <b>{survey.enterpriseSizes?.fortune1000}</b> Fortune 1000(N)
                </div>
              </div>
              <div className="w-full">
                <div>
                  <b>{survey.enterpriseSizes?.global1000}</b> Global 1000(N)
                </div>
                <div>
                  <b>{survey.enterpriseSizes?.global2000}</b> Global 2000(N)
                </div>
                <div>
                  <b>{survey.enterpriseSizes?.giant_public_private}</b> Giant Public + Private(N)
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

function Content({ renderAspectRatioContainer }: Pick<WidgetPropsBase, "renderAspectRatioContainer">) {
  const metricsFilterGroup = useFilterGroupStore((s) => s.metrics)
  const company = useFilter("companies", { returnType: "single" })
  const products = useFilter("products", { returnType: "multiple" })
  const { value: cut } = useDefaultFilter("cuts", {
    queryFn: getCuts,
    select: (data: any) => data.filter((item: any) => item.name === "All Respondents"),
    returnType: "single",
  })

  const metric = getSelectedRadioValue(metricsFilterGroup?.selectedItems, {
    name: "Adoption",
    id: METRICS.ADOPTION,
  })

  const { data: reasonings, isLoading: isReasoningsLoading } = useQuery({
    queryKey: ["tsis_reasonings", company, cut, products, metric],
    queryFn: () => {
      return getTsisReasonings({
        company,
        cut,
        products,
        metric: metric.id,
      })
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!company && !!cut,
  })

  let surveys: any[] = []

  if (reasonings?.length) {
    surveys = uniqBy<any>(
      reasonings.map((r: any) => r.survey),
      "id"
    ).sort((surveyA, surveyB) => sortDateStrings(surveyA.date, surveyB.date))
  }

  const groupedBySurvey = groupBy(reasonings, (r) => r.survey.date)

  const sortedSurveyMap = new Map(Object.entries(groupedBySurvey).toSorted(([dateA], [dateB]) => sortDateStrings(dateA, dateB)))

  const surveyCounts = new Map()
  for (let [date, reasonings] of sortedSurveyMap) {
    surveyCounts.set(date, uniqBy(reasonings, "survey_respondent_id").length)
  }

  const { data: enterpriseSizes, isLoading: isEnterpriseSizesLoading } = useQuery({
    queryKey: ["survey_counts", company, cut, products, metric],
    queryFn: () => {
      return getSurveyEnterpriseSizes({
        survey: surveys.pop().id,
        company,
        products,
        metric: metric.id,
      })
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!surveys?.length,
  })

  const isLoading = isReasoningsLoading || isEnterpriseSizesLoading

  let surveyChange = 0
  let currentSurveyCount = 0
  let oldSurveyCount = 0
  if (surveyCounts.size > 1) {
    const values = surveyCounts.values()
    oldSurveyCount = values.next().value
    currentSurveyCount = values.next().value

    const diff = currentSurveyCount - oldSurveyCount
    surveyChange = (diff / oldSurveyCount) * 100
  } else {
    const values = surveyCounts.values()
    currentSurveyCount = values.next().value
  }

  const surveysUniqueRespondentsGroupedByReason: SurveyReasonMap = Object.fromEntries(
    Object.entries(groupedBySurvey).map(([key, values]: [string, any]) => [
      key,
      Object.entries(groupBy(values, (item) => item.reason.name)).map(([reason, values]) => [
        reason,
        uniqBy(values, "survey_respondent_id").length,
      ]),
    ])
  )

  const surveyReasonsCalculated = calculateReasonMetrics(surveysUniqueRespondentsGroupedByReason, surveyCounts)

  if (!company) {
    return (
      <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
        <div className="h-full flex flex-col items-center justify-center">
          <BarChartIcon className="h-48 w-48 text-muted" />
          <p className="text-muted-foreground text-sm">Please select a company to view the product area graph</p>
        </div>
      </WithAspectRatio>
    )
  }

  return !Object.keys(surveyReasonsCalculated)?.length && !isLoading ?
      <Widget.Empty />
    : <>
        <Breakdown
          survey={{
            currentCount: currentSurveyCount,
            change: surveyChange,
            enterpriseSizes,
          }}
          loading={isLoading}
        />
        <div className="relative flex-1">
          <WithAspectRatio ratio={WIDGET_ASPECT_RATIO} shouldWrap={renderAspectRatioContainer}>
            <AdoptionReplacingReasoningChart
              metric={metric.name as string}
              survey={{
                reasons: surveyReasonsCalculated,
                uniqueRespondentsGroupedByReason: surveysUniqueRespondentsGroupedByReason,
                enterpriseSizes: surveyCounts,
              }}
              loading={isLoading}
            />
          </WithAspectRatio>
        </div>
      </>
}

function getShortMonthYear(date: Date) {
  return new Intl.DateTimeFormat("en-US", {
    year: "2-digit",
    month: "short",
  }).format(date)
}

interface SurveyCalculations {
  reasons: Record<string, Map<string, number>>
  uniqueRespondentsGroupedByReason: SurveyReasonMap
  enterpriseSizes: EnterpriseSizes
}

interface AdoptionReplacingReasoningChartProps {
  metric: string
  survey: SurveyCalculations
  loading?: boolean
}

function AdoptionReplacingReasoningChart(props: AdoptionReplacingReasoningChartProps) {
  const { metric, survey, loading = true } = props

  const [options, setOptions] = useState<Highcharts.Options>({
    ...defaultChartOptions,
    chart: {
      ...defaultChartOptions.chart,
      type: "column",
    },
    xAxis: {
      crosshair: true,
      type: "category",
      reversedStacks: true,
    },
    yAxis: {
      labels: {
        format: "{value}%",
      },
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          color: "#000000",
          enabled: true,
          formatter: function () {
            return `${this.point.y}%`
          },
        },
      },
    },
    series: [],
    tooltip: {
      useHTML: true,
      formatter: function () {
        const point = this.point as PointLabelObject["point"] & { custom: any }
        const formatSurveyName = (dateString: string) => getShortMonthYear(parseISO(dateString))

        const currentSurveyName = formatSurveyName(point.custom.survey.name)

        return `<p><b>Reason: </b>${point.name}</p>
        <p><b>${currentSurveyName}: </b>${point.custom.value}%</p>
        ${Object.entries(point.custom.survey.uniqueRespondentsByReason as SurveyReasonMap)
          .toSorted(([a], [b]) => sortDateStrings(a, b))
          .map(([name, reason]) => `<p><b>${formatSurveyName(name)} Respondent: </b>${Object.fromEntries(reason)[point.name] ?? 0}`)
          .join("")}
        `
      },
    },
  })

  const chartRef = useChartLoading(loading)

  useEffect(() => {
    if (!survey.reasons) return

    const surveyReasonsByLatestSurveyDate = Object.entries(survey.reasons).toReversed()
    const series = surveyReasonsByLatestSurveyDate.map(([surveyName, reasonMetrics], idx) => {
      const data = Array.from(reasonMetrics).map(([reason, value]) => ({
        name: reason,
        y: round(value),
        custom: {
          value: round(value, 1),
          survey: {
            name: surveyName,
            counts: survey.enterpriseSizes,
            uniqueRespondentsByReason: survey.uniqueRespondentsGroupedByReason,
          },
        },
      }))

      return {
        data: idx === 0 ? data.toSorted((a, b) => b.y - a.y) : data,
        name: getShortMonthYear(parseISO(surveyName)),
      }
    }) as Highcharts.SeriesOptionsType[]

    setOptions({
      ...options,
      legend: {
        reversed: true,
      },
      yAxis: {
        title: {
          text: metric,
        },
      },
      series,
    })
  }, [survey.reasons])

  return (
    <HC highcharts={Highcharts} options={options} containerProps={{ className: "absolute top-0 right-0 bottom-0 left-0" }} ref={chartRef} />
  )
}
