import request from "@/api/client"

export function getTsisUniqueRespondents({ cut, companies, metrics, products, sectors, surveys }) {
  return request({
    url: "/api/v1/tsis_responses/unique_respondents",
    method: "GET",
    params: {
      cut,
      companies,
      metrics,
      products,
      sectors,
      surveys,
    },
  })
}
