import { Widget, WidgetProps } from "@/components/widget"
import React from "react"

export const SampleWidget = (props: WidgetProps) => {
  return (
    <Widget {...props} menuActions={[]} hidePrimaryFilters>
      <Widget.Body>
        <Widget.Empty />
        {props.children}
      </Widget.Body>
    </Widget>
  )
}
