import React from "react"

export default function AnalyzeIdeaGeneratorIcon({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40" className={className}>
      <g clipPath="url(#clip0_531_108121)">
        <path
          fill="#CBD5E0"
          d="M29.355 25.86a16.25 16.25 0 10-3.492 3.495h-.003c.075.1.155.195.245.287l9.625 9.625a2.5 2.5 0 103.538-3.535l-9.626-9.624a2.505 2.505 0 00-.287-.25v.002zM30 16.25a13.75 13.75 0 11-27.5 0 13.75 13.75 0 0127.5 0z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_531_108121">
          <path fill="#fff" d="M0 0H40V40H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
